import React from "react";
import { Button } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import { helperFunctions as HF } from "../../../helper/helperFunctions";

class HashAddForm extends React.Component {
    constructor(props) {
        super(props);
        this.validatorForm = new SimpleReactValidator({
            autoForceUpdate: this,
            locale: "en"
        });
        this.state = {
            hash: {
                ...this.props.recPro
            },
            submitted: false,
            formType: this.props.formTypePro || ""
        };

        this.handleRec = this.handleRec.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.handleChangeCategory = this.handleChangeCategory.bind(this);
        this.handleRecSelect = this.handleRecSelect.bind(this);
    }
    handleChangeCategory(selectedOption, name) {
        console.log(selectedOption, name);
        if (name.action === "select-option") {
            this.setState(prevState => ({
                hash: {
                    ...prevState.hash,
                    categories: [
                        ...prevState.hash.categories,
                        name.option.value
                    ]
                }
            }));
        }
        if (name.action === "remove-value") {
            const catTemp = this.state.hash.categories;
            catTemp.splice(catTemp.indexOf(name.removedValue.value), 1);
            this.setState(prevState => ({
                hash: {
                    ...prevState.hash,
                    categories: catTemp
                }
            }));
        }
    }

    handleRecSelect(selectedOptionType, info) {
        if (info["action"] === "select-option") {
            let recState = { ...this.state.hash };
            recState[info["name"]] = selectedOptionType["value"];
            this.setState({ hash: recState });
        }
    }

    handleRec(e) {
        const { name, value, type } = e.target;
        const recState = { ...this.state.hash };
        recState[name] =
            type === "checkbox" ? (e.target.checked ? 1 : 0) : value;
        this.setState({ hash: recState });
    }
    handleSubmitForm(e) {
        e.preventDefault();
        if (this.validatorForm.allValid()) {
            this.setState({ submitted: true }, function() {
                this.props.submitForm(this.state.hash, this.state.formType);
            });
        } else {
            this.setState({ submitted: false });
            this.validatorForm.showMessages();
            this.forceUpdate();
        }
    }

    extractImageData = value => {
        const file = {};
        file["file"] = value["file"];
        file["name"] = value["name"];
        file["size"] = value["size"];
        file["extension"] = HF.returnExtFromImage(value["name"]);
        file["preview"] = value["preview"];
        file["type"] = value["type"];
        file["lastModified"] = value["lastModified"];
        return file;
    };

    render() {
        const { hash, formType } = this.state;
        const { categories } = this.props;
        console.log("categories", categories);
        return (
            <form onSubmit={this.handleSubmitForm}>
                {formType === "edit" && (
                    <input
                        type="hidden"
                        name="hash_id"
                        className="form-control"
                        value={hash.id}
                    />
                )}
                <div className="row">
                    <div className="col-md-8">
                        <div className="form-group">
                            <label htmlFor="title">
                                Title<span className="requiredClass">*</span>
                            </label>
                            <input
                                type="text"
                                name="title"
                                className="form-control"
                                value={hash.title}
                                onChange={this.handleRec}
                            />
                            {this.validatorForm.message(
                                "title",
                                hash.title,
                                "required||max:250"
                            )}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="">
                            <label className="container-check checkbox_down">
                                {" "}
                                Is Active?
                                <input
                                    type="checkbox"
                                    name="is_active"
                                    className="checkbox"
                                    value={hash.is_active ? 1 : 0}
                                    onChange={this.handleRec}
                                    checked={hash.is_active ? 1 : 0}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="name">
                                Select Category
                                <span className="requiredClass">*</span>
                            </label>
                            {/* <Select
                            value={HF.returnLabelsWithValues(hash.category_id, categories)}
                            onChange={this.handleChangeCategory}
                            options={categories}
                            isSearchable={true}
                            isMulti={false}
                            placeholder={'Select Category'}
                            isClearable={false}
                        /> */}
                            {/* {this.validatorForm.message('category_id', hash.category_id, 'required|min:1,array')} */}
                            <Select
                                value={HF.returnLabelWithValue(
                                    hash.category_id,
                                    categories
                                )}
                                onChange={this.handleRecSelect}
                                name="category_id"
                                options={categories}
                                isSearchable={true}
                                isMulti={false}
                                placeholder={"Select Category"}
                                isClearable={false}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="text-center">
                            <Button
                                type="submit"
                                bsSize="large"
                                bsStyle="info"
                                className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default "
                            >
                                Save
                            </Button>
                            <Button
                                bsSize="large"
                                bsStyle="info"
                                onClick={() => this.props.closeModel()}
                                className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info "
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default HashAddForm;

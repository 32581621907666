import { Roles } from "../constants/Roles";
export default class User {

    static user = localStorage.getItem('user') !== null ? JSON.parse(localStorage.getItem('user')).user : undefined;
    // static shipped;

    static isAuthenticated() {
        if (this.user !== undefined) {
            return true;
        } else {
            return false;
        }
    }

    static hasRole(roles) {
        if (this.user !== undefined && this.user.role) {
            if (roles.includes(this.user.role.name)) {
                return true
            } else {
                return false
            }
        }
    }

    static agency(prop) {
        if (this.isAuthenticated() && this.hasRole([...Object.values(Roles)])) {
            if (prop in this.user.agency) {
                return this.user.agency[prop];
            }
        }
    }

    static order(prop) {
        if (this.isAuthenticated() && this.hasRole([...Object.values(Roles)])) {
            if (prop in this.user.order) {
                return this.user.order[prop];
            }
        }
    }

    static brand(prop) {
        if (this.user === undefined || this.user.brand === undefined) {
            return null
        }
        if (prop in this.user.brand && this.hasRole([...Object.values(Roles)])) {
            return this.user.brand[prop];
        }
    }

    static getProperty(prop) {
        const param = prop.split('.');
        const parent = param[0];
        if (this.user === undefined || this.user[parent] === undefined) {
            return null;
        }
        if (1 in param) {
            const property = param[1];
            if (property in this.user[parent]) {
                return this.user[parent][property];
            }
        } else {
            if (parent in this.user) {
                return this.user[parent];
            }
        }

    }

    static logout() {
        localStorage.removeItem('user');
    }

}
import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import {RiVideoLine} from 'react-icons/ri';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import Confirmalertfordelete from '../../../ThemeComponents/confirmAlertForDelete';
import OpenModalButton from '../../../ThemeComponents/openModelButton';
import { helperFunctions as HF } from '../../../../helper/helperFunctions';
import Pagination from '../../../ThemeComponents/Pagination';
import { cardService } from '../../../../services/card';
import { cartService } from '../../../../services/cart';

import ReactValidator from "simple-react-validator";
import "react-datepicker/dist/react-datepicker.css";
var cloneDeep = require('lodash.clonedeep');
var Modal = require('react-bootstrap-modal');
const moment = window.moment;

class OrderItemListingPage extends Component{
  constructor(props){
  	super(props);
    const { id } = this.props.match.params;
    const user = JSON.parse(localStorage.getItem('user'));
  	this.state = {
  	  addModalForm  : false,
      editModalForm : false,
      openVideoModal: false,
      video_url:'',
      sorted   : '',
      filtered : '',
      pageSize : 10,
      order_id:id,
  	};

  	this.editRecord = this.editRecord.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
  	this.deleteConfirmModal = this.deleteConfirmModal.bind(this);
    this.getPaginationData  = this.getPaginationData.bind(this);
    this.filterSearchHandle     = this.filterSearchHandle.bind(this);
    this.callFunction= this.callFunction.bind(this);
    this.closeModal = HF.closeModal.bind(this);
    this.videoModal= this.videoModal.bind(this);
  };


  videoModal(url) {
    this.setState({video_url:url}, ()=>{
        this.setState({openVideoModal:true});
        console.log('url',this.state.video_url);
    });

  }

//   componentDidMount() {
//     this.getUsersCall();
//   }

//   getUsersCall(){
//     cardService.getUsers().then(res => {
//         console.log('Users',res);
//         this.setState({users:res.data});
//       });
//   }

  getDurationsCall(){
    cardService.getDuration().then(res => {
        console.log('Durations',res);
        this.setState({durations:res.data});
      });
  }


  callFunction(formData, formType) {
    if (formType === 'add'){
        this.setState({addModalForm:false});
        this.pagination.dataCall();
    }else if (formType === 'edit'){
        this.setState({editModalForm:false});
        this.pagination.dataCall();
    }
  }

  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }

  editRecord(id)
  {
    cardService.getCard(id).then(res => {
        console.log(res);
        this.setState({editRec:res.data}, ()=>{
            this.setState({editModalForm:true});
        });
      });
  }

  deleteRecord(recID)
  {
    cardService.deleteCard(recID).then(res => {
      this.pagination.dataCall();
    });
  }

  deleteConfirmModal(recID)
  {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Record" description="Are you sure to delete the Record?" onClosePro={onClose} deleteType={() => this.deleteRecord(recID)} />
        )
      }
    })
  }


getPaginationData(page, filter, sort, pageSize, status)
{
    this.setState({ pageSize: pageSize }, function () {
      cartService.paginationData(this.state.userID, page, filter, sort, pageSize, status,this.state.search);
    });
}

updateStatus = recID => (e) => {
// return false;
cardService.updateCardStatus(recID, { set_default: e.target.checked ? 1 : 0 }).then(
    response => {
    cardService.paginationData(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status,this.state.search);
    this.pagination.dataCall();
    }
);
}


  componentDidUpdate(prevProps, prevState)
  {
    if (this.state.rec_status !== prevState.rec_status) {
      this.table.state.onFetchData(this.table.state, this.table.instance)
    }

  }

  paginationCall = (data) => {
    return cartService.paginationDataOrderItem(this.state.order_id, data.page, data.filter, data.sort, data.pageSize,this.state.rec_status,this.state.search);
  }

  render()
  {
  	const { filtered,editRec,durations } = this.state;
    const columns = [
        {
          Header:"Cards Listing",
          headerClassName:'headerPagination',
          columns: [
           
            {
                Header: "Caret Title",
                accessor: "caret_title",
                sortable: false
            },
            {
                Header: "Amount",
                accessor: "subscription_amount",
                sortable: false
            },
          //   {
          //     Header: "Duration",
          //     accessor: "interval_count",
          //     sortable: false
          // },

          {
            Header: "Plan Type",
            accessor: "subscription_pricing_id",
            Cell: ({ original }) => (
              original.pricing ? (
                <div>{original.pricing.title}</div>
              ) : (
                <div>No Plan</div>
              )
            ),
            className: "",
            sortable: false
          },
          
            {
                Header: "Status",
                accessor: "subscription_status",
                sortable: false
            },
         
           
            
            // {
            //   Header:"Actions",
            //   accessor: "id",
            //   Cell: ({row}) => (
            //     <div>

            //     <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Delete Card</Popover>}>
            //       <span>
            //       {
            //         <button type='button' className="deleteIcon orderDetailsAction" onClick={() => this.deleteConfirmModal(row.id)}>&nbsp;</button>
            //       }
            //       </span>

            //     </OverlayTrigger>
            //     </div>
            //     ),
            //   className: "justifyEnd text-right",
            //   headerClassName: "justifyEnd",
            //   sortable:false
            // }


          ]
        }
    ];

  	return(
  		<div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                	<div>
                  	<Grid fluid>
                      <Row>
                          <Col md={6}>
                            <div className="secHeading">Order Item Listing </div>
                          </Col>



                  
                          <Col md={12} className="mt-md">
                          <Pagination
                          ref={(p) => this.pagination = p}
                          showPagination={false}
                          columns={columns}
                          pageSize={20}
                          getDataCall={this.paginationCall}
                          filterView={true}
                          filterPlaceHolder={'Cards'}
                          defaultSorted={
                            [
                              {
                                id: 'id',
                                desc: true
                              }
                            ]
                          }
                          // downloadData={true}
                          // downloadFileName={'Orders'}
                          // lowerContent = {null}
                          // filterPlaceHolder = {'Orders'}
                          noDataText='No Record found'
                          getRowProps={this.getRowProps}
                        // showAllToggle={true}
                        />
                          </Col>
                      </Row>
                    </Grid>

          
                	</div>
                } />
            </Col>
          </Row>
        </Grid>
      </div>
  	);
  };
}
const OrderListing = OrderItemListingPage;
export default OrderListing;

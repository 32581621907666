import { SC } from "../helper/ServerCall";

export const hashService = {
    paginationData,
    storeHash,
    getHash,
    editHash,
    deleteHash,
    updateHashStatus,
    getCategories,
    getCatsPagination,
    storeCat,
    getCat,
    editCat,
    updateCatStatus,
    deleteCat
};
function paginationData(page, filter, sort, pageSize, status) {
    return SC.postCall(
        "hash/data?page=" +
            page +
            "&filter=" +
            filter +
            "&sort=" +
            sort +
            "&pageSize=" +
            pageSize
    );
}
// function paginationData(
//     user_id,
//     page,
//     filter,
//     sort,
//     pageSize,
//     status,
//     search = null
// ) {
//     const data = {
//         page: page,
//         filter: filter,
//         sort: sort,
//         pageSize: pageSize,
//         status: status,
//         search: search
//     };
//     return SC.postCall("hash/data", data);
// }
function getCategories() {
    return SC.getCall("hash/categories");
}

function storeHash(catData) {
    return SC.postCall("hash/listing", catData);
}

function getHash(id) {
    return SC.getCall("hash/listing/" + id);
}

function editHash(data, HashId) {
    data["sound_id"] = HashId;
    return SC.postCall("hash/listing", data);
}

function deleteHash(catId) {
    return SC.deleteCall("hash/listing/" + catId);
}

function updateHashStatus(catId, data) {
    data["hash_id"] = catId;
    return SC.postCall("hash/status", data);
}

function getCatsPagination(page, filter, sort, pageSize, status) {
    return SC.getCall(
        "hash/categories?page=" +
            page +
            "&filter=" +
            filter +
            "&sort=" +
            sort +
            "&pageSize=" +
            pageSize
    );
}

function storeCat(catData) {
    return SC.postCall("hash/addCategory", catData);
}
function getCat(catId) {
    return SC.getCall("hash/category/" + catId);
}
function updateCatStatus(catId, data) {
    data["category_id"] = catId;
    return SC.postCall("hash/category/status", data);
}

function deleteCat(catId) {
    return SC.getCall("hash/category/delete/" + catId);
}

function editCat(catData, userId) {
    return SC.postCall("hash/addCategory", catData);
}

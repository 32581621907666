import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { advertiserUserActions } from 'redux/actions/advertiser-user-action';
import { CLMUserActions } from 'redux/actions/CLM-user-action';
import appBackgourndImg from "assets/img/logo_frontEnd.png";
import MessageCom from '../../layouts/Advertiser/Message';
import caretFooterLogo from '../../assets/img/logo_white.png'
import user from '../../assets/img/user.png'
import { Button } from 'reactstrap';
class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    var checkLogin = localStorage.getItem('user');
    if (checkLogin) {
      localStorage.removeItem('user');
      // this.props.dispatch(advertiserUserActions.logout());
    }

    this.state = {
      email: '',
      password: '',
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    console.log("asdfkjhasdkf askdfh ");
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { email, password } = this.state;
    const { dispatch } = this.props;
    if (email && password) {
      dispatch(CLMUserActions.login(email, password));
    }
  }
  render() {
    const { email, password, submitted } = this.state;
    const { loggingIn } = this.props;
    return (
      <Fragment>
        <div className='fixLoginWrap'>
          <div className="yellowBar" />
          <div className="main_login">
            <MessageCom />
            <p className="text-center logo_container">
              <img className="img-responsive logoImageLogin" src={appBackgourndImg} alt="backGroundImage" /> </p>
            <div className="login_form_container">
              <div className="login_form">
                <h1 className="text-center testrrr text-white"  >Please check your mail box to verify email address.</h1>
                {/* <form name="form" onSubmit={this.handleSubmit}>
                  <div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                    <input type="email" placeholder="Enter Your Email..." className="form-control email_ctrl" name="email" value={email} onChange={this.handleChange} />
                    {submitted && !email &&
                      <div className="help-block">Email is required.</div>
                    }
                  </div>
                  <Button color="primary" block className='roundedFull py-1 mb-1'>Reset Password</Button>
                  <div className='flexElem alignCenter text-white'>
                    <span className='ml-auto'>
                      <a href='/Advertiser-Login' className='text-info'>Login</a>
                    </span>
                  </div>
                </form> */}
                <Button color="primary" href="/CLM-login" tag="a" block className='roundedFull py-1 mb-1'>Login</Button>
              </div>
            </div>
          </div>
          <div className='copyRights text-white flexElem alignCenter gap10p p-1'>
            <p className='font-weight-light small'>Copyright © 2023, Carets Corporation, All Rights Reserved.</p>
            <img src={caretFooterLogo} alt='footer logo' className='ml-auto' width={64} />
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.advertiserReducer;
  return {
    loggingIn
  };
}

const Login = connect(mapStateToProps)(VerifyEmail);
export default VerifyEmail;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Row,
  Col,
  // Tooltip,
  OverlayTrigger,
  // Button,
  Popover
} from "react-bootstrap";
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import { userActions } from '../../../redux/actions/user-actions';
import { roleActions } from '../../../redux/actions/role-actions';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import UserForm from "./UserForm";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { hasPermission } from 'helper/hasPermission';
import Confirmalertfordelete from '../../ThemeComponents/confirmAlertForDelete';
import { adminLabels } from '../../../redux/constant/admin-label-constant';
import OpenModalButton from '../../ThemeComponents/openModelButton';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import Pagination from '../../ThemeComponents/Pagination';

var Modal = require('react-bootstrap-modal');

class AdminUserListPage extends Component{
  constructor(props){
  	super(props);
  	this.state = {
  		addModalUser  : false,
      editModalUser : false,
      sorted   : '',
      filtered : '',
      pageSize : 10,
  	};

  	this.callEditUser = this.callEditUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  	this.deleteConfirmUser = this.deleteConfirmUser.bind(this);
    this.usersPaginationData  = this.usersPaginationData.bind(this);
    this.filterSearchHandle     = this.filterSearchHandle.bind(this);
    this.handleUserSubmit= this.handleUserSubmit.bind(this);
    this.props.getAllRolesCall();
  };
  handleUserSubmit(userData, formType) {
    if (formType === 'add')
      this.props.storeUserCall(userData);
    else if (formType === 'edit')
      this.props.editUserCall(userData, this.props.editUser.id);
  }

  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }
  callEditUser(id) {
    this.props.getUserCall(id);
  }

  deleteUser(userId) {
    this.props.deleteUserCall(userId).then(res => {
      this.pagination.dataCall();
    });
  }

  deleteConfirmUser(userId) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="User" description="Are you sure to delete the admin user?" onClosePro={onClose} deleteType={() => this.deleteUser(userId)} />
        )
      }
    })
  }

//   showPasswordModal = (userId) => {
//     this.props.showPasswordCall(userId).then(response => {
//       confirmAlert({
//         customUI: ({ onClose }) => {
//           return (
//             <ShowPassword description={`Password for ${response.user.username} : `} user={response.user} onClosePro={onClose} />
//           )
//         }
//       })
//     })
//   }

  usersPaginationData(page, filter, sort, pageSize, status) {
    this.setState({ pageSize: pageSize }, function () {
      this.props.getUsersCall(page, filter, sort, pageSize, status);
    })
  }

  handleUpdateUser = userId => (e) => {
    // return false;
    this.props.updateUserStatusCall(userId, { is_active: e.target.checked ? 1 : 0 }).then(
      response => {
        this.props.getUsersCall(this.props.current_page, '', '', this.state.pageSize, this.state.user_status);
        // this.props.usersPaginationData();
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.editUser !== this.props.editUser) && !this.props.loading) {
      this.setState({ editModalUser: true });
    }
    if ((prevProps.storedUser !== this.props.storedUser) && !this.props.loading) {
      this.setState({ addModalUser: false }, function () {
        this.props.getUsersCall(this.props.current_page, '', '', this.state.pageSize, this.state.user_status);
      });
    }
    if ((prevProps.updatedUser !== this.props.updatedUser) && !this.props.loading) {
      this.setState({ editModalUser: false }, function () {
        this.props.getUsersCall(this.props.current_page, '', '', this.state.pageSize, this.state.user_status);
      });
    }
    if ((prevProps.deleteUser !== this.props.deleteUser) && !this.props.loading) {
      this.props.getUsersCall(this.props.current_page, '', '', this.state.pageSize, this.state.user_status);
    }
    if (this.state.user_status !== prevState.user_status) {
      this.table.state.onFetchData(this.table.state, this.table.instance)
    }
  }

  paginationCall = (data) => {
    return this.props.getUsersCall(data.page, data.filter, data.sort, data.pageSize,this.state.user_status);
  }
  render(){
  	const { filtered } = this.state;
  	const { editUser, usersData, pages, loading, roles } = this.props;
    const columns = [
        {
          Header:"Users Listing",
          headerClassName:'headerPagination',
          columns: [
            {
              Header: "User Name",
              accessor: "name",
              className: "justifyStart text-left",
              headerClassName: "justifyStart text-left"
            },
            {
              Header: "Email",
              accessor: "email",
              minWidth: 200,
              className: "justifyStart text-left",
              headerClassName: "justifyStart text-left"
            },
            {
              Header: "Roles",
              accessor: "roles",
              Cell: ({value}) => (
                <div>
                  {
                    value.map((prop, key)=>{
                      return(<span className="badge badge-success" key={key}>{prop.name}</span>)
                    })
                  }
                </div>
                ),
              className: "action-center",
              sortable:false
            },
            {
              Header: "Image",
              accessor: "image",
              Cell: ({row}) => (
                <div>
                  {
                    row.image?<img className="initialsLogo" alt="initialLogo" src={row.image.preview} />:TF.initialsLogo(row.name)

                  }
                </div>
                ),
              className: "action-center"
            },
            {
              Header: "Status",
              accessor: "is_active",
              Cell: ({row}) => (
                  <label className="container-check">
                    &nbsp;
                    <input
                      type="checkbox" name="is_active" className="checkbox" value={row.is_active? 1 : 0} onChange={this.handleUpdateUser(row.id)}  checked={row.is_active?1:0}
                     /><span className="checkmark"></span>
                  </label>
                ),
              className: "action-center",
              sortable:false
            },
            {
              Header:"Actions",
              accessor: "id",
              Cell: ({value}) => (
                <div>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{ hasPermission('user-edit')?'Edit User': adminLabels.DONT_ALLOW  }</Popover>}>
                  <span>
                  {
                    hasPermission('user-edit') &&
                    <button type='button' className="editIcon orderDetailsAction" onClick={() => this.callEditUser(value)}>&nbsp;</button>
                  }
                  {
                    !hasPermission('user-edit') &&
                    <button type='button' className="editIcon orderDetailsAction button_disabled" >&nbsp;</button>
                  }
                  </span>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{ hasPermission('user-delete')?'Delete User': adminLabels.DONT_ALLOW  }</Popover>}>
                  <span>
                  {
                    hasPermission('user-delete') &&
                    <button type='button' className="deleteIcon orderDetailsAction" onClick={() => this.deleteConfirmUser(value)}>&nbsp;</button>
                  }
                  {
                    !hasPermission('user-delete') &&
                    <button type='button' className="deleteIcon orderDetailsAction button_disabled" >&nbsp;</button>
                  }
                  </span>

                </OverlayTrigger>
                </div>
                ),
              className: "justifyEnd text-right",
              headerClassName: "justifyEnd text-right",
              sortable:false
            }
          ]
        }
    ];

  	return(
  		<div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                	<div>
                  	<Grid fluid>
                      <Row>
                          <Col md={12}>
                            <div className="secHeading">Users List</div>
                          </Col>

                          <Col md={12}>
                            <div className="flexElem flexResponsive" style={{"alignItems": "flex-start", "justifyContent": "space-between"}}>
                              <div className="">
                                <OpenModalButton
                                  openModal={hasPermission('user-create')?HF.openModal.bind(this, "addModalUser"):null}
                                  classButton={['backButton pt-sm no_radius pb-sm primary mt-none btn-block btn-info btn', !hasPermission('user-create')?'button_disabled':'']}
                                  buttonName="Add User"
                                  tooltipText={hasPermission('user-create')?'Add User':adminLabels.DONT_ALLOW }
                                  classIcon={['fa', 'fa-plus']}
                                />
                              </div>


                            </div>
                          </Col>

                          <Col md={12} className="mt-md">
                          <Pagination
                          ref={(p) => this.pagination = p}
                          showPagination={false}
                          columns={columns}
                          pageSize={20}
                          getDataCall={this.paginationCall}
                          filterView={true}
                          filterPlaceHolder={'users'}
                          defaultSorted={
                            [
                              {
                                id: 'name',
                                desc: 'false'
                              },
                              {
                                id: 'is_active',
                                desc: 'false'
                              }
                            ]
                          }
                          // downloadData={true}
                          // downloadFileName={'Orders'}
                          // lowerContent = {null}
                          // filterPlaceHolder = {'Orders'}
                          noDataText='No user found'
                          getRowProps={this.getRowProps}
                        // showAllToggle={true}
                        />
                          </Col>
                      </Row>
                    </Grid>

                    {/*Add User Modal Start*/}
                    { roles !== null &&
                      <Modal backdrop={'static'} show={this.state.addModalUser} onHide={HF.closeModal.bind(this, "addModalUser")} aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                          <Modal.Title id='ModalHeader' className="headerTitle">Add User</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12">
                              <Card bsClass={["innerCard mb-none"]} content={
                                <UserForm
                                  closeModel={HF.closeModal.bind(this, "addModalUser")}
                                  formTypePro="add"
                                  userPro={OC.USER}
                                  handleUserSubmit= { this.handleUserSubmit }
                                  componentPro="adminUser"
                                  roles={roles}
                                />
                              } />
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    }

                    {/*Add User Modal End*/}

              		  {/*Edit User Modal start*/}
                    { editUser && roles !== null &&
                      <Modal backdrop={'static'} show={this.state.editModalUser} onHide={HF.closeModal.bind(this, "editModalUser")} aria-labelledby="ModalHeader" >
                      <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Edit User</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-md-12">
                          <Card bsClass={["innerCard mb-none"]} content={
                            <UserForm
                              closeModel={HF.closeModal.bind(this, "editModalUser")}
                              formTypePro="edit"
                              userPro={editUser}
                              handleUserSubmit= { this.handleUserSubmit }
                              roles={roles}
                            />
                          } />
                          </div>
                        </div>
                      </Modal.Body>
                      </Modal>
                    }
                    {/*Edit User Modal End*/}
                	</div>
                } />
            </Col>
          </Row>
        </Grid>
      </div>
  	);
  };
}


const mapDispatchToProps = dispatch => {
    return ({
      getUsersCall: (page, filter, sort, pageSize, status) => { return dispatch(userActions.getUsersPagination(page, filter, sort, pageSize, status)) },
      getUserCall: (id) => { dispatch(userActions.getUser(id)) },
      storeUserCall: (userData) => { dispatch(userActions.storeUser(userData)) },
      editUserCall: (userData, id) => { dispatch(userActions.editUser(userData, id)) },
      deleteUserCall: (id) => {return dispatch(userActions.deleteUser(id)) },
      getAllRolesCall: () => { dispatch(roleActions.getUserRoles()) },
      updateUserStatusCall: (id, data) => { return dispatch(userActions.updateUserStatus(id, data)) }

    });
  };

  function mapStateToProps(state) {
    const { editUser, storedUser, updatedUser, deleteUser } = state.userReducer;
    const { roles } = state.rolesReducer;
    return {
      storedUser, updatedUser, editUser, deleteUser, roles
    };
  }
  const AdminUsers = connect(mapStateToProps, mapDispatchToProps)(AdminUserListPage);
  export default AdminUsers;

import React, { Component, Fragment, useState } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import MessageCom from "layouts/Dashboard/Message";
import {
    Step,
    Stepper,
    StepLabel as span,
    StepContent as div,
    FormStep,
    FormStepper
} from "react-form-stepper";
import { frontEndUserActions } from "redux/actions/front-end-action";
import caretFooterLogo from "../../assets/img/logo_white.png";
import appBackgourndImg from "assets/img/logo_frontEnd.png";
import user from "../../assets/img/user.png";
import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    InputGroup,
    InputGroupText,
    FormText,
    Row,
    Col
} from "reactstrap";

import { appuserService } from "../../services/appuser";
import { Router } from "react-router";

import { helperFunctions as HF } from "../../helper/helperFunctions";
import { Card } from "components/Card/Card.jsx";

import AdPolicies from "./AdPolicies";
import AdTerms from "./AdTerms";
import caretLogo from "../../assets/img/caretLogo.svg";
import Appstore from "../../assets/img/appleLogo.svg";
import Playstore from "../../assets/img/playstore.svg";

import { withRouter } from "react-router-dom";
var Modal = require("react-bootstrap-modal");

class SignupPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            policyModal: "",
            termModal: "",
            formData: {
                terms: "",
                email: "",
                password: ""
            }
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.AdPoliciesFunc = this.AdPoliciesFunc.bind(this);
        this.AdTermsFunc = this.AdTermsFunc.bind(this);
    }

    AdPoliciesFunc(id) {
        this.setState({ policyModal: true });
    }
    AdTermsFunc(id) {
        this.setState({ termModal: true });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.setState({ submitted: true });
        const { history } = this.props;
        const { email, password, terms } = this.state.formData;
        if (email && password && terms) {
            appuserService.registerClmUser(this.state.formData).then(res => {
                if (res.error === true) {
                    Swal.fire({
                        title: "Alert",
                        icon: "info",
                        confirmButtonText: "Continue",
                        text: res.customMessage,
                        showCancelButton: true,
                        cancelButtonText: "Cancel",
                        didOpen: () => {},
                        didClose: () => {}
                    })
                        .then(result => {
                            if (result.isConfirmed) {
                                history.push(`/CLM-Login?role=clm`);
                            }
                        })
                        .catch();
                } else {
                    window.location.replace("/CLM-verify-email");
                }
            });
        }
    };
    handleChange = e => {
        const { name, value, type } = e.target;
        const recState = { ...this.state.formData };
        recState[name] =
            type === "checkbox" ? (e.target.checked ? 1 : 0) : value;
        this.setState({ formData: recState });
    };

    render() {
        const { email, password, terms, submitted } = this.state.formData;
        const { loggingIn } = this.props;
        return (
            <Fragment>
                <div className="fixLoginWrap">
                    <header>
                        <div className="py-50 px-1 bg-whiteF flexElem w-full alignCenter flexWrap">
                            <img
                                src={caretLogo}
                                alt={caretLogo}
                                height={36}
                                className="mx-auto mb-1 mb-md-0 ms-md-0"
                            />
                            <div className="ml-auto flexElem gap10p">
                                <a
                                    href="/CLM-login"
                                    className="flexElem text-white alignCenter py-50 px-2 roundedFull hover:scale-105 transition ease transform scale-100"
                                    style={{ background: "#12064b" }}
                                >
                                    Login
                                </a>

                                {/* <a href="https://play.google.com/store/apps/details?id=com.carets.tv" target="_blank" className="flexElem gap10p text-white py-50 px-2 roundedFull hover:scale-105 transition ease transform scale-100" style={{ background: '#12064b' }}>
                  <img src={Playstore} alt='Playstore' width={18} />
                  <span className='flexElem flexColumn'>
                    <small className='text-10'>Download from</small>
                    <span className='text-16'>Google Play</span>
                  </span>
                </a> */}
                            </div>
                        </div>
                    </header>
                    <div className="main_login w-100">
                        <div className="container">
                            {/* <div className='flexElem alignCenter justifyCenter'>
                <img src={caretFooterLogo} alt='footer logo' className='' width={100} />
              </div> */}
                            <h2 className="text-center text-white">
                                Sign Up for Carets
                            </h2>
                            <form
                                name="form"
                                className="signupForm w-100"
                                onSubmit={this.handleSubmit}
                            >
                                <Row>
                                    <Col md={12}>
                                        <div
                                            className={
                                                "form-group" +
                                                (submitted && !email
                                                    ? " has-error"
                                                    : "")
                                            }
                                        >
                                            <input
                                                type="text"
                                                placeholder="Enter Your Email..."
                                                className="form-control email_ctrl inputTransparentRound"
                                                name="email"
                                                value={email}
                                                onChange={this.handleChange}
                                            />
                                            {submitted && !email && (
                                                <div className="help-block">
                                                    Email is required.
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div
                                            className={
                                                "form-group" +
                                                (submitted && !password
                                                    ? " has-error"
                                                    : "")
                                            }
                                        >
                                            <input
                                                type="password"
                                                placeholder="Password"
                                                className="form-control password_ctrl inputTransparentRound"
                                                name="password"
                                                value={password}
                                                onChange={this.handleChange}
                                            />
                                            {submitted && !password && (
                                                <div className="help-block">
                                                    Password is required.
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup
                                            check
                                            inline
                                            className="text-white mb-1"
                                        >
                                            <Label
                                                check
                                                className="text-white"
                                                for="terms"
                                            >
                                                <Input
                                                    type="checkbox"
                                                    className="me-50"
                                                    name="terms"
                                                    value={terms ? 1 : 0}
                                                    onChange={this.handleChange}
                                                    checked={terms ? 1 : 0}
                                                />
                                                I agree with{" "}
                                                {/*to <a href='#' onClick={this.AdPoliciesFunc}>policies</a> and */}
                                                <a
                                                    href="#"
                                                    onClick={this.AdTermsFunc}
                                                >
                                                    terms & conditions
                                                </a>
                                            </Label>
                                            {!terms ? (
                                                <div className="text-danger small">
                                                    Please agree our terms and
                                                    conditions
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <Button
                                            onClick={this.handleSubmit}
                                            color="primary"
                                            className="roundedFull py-1"
                                            block
                                        >
                                            Signup
                                        </Button>
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </div>
                    <MessageCom />
                    <div className="copyRights text-white flexElem alignCenter gap10p p-1">
                        <p className="font-weight-light small">
                            Copyright © 2024, Carets Corporation, All Rights
                            Reserved.
                        </p>
                        <img
                            src={caretFooterLogo}
                            alt="footer logo"
                            className="ml-auto"
                            width={64}
                        />
                    </div>
                </div>

                {
                    <Modal
                        Modal
                        backdrop={"static"}
                        show={this.state.policyModal}
                        onHide={HF.closeModal.bind(this, "policyModal")}
                        aria-labelledby="ModalHeader"
                        className="modalLG modal-lg mx-auto"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title
                                id="ModalHeader"
                                className="headerTitle"
                            >
                                Policies
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-12">
                                    <Card
                                        bsClass={["innerCard mb-none"]}
                                        content={
                                            <AdPolicies
                                                closeModel={HF.closeModal.bind(
                                                    this,
                                                    "policyModal"
                                                )}
                                                componentPro="AdPolicies"
                                            />
                                        }
                                    />
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                }

                {
                    <Modal
                        backdrop={"static"}
                        show={this.state.termModal}
                        onHide={HF.closeModal.bind(this, "termModal")}
                        aria-labelledby="ModalHeader"
                        className="modalLG modal-lg mx-auto"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title
                                id="ModalHeader"
                                className="headerTitle"
                            >
                                Terms and Conditions for Advertising on Carets
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body
                            style={{ maxHeight: "80vh", overflowY: "auto" }}
                        >
                            {/* <h6>Revision: January 29, 2024</h6> */}
                            <p className="text-14">
                                Welcome to Carets. These Terms and Conditions
                                ("Terms") govern your placement of
                                advertisements on our platform. By submitting an
                                ad or advertising campaign, you ("Advertiser")
                                agree to be bound by these Terms.{" "}
                            </p>
                            <ol className="listNumber">
                                <li className="mt-1">
                                    General Provisions
                                    <ul className="listAplhaCap">
                                        <li>
                                            You must be at least 18 years old
                                            and have the legal authority to
                                            enter into this agreement.
                                        </li>
                                        <li>
                                            You are solely responsible for the
                                            content of your ads and any claims
                                            arising from them.
                                        </li>
                                        <li>
                                            Your ads must comply with all
                                            applicable laws and regulations,
                                            including advertising, intellectual
                                            property, data privacy, and consumer
                                            protection laws.
                                        </li>
                                        <li>
                                            You must not submit ads that are:
                                            <ul className="listAplha">
                                                <li>
                                                    False, misleading, or
                                                    deceptive.
                                                </li>
                                                <li>
                                                    Offensive, harmful, or
                                                    discriminatory.
                                                </li>
                                                <li>
                                                    Infringing on any
                                                    intellectual property
                                                    rights.
                                                </li>
                                                <li>
                                                    Illegal or in violation of
                                                    the Carets user guidelines.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            You will provide us with all
                                            necessary information and materials
                                            for verifying your identity and
                                            compliance with these Terms.
                                        </li>
                                        <li>
                                            We reserve the right to reject or
                                            remove any ad at any time and for
                                            any reason, without notice.
                                        </li>
                                        <li>
                                            If you are using Carets ad services
                                            as an agent for another individual
                                            or entity, then you represent and
                                            warrant that:
                                            <br />
                                            (a) you are authorized to, and do,
                                            bind that individual or entity to
                                            these terms and conditions.
                                            <br />
                                            (b) all of your actions in
                                            connection with these Terms are and
                                            will be within the scope of the
                                            agency relationship between you and
                                            that individual or entity, and in
                                            accordance with any applicable legal
                                            and fiduciary duties.{" "}
                                        </li>
                                        <li>
                                            H. Your use of Carets ad services
                                            will constitute acceptance of these
                                            Terms.{" "}
                                        </li>
                                    </ul>
                                </li>
                                <li className="mt-1">
                                    Advertising Formats and Specifications
                                    <ul className="listAplhaCap">
                                        <li>
                                            We offer various ad formats such as
                                            video, banner, and native ads. Refer
                                            to our advertising specifications
                                            for detailed requirements.
                                        </li>
                                        <li>
                                            You are responsible for ensuring
                                            that your ads meet all technical
                                            specifications including content,
                                            links, narrative, etc.
                                        </li>
                                        <li>
                                            We may modify ad formats and
                                            specifications at any time, with
                                            reasonable notice.
                                        </li>
                                    </ul>
                                </li>
                                <li className="mt-1">
                                    Licensing
                                    <ul className="listAplhaCap">
                                        <li>
                                            If your ad content contains
                                            copyrighted auditable or visual
                                            works then you agree that you have
                                            obtained all rights, licenses and
                                            permissions as may be necessary for
                                            such content to be incorporated
                                            within your ad content.{" "}
                                        </li>
                                        <li>
                                            If your ad content contains musical
                                            works, compositions or sound
                                            recordings not sourced from Carets,
                                            then you agree that you have
                                            obtained all rights, licenses and
                                            permissions as may be necessary for
                                            such music to be incorporated within
                                            your ad content. For example,
                                            ensuring that such music can remain
                                            on Carets for a specified period
                                            after the campaign end date for
                                            certain ad and campaign products.
                                        </li>
                                        <li>
                                            You grant license to Carets use ad
                                            content to:
                                            <br />
                                            (a) perform its obligations under
                                            these Terms, such as providing the
                                            ad services you have purchased;
                                            <br />
                                            (b) to comply with any legal or
                                            regulatory obligations to which
                                            Carets or its affiliates are subject
                                            or to assist with a lawful
                                            investigation;
                                            <br />
                                            (c) for testing and internal
                                            research and development purposes;
                                            <br />
                                            (d) with your prior consent, for
                                            external marketing purposes or
                                            external research and development;
                                            and
                                            <br />
                                            (e) to provide the transparency
                                            tools as described below.
                                        </li>
                                        <li>
                                            Carets may remove or restrict access
                                            to your ad content, if we have
                                            reason to believe
                                            <br />
                                            (i) you are in breach of these
                                            Terms; or
                                            <br />
                                            (ii) you cause, or may cause, harm
                                            to Carets, its users, or other third
                                            parties. If we remove or restrict
                                            access to your ad content, we will
                                            notify you without undue delay,
                                            unless it is not appropriate or
                                            legally prevented from doing so.{" "}
                                        </li>
                                        <li>
                                            By submitting your ad content, you
                                            grant Carets a non-exclusive,
                                            royalty-free, worldwide,
                                            transferable, sublicensable license
                                            to access, use, host, cache, store,
                                            display, publish, distribute,
                                            modify, and adapt ad content in
                                            order to develop, research, provide,
                                            promote, and improve Carets products
                                            and services.{" "}
                                        </li>
                                    </ul>
                                </li>
                                <li className="mt-1">
                                    Content Creators and Influencers
                                    <ul className="listAplhaCap">
                                        <li>
                                            You may choose to engage
                                            individuals, such as bloggers,
                                            influencers (directly or through
                                            third parties) or other content
                                            creators, such as creative agencies
                                            or production companies to edit,
                                            create, produce and/or distribute ad
                                            content either
                                            <br />
                                            (a) independently of Carets, or
                                            <br />
                                            (b) through a program or initiative
                                            operated by Carets in connection
                                            with its third party creative
                                            partners. In each case, you remain
                                            solely responsible for any and all
                                            content and messaging that you
                                            suggest, request, and/or require
                                            creators to include, or that you
                                            otherwise approve to be included, in
                                            an ad or campaign, which will form
                                            part of your advertising content.
                                            Creators own their submitted content
                                            unless negotiated separately between
                                            you and the relevant creators.
                                            Carets is an independent contractor
                                            and will not be deemed an agent of
                                            either creators or you, nor will
                                            Carets be liable for the acts or
                                            omissions of creators.
                                        </li>
                                    </ul>
                                </li>
                                <li className="mt-1">
                                    Targeting and Delivery
                                    <ul className="listAplhaCap">
                                        <li>
                                            We offer various targeting options
                                            based on demographics, interests,
                                            and user behavior.
                                        </li>
                                        <li>
                                            You are responsible for selecting
                                            the appropriate targeting options
                                            for your ads and campaigns.
                                        </li>
                                        <li>
                                            We may refuse to display your ads on
                                            certain categories of content or to
                                            certain audiences.
                                        </li>
                                        <li>
                                            We cannot guarantee the delivery of
                                            your ads to a specific number of
                                            users or at a specific time.
                                        </li>
                                        <li>
                                            We may refuse to display ads and
                                            campaigns that do not meet the
                                            requirements outlined in General
                                            Provisions.{" "}
                                        </li>
                                    </ul>
                                </li>
                                <li className="mt-1">
                                    Billing and Payment
                                    <ul className="listAplhaCap">
                                        <li>
                                            You will be responsible for all
                                            charges associated with your ad
                                            campaigns.
                                        </li>
                                        <li>
                                            We offer a fixed rate pricing model.
                                        </li>
                                        <li>
                                            You must provide a valid payment
                                            method and keep it updated.
                                        </li>
                                        <li>
                                            We will bill you according to your
                                            chosen pricing model upon approval
                                            of your submitted ad and advertising
                                            campaign.
                                        </li>
                                        <li>
                                            You are responsible for any taxes
                                            applicable to your ad campaigns.
                                        </li>
                                        <li>
                                            Payment triggers the activation of
                                            the ads and advertiser campaigns.
                                            Once payment is processed it is
                                            non-refundable.{" "}
                                        </li>
                                        <li>
                                            You must have legal authority to
                                            make payment on your entity’s
                                            behalf.{" "}
                                        </li>
                                    </ul>
                                </li>
                                <li className="mt-1">
                                    Data Privacy and Security
                                    <ul className="listAplhaCap">
                                        <li>
                                            You must comply with all applicable
                                            data privacy and security laws and
                                            regulations.
                                        </li>
                                        <li>
                                            You will not collect or use any user
                                            data from our platform without
                                            explicit written consent.
                                        </li>
                                        <li>
                                            We will not share any user data with
                                            you except as necessary to deliver
                                            your ads and conduct analytics.
                                        </li>
                                        <li>
                                            We have implemented appropriate
                                            security measures to protect user
                                            data.
                                        </li>
                                    </ul>
                                </li>
                                <li className="mt-1">
                                    Intellectual Property
                                    <ul className="listAplhaCap">
                                        <li>
                                            You own all intellectual property
                                            rights in your ads and the materials
                                            used in them.
                                        </li>
                                        <li>
                                            You grant us a non-exclusive,
                                            worldwide license to use, reproduce,
                                            promote, and display your ads on our
                                            platform.
                                        </li>
                                        <li>
                                            We own all intellectual property
                                            rights in our platform and brand.
                                            You will not infringe on our
                                            intellectual property rights.
                                        </li>
                                        <li>
                                            Advertiser acknowledges and agrees
                                            to all rights to Carets intellectual
                                            property, including the patented use
                                            of a ^Caret, like a #Hashtag, and
                                            agrees to the terms of use of the
                                            ^Caret in ads and campaigns.
                                            Advertiser may purchase a license
                                            agreement for designated and
                                            dedicated ^Carets for promotional
                                            and licensing purposes of content
                                            for promotion within and outside the
                                            Carets platform.{" "}
                                        </li>
                                    </ul>
                                </li>
                                <li className="mt-1">
                                    Disclaimer of Warranties
                                    <ul className="listAplhaCap">
                                        <li>
                                            We provide our platform and
                                            advertising services "as is" and
                                            without any warranties, express or
                                            implied.
                                        </li>
                                        <li>
                                            We do not warrant that our platform
                                            or advertising services will be
                                            uninterrupted, error-free, or
                                            secure.
                                        </li>
                                        <li>
                                            We will not be liable for any loss
                                            or damage arising from your use of
                                            our platform or advertising
                                            services.
                                        </li>
                                        <li>
                                            We do guarantee a minimum number of
                                            ad impressions or ad click through
                                            rate.{" "}
                                        </li>
                                    </ul>
                                </li>
                                <li className="mt-1">
                                    Indemnification
                                    <ul className="listAplhaCap">
                                        <li>
                                            You agree to indemnify and hold us
                                            harmless from any and all claims,
                                            losses, damages, liabilities, costs,
                                            and expenses (including attorney's
                                            fees) arising from or in connection
                                            with your ads or your breach of
                                            these Terms.
                                        </li>
                                    </ul>
                                </li>
                                <li className="mt-1">
                                    Limitation of Liability
                                    <ul className="listAplhaCap">
                                        <li>
                                            Our total liability to you for any
                                            claim arising from or in connection
                                            with these Terms will be limited to
                                            the amount you paid for advertising
                                            services during the preceding month.
                                        </li>
                                        <li>
                                            We will not be liable for any
                                            indirect, incidental, consequential,
                                            special, or punitive damages.
                                        </li>
                                        <li>
                                            Nothing in these terms will exclude
                                            or limit either party's liability
                                            for losses which may not be lawfully
                                            excluded or limited.
                                        </li>
                                        <li>
                                            Carets and its affiliates will not
                                            be liable to you for any:
                                            <br />
                                            (a) indirect, incidental, special,
                                            consequential, or punitive damages;
                                            or
                                            <br />
                                            (b) any loss of profits or revenues
                                            (whether incurred directly or
                                            indirectly), loss of data, use,
                                            goodwill, or intangible losses, even
                                            if Carets has been advised or should
                                            be aware of the possibility of any
                                            such losses arising.
                                        </li>
                                        <li>
                                            The maximum liability of Carets and
                                            its affiliates to you will not in
                                            aggregate exceed the greater of $250
                                            usd or the amounts paid by you to
                                            Carets, if any, within the 2 months
                                            before the claim arose.
                                        </li>
                                    </ul>
                                </li>
                                <li className="mt-1">
                                    Term and Termination
                                    <ul className="listAplhaCap">
                                        <li>
                                            These Terms will commence upon your
                                            first submission of an ad and will
                                            continue until terminated by either
                                            party.
                                        </li>
                                        <li>
                                            You may terminate these Terms at any
                                            time by removing your ads and
                                            ceasing to use our platform.
                                        </li>
                                        <li>
                                            We may terminate these Terms at any
                                            time and for any reason, with or
                                            without notice.
                                        </li>
                                    </ul>
                                </li>
                                <li className="mt-1">
                                    Governing Law and Dispute Resolution
                                    <ul className="listAplhaCap">
                                        <li>
                                            These Terms will be governed by and
                                            construed in accordance with the
                                            laws of the State of Utah, without
                                            regard to its conflict of laws
                                            principles.
                                        </li>
                                        <li>
                                            The laws of the state of Utah will
                                            govern these Terms and any claims
                                            and disputes (whether contractual or
                                            otherwise) arising out of or
                                            relating to these Terms or their
                                            subject matter.
                                        </li>
                                    </ul>
                                </li>
                                <li className="mt-1">
                                    Entire Agreement
                                    <ul className="listAplhaCap">
                                        <li>
                                            These Terms constitute the entire
                                            agreement between Advertiser and
                                            Carets with respect to advertising
                                            on the Carets platform.
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                        </Modal.Body>
                    </Modal>
                }
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { loading, frontEndUser, loggedIn } = state.frontEndUserReducer;
    return {
        loading,
        frontEndUser,
        loggedIn
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loginCall: (email, password) => {
            dispatch(frontEndUserActions.login(email, password));
        },
        logoutCall: () => {
            dispatch(frontEndUserActions.logout());
        }
    };
};

const Signup = connect(mapStateToProps, mapDispatchToProps)(SignupPage);
export default Signup;

import { SC } from "helper/ServerCall";

export const CLMUserService = {
    login,
    logout,
    dashboard,
    dashboardMenu
};
function login(email, password, role = null, keyword = null) {
    return SC.postCallLoginCLM("auth/clmlogin", { email, password, role, keyword });
}

function logout() {
    return SC.getCall("logout");
}

function dashboard() {
    return SC.getCall("dashboard");
}

function dashboardMenu() {
    return SC.getCall("getCLMMenu");
}

import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  // Tooltip,
  OverlayTrigger,
  // Button,
  Popover
} from "react-bootstrap";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import ReactTable from "react-table";
import { videoService } from '../../../services/video';
import { confirmAlert } from 'react-confirm-alert';
import Confirmalertcommon from '../../ThemeComponents/confirmAlertCommon';
import {RiVideoLine} from 'react-icons/ri';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
const cloneDeep = require('lodash.clonedeep');

var Modal = require('react-bootstrap-modal');

class CaretsPage extends Component{
  constructor(props){
  	super(props);
  	this.state = {
        data  : this.props.caretData,
        sorted   : '',
        filtered : '',
        pageSize : 10,
        video_url:'',
        openVideoModal: false,
      };
      console.log('state',this.state.selectedVideo)
    this.blockRecord = this.blockRecord.bind(this);
    this.deleteConfirmModal = this.deleteConfirmModal.bind(this);

    this.videoModal = this.videoModal.bind(this);
  };


  videoModal(url) {
    this.setState({video_url:url}, ()=>{
        this.setState({openVideoModal:true});
        console.log('url',this.state.video_url);
    });

  }

    blockRecord(recID)
    {
      videoService.blockRecord(recID).then(res => {
        this.props.closeModel();
      });
    }
    updateBlocked = (recID,rowID) => (e) => {
        // return false;
        videoService.blockRecord(recID).then(
            response => {
                let copy = cloneDeep(this.state.selectedVideo);
                copy.collages[rowID].videos = response.data;
                this.setState({selectedVideo:copy},()=>function(){
                    console.log('selectedVideo',this.state.selectedVideo);
                });
            }
        );
        }

    deleteConfirmModal(recID)
  {
      console.log('video',this.props.selectedVideo.id);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertcommon
          heading="Block Caret"
          description="Are you sure to change?"
          onClosePro={onClose}
          deleteType={() => this.blockRecord(this.props.selectedVideo.id)} />
        )
      }
    })
  }

  render()
  {
    console.log('selectedVideo',this.state.selectedVideo);


    const videoColumns = [
        {
          Header:"Listing",
          headerClassName:'headerPagination',
          columns: [
            {
                Header: "Video",
                accessor: "video_url",
                Cell: ({value}) => (
                    <div style={{cursor:'pointer',fontSize:'20px'}} onClick={()=>this.videoModal(value)}>
                        <RiVideoLine />
                    </div>
                ),

                className: "action-center"
            },

            {
                Header: "Details",
                accessor: "video_description",
                Cell: ({value}) => (
                  <div>
                    {
                      value
                    }
                  </div>
                  ),
                className: "action-center"
            },


          ]
        }
    ];


  	return(
  		<div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}></Col>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                	<div>
                  	<Grid fluid>
                      <Row>

                          <Col md={12} className="mt-md">

                          <ReactTable
                                title="Videos"
                                columns={videoColumns}
                                data={this.state.data}
                                pageSize={5}
                            />

                          </Col>

                      </Row>
                    </Grid>
                	</div>
                } />
            </Col>
          </Row>
          {
            <Modal backdrop={'static'} show={this.state.openVideoModal} onHide={HF.closeModal.bind(this, "openVideoModal")} aria-labelledby="ModalHeader" >
            <Modal.Header closeButton>
            <Modal.Title id='ModalHeader' className="headerTitle">Video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="row">
                <div className="col-md-12" style={{textAlignVertical: "center",textAlign: "center"}}>
                <video key={this.state.video_url} id="playVid" controls width="550" ><source src={this.state.video_url} type="video/mp4" /></video>
                </div>
            </div>
            </Modal.Body>
            </Modal>
        }

        </Grid>

      </div>
  	);
  };
}
const Carets = CaretsPage;
export default Carets;

import React from 'react';
import {
  Button
} from "react-bootstrap";


import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
import { helperFunctions as HF } from '../../../helper/helperFunctions';

class SettingForm extends React.Component {
	constructor(props){
		super(props);
		this.validatorForm = new SimpleReactValidator({autoForceUpdate: this, locale: 'en'});
		this.state = {
			setting: {
				...this.props.recPro,
			},
		    submitted: false,
		    formType: this.props.formTypePro 	|| '',
		};

		this.handleRec = this.handleRec.bind(this);
  		this.handleSubmitForm = this.handleSubmitForm.bind(this);
  		this.handleRecSelect = this.handleRecSelect.bind(this);
    }



    handleRecSelect(selectedOptionType, info) {
        if (info['action'] === 'select-option') {
          let settingsState = { ...this.state.setting };
          settingsState[info['name']] = selectedOptionType['value'];
          this.setState({setting: settingsState});
        }
    }

	handleRec(e){
	    const { name, value, type } = e.target;
        const recState = { ...this.state.setting };
	    recState[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
	    this.setState({setting: recState});
	}
	handleSubmitForm(e){
	    e.preventDefault();
	    if (this.validatorForm.allValid()) {
			this.setState({submitted: true}, function(){
				this.props.submitForm(this.state.setting, this.state.formType);
			});
		} else {
			this.setState({submitted: false});
			this.validatorForm.showMessages();
			this.forceUpdate();
		}
	}



	render(){
		const { setting,formType} = this.state;
		const { types } = this.props;
        console.log('types',types);
		return(
			<form onSubmit={this.handleSubmitForm}>
                 { formType === 'edit' &&
			        	<input type="hidden" name="setting_id" className="form-control"  value={setting.id}/>
			    }
			  <div className="row">
			    <div className="col-md-8">
					<div className='form-group'>
						<label htmlFor="name">Title<span className="requiredClass">*</span></label>
						<input type="text" name="name" className="form-control"  value={setting.name} onChange={this.handleRec} />
						{this.validatorForm.message('name', setting.name, 'required|min:3')}
					</div>
			    </div>
			    <div className="col-md-4">
	              <div className=''>
	                <label className="container-check checkbox_down"> Is Active?
	                  <input type="checkbox" name="is_active" className="checkbox" value={setting.is_active? 1 : 0} onChange={this.handleRec} checked={setting.is_active?1:0} />
	                  <span className="checkmark"></span>
	                </label>
	                {/* {this.validatorPermission.message('is_active', setting.is_active, 'required')} */}
	              </div>
	            </div>
			  </div>


                <div className="row">
                <div className="col-md-6">
					<div className='form-group'>
						<label htmlFor="value">Value</label>
						<input type="text" name="value" className="form-control"  value={setting.value} onChange={this.handleRec} />
						{/* {this.validatorForm.message('value', setting.value, '')} */}
					</div>
			    </div>
                
                    <div className="col-md-6">
                        <div className='form-group'>
                            <label htmlFor="types">Select Type<span className="requiredClass">*</span></label>
                            <Select
                                value={HF.returnLabelsWithValues(setting.setting_type, types)}
                                onChange={this.handleRecSelect}
                                name='setting_type'
                                options={types}
                                isSearchable={true}
                                isMulti={false}
                                placeholder={'Select type'}
                                isClearable={false}
                            />
                            {this.validatorForm.message('types', setting.setting_type, 'required|min:1,array')}
                        </div>
                    </div>
                </div>


			  <div className="row">
			    <div className="col-md-12">
			      <div className='text-center'>
			        <Button  type='submit' bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default " >Save</Button>
			        <Button bsSize="large" bsStyle="info" onClick={()=>this.props.closeModel() } className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info " >Cancel</Button>
			      </div>
			    </div>
			  </div>
			</form>
		);
	}
}


export default SettingForm;

import { SC } from '../helper/ServerCall';

export const planService = {
    paginationData,
    storePlan,
    getPlan,
    editPlan,
    deletePlan,
    updatePlanStatus,
    getDuration,
    blockRecord
}
function paginationData(page, filter, sort, pageSize,status,search=null) {
    const data = {'page': page,'filter': filter,'sort': sort,'pageSize': pageSize,'status': status,'search':search};
    return SC.getCall('plans/listing?page=' + page + '&filter=' + filter + '&sort=' + sort + '&pageSize=' + pageSize);
}
function getDuration() {
    return SC.getCall('plans/durations');
}
function storePlan(catData) {
    return SC.postCall('plans/listing', catData);
}

function getPlan(id) {

    return SC.getCall('plans/listing/' + id);
}

function editPlan(data, PlanId) {
    data['plan_id'] = PlanId;
    return SC.postCall('plans/listing', data);
}

function deletePlan(catId) {
    return SC.deleteCall('plans/listing/' + catId);
}

function updatePlanStatus(catId, data) {
    data['plan_id'] = catId;
    return SC.postCall('plans/listing/status',data);
}
function blockRecord(catId) {
    return SC.getCall('plans/block/status/'+catId);
}


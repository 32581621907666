import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { RiVideoLine } from 'react-icons/ri';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import CampaignForm from "./CampaignForm";
import CampaignForm2 from "./CampaignForm2";
import Confirmalertfordelete from '../../ThemeComponents/confirmAlertForDelete';
import OpenModalButton from '../../ThemeComponents/openModelButton';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import Pagination from '../../ThemeComponents/Pagination';
import { campaignService } from '../../../services/campaign';
import ReactValidator from "simple-react-validator";
import { Link } from 'react-router-dom';
import Eye from "../../../assets/img/eye.svg"
import Logo from "../../../assets/img/caretLogo.svg"

import "react-datepicker/dist/react-datepicker.css";
var cloneDeep = require('lodash.clonedeep');
var Modal = require('react-bootstrap-modal');
const moment = window.moment;

class CampaignListingPage extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props.match.params;
    const user = JSON.parse(localStorage.getItem('user'));
    this.state = {
      addModalForm: false,
      adsModalForm: false,
      cardsModalForm: false,
      editModalForm: false,
      openVideoModal: false,
      video_url: '',
      sorted: '',
      filtered: '',
      pageSize: 10,
      userID: user.data ? user.data.id : null,
      formStep: 0,
      currentCampaignID: 0,
      currentCampaign: [],
    };

    this.editRecord = this.editRecord.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
    this.deleteConfirmModal = this.deleteConfirmModal.bind(this);
    this.getPaginationData = this.getPaginationData.bind(this);
    this.filterSearchHandle = this.filterSearchHandle.bind(this);
    this.callFunction = this.callFunction.bind(this);
    this.closeModal = HF.closeModal.bind(this);
    this.videoModal = this.videoModal.bind(this);
  };


  videoModal(url) {
    this.setState({ video_url: url }, () => {
      this.setState({ openVideoModal: true });
      console.log('url', this.state.video_url);
    });

  }

  getDurationsCall() {
    campaignService.getDuration().then(res => {
      console.log('Durations', res);
      this.setState({ durations: res.data });
    });
  }


  callFunction(formData, formType) {

    if (formType === 'add') {
      this.setState({ addModalForm: false });
      //this.pagination.dataCall();
      console.log("formData", formData.data.id)
      this.setState({ formStep: 2 });
      this.setState({ currentCampaign: formData.data });
      this.setState({ currentCampaignID: formData.data.id });
      this.setState({ adsModalForm: true });

    } else if (formType === 'edit') {
      this.setState({ editModalForm: false });
      this.pagination.dataCall();

    } else if (formType === 'adsForm') {
      this.setState({ adsModalForm: false });
      this.pagination.dataCall();
    }
  }

  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }

  editRecord(id) {
    campaignService.getCampaign(id).then(res => {
      console.log(res);
      this.setState({ editRec: res.data }, () => {
        this.setState({ editModalForm: true });
      });
    });
  }

  deleteRecord(recID) {
    campaignService.deleteCampaign(recID).then(res => {
      this.pagination.dataCall();
    });
  }

  deleteConfirmModal(recID) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Record" description="Are you sure you want to delete this Record?" onClosePro={onClose} deleteType={() => this.deleteRecord(recID)} />
        )
      }
    })
  }


  getPaginationData(page, filter, sort, pageSize, status) {
    this.setState({ pageSize: pageSize }, function () {
      campaignService.paginationData(this.state.userID, page, filter, sort, pageSize, status, this.state.search);
    });
  }

  updateStatus = recID => (e) => {
    // return false;
    campaignService.updateCampaignStatus(recID, { is_active: e.target.checked ? 1 : 0 }).then(
      response => {
        campaignService.paginationData(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
        this.pagination.dataCall();
      }
    );
  }

  updateBlocked = recID => (e) => {
    // return false;
    campaignService.blockRecord(recID).then(
      response => {
        campaignService.paginationData(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
        this.pagination.dataCall();
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.rec_status !== prevState.rec_status) {
      this.table.state.onFetchData(this.table.state, this.table.instance)
    }

  }

  paginationCall = (data) => {
    return campaignService.paginationData(this.state.userID, data.page, data.filter, data.sort, data.pageSize, this.state.rec_status, this.state.search);
  }

  render() {
    const { filtered, editRec, durations } = this.state;
    const columns = [
      {
        Header: "Campaigns Listing",
        headerClassName: 'headerPagination',
        columns: [
          {
            Header: "Campaign Ads",
            accessor: "id",
            Cell: ({ value }) => {
              return <Link to={'/Advertiser/campaign-details/' + value} >
                <img src={Eye} alt='Logo' height={14} />
              </Link>
            },
            className: ""
          },
          {
            Header: "Campaign Title",
            accessor: "campaign_title"
          },
          {
            Header: "Audiance",
            accessor: "id",
            Cell: ({ value, original }) => (
              <div>
                {original.details.genders ? original.details.genders.title + " | " : ''}
                {
                  original.details.age ? original.details.age.title + " | " : ''
                }
                {original.details.location ? original.details.location : ''}
              </div>
            ),
          },

          {
            Header: "Edit Campaign Targets",
            accessor: "id",
            Cell: ({ row, value }) => (
              <div>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Edit Campaign</Popover>}>
                  <span>
                    {
                      <button type='button' className="editIcon orderDetailsAction" onClick={() => this.editRecord(row.id)}>&nbsp;</button>
                    }
                  </span>
                </OverlayTrigger>
               
              </div>
            ),
            className: "",
            headerClassName: "",
            sortable: false
          },
          {
            Header: "Status",
            accessor: "is_active",
            Cell: ({ row }) => (
              // <label className="container-check">
              <label className= {`${row.is_active ? "invoiceStatusActive" :  "invoiceStatus"}`}>
                {row.is_active ? "Active" : "In-Active"}
              </label>
            ),
            className: "",
            sortable: false
          },
          {
            Header: "Actions",
            accessor: "id",
            Cell: ({ row, value }) => (
              <div>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Delete Campaign</Popover>}>
                  <span>
                    {
                      <button type='button' className="deleteIcon orderDetailsAction" onClick={() => this.deleteConfirmModal(row.id)}>&nbsp;</button>
                    }
                  </span>

                </OverlayTrigger>
              </div>
            ),
            className: "justifyEnd text-right",
            headerClassName: "justifyEnd",
            sortable: false
          }


        ]
      }
    ];

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <div>
                <div>
                  <Grid fluid className='px-0F'>
                    <Row>
                      <Col md={9}>
                        <div className="secHeading">My Campaigns</div>
                      </Col>
                      <Col md={3}>
                        <Link to="/Advertiser/new-campaigns" className="text-uppercase btn btn-primary btn-block">CREATE A NEW CAMPAIGN</Link>
                      </Col>
                      <Col md={12} className="mt-md">
                        <Pagination
                          ref={(p) => this.pagination = p}
                          showPagination={false}
                          columns={columns}
                          pageSize={20}
                          getDataCall={this.paginationCall}
                          filterView={true}
                          filterPlaceHolder={'Campaigns'}
                          defaultSorted={
                            [
                              {
                                id: 'id',
                                desc: true
                              }
                            ]
                          }
                          // downloadData={true}
                          // downloadFileName={'Orders'}
                          // lowerContent = {null}
                          // filterPlaceHolder = {'Orders'}
                          noDataText='No Record found'
                          getRowProps={this.getRowProps}
                        // showAllToggle={true}
                        />
                      </Col>
                    </Row>
                  </Grid>

                  {
                    <Modal backdrop={'static'} show={this.state.addModalForm} onHide={HF.closeModal.bind(this, "addModalForm")} aria-labelledby="ModalHeader" >
                      <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Add Campaign</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-md-12">
                            <Card bsClass={["mb-none borderNone shadowNone"]} content={
                              <CampaignForm
                                closeModel={HF.closeModal.bind(this, "addModalForm")}
                                formTypePro="add"
                                recPro={OC.CAMPAIGN}
                                user_id={this.state.userID}
                                callFunction={this.callFunction}
                                componentPro="AdListing"
                              />
                            } />
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  }
                  {/*Add Record Modal End*/}

                  {/*Edit Record Modal start*/}
                  {editRec &&
                    <Modal backdrop={'static'} show={this.state.editModalForm} onHide={HF.closeModal.bind(this, "editModalForm")} aria-labelledby="ModalHeader" >
                      <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Edit Campaign Targets</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-md-12">
                            <Card bsClass={["mb-none borderNone shadowNone"]} content={
                              <CampaignForm
                                closeModel={HF.closeModal.bind(this, "editModalForm")}
                                formTypePro="edit"
                                recPro={editRec}
                                user_id={this.state.userID}
                                callFunction={this.callFunction}
                              />
                            } />
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  }

                  {this.state.formStep === 2 &&
                    <Modal backdrop={'static'} show={this.state.adsModalForm} onHide={HF.closeModal.bind(this, "addModalForm")} aria-labelledby="ModalHeader" >
                      <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Ads for "{this.state.currentCampaign.campaign_title}" Campaign</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-md-12">
                            <Card bsClass={["mb-none borderNone shadowNone"]} content={
                              <CampaignForm2
                                closeModel={HF.closeModal.bind(this, "adsModalForm")}
                                formTypePro="adsForm"
                                recPro={OC.CAMPAIGNADS}
                                user_id={this.state.userID}
                                campaign_id={this.state.currentCampaignID}
                                callFunction={this.callFunction}
                                componentPro="AdListing"
                              />
                            } />
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  }


                  {
                    <Modal backdrop={'static'} show={this.state.openVideoModal} onHide={HF.closeModal.bind(this, "openVideoModal")} aria-labelledby="ModalHeader" >
                      <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Ad</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-md-12" style={{ textAlignVertical: "center", textAlign: "center" }}>
                            <video key={this.state.video_url} id="playVid" controls style={{ width: 'auto', maxHeight: 'calc(100vh - 150px)' }} ><source src={this.state.video_url} type="video/mp4" /></video>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  }
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };
}
const CampaignListing = CampaignListingPage;
export default CampaignListing;

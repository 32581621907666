import React from 'react';
import {
  Button
} from "react-bootstrap";

import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import FileUpload from "components/Upload/FileUpload";
import ImagePreview from "components/Upload/ImagePreview";
import fileUploadIcon from "../../../assets/img/upload.svg";


class AppuserForm extends React.Component {
	constructor(props){
		super(props);
		this.validatorUser = new SimpleReactValidator({autoForceUpdate: this, locale: 'en'});
		this.state = {
			user: {
				...this.props.userPro,
				password:'',
				confirm_password:''
			},
			errorImg:'',
		    submitted: false,
		    formType: this.props.formTypePro 	|| '',
			allowedFormatsStringMime : 'image/jpeg, image/png',
			allowedFormatsStringExt : 'jpg, png',
			imagePreviewUrl:this.props.userPro && this.props.userPro.profile ? this.props.userPro.profile.user_photo : null,
			isDragging: false,

		};

		this.handleUser = this.handleUser.bind(this);
		this.handleProfile = this.handleProfile.bind(this);
  		this.handleUserSubmitForm = this.handleUserSubmitForm.bind(this);
  		this.handleChangeRole = this.handleChangeRole.bind(this);
	}
	handleChangeRole(selectedOption, name){
		if (name.action === 'select-option'){
			this.setState(prevState => ({
			    user: {
			        ...prevState.user,
			        roles:  [...prevState.user.roles, name.option.value]
			    }
			}));
		}
		if (name.action === 'remove-value'){
			const rolesTemp = this.state.user.roles;
			rolesTemp.splice(rolesTemp.indexOf(name.removedValue.value), 1);
			this.setState(prevState => ({
				user: {
			        ...prevState.user,
			        roles: rolesTemp
			    }
			}))
		}
	}
	handleUser(e){
	    const { name, value, type } = e.target;
        const userState = { ...this.state.user };
	    userState[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
	    this.setState({user: userState});
	}
	handleProfile(e){
	    const { name, value, type } = e.target;
        const userState = { ...this.state.user };
	    userState.profile[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
	    this.setState({user: userState});
	}
	handleUserSubmitForm(e){
	    e.preventDefault();
	    if (this.validatorUser.allValid()) {
			this.setState({submitted: true}, function(){
				this.props.handleUserSubmit(this.state.user, this.state.formType);
			});
		} else {
			this.setState({submitted: false});
			this.validatorUser.showMessages();
			this.forceUpdate();
		}
	}
	extractImageData =  (value) =>  {
		const file = {};
		file['file'] = value['file'];
		file['name'] = value['name'];
		file['size'] = value['size'];
		file['extension'] = HF.returnExtFromImage(value['name']);
		file['preview'] = value['preview'];
		file['type'] = value['type'];
		file['lastModified'] = value['lastModified'];
		return file;
	}
	setResourceFile = (key, value) => {
		this.setState({
			user: {...this.state.user, image:this.extractImageData(value) },
		});
	}
	removeFile = (key, index) =>{
		this.setState({
			user: {...this.state.user, image:null },
		});
	}
	setImageFile = (event) => {
		const file = event.target.files[0];
		const fileData = {};
		fileData["name"] = file["name"];
		fileData['size'] = file['size'];
		fileData['extension'] = HF.returnExtFromImage(file['name']).toLowerCase();
		fileData['type'] = file['type'];
		fileData['lastModified'] = file['lastModified'];
		const reader = new FileReader();

		// Define allowed extensions
		const allowedExtensions = ['png', 'jpeg', 'jpg', 'gif', 'heic'];
		const fileExtension = fileData['extension']; // Get the extension
		// Check if the file extension is allowed
		if (!allowedExtensions.includes(fileExtension)) {
			this.setState({ errorImg: 'Only PNG, JPEG, JPG, and GIF, images are allowed.' });
			return;
		}
		reader.onload = () => {
			fileData['file'] = reader.result;
			const imagePreviewUrl = URL.createObjectURL(file);
      this.setState({magePreviewUrl:null, errorImg: null})
			fileData['preview'] = imagePreviewUrl;
			this.setState({
				imagePreviewUrl: imagePreviewUrl,
				// imageFile: fileData,
				audio: { ...this.state.audio, image: fileData },
			});
		};
		reader.readAsDataURL(file);
	};
	handleDragOver = (event) => {
		event.preventDefault();
		this.setState({ isDragging: true });
	};

	handleDragLeave = () => {
		this.setState({ isDragging: false });
	};

	handleDrop = (event) => {
		event.preventDefault();		
		const file = event.dataTransfer.files[0];
		if (file) {
			this.setImageFile({ target: { files: [file] } });
		}
		this.setState({ isDragging: false });
	};

	render(){
		const { user, formType, allowedFormatsStringMime, allowedFormatsStringExt, isDragging } = this.state;
		const { roles } = this.props;
		return(
			<form onSubmit={this.handleUserSubmitForm}>
			  <div className="row">
			    <div className="col-md-6">
					<div className='form-group'>
						<label htmlFor="name">User Name<span className="requiredClass">*</span></label>
						<input type="text" name="name" className="form-control"  value={user.name} onChange={this.handleUser} />
						{this.validatorUser.message('userName', user.name, 'required|min:5')}
					</div>
			    </div>
			    <div className="col-md-6">
					<div className='form-group'>
						<label htmlFor="email">Email<span className="requiredClass">*</span></label>
						<input type="text" name="email" className="form-control email_icon" value={user.email} onChange={this.handleUser} readOnly={formType === 'edit'?true:false} />
						{this.validatorUser.message('email', user.email, 'required|email')}
					</div>
			    </div>
			  </div>
			  <div className="row">
			    <div className="col-md-6">
			      <div className='form-group'>
			        <label htmlFor="password">Password<span className="requiredClass">*</span></label>
			        <input type="password" autoComplete="off" name="password" className="form-control password_icon" value={user.password}  onChange={this.handleUser} />
			        { formType === 'add' &&
			        	this.validatorUser.message('password', user.password, 'required|min:5')
			        }
			      </div>
			    </div>
			    <div className="col-md-6">
			      <div className='form-group'>
			        <label htmlFor="confirm_password">Confirm Password<span className="requiredClass">*</span></label>
			        <input type="password" autoComplete="off" name="confirm_password" className="form-control password_icon" value={user.confirm_password}  onChange={this.handleUser}/>
			        { formType === 'add' &&
			        	this.validatorUser.message('confirm_password', user.confirm_password, 'required|min:5')
			      	}
			      </div>
			    </div>
			  </div>

              <div className="row">
			    <div className="col-md-4">
			      <div className='form-group'>
			        <label htmlFor="first_name">First Name<span className="requiredClass">*</span></label>
			        <input type="text" autoComplete="off" name="first_name" className="form-control" value={user.profile?user.profile.first_name:''}  onChange={this.handleProfile} />
			        { formType === 'add' &&
			        	this.validatorUser.message('first_name', user.profile.first_name, 'required|min:5')
			        }
			      </div>
			    </div>
			    <div className="col-md-4">
			      <div className='form-group'>
			        <label htmlFor="last_name">Last Name<span className="requiredClass">*</span></label>
			        <input type="text" autoComplete="off" name="last_name" className="form-control" value={user.profile?user.profile.last_name:''}  onChange={this.handleProfile}/>
			        { formType === 'add' &&
			        	this.validatorUser.message('last_name', user.profile.last_name, 'required|min:5')
			      	}
			      </div>
			    </div>

                <div className="col-md-4">
			      <div className='form-group'>
			        <label htmlFor="phone">Phone<span className="requiredClass">*</span></label>
			        <input type="text" autoComplete="off" name="phone" className="form-control" value={user.phone}  onChange={this.handleUser}/>
			        { formType === 'add' &&
			        	this.validatorUser.message('phone', user.phone, 'required|min:5')
			      	}
			      </div>
			    </div>


			  </div>

              <div className="row">
			    <div className="col-md-10">
			      <div className='form-group'>
			        <label htmlFor="user_bio">Bio<span className="requiredClass">*</span></label>
			        <textarea name="user_bio" className="form-control"  onChange={this.handleProfile}>
                    {user.profile?user.profile.user_bio:''}
                    </textarea>
			        { formType === 'add' &&
			        	this.validatorUser.message('user_bio', user.profile.user_bio, 'required|min:5')
			        }
			      </div>
			    </div>
                <div className="col-md-2">
	              <div className=''>
	                <label className="container-check checkbox_down"> Is Active?
	                  <input type="checkbox" name="is_active" className="checkbox" value={user.is_active? 1 : 0} onChange={this.handleUser} checked={user.is_active?1:0} />
	                  <span className="checkmark"></span>
	                </label>
	                {/* {this.validatorPermission.message('is_active', user.is_active, 'required')} */}
	              </div>
	            </div>
			  </div>

			  <div className="row">

                <div className="col-md-4">
			      <div className='form-group'>
			        <label htmlFor="dob">DOB<span className="requiredClass">*</span></label>
			        <input type="date" autoComplete="off" name="dob" className="form-control" value={user.profile?HF.dateFormatSystem(user.profile.dob):null}  onChange={this.handleProfile}/>
			        { formType === 'add' &&
			        	this.validatorUser.message('dob', user.profile.dob, 'required|min:5')
			      	}
			      </div>
			    </div>

			  	<div className="col-md-4">
			      <div className='form-group'>
			      	<label htmlFor="name">Select Role<span className="requiredClass">*</span></label>
			      	<Select
						value={HF.returnLabelsWithValues(user.roles, roles)}
						onChange={this.handleChangeRole}
						options={roles}
						isSearchable={true}
						isMulti={true}
						placeholder={'Select Role'}
						isClearable={false}
	                />
	                {this.validatorUser.message('roles', user.roles, 'required|min:1,array')}
			      </div>
			     </div>
			  	<div className="col-md-4 pofilePicContainer">
				  {/* {!this.state.user.image ?
			      <div className='form-group dropzone_profileImage'>

					<FileUpload
						files={this.state.user.image}
						setResourceFile={this.setResourceFile}
						allowedFormatsStringMimePro={allowedFormatsStringMime}
						allowedFormatsStringExtPro={allowedFormatsStringExt}
						multiple={false}
					/>
			      </div>
				  :
				  <ImagePreview
					  filesToPreview={this.state.user.image}
					  removeFile={this.removeFile}
				  />
				  } */}
					<div className=''>
						<label>Select Image</label>
						{/* <div className='uploadBtn'> */}
							<div
							className={`uploadBtn ${isDragging ? 'dragging' : ''}`}
							onDragOver={this.handleDragOver}
							onDragLeave={this.handleDragLeave}
							onDrop={this.handleDrop}
							>
							<label for="upload-image" id='upload-image-btn'>
							<img src={fileUploadIcon} alt={fileUploadIcon} width={20} />
							<span id="text">
								{this.state.imageFile ? this.state.imageFile.name : "Drop / Upload image file"}
							</span>
							</label>
							<input
							type="file"
							accept="image/png, image/jpeg, image/jpg, image/gif"
							id="upload-image"
							onChange={this.setImageFile}
							/>
						</div>
						{this.state.errorImg && <p className='srv-validation-message'>{this.state.errorImg}</p>}
						{this.state.imagePreviewUrl && (
							<div className='text-center'>
							<img src={this.state.imagePreviewUrl} alt="Preview" style={{ maxWidth: '100%', height: 'auto' }} />
							</div>
						)}
					</div>
			    </div>
			  </div>

			  <div className="row">
			    <div className="col-md-12">
			      <div className='text-center'>
			        <Button  type='submit' bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default " >Save</Button>
			        <Button bsSize="large" bsStyle="info" onClick={()=>this.props.closeModel() } className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info " >Cancel</Button>
			      </div>
			    </div>
			  </div>
			</form>
		);
	}
}


export default AppuserForm;

import React from 'react';
import {
  Button
} from "react-bootstrap";


import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
import { helperFunctions as HF } from '../../../../helper/helperFunctions';
import { type } from 'jquery';

class KeywordForm extends React.Component {
	constructor(props){
		super(props);
		this.validatorForm = new SimpleReactValidator({autoForceUpdate: this, locale: 'en'});
		this.state = {
			keyword: {
				...this.props.recPro,
                type: this.props.recPro.type || '',
			},
		    submitted: false,
		    formType: this.props.formTypePro 	|| '',
		};

		this.handleRec = this.handleRec.bind(this);
        this.handleRecSelect = this.handleRecSelect.bind(this);
  		this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
	}


    handleTypeChange(selectedOption) {
        this.setState({
          keyword: {
            ...this.state.keyword,
            type: selectedOption ? selectedOption.value : '',
          },
        });
      }
	handleRec(e){
	    const { name, value, type } = e.target;
        const recState = { ...this.state.keyword };
	    recState[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
	    this.setState({keyword: recState});
	}

    handleRecSelect(selectedOptionType, info) {
        console.log("selectedOptionType ######## ",selectedOptionType, info)
        if (info['action'] === 'select-option') {
          let recState = { ...this.state.keyword };
          recState[info['name']] = selectedOptionType['value'];
          console.log(recState);
          this.setState({keyword: recState});
        }
    }

	handleSubmitForm(e){
	    e.preventDefault();
	    if (this.validatorForm.allValid()) {
			this.setState({submitted: true}, function(){
				this.props.submitForm(this.state.keyword, this.state.formType);
			});
		} else {
			this.setState({submitted: false});
			this.validatorForm.showMessages();
			this.forceUpdate();
		}
	}


	render(){
		const { keyword,formType, allowedFormatsStringMime, allowedFormatsStringExt, allowedFormatsVideoMime, allowedFormatsVideoExt} = this.state;
		const { durations } = this.props;
        console.log('keyword',keyword,durations);
        const options = [
            { value: 'special_pricing', label: 'Special pricing' },
            { value: 'not_for_sale', label: 'Not for sale' },
            { value: 'assigned', label: 'Assigned' },
            { value: 'naughty', label: 'Naughty' },
          ];
		return(
			<form onSubmit={this.handleSubmitForm}>
                 { formType === 'edit' &&
			        	<input type="hidden" name="keyword_id" className="form-control"  value={keyword.id}/>
			    }
                 <div className="row">
                    <div className="col-md-6">
                        <div className='form-group'>
                            <label htmlFor="duration">Keyword<span className="requiredClass">*</span></label>
                            <input type="text" name="title" className="form-control"  value={keyword.title} onChange={this.handleRec} />
                            {this.validatorForm.message('title', keyword.title, 'required|max:250')}

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className=''>
                            <label className="container-check checkbox_down"> Is Active?
                            <input type="checkbox" name="is_active" className="checkbox" value={keyword.is_active? 1 : 0} onChange={this.handleRec} checked={keyword.is_active?1:0} />
                            <span className="checkmark"></span>
                            </label>
                            {/* {this.validatorPermission.message('is_active', video.is_active, 'required')} */}
                        </div>
                    </div>

                </div>

			  <div className="row">
                    <div className="col-md-12">
                        
                        {/* <div className='form-group'>
                            <label htmlFor="title">Title<span className="requiredClass">*</span></label>
                            <input type="text" name="title" className="form-control"  value={plan.title} onChange={this.handleRec} />
                            {this.validatorForm.message('title', plan.title, 'required|max:250')}
                        </div> */}

                    <div className="form-group">
                        <label htmlFor="typeSelect">
                            Keyword Type <span className="requiredClass">*</span>
                        </label>
                        <Select
                            name="typeSelect"
                            options={options}
                            className="basic-single"
                            classNamePrefix="select"
                            value={options.find(option => option.value === keyword.type)} // Set the current selected value
                            onChange={this.handleTypeChange} // Handle dropdown change
                        />
                        {this.validatorForm.message('type', keyword.type, 'required')}
                    </div>
                    </div>
                    
                </div>

                {/* <div className="row">
                    <div className="col-md-6">
                        <div className='form-group'>
                            <label htmlFor="position">Position<span className="requiredClass">*</span></label>
                            <input type="text" name="position" className="form-control"  value={plan.position} onChange={this.handleRec} />
                            {this.validatorForm.message('position', plan.position, 'required|numeric|max:5')}
                        </div>
                    </div>
                </div> */}






			  <div className="row">
			    <div className="col-md-12">
			      <div className='text-center'>
			        <Button  type='submit' bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default " >Save</Button>
			        <Button bsSize="large" bsStyle="info" onClick={()=>this.props.closeModel() } className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info " >Cancel</Button>
			      </div>
			    </div>
			  </div>
			</form>
		);
	}
}


export default KeywordForm;

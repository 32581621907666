import React, { Fragment } from 'react';
import {
  Grid,
  Row,
  Col,
  Button
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';

import SimpleReactValidator from 'simple-react-validator';
import Select, { createFilter } from 'react-select';
import AsyncSelect from 'react-select/async';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { campaignService } from '../../../services/campaign';

import NewCampaigns from "../../../assets/img/NewCampaigns.svg";
import CreateAd from "../../../assets/img/CreateAd.svg";
import PaymentAd from "../../../assets/img/PaymentAd.svg";
import ActiveAd from "../../../assets/img/ActiveAd.svg";
import CampaignForm2 from './CampaignForm2';
import CampaignForm4 from './CampaignForm4';
import CampaignForm5 from './CampaignForm5';





class CampaignForm1 extends React.Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem('user'));
    this.validatorForm = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
    this.state = {
      campaign: {
        ...OC.CAMPAIGN,
        user_id: user.data ? user.data.id : null,
      },
      usersList: [],
      agesList: [],
      gendersList: [],
      counstriesList: [],
      defaultCountry: '',
      submitted: false,
      formType: 'add',
      result: '',
      tabs: {
        CampaignForm1: true,
        CampaignForm2: false,
        CampaignForm3: false,
        CampaignForm4: false
      }
    };
    console.log("campaign", this.state.campaign);
    this.handleRec = this.handleRec.bind(this);
    this.handleDetails = this.handleDetails.bind(this);
    this.handleRecSelect = this.handleRecSelect.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
  }

  componentDidMount() {
    this.getAgesCall();
    this.getGendersCall();
    this.getCountriesCall();
  }

  getUsersCall = async (input) => {
    const res = await campaignService.getUsers(input);
    console.log('getUsersCall', res.data)
    this.setState({ usersList: res.data });
    return res.data;
  }
  getAgesCall = async (input) => {
    const res = await campaignService.getAges(input);
    this.setState({ agesList: res.data });
    return res.data;
  }
  getGendersCall = async (input) => {
    const res = await campaignService.getGenders(input);
    this.setState({ gendersList: res.data });
    return res.data;
  }
  getCountriesCall = async (input) => {
    const res = await campaignService.getCountries(input);
    this.setState({ counstriesList: res.data });
    console.log("contries", res.data)
    this.setState({ defaultCountry: res.data[0] });
    console.log(this.state.defaultCountry)
    return res.data;
  }

  userOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.getUsersCall(inputValue));
      }, 1000);
    });


  handleRec(e) {
    const { name, value, type } = e.target;
    const recState = { ...this.state.campaign };
    recState[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
    this.setState({ campaign: recState });
  }

  handleDetails(e) {
    const { name, value, type } = e.target;
    const recState = { ...this.state.campaign };
    recState['details'][name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
    this.setState({ campaign: recState });
  }


  handleRecSelect = (selectedOptionType, info) => {
    console.log("selectedOptionType", selectedOptionType)
    if (info['action'] === 'select-option') {
      let recState = { ...this.state.campaign };
      recState[info['name']] = selectedOptionType['value'];
      console.log(recState);
      this.setState({ campaign: recState });
    }
  }

  handleDetailsSelect = (selectedOptionType, info) => {
    if (info['action'] === 'select-option') {
      let recState = { ...this.state.campaign };
      recState['details'][info['name']] = selectedOptionType['value'];
      console.log(recState);
      this.setState({ campaign: recState });
    }

    if (info['action'] === 'clear') {
      let recState = { ...this.state.campaign };
      recState['details'][info['name']] = null;
      this.setState({ campaign: recState });
    }

  }

  handleDetailsSelect1 = (event) => {
    const selectedValue = event.target.value;
    this.setState(prevState => ({
      selectedLocation: selectedValue,
      campaign: {
        ...prevState.campaign,
        details: {
          ...prevState.campaign.details,
          location: selectedValue,
        },
      },
    }));
  }


  handleSubmitForm(e) {
    e.preventDefault();
    if (this.state.campaign.details.location === "") {
      let recState = { ...this.state.campaign };
      recState['details']["location"] = this.state.defaultCountry["value"];
      this.setState({ campaign: recState });
      console.log(this.state.campaign);
    }
    if (this.validatorForm.allValid()) {
      this.setState({ submitted: true }, function () {
        this.submitForm(this.state.campaign, this.state.formType);
      });
    } else {
      this.setState({ submitted: false });
      this.validatorForm.showMessages();
      this.forceUpdate();
    }
  }


  submitForm = (formData, formType) => {
    if (formType === 'add') {
      campaignService.storeCampaign(formData).then(res => {
        this.setState({ result: res.data });
        this.setState({
          tabs: {
            CampaignForm1: false,
            CampaignForm2: true,
            CampaignForm3: false,
            CampaignForm4: false
          }
        });
        // window.location.replace("/Advertiser/new-campaigns-ads/"+res.data.id);
      });
    } else if (formType === 'edit') {
      campaignService.editCampaign(formData, this.state.campaign.id).then(res => {
        window.location.replace("/Advertiser/new-campaigns-ads/" + res.data.id);
      });
    }
  }

  setStateOfForm2 = (newValue, campaign_id) => {
    this.setState({
      tabs: {
        CampaignForm1: false,
        CampaignForm2: false,
        CampaignForm3: newValue,
        CampaignForm4: false
      },
      campaign_id: campaign_id,
    });
  }

  setStateOfForm3 = (newValue) => {
    this.setState({
      tabs: {
        CampaignForm1: false,
        CampaignForm2: false,
        CampaignForm3: false,
        CampaignForm4: newValue
      },
    });
  }

  goBackToForm1 = (campaign_id) => {
    this.setState({
      tabs: {
        CampaignForm1: true,
        CampaignForm2: false,
        CampaignForm3: false,
        CampaignForm4: false
      },
      campaign_id: campaign_id,
    });
  }

  goBackToForm2 = (campaign_id) => {
    this.setState({
      tabs: {
        CampaignForm1: false,
        CampaignForm2: true,
        CampaignForm3: false,
        CampaignForm4: false
      },
      campaign_id: campaign_id,
    });
  }
  goBackToForm3 = (campaign_id) => {
    this.setState({
      tabs: {
        CampaignForm1: false,
        CampaignForm2: false,
        CampaignForm3: true,
        CampaignForm4: false
      },
      campaign_id: campaign_id
    });
  }


  render() {
    const { campaign, formType, tabs } = this.state;
    const filterConfig = {
      ignoreCase: true,
      matchFrom: 'start',
    };
    return (
      <Fragment>
        <div className='innerCustomContainer'>
          <Grid fluid>
            <div ctTableResponsive className="mb-lg" >
              <div className="w-100 py-1">
                {/* //////////////impliment new Design for Campaigns 26-1-2024 /////////////// */}

                <Row>
                  <Col md={12}>
                    <Row className='stepCalculator'>
                      <div className='bgLine'></div>
                      <Col md={3} className={`text-center borderRight ${(tabs.CampaignForm1) ? 'stepActivated' : ''} ${(tabs.CampaignForm2 || tabs.CampaignForm3 || tabs.CampaignForm4) ? 'stepVerified' : ''}`}>
                        <span>1</span>
                        <span className='vLine'></span>
                      </Col>
                      <Col md={3} className={`text-center borderLR ${(tabs.CampaignForm2) ? 'stepActivated' : ''} ${(tabs.CampaignForm3 || tabs.CampaignForm4) ? 'stepVerified' : ''}`}>
                        <span>2</span>
                        <span className='vLine'></span>
                      </Col>
                      <Col md={3} className={`text-center borderLR ${(tabs.CampaignForm3) ? 'stepActivated' : ''} ${(tabs.CampaignForm4) ? 'stepVerified' : ''}`}>
                        <span>3</span>
                        <span className='vLine'></span>
                      </Col>
                      <Col md={3} className={`text-center borderLeft ${(tabs.CampaignForm4) ? 'stepActivated' : ''}`}>
                        <span>4</span>
                        <span className='vLine'></span>
                      </Col>
                    </Row>
                    <Row className="mt-1 mb-20">
                      <Col md={3}>
                        {/* <div className='shadow cardH110' onClick={(tabs.CampaignForm1 || tabs.CampaignForm2) ? () => this.goBackToForm1(this.state.result) : undefined}> */}
                        <div className='shadow cardH110'>
                          <div className={`p-1 ${(tabs.CampaignForm1) ? 'bg-after-cards' : ''} ${(tabs.CampaignForm2 || tabs.CampaignForm3 || tabs.CampaignForm4) ? 'bg-comp-cards' : ''}`}>
                            <div className="flexElem alignCenter">
                              <img src={NewCampaigns} alt='NewCampaigns' width={36} className='mt-1 mb-1 mx-auto' />
                            </div>
                            <div className="flexElem alignCenter">
                              <div className="mx-auto text-primary text-16 font-weight-bold">Create New Campaigns</div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className='shadow cardH110' onClick={(tabs.CampaignForm2 || tabs.CampaignForm3) ? () => this.goBackToForm2(this.state.result) : undefined}>
                          <div className={`p-1 ${(tabs.CampaignForm2) ? 'bg-after-cards' : ''} ${(tabs.CampaignForm3 || tabs.CampaignForm4) ? 'bg-comp-cards' : ''}`}>
                            <div className="flexElem alignCenter">
                              <img src={CreateAd} alt='CreateAd' width={36} className='mt-1 mb-1 mx-auto' />
                            </div>
                            <div className="flexElem alignCenter">
                              <div className="mx-auto text-primary text-16 font-weight-bold">Create / Upload Ads</div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className='shadow cardH110' onClick={(tabs.CampaignForm3 || tabs.CampaignForm4) ? () => this.goBackToForm3(this.state.result) : undefined}>
                          <div className={`p-1 ${(tabs.CampaignForm3) ? 'bg-after-cards' : ''} ${(tabs.CampaignForm4) ? 'bg-comp-cards' : ''}`}>
                            <div className="flexElem alignCenter">
                              <img src={PaymentAd} alt='PaymentAd' width={36} className='mt-1 mb-1 mx-auto' />
                            </div>
                            <div className="flexElem alignCenter">
                              <div className="mx-auto text-primary text-16 font-weight-bold">Payment Method</div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className='shadow cardH110'>
                          <div className={`p-1 ${(tabs.CampaignForm4) ? 'bg-after-cards' : ''}`}>
                            <div className="flexElem alignCenter">
                              <img src={ActiveAd} alt='ActiveAd' width={36} className='mt-1 mb-1 mx-auto' />
                            </div>
                            <div className="flexElem alignCenter">
                              <div className="mx-auto text-primary text-16 font-weight-bold">Active Campaigns</div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>


                {/* ///////////////////////////// */}

                {
                  tabs.CampaignForm1 &&
                  <div className="">
                    <h3 className="text-left font-bold">Create New Campaign</h3>
                    <form onSubmit={this.handleSubmitForm}>
                      {formType === 'edit' &&
                        <input type="hidden" name="ad_id" className="form-control" value={campaign.id} />
                      }
                      <div className="row">
                        <div className="col-md-12">
                          <div className='form-group'>
                            <label htmlFor="campaign_title" className='text-14'>Campaign Title<span className="requiredClass">*</span></label>
                            <input type="text" name="campaign_title" className="form-control text-14" value={campaign.campaign_title} onChange={this.handleRec} />
                            {this.validatorForm.message('campaign_title', campaign.campaign_title, 'required|max:250')}
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className="col-md-6">
                          <div className='form-group'>
                            <label htmlFor="duration">Age Group to Show ads</label>
                            <Select
                              value={HF.returnLabelWithValue(campaign.details.ages, this.state.agesList)}
                              onChange={(event, info) => this.handleDetailsSelect(event, info)}
                              options={this.state.agesList}
                              isSearchable={true}
                              isMulti={false}
                              name='ages'
                              isClearable={true}
                              placeholder={'Select Age'}
                              filterOption={createFilter(filterConfig)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className='form-group'>
                            <label htmlFor="duration">Gender to show ads</label>
                            <Select
                              value={HF.returnLabelWithValue(campaign.details.gender, this.state.gendersList)}
                              onChange={(event, info) => this.handleDetailsSelect(event, info)}
                              options={this.state.gendersList}
                              isSearchable={true}
                              isMulti={false}
                              name='gender'
                              isClearable={true}
                              placeholder={'Select Gender'}
                              filterOption={createFilter(filterConfig)}
                            />
                          </div>
                        </div>

                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className='form-group'>
                            <label htmlFor="location">Region: e.g. "USA"</label><br />
                            {/* <Select
                              value={HF.returnLabelWithValue(null, this.state.counstriesList, this.state.defaultCountry)}
                              onChange={(event, info) => this.handleDetailsSelect(event, info)}
                              options={this.state.counstriesList}
                              isSearchable={true}
                              isMulti={false}
                              name='location'
                              isClearable={true}
                              // placeholder={'Select Region'}
                              filterOption={createFilter(filterConfig)}
                            /> */}
                            <select
                              style={{ cursor: 'pointer', width: "100%", height: "40px" }}
                              onChange={this.handleDetailsSelect1}
                              // onChange={(event, info) => this.handleDetailsSelect(event, info)}
                              name='location'
                            >
                              <option value="" disabled>Select Region</option>
                              {this.state.counstriesList.map(country => (
                                <option key={country.value} value={country.value}>
                                  {country.label}
                                </option>
                              ))}
                            </select>
                            {/* <input type="text" name="location" className="form-control" value={campaign.details.location} onChange={this.handleDetails} />
                            {this.validatorForm.message('location', campaign.details.location, 'required|max:250')} */}
                          </div>
                        </div>
                      </div>


                      <div className="row">
                        <div className="col-md-12">
                          <Button type='submit' bsSize="large" bsStyle="primary" className="w-full pt-sm no_radius pb-sm btn btn-lg btnDark" >Create</Button>
                        </div>
                      </div>
                    </form>
                  </div>
                }
                {
                  tabs.CampaignForm2 && this.state.result &&
                  <CampaignForm2
                    id={this.state.result.id}
                    setStateOfForm2={this.setStateOfForm2}
                  />
                }
                {
                  tabs.CampaignForm3 &&
                  <CampaignForm4
                    id={this.state.result.id}
                    user_id={this.state.campaign.user_id}
                    campaign_id={this.state.campaign_id}
                    setStateOfForm3={this.setStateOfForm3}
                  />
                }
                {
                  tabs.CampaignForm4 &&
                  <CampaignForm5
                    id={this.state.result.id}
                    user_id={this.state.campaign.user_id}
                  //setStateOfForm2={this.setStateOfForm2}
                  />
                }

              </div>
            </div>
          </Grid>
        </div>
      </Fragment>
    );
  }
}


export default CampaignForm1;

import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import { CLMUserActions } from "redux/actions/CLM-user-action";
import { Grid, Row, Col, Button } from "react-bootstrap";
import MessageCom from "../../../layouts/Advertiser/Message";
import videoSample from "../../../assets/img/businessIMG.png";
import { Link, useLocation, withRouter } from "react-router-dom";
import { history } from "helper/history";


class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      submitted: false,
      role: props.role,
      caret_title: "",
      keyword: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCaretChange = this.handleCaretChange.bind(this);
    this.focusableDivRef = React.createRef();
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }


  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { email, password, keyword } = this.state;
    const { dispatch } = this.props;
    const { role } = this.props;
    // console.log("role:", role);
    if (email && password) {
      dispatch(CLMUserActions.cartLogin(email, password, role, keyword));
    }
    this.props.loginData(email, password);
  }
  handleCaretChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    localStorage.setItem("caret_title", value);
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      history.push('/CLM-search');
    }
  }


  render() {
    const { email, password, submitted } = this.state;
    const { loggingIn } = this.props;
    return (
      <Fragment>
        <div className="">
          <div className="login_form" style={{ marginLeft: 0 }}>
            <form name="form" onSubmit={this.handleSubmit} >
              <div className={"form-group" + (submitted && !email ? " has-error" : "")}>
                <input type="email" placeholder="Enter Your Email..." className="form-control email_ctrl" name="email" value={email} onChange={this.handleChange} />
                {submitted && !email && (
                  <div className="help-block">
                    Email is required.
                  </div>
                )}
              </div>
              <div className={"form-group" + (submitted && !password ? " has-error" : "")} >
                <input type="password" placeholder="Password" className="form-control password_ctrl" name="password" value={password}
                  onChange={this.handleChange} />
                {submitted && !password && (
                  <div className="help-block">
                    Password is
                    required.
                  </div>
                )}
              </div>
              <div className="form-group">
                <button type='submit' className="btn btn_login">
                  LOGIN
                </button>
                {loggingIn && (
                  <img alt="imageLogin" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                  />
                )}
              </div>
              {/* <div className="form-group">
                          <a href="/CLM-signup" className="btn btn_login" >
                            Create Account
                          </a>
                        </div>
                        <div className="flexElem alignCenter text-white">
                          <a href="/CLM-ForgotPassword" className="text-info"  >
                            Forgot Password?
                          </a>
                          <span className="ml-auto">
                            Goto
                            <a href="https://carets.tv/" className="text-info ml-auto" >
                              {" "}
                              ^Carets
                            </a>
                          </span>
                        </div> */}
            </form>
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.CLMReducer;
  return {
    loggingIn
  };
}

const Login = connect(mapStateToProps)(LoginPage);
export default Login;



import React, { Component, Fragment } from 'react';
import { Grid, Row, Col } from "react-bootstrap";
import { Jumbotron, Button, Badge } from "reactstrap";
// import Footer from "../../FrontEnd/Footer/Footer"
// import Header from "../../FrontEnd/Header/Header"
import Heart from "../../../assets/img/heart.svg"
import CartIcon from "../../../assets/img/cart.svg"
import Clip3 from "../../../assets/img/dashboard/finishing-clip-3.png"
import Clip1 from "../../../assets/img/dashboard/finishing-clip-1.png"
import Clip7 from "../../../assets/img/dashboard/finishing-clip-7.png"
import Splash7 from "../../../assets/img/dashboard/splash-video-7.png"
import Checked from "../../../assets/img/checkWhite.svg"
import { Link } from 'react-router-dom';
class CLMCartSuccess extends Component {
  constructor(props) {
    super(props);
    // var checkLogin = localStorage.getItem('user');
    // if (checkLogin) {
    //   localStorage.removeItem('user');
    // }
  }
  render() {
    return (
      <Fragment>
        {/* <Header /> */}
        <main className='content pl-2-5F pt-2'>
          <Jumbotron>
            <div className='flexElem alignCenter justifyCenter text-center'>
              <div className='px-2 py-2 text-center roundedFull' style={{ background: '#6fab57' }}>
                <img src={Checked} alt='Heart' width={48} />
              </div>
            </div>
            <h2 className='display-3 text-center'><img src={Heart} alt='Heart' width={36} /> Thank you for your purchase! <img src={Heart} alt='Heart' width={36} /></h2>
            <hr className="my-2" />
            {/* <div className='col-md-8 mx-auto' style={{ float: 'none' }}>
              <h5 className='text-center lead'>I'm thrilled to start using this amazing platform. The purchase process was incredibly easy.</h5>
              <h5 className='text-center lead'>I can't wait to start creating and sharing my videos with the world. Your support is greatly appreciated. Looking forward to a fantastic experience!</h5>
            </div> */}
            <div className='flexElem alignCenter justifyCenter text-center mt-2-5'>
              <Link to="/CLM/dashboard" className="btn btnDark text-white" style={{ width: 'auto' }}>Back to Dashboard</Link>
            </div>
          </Jumbotron>
        </main>
        {/* <Footer /> */}
      </Fragment>
    )
  }
}
export default CLMCartSuccess;
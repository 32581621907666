import React, { Fragment } from "react";
import { Button, Grid, Row, Col } from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import SimpleReactValidator from "simple-react-validator";
import Select, { createFilter } from "react-select";
import AsyncSelect from "react-select/async";
import { helperFunctions as HF } from "../../../helper/helperFunctions";
import { cardService } from "../../../services/card";
import { splashService } from "../../../services/splash";
import { campaignService } from "../../../services/campaign";

import { adService } from "../../../services/ad";

import NoData from '../../../assets/img/noData.png';
import Payment from "payment";
import Pagination from '../../ThemeComponents/Pagination';
// import masterCard from "../../../assets/img/mastercard.png"
import masterCard from "../../../assets/img/sampleCard.svg"

import visaCard from "../../../assets/img/visa.png"

class MyCaretsForm4 extends React.Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem("user"));
    //const { id } = this.props.match.params;
    const licenseId = localStorage.getItem("license_id");
    this.validatorForm = new SimpleReactValidator({
      autoForceUpdate: this,
      locale: "en",
    });
    this.state = {
      // campaign_id: this.props.id,
      usersList: [],
      adList: [],
      ad_title: [],
      video_url: [],
      adId: [],
      xid: 0,
      submitted: false,
      formType: "add",
      campaignDetails: [],
      DataAds: [],
      ExCardData: [],
      totalAmount: 0,
      CardForm: false,
      user_id: user.data ? user.data.id : null,
      searchAd: "",
      license_id: licenseId
    };

  }




  getUsersCall = async (input) => {
    const res = await campaignService.getUsers(input);
    this.setState({ usersList: res.data });
    if (this.state.card.user_id) {
      this.getAdsCall(this.state.card.user_id);
    }
    return res.data;
  };

  userOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.getUsersCall(inputValue));
      }, 1000);
    });

  componentDidMount() {
    // console.log('ss',this.state.user_id)
    this.getAdsCall(this.state.user_id, this.state.license_id, this.state.searchAd);

  }

  handleSearchChange = (e) => {
    const searchAd = e.target.value;
    this.setState({ searchAd });
    this.getAdsCall(this.state.user_id, this.state.license_id, searchAd);
  };

  getAdsCall = (val, licenseId, searchAd) => {
    adService.getAdsData(val, licenseId, searchAd).then(res => {
      this.setState({ adList: res.data });
    });
  }
  


  handleAdSelect = (selectedOptionType) => {
    // console.log("selectedOptionType", selectedOptionType)

    const filtered = this.state.adList.filter(obj => {
      return obj.value === selectedOptionType['value'];
    });

    if (filtered) {
      let recState = { ...this.state.adId };
      recState[this.state.xid] = filtered[0].value;
      // console.log("this.state.adId ########## ", recState);
      this.setState({ adId: recState });
    }
  };

  handleNext = () => {
    this.props.setStateOfForm3(true, this.state.adId);
  }

  render() {
    const { adList } = this.state;
    return (
      <div className="">
        <div className="flexElem gap-2 items-center" style={{ marginBottom: "20px" }}>
          <h3 className="secHeading m-0">Select Ad</h3>
          <div className="ml-auto w-25">
          <input
            type="text"
            placeholder="Search Ad..."
            value={this.state.searchAd}
            onChange={this.handleSearchChange}
            className="form-control"
          />
        </div>
        </div>
        
        <div className='innerScrollWrap'>
          <div className='gridSplash'>
            {this.state.adList.map((ad, index) => (
              <div>
                <div className='splashWrap' >
                  {/* {(index % 8 === 0)} */}
                  {/* <video src={ad.video_url} className='adImage' /> */}
                  <img src={ad.image_url} className='adImage' />
                  <div className='adContent flexElem flexColumn mt-auto gap10p' style={{ wordBreak: 'break-word' }}>
                    <div style={{ marginTop: 'au to' }}>
                      <h6 className='text-primary m-0' style={{ textTransform: 'capitalize', fontSize: 11 }}>Ad Title:</h6>
                      <h5 className='m-0'>{ad.label}</h5></div>
                    <div>
                    </div>
                  </div>
                </div>
                <button className={`mt-1 selectBtn ${(this.state.adId[this.state.xid] === ad.value) ? 'bcCelectBtn' : 'selectBtn'}`} onClick={() => this.handleAdSelect(ad)}>
                  Select
                </button>
                {/* {(index % 3 === 2 || index === this.state.splashList.length - 1)} */}

              </div>
            ))}
          </div>
        </div>
        <div className="flexElem gap-2 pl-btn-fix" style={{ gap: 10 }}>
          <Button
            type="submit"
            className="btnDark px-5 font-boldF text-16 w-autoF"
            // disabled={this.state.adId.length === 0}
            onClick={() => this.handleNext()}>
            Next
          </Button>
        </div>
      </div>
    );
  }
}

export default MyCaretsForm4;

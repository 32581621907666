import React from 'react';
import {
  Button
} from "react-bootstrap";


import SimpleReactValidator from 'simple-react-validator';

import CKEditor from "react-ckeditor-component";


import Select from 'react-select';
import { helperFunctions as HF } from '../../../helper/helperFunctions';

class PagesForm extends React.Component {
	constructor(props){
		super(props);
		this.validatorForm = new SimpleReactValidator({autoForceUpdate: this, locale: 'en'});
		this.state = {
			page: {
				...this.props.recPro,
			},
		    submitted: false,
		    formType: this.props.formTypePro 	|| '',
		};

		this.handleRec = this.handleRec.bind(this);
  		this.handleSubmitForm = this.handleSubmitForm.bind(this);
  		this.handleRecSelect = this.handleRecSelect.bind(this);
        this.handleCK = this.handleCK.bind(this);
    }



    handleRecSelect(selectedOptionType, info) {
        if (info['action'] === 'select-option') {
          let pagesState = { ...this.state.page };
          pagesState[info['name']] = selectedOptionType['value'];
          this.setState({page: pagesState});
        }
      }

	handleRec(e){
	    const { name, value, type } = e.target;
        const recState = { ...this.state.page };
	    recState[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
	    this.setState({page: recState});
    }

    handleCK(evt){
        console.log("onChange fired with event info: ", evt);
        var newContent = evt.editor.getData();
        const recState = { ...this.state.page };
        recState['page_description'] = newContent;
        this.setState({page: recState});
      }

	handleSubmitForm(e){
	    e.preventDefault();
	    if (this.validatorForm.allValid()) {
			this.setState({submitted: true}, function(){
				this.props.submitForm(this.state.page, this.state.formType);
			});
		} else {
			this.setState({submitted: false});
			this.validatorForm.showMessages();
			this.forceUpdate();
		}
	}



	render(){
		const { page,formType} = this.state;
		const { types } = this.props;
        console.log('types',types);
		return(
			<form onSubmit={this.handleSubmitForm}>
                 { formType === 'edit' &&
			        	<input type="hidden" name="page_id" className="form-control"  value={page.id}/>
			    }
			<div className="row">
			    <div className="col-md-12">
					<div className='form-group'>
						<label htmlFor="page_title">Page Title<span className="requiredClass">*</span></label>
						<input type="text" name="page_title" className="form-control"  value={page.page_title} onChange={this.handleRec} />
						{this.validatorForm.message('page_title', page.page_title, 'required|min:3')}
					</div>
			    </div>
            </div>

            <div className="row">
			    <div className="col-md-12">
					<div className='form-group'>
						<label htmlFor="page_slug">Page Slug<span className="requiredClass">*</span></label>
						<input type="text" name="page_slug" className="form-control"  value={page.page_slug} onChange={this.handleRec} />
						{this.validatorForm.message('page_slug', page.page_slug, 'required|min:3')}
					</div>
			    </div>
            </div>

            <div className="row">
			    <div className="col-md-12">
					<div className='form-group'>
						<label htmlFor="meta_title">Meta Title</label>
						<input type="text" name="meta_title" className="form-control"  value={page.meta_title} onChange={this.handleRec} />
					</div>
			    </div>
            </div>

            <div className="row">
			    <div className="col-md-12">
					<div className='form-group'>
						<label htmlFor="meta_tags">Meta Tags</label>
						<input type="text" name="meta_tags" className="form-control"  value={page.meta_tags} onChange={this.handleRec} />

					</div>
			    </div>
            </div>

            <div className="row">
			    <div className="col-md-12">
					<div className='form-group'>
						<label htmlFor="meta_description">Meta Description</label>
						<textarea name="meta_description" className="form-control"  onChange={this.handleRec}>
                        {page.meta_description}
                        </textarea>
					</div>
                </div>
            </div>

            <div className="row">
			    <div className="col-md-12">
					<div className='form-group'>
						<label htmlFor="page_description">Page Description</label>

                        <CKEditor
                            content={page.page_description}
                            events={{
                                "change": this.handleCK
                              }}
                        />

					</div>
                </div>
            </div>

            <div className="row">
			    <div className="col-md-12">
	              <div className=''>
	                <label className="container-check checkbox_down"> Is Active?
	                  <input type="checkbox" name="is_active" className="checkbox" value={page.is_active? 1 : 0} onChange={this.handleRec} checked={page.is_active?1:0} />
	                  <span className="checkmark"></span>
	                </label>
	                {/* {this.validatorPermission.message('is_active', page.is_active, 'required')} */}
	              </div>
	            </div>
			  </div>


			  <div className="row">
			    <div className="col-md-12">
			      <div className='text-center'>
			        <Button  type='submit' bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default " >Save</Button>
			        <Button bsSize="large" bsStyle="info" onClick={()=>this.props.closeModel() } className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info " >Cancel</Button>
			      </div>
			    </div>
			  </div>
			</form>
		);
	}
}


export default PagesForm;

import { adminConstants } from "./../constant/admin-constant";

const initialState={
    modules:[],
    loadingModule:false,
    permissions:[],
    submittedModule:false
}

export function moduleReducer(state=initialState,action){
    switch (action.type) {
        case adminConstants.GET_ALL_MODULES:
            return{
                ...state,
                modules: action.payload.data.modules,
                role: action.payload.data.role,
                permissions: action.payload.data.permissions
            }
        case adminConstants.STORE_MODULE_REQUEST:
            return{
                ...state,
                loadingModule:true,
                submittedModule:false
                
            }
        case adminConstants.STORE_MODULE_SUCCESS:
            return{
                ...state,
                laodingModule:false,
                submittedModule:true
            }
        case adminConstants.STORE_MODULE_FAILURE:
            return{
                ...state,
                error:action.payload,
                submittedModule:false,
                laodingModule:false
            }
        case adminConstants.CHANGE_MODULE_PERMISSIONS:
            return{
                ...state,
                modules:action.modules,
                permissions:action.permissions,
                loadingModule:false
            }
        default:
            
    }
    return state;
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Row,
  Col,
  // Tooltip,
  OverlayTrigger,
  // Button,
  Popover
} from "react-bootstrap";
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import CatForm from "./CatForm";
import { hasPermission } from 'helper/hasPermission';
import Confirmalertfordelete from '../../ThemeComponents/confirmAlertForDelete';
import { adminLabels } from '../../../redux/constant/admin-label-constant';
import OpenModalButton from '../../ThemeComponents/openModelButton';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import Pagination from '../../ThemeComponents/Pagination';

import { musicService } from '../../../services/music';

var Modal = require('react-bootstrap-modal');

class MusicCategoriesPage extends Component{
  constructor(props){
  	super(props);
  	this.state = {
  	  addModalCat  : false,
      editModalCat : false,
      sorted   : '',
      filtered : '',
      pageSize : 10,
  	};

  	this.callEditCat = this.callEditCat.bind(this);
    this.deleteCat = this.deleteCat.bind(this);
  	this.deleteConfirmCat = this.deleteConfirmCat.bind(this);
    this.catsPaginationData  = this.catsPaginationData.bind(this);
    this.filterSearchHandle     = this.filterSearchHandle.bind(this);
    this.handleCatSubmit= this.handleCatSubmit.bind(this);
  };

  handleCatSubmit(catData, formType) {
    if (formType === 'add'){
        musicService.storeCat(catData).then(res => {
            this.setState({addModalCat:false});
            this.pagination.dataCall();
          });
    }else if (formType === 'edit'){
        musicService.editCat(catData, this.state.editCat.id).then(res => {
            this.setState({editModalCat:false});
            this.pagination.dataCall();
          });
    }
  }

  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }

  callEditCat(id)
  {
    //musicService.getCat(id);
    musicService.getCat(id).then(res => {
        console.log(res);
        this.setState({editCat:res.data}, ()=>{
            this.setState({editModalCat:true});
        });
      });
  }

  deleteCat(CatId)
  {
    musicService.deleteCat(CatId).then(res => {
      this.pagination.dataCall();
    });
  }

  deleteConfirmCat(CatId)
  {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Cat" description="Are you sure to delete the Category?" onClosePro={onClose} deleteType={() => this.deleteCat(CatId)} />
        )
      }
    })
  }


catsPaginationData(page, filter, sort, pageSize, status)
{
    this.setState({ pageSize: pageSize }, function () {
      musicService.getCatsPagination(page, filter, sort, pageSize, status);
    });
}

  handleUpdateCat = catId => (e) => {
    // return false;
    musicService.updateCatStatus(catId, { is_active: e.target.checked ? 1 : 0 }).then(
      response => {
        musicService.getCatsPagination(this.props.current_page, '', '', this.state.pageSize, this.state.cat_status);
        this.pagination.dataCall();
      }
    );
  }

  componentDidUpdate(prevProps, prevState)
  {
    if ((prevProps.editCat !== this.props.editCat) && !this.props.loading) {
      this.setState({ editModalCat: true });
    }
    if ((prevProps.storedCat !== this.props.storedCat) && !this.props.loading) {
      this.setState({ addModalCat: false }, function () {
        musicService.getCatsPagination(this.props.current_page, '', '', this.state.pageSize, this.state.cat_status);
      });
    }
    if ((prevProps.updatedCat !== this.props.updatedCat) && !this.props.loading) {
      this.setState({ editModalCat: false }, function () {
        musicService.getCatsPagination(this.props.current_page, '', '', this.state.pageSize, this.state.cat_status);
      });
    }
    if ((prevProps.deleteCat !== this.props.deleteCat) && !this.props.loading) {
      musicService.getCatsPagination(this.props.current_page, '', '', this.state.pageSize, this.state.cat_status);
    }
    if (this.state.cat_status !== prevState.cat_status) {
      this.table.state.onFetchData(this.table.state, this.table.instance)
    }

  }

  paginationCall = (data) => {
    return musicService.getCatsPagination(data.page, data.filter, data.sort, data.pageSize,this.state.cat_status);
  }
  render()
  {
  	const { filtered,editCat } = this.state;
    const columns = [
        {
          Header:"Categories Listing",
          headerClassName:'headerPagination',
          columns: [
            {
              Header: "Category",
              accessor: "title",
              className: "action-center"
            },

            {
              Header: "Status",
              accessor: "is_active",
              Cell: ({row}) => (
                  <label className="container-check">
                    &nbsp;
                    <input
                      type="checkbox" name="is_active" className="checkbox" value={row.is_active? 1 : 0} onChange={this.handleUpdateCat(row.id)}  checked={row.is_active?1:0}
                     /><span className="checkmark"></span>
                  </label>
                ),
              className: "action-center",
              sortable:false
            },
            {
              Header:"Actions",
              accessor: "id",
              Cell: ({value}) => (
                <div>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Edit Category</Popover>}>
                  <span>
                  {
                    <button type='button' className="editIcon orderDetailsAction" onClick={() => this.callEditCat(value)}>&nbsp;</button>
                  }

                  </span>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Delete Category</Popover>}>
                  <span>
                  {

                    <button type='button' className="deleteIcon orderDetailsAction" onClick={() => this.deleteConfirmCat(value)}>&nbsp;</button>
                  }
                  </span>

                </OverlayTrigger>
                </div>
                ),
              className: "action-center",
              sortable:false
            }
          ]
        }
    ];

  	return(
  		<div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                	<div>
                  	<Grid fluid>
                      <Row>
                          <Col md={12}>
                            <div className="secHeading">Audio Categories</div>
                          </Col>

                          <Col md={12}>
                            <div className="flexElem flexResponsive" style={{"alignItems": "flex-start", "justifyContent": "space-between"}}>
                              <div className="">
                                <OpenModalButton
                                  openModal={hasPermission('cat-create')?HF.openModal.bind(this, "addModalCat"):HF.openModal.bind(this, "addModalCat")}
                                  classButton={['backButton pt-sm no_radius pb-sm primary mt-none btn-block btn-info btn']}
                                  buttonName="Add Category"
                                  classIcon={['fa', 'fa-plus']}
                                />
                              </div>


                            </div>
                          </Col>

                          <Col md={12} className="mt-md">
                          <Pagination
                          ref={(p) => this.pagination = p}
                          showPagination={false}
                          columns={columns}
                          pageSize={20}
                          getDataCall={this.paginationCall}
                          filterView={true}
                          filterPlaceHolder={'cats'}
                          defaultSorted={
                            [
                              {
                                id: 'title',
                                desc: 'false'
                              },
                              {
                                id: 'is_active',
                                desc: 'false'
                              }
                            ]
                          }
                          // downloadData={true}
                          // downloadFileName={'Orders'}
                          // lowerContent = {null}
                          // filterPlaceHolder = {'Orders'}
                          noDataText='No cat found'
                          getRowProps={this.getRowProps}
                        // showAllToggle={true}
                        />
                          </Col>
                      </Row>
                    </Grid>

                    {/*Add Cat Modal Start*/}
                      <Modal backdrop={'static'} show={this.state.addModalCat} onHide={HF.closeModal.bind(this, "addModalCat")} aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                          <Modal.Title id='ModalHeader' className="headerTitle">Add Category</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12">
                              <Card bsClass={["innerCard mb-none"]} content={
                                <CatForm
                                  closeModel={HF.closeModal.bind(this, "addModalCat")}
                                  formTypePro="add"
                                  catPro={OC.MUSICCAT}
                                  handleCatSubmit= { this.handleCatSubmit }
                                  componentPro="MusicCategories"
                                />
                              } />
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>

                    {/*Add Cat Modal End*/}

              		  {/*Edit Cat Modal start*/}
                    { editCat &&
                      <Modal backdrop={'static'} show={this.state.editModalCat} onHide={HF.closeModal.bind(this, "editModalCat")} aria-labelledby="ModalHeader" >
                      <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Edit Category</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-md-12">
                          <Card bsClass={["innerCard mb-none"]} content={
                            <CatForm
                              closeModel={HF.closeModal.bind(this, "editModalCat")}
                              formTypePro="edit"
                              catPro={editCat}
                              handleCatSubmit= { this.handleCatSubmit }
                            />
                          } />
                          </div>
                        </div>
                      </Modal.Body>
                      </Modal>
                    }
                    {/*Edit Cat Modal End*/}
                	</div>
                } />
            </Col>
          </Row>
        </Grid>
      </div>
  	);
  };
}
const MusicCategories = MusicCategoriesPage;
export default MusicCategories;

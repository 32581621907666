import React, { Component, Fragment } from "react";
import { adminLabels } from '../../redux/constant/admin-label-constant';
import fileLogo from "assets/img/File-icon.svg";
// import Img from 'react-fix-image-orientation';
// import ExifOrientationImg from 'react-exif-orientation-img'
import $ from 'jquery';
// import pdfIcon from "assets/img/pdf.svg";
// import tiffIcon from "assets/img/tiff.svg";
// import videoIcon from "assets/img/video-player.svg";
const previewStyle = {
	display: 'inline-block',
	width: 100,
	height: 100,
};

class ImagePreview extends Component {
	constructor(props) {
		super(props);
		this.previewImg = this.previewImg.bind(this);
		this.previewFile = this.previewFile.bind(this);
		this.previewVideo = this.previewVideo.bind(this);
		this.preview = this.preview.bind(this);
	}
	componentDidMount() {
		console.log('componentDidMount');
		let stl = $(".imgContainer .prevImage").attr("style");
		console.log(stl);
	}
	preview = (preview, name, index, type) => {
		if (adminLabels.IMAGESHOW.includes(type)) {
			return this.previewImg(preview, name, index);
		} else if (adminLabels.VIDEOSHOW.includes(type)) {
			return this.previewVideo(preview, name, index);
		} else {
			return this.previewFile(preview, name, index);
		}
	}
	previewVideo = (src, name, index = null) => {
		return (
			<div className="text-center">
				<div key={index} className="prevVidContainer display_ib">
					<video key={src} id="playVid" controls className="videoPreview">
						<source src={src} type="video/mp4" />
					</video>
					<div className="prevName">
						{name}
					</div>
					{
						(this.props.removeFile && index !== null) &&
						<div className="prevRemove">
							<span onClick={() => this.props.removeFile(this.props.type ? this.props.type : null, index)}>
								&times;
							</span>
						</div>
					}
				</div>
			</div>
		);
	}
	previewImg = (src, name, index = null) => {
		return (
			<div key={index} className="prevImgContainer">
				<div className="imgContainer">
					{/*<Img 
						className="prevImage"
						alt="Preview"
						key={src}
						src={src}
					/>
					*/}


					<img
						className="prevImage"
						alt="Preview"
						key={src}
						src={src}
					/>





				</div>
				<div className="prevName">
					{name}
				</div>
				{
					(this.props.removeFile && index !== null) &&
					<div className="prevRemove">
						<span onClick={() => this.props.removeFile(this.props.type ? this.props.type : null, index)}>
							&times;
						</span>
					</div>
				}
			</div>
		);
	}
	previewFile = (src, name, index = null) => {
		return (
			<div key={index} className="prevImgContainer">
				{
					/*<Img
					alt="Preview"
					key={src}
					src={fileLogo}
					style={previewStyle}
				/>
*/
				}

				<img
					alt="Preview"
					key={src}
					src={fileLogo}
					style={previewStyle}
				/>

				<div>
					{name}
				</div>
				{
					(this.props.removeFile && index !== null) &&
					<div className="prevRemove">
						<span onClick={() => this.props.removeFile(this.props.type ? this.props.type : null, index)}>
							&times;
						</span>
					</div>

				}
			</div>
		);
	}
	render() {
		const { filesToPreview } = this.props;
		return (
			<div className="content">
				{
					filesToPreview ?
						Array.isArray(filesToPreview) ?
							<div className="uploadImages flexElem flexResponsive">
								{
									filesToPreview.map((file, index) => (
										file.name ?
											adminLabels.IMAGESHOW.includes(file.type) ?
												this.previewImg(file.preview, file.name, index)

												:
												this.previewFile(file.preview, file.name, index)
											: null
									)
									)
								}
							</div> :
							<Fragment>
								{
									filesToPreview.name ?
										this.preview(filesToPreview.preview, filesToPreview.name, 0, filesToPreview.type)
										/*
								adminLabels.IMAGESHOW.includes(filesToPreview.type) ?
											this.previewImg(filesToPreview.preview, filesToPreview.name, 0)
											
										:
											this.previewFile(filesToPreview.preview, filesToPreview.name, 0)
										*/


										: null
								}
							</Fragment>
						: null
				}
			</div>
		);
	};
}

export default ImagePreview;

import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover,
  Button
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import Logo from "../../assets/img/logo_frontEnd.png"
import VidSample from "../../assets/img/landing/sampleAd.png"
import VidDummy from "../../assets/img/landing/video-dummy3.png"
import VidDummy2 from "../../assets/img/landing/video-dummy.png"
import VidDummy3 from "../../assets/img/landing/video-dummy2.png"
import ad1 from "../../assets/img/landing/ad1.png"
import ad2 from "../../assets/img/landing/ad2.png"
import ad3 from "../../assets/img/landing/ad3.png"
import ad4 from "../../assets/img/landing/ad4.png"
import ad5 from "../../assets/img/landing/ad5.png"
import ad6 from "../../assets/img/landing/ad6.png"
import ad7 from "../../assets/img/landing/ad7.png"
import arrow from "../../assets/img/arrow-down.svg"
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { RiVideoLine } from 'react-icons/ri';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import AdPosition from "../../assets/img/landing/position.jpg";
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import { helperFunctions as HF } from '../../helper/helperFunctions';

import AdVideoBanner from "../../assets/img/videoPreview.png"

var cloneDeep = require('lodash.clonedeep');
var Modal = require('react-bootstrap-modal');
const moment = window.moment;

class StrategicAdPlacement extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  };

  render() {

    return (
      <div className="content">
        <Grid>
          <Col md={12} className='flexElem'>
            <div className='col-md-8 mx-auto text-center'>
              <h2 class="text_36 text-center mb-0 mt-2-5 font-weight-bold">High Impact Advertising!</h2>
              <h2 class="text_36 text-center mb-2 mt-0 font-weight-bold">Choose when your ads will appear in the video playback sequence.</h2>
            </div>
          </Col>
          {/* <Col md={3}>
            <img src={VidDummy} alt='VidDummy' className='w-100' />
          </Col>
          <Col md={3}>
            <img src={VidSample} alt='VidDummy' className='w-100' />
          </Col>
          <Col md={3}>
            <img src={VidDummy2} alt='VidDummy' className='w-100' />
          </Col>
          <Col md={3}>
            <img src={VidDummy3} alt='VidDummy' className='w-100' />
          </Col> */}
          <Col md={12} className='px-0'>
            <div className='adOrderPosition'>
              <div className='adWrap'>
                <h5 className='text-center adPos'>1st</h5>
                <h6 className='text-center'>User Video</h6>
                <div className='adPreview'>

                  <img src={ad1} alt='ad1' />
                </div>
              </div>
              <div className='adWrap'>
                <h5 className='text-center adPos'>2nd</h5>
                <h6 className='text-center'>User Video</h6>
                <div className='adPreview'>
                  <img src={ad2} alt='ad2' />
                </div>
              </div>
              <div className='adWrap yourAd'>
                <h5 className='text-center adPos'>Position 3</h5>
                <h6 className='text-center'>Your Ad Here</h6>
                <div className='adPreview'>
                  <img src={arrow} alt='arrow' className='addPointer' />
                  <img src={ad3} alt='ad3' />
                </div>
              </div>
              <div className='adWrap'>
                <h5 className='text-center adPos'>4th</h5>
                <h6 className='text-center'>User Video</h6>
                <div className='adPreview'>
                  <img src={ad4} alt='ad4' />
                </div>
              </div>
              <div className='adWrap'>
                <h5 className='text-center adPos'>5th</h5>
                <h6 className='text-center'>User Video</h6>
                <div className='adPreview'>
                  <img src={ad5} alt='ad5' />
                </div>
              </div>
              <div className='adWrap'>
                <h5 className='text-center adPos'>6th</h5>
                <h6 className='text-center'>User Video</h6>
                <div className='adPreview'>
                  <img src={ad6} alt='ad6' />
                </div>
              </div>
              <div className='adWrap yourAd'>
                <h5 className='text-center adPos'>Position 7</h5>
                <h6 className='text-center'>Your Ad Here</h6>
                <div className='adPreview'>
                  <img src={arrow} alt='arrow' className='addPointer' />
                  <img src={ad7} alt='ad7' />
                </div>
              </div>
            </div>
          </Col>
        </Grid>
      </div >
    );
  };
}
export default StrategicAdPlacement;

import React, { Component, Fragment } from 'react';
import Login from 'components/Admin/Login';

class AdminLogin extends Component{
 	render(){
    require('bootstrap/dist/css/bootstrap.min.css')
    require('../../assets/css/animate.min.css')
    require('../../assets/sass/light-bootstrap-dashboard.css?v=1.2.0')
    require('../../assets/css/demo.css')
    require('../../assets/css/pe-icon-7-stroke.css')
    require('../../assets/fonts/robotoFont.css')
  	return(
      <Fragment>

    	{/* <div className="container">
        <div className="content">
          <div className="row"> */}
            <Login />
          {/* </div>
        </div>
      </div> */}
      </Fragment>
  	);
  }
}
export default AdminLogin;
import React, { Component, Fragment, useState } from 'react';
import MessageCom from "layouts/Dashboard/Message";
import { Button, Label, Input } from 'reactstrap';
import { appuserService } from '../../../services/appuser';
import SimpleReactValidator from 'simple-react-validator';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.validatorUser = new SimpleReactValidator({autoForceUpdate: this, locale: 'en'});
    const user = JSON.parse(localStorage.getItem('user'));
    this.state = {
      user:{
        current_password: '',
        new_password: '',
        new_confirm_password: '',
        user_id:user.data.id,
    },
        ubmitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUserSubmitForm = this.handleUserSubmitForm.bind(this);
  }
  handleChange(e) {
    const { name, value, type } = e.target;
    const userState = { ...this.state.user };
    userState[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
    this.setState({user: userState});
  }

  handleUserSubmitForm(e){
    e.preventDefault();
    if (this.validatorUser.allValid()) {
        this.setState({submitted: true}, function(){
            appuserService.updatePassword(this.state.user).then(res => {
                console.log("submitted");
              });
        });
    } else {
        this.setState({submitted: false});
        this.validatorUser.showMessages();
        this.forceUpdate();
    }
}

  render() {
    const { user, formType, allowedFormatsStringMime, allowedFormatsStringExt } = this.state;
    const filterConfig = {
        ignoreCase : true,
        matchFrom  : 'start',
      };
    return (
      <Fragment>
        <div className="container main_login1 w-100 py-1">
          <div className="">
            <h4 className='text-center font-weight-bold'>Change Password</h4>
            <p>&nbsp;</p>
            <form name="form" onSubmit={this.handleUserSubmitForm} className='signupForm'>
            <div className='form-group'>
                <Label for='current_password'>Current Password</Label>
                <input type="password" autoComplete="off" name="current_password" className="form-control" value={user.current_password}  onChange={this.handleChange}/>
			        {
			        	this.validatorUser.message('current_password', user.current_password, 'required|min:5')
			      	}
              </div>
              <div className='form-group'>
              <Label for='oldPass'>New Password</Label>

                <input type="password" autoComplete="off" name="new_password" className="form-control" value={user.new_password}  onChange={this.handleChange}/>
			        {
			        	this.validatorUser.message('new_password', user.new_password, 'required|min:5')
			      	}
              </div>
              <div className='form-group'>
                <Label for='oldPass'>Confirm New Password</Label>
                <input type="password" autoComplete="off" name="new_confirm_password" className="form-control" value={user.new_confirm_password}  onChange={this.handleChange}/>
			        {
			        	this.validatorUser.message('new_confirm_password', user.new_confirm_password, 'required|min:5')
			      	}
              </div>
              <p className='m-0'>&nbsp;</p>
              <Button color="primary" block className='roundedFull py-1 mb-1'>Update Password</Button>
              <div className='flexElem alignCenter text-white'>
                <span className='ml-auto'>
                  <a href='/CLM/dashboard' className='text-info'>Return to Dashboard</a>
                </span>
              </div>
            </form>
          </div>
        </div>
        <MessageCom />
      </Fragment>
    );
  }
}
export default ChangePassword;

import React, { Component } from "react";
import HeaderLinks from "../Header/HeaderLinks.jsx";
import { connect } from 'react-redux';
import {FiChevronDown} from 'react-icons/fi'
// import {basePathApi} from 'helper/basePathApi';
import { adminUserActions } from '../../../redux/actions/admin-user-action';
import $ from 'jquery';
import { Link } from 'react-router-dom';

// var module_exists = require('module-exists');

class SidebarPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      dashboardName: 'GTV',
      iconHeight:'20px',
      iconWidth:'20px',
    };

    this.OpenDropDown = this.OpenDropDown.bind(this);
    this.activeRoute = this.activeRoute.bind(this);
    this.createUl = this.createUl.bind(this);
    this.createLi = this.createLi.bind(this);
  }

  createLi(menu_id, menu, key){
    if (typeof menu['parent_labels'][menu_id] === 'undefined' ) {
      return(
        <li id={"id-"+menu_id} className={ this.activeRoute(menu['permissions'][menu_id]['path'], menu_id) } key={key}>
          <span className="nav-link" >
            {
                menu['permissions'][menu_id]['icon'] ?
                <img alt="logo_image"  src={ require('assets/img/'+menu['permissions'][menu_id]['icon']) }  className="imgIconMarRight" />
                :
                <img alt="logo_image"  src={ require('assets/img/question.svg') }  className="imgIconMarRight" />
            }
            <p>
              <Link to={menu['permissions'][menu_id]['path']}> { menu['permissions'][menu_id]['label'] }
              </Link>
            </p>
          </span>
        </li>
      );    
    }
    else if (typeof menu['parent_labels'][menu_id] !== 'undefined') {
      return(
        <li id={"id-"+menu_id} className="hasChild"  key={key}>
          <span className="nav-link" >
            {
              menu['permissions'][menu_id]['icon'] ?
              <img alt="logo_image" src={ require('assets/img/'+menu['permissions'][menu_id]['icon']) }  className="imgIconMarRight" />
              :
              <img alt="logo_image"  src={ require('assets/img/question.svg') }  className="imgIconMarRight" />
            }
            <p>
              { menu['permissions'][menu_id]['label'] }
              <button  onClick={()=>this.OpenDropDown("id-"+menu_id)} ><FiChevronDown /></button>
            </p>
          </span>
          { this.createUl(menu_id, menu, "subnav") }
        </li>
      );
    }
  }
  createUl(parent, menu, ulClass){
    if (typeof menu['parent_labels'][parent] !== 'undefined') {
      return(
        <ul className={ulClass}>
          {
            menu['parent_labels'][parent].map((menu_id, key) => {
              return(
                this.createLi(menu_id, menu, key)
              );
            })
          }
        </ul>
      );
    }
  }
  OpenDropDown(id){
    var sub_height;
    if ($('#'+id).hasClass('openSubMenu')){
      $('#'+id).removeClass('openSubMenu');
      sub_height = $(".sidebar").outerWidth();
      $(".main-panel").css({'width':'calc(100% - '+sub_height+'px)'}); 
    } else {
      $('#'+id).addClass('openSubMenu');
      sub_height = $(".sidebar").outerWidth();
      $(".main-panel").css({'width':'calc(100% - '+sub_height+'px)'});
    }
  }
  activeRoute(routeName, menu_id) {
    var dashboardMenuTemp  = this.props.menu;
    if (typeof dashboardMenuTemp['parent_labels'][menu_id] !== 'undefined'){ 
      return this.props.location.pathname.indexOf(routeName) > -1 ? "active noChild" : "";
    }
    else if(typeof dashboardMenuTemp['parent_labels'][menu_id] === 'undefined'){
      if (dashboardMenuTemp['permissions'][menu_id]['parent_label'] === 0) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? "active lastChild " : "";
      } else {
        return this.props.location.pathname.indexOf(routeName) > -1 ? "active hasParent " : "";
      }
      
    }
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    var checkLogin = localStorage.getItem('user');
    if (checkLogin){
      this.props.getMenuCall();
      this.OnLoadIfOpenSubMenu();
    }
    
  }
  componentDidUpdate() {
    this.OnLoadIfOpenSubMenu();
  }
  OnLoadIfOpenSubMenu(){
    $('li.active').parents('li').addClass('openSubMenu');
  }
  render() {
    const { menu  } = this.props;
    return (
      <div id="sidebar" className="sidebar" data-color="#2c3356" onMouseEnter={this.props.showSideNav} onMouseLeave={this.props.hideSideNav}>
        <div className="sidebar-background"/>
          <div className="sidebar-wrapper">
              { this.state.width <= 991 ? <HeaderLinks /> : null }
              { typeof menu !== 'undefined' && Object.keys(menu['parent_labels']).length>0  &&
                this.createUl(0, menu, "nav")
              }
          </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return ({
    getMenuCall  : () => { dispatch(adminUserActions.dashboardMenu()) },

  });
};

function mapStateToProps(state) {
  const { userAdmin, menu, loading } = state.adminReducer;
  return {
    userAdmin, menu, loading
  };
};

const Sidebar = connect(mapStateToProps, mapDispatchToProps)(SidebarPage);
export default Sidebar;


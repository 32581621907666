import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Row,
  Col,
  // Tooltip,
  OverlayTrigger,
  // Button,
  Popover
} from "react-bootstrap";
import {FaUserTie, FaLock} from 'react-icons/fa';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import { roleActions } from '../../../redux/actions/role-actions';
import { permissionActions } from '../../../redux/actions/permission-action';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import RoleForm from "./RoleForm";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { hasPermission } from 'helper/hasPermission';
import Confirmalertfordelete from '../../ThemeComponents/confirmAlertForDelete';
import { adminLabels } from '../../../redux/constant/admin-label-constant';
import OpenModalButton from '../../ThemeComponents/openModelButton';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import { Link } from 'react-router-dom';
var Modal = require('react-bootstrap-modal');

class RolesListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addModalRole: this.props.addModalRole,
      editModalRole: this.props.editModalRole,
      role: {
        name: '',
        //permission: []
      },
      sorted: '',
      filtered: '',
      pageSize: 10,
      selectedOption: null,
      options: null,
      permission: null,
    };

    this.openModalRole = this.openModalRole.bind(this);
    this.closeModalRole = this.closeModalRole.bind(this);

    this.openModalRoleEdit = this.openModalRoleEdit.bind(this);
    this.closeModalRoleEdit = this.closeModalRoleEdit.bind(this);

    this.handleRoleSubmitPar = this.handleRoleSubmitPar.bind(this);
    this.handleRoleEditSubmitPar = this.handleRoleEditSubmitPar.bind(this);

    this.clearField = this.clearField.bind(this);

    this.deleteRole = this.deleteRole.bind(this);
    this.deleteConfirmRole = this.deleteConfirmRole.bind(this);

    this.rolesPaginationData = this.rolesPaginationData.bind(this);
    this.filterSearchHandle = this.filterSearchHandle.bind(this);
  };
  openModalRole() {
    this.clearField();
    this.setState({ selectedOption: null }, function () {
      this.setState({ addModalRole: true });
    });
  }
  closeModalRole() {
    this.clearField();
    this.setState({ addModalRole: false });
  }
  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }
  openModalRoleEdit(id) {
    this.clearField();
    this.props.getRoleCall(id);
  }
  closeModalRoleEdit() {
    this.clearField();
    this.setState({ editModalRole: false });
  }
  handleRoleSubmitPar(roleData) {
    this.props.storeRoleCall(roleData);
  }
  handleRoleEditSubmitPar(roleData) {
    this.props.editRoleCall(roleData, this.props.editRole.value);
  }
  clearField() {
    this.setState({ role: { name: '', permissions: [] } });
  }
  deleteRole(userId) {
    this.props.deleteRoleCall(userId);
  }
  deleteConfirmRole(userId) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Role" description="Are you sure to delete the role?" onClosePro={onClose} deleteType={() => this.deleteRole(userId)} />
        )
      }
    })
  }
  rolesPaginationData(page, filter, sort, pageSize) {
    this.setState({ pageSize: pageSize }, function () {
      this.props.getRolesPaginationCall(page, filter, sort, pageSize);
    })

  }

  componentDidUpdate(prevProps) {
    if ((this.props.editRole !== prevProps.editRole) && !this.props.loadingRole) {
      this.setState({
        role: {
          name: this.props.editRole.label
        }
      }, function () {
        console.log('role data', this.state.role);
        this.setState({ editModalRole: true });
      });
    }
    if ((this.props.storedRole !== prevProps.storedRole) && !this.props.loadingRole) {
      this.setState({ addModalRole: false }, function () {
        this.props.getRolesPaginationCall(this.props.current_page, '', '', this.state.pageSize);
      });
    }
    if ((this.props.updatedRole !==  prevProps.updatedRole) && !this.props.loadingRole) {
      this.setState({ editModalRole: false }, function () {
        this.props.getRolesPaginationCall(this.props.current_page, '', '', this.state.pageSize);
      });
    }
    if ((this.props.deleteRole !== prevProps.deleteRole) && !this.props.loadingRole) {
      this.props.getRolesPaginationCall(this.props.current_page, '', '', this.state.pageSize);
    }
    // if (prevProps.permissions && !prevProps.loadingPermissions) {
    //   this.setState({ options: prevProps.permissions });
    // }
  }

  componentDidMount() {
    // this.props.getAllPermissionCall();
    // this.props.getAllParentLabelCall();
  }

  render() {
    const { role, filtered } = this.state;
    const { editRole, rolesData, pages, loadingRole } = this.props;
    const columns = [
      {
        Header: "Roles Listing",
        headerClassName: 'headerPagination',
        columns: [
          {
            Header: "Role Name",
            accessor: "name",
            className: "action-center"
          },
          {
            Header: "Actions",
            accessor: "id",
            Cell: ({ value }) => (
              <div>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('role-edit') ? 'Edit Role' : adminLabels.DONT_ALLOW}</Popover>}>
                  <span>
                    {
                      hasPermission('role-edit') &&
                      <button className="btn_link m-sm" type='button' onClick={() => this.openModalRoleEdit(value)}><FaUserTie/></button>
                    }
                    {
                      !hasPermission('role-edit') &&
                      <button type='button'  className="btn_link m-sm button_disabled" ><FaUserTie /></button>
                    }
                  </span>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Edit Permissions</Popover>}>
                  <span>
                    <Link to={"/Admin/modules/" + value} style={{'color':'#666'}} className="btn_link m-sm" ><FaLock /></Link>
                  </span>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{hasPermission('role-delete') ? 'Delete Role' : adminLabels.DONT_ALLOW}</Popover>}>
                  <span>
                    {
                      hasPermission('role-delete') &&
                      <button type='button' className="deleteIcon ml-xs orderDetailsAction" onClick={() => this.deleteConfirmRole(value)}>&nbsp;</button>
                    }
                    {
                      !hasPermission('role-delete') &&
                      <button type='button' className="deleteIcon ml-xs orderDetailsAction button_disabled" >&nbsp;</button>
                    }
                  </span>
                </OverlayTrigger>
              </div>
            ),
            className: "justifyEnd text-right",
            headerClassName: "justifyEnd text-right",
            sortable: false
          }
        ]
      }
    ];
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                  <div>
                    <Grid fluid>
                      <Row>
                        <Col md={12}>
                          <div className="secHeading">
                            Roles List
                          </div>
                        </Col>

                        <Col md={12}>
                          <div className="flexElem flexResponsive" style={{"alignItems":"flex-start", "justifyContent": "space-between"}}>
                            <div className="">
                              <OpenModalButton
                                  openModal={hasPermission('role-create')?HF.openModal.bind(this, "addModalRole"):null}
                                  classButton={['backButton pt-sm no_radius pb-sm primary mt-none btn-block btn-info btn', !hasPermission('role-create')?'button_disabled':'']}
                                  buttonName="Add Role"
                                  tooltipText={hasPermission('role-create')?'Add Role':adminLabels.DONT_ALLOW }
                                  classIcon={['fa', 'fa-plus']}
                                />
                            </div>

                            <div className="custom-search-input">
                              <FormInputs
                                ncols={["col-md-12"]}
                                onChange={this.filterSearchHandle}
                                proprieties={[
                                  {
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Search Roles",
                                    onChange: this.filterSearchHandle,
                                    name: "filter"
                                  }
                                ]}
                              />
                            </div>
                          </div>
                        </Col>

                        <Col md={12} className="mt-md">
                          <ReactTable
                            noDataText= 'No role found'
                            data={rolesData}
                            pages={pages}
                            columns={columns}
                            filtered={filtered}
                            defaultPageSize={10}
                            loading={loadingRole}
                            className="-striped listing"
                            loadingText={'Loading...'}
                            pageData={this.rolesPaginationData}
                            manual
                            onFetchData={(state, instance) => {
                              var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
                              state.pageData(state.page + 1, state.filtered, sort, state.pageSize);
                            }}
                          />
                        </Col>
                      </Row>
                    </Grid>

                    {/*Add Role Modal Start*/}

                      <Modal backdrop={'static'} show={this.state.addModalRole} onHide={this.closeModalRole} aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                          <Modal.Title id='ModalHeader' className="headerTitle">Add Role</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12">
                              <Card bsClass={['innerCard mb-none']} content={
                                <RoleForm
                                  closeModalRoleEditPro={this.closeModalRoleEdit}
                                  closeModalRolePro={this.closeModalRole}
                                  formTypePro="add"
                                  rolePro=''
                                  handleRoleSubmitParPro={this.handleRoleSubmitPar}
                                  handleRoleEditSubmitParPro={this.handleRoleEditSubmitPar}
                                  componentPro="adminRole"
                                />
                              } />
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>


                    {/*Add Role Modal End*/}

                    {/*Edit Role Modal start*/}
                    {editRole &&
                      <Modal backdrop={'static'} show={this.state.editModalRole} onHide={this.closeModalRoleEdit} aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                          <Modal.Title id='ModalHeader' className="headerTitle">Edit Role</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12">
                              <Card bsClass={['innerCard mb-none']} content={
                                <RoleForm
                                  closeModalRoleEditPro={this.closeModalRoleEdit}
                                  closeModalRolePro={this.closeModalRole}
                                  formTypePro="edit"
                                  rolePro={role}
                                  handleRoleSubmitParPro={this.handleRoleSubmitPar}
                                  handleRoleEditSubmitParPro={this.handleRoleEditSubmitPar}
                                  componentPro="adminRole"
                                />
                              } />
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    }
                    {/*Edit Role Modal End*/}
                  </div>
                } />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };
};

const mapDispatchToProps = dispatch => {
  return ({
    getRolesPaginationCall: (page, filter, sort, pageSize) => { dispatch(roleActions.getRolesPagination(page, filter, sort, pageSize)) },
    getRoleCall: (id) => { dispatch(roleActions.getRole(id)) },
    storeRoleCall: (roleData) => { dispatch(roleActions.storeRole(roleData)) },
    editRoleCall: (roleData, id) => { dispatch(roleActions.editRole(roleData, id)) },
    deleteRoleCall: (id) => { dispatch(roleActions.deleteRole(id)) },
    getAllPermissionCall: () => { dispatch(permissionActions.getAllPermissions()) },
    getAllParentLabelCall: () => { dispatch(permissionActions.getAllParentLabel()) },
  });
};

function mapStateToProps(state) {
  const { permissions, loadingPermissions, parent_labels } = state.permissionsReducer;
  const { editRole, loadingRole, rolesData, pages, storedRole, updatedRole, deleteRole, current_page } = state.rolesReducer;
  console.log('editRole', editRole);
  return {
    rolesData, parent_labels, loadingRole, pages, storedRole, updatedRole, editRole, deleteRole, current_page, permissions, loadingPermissions
  };
};

const Roles = connect(mapStateToProps, mapDispatchToProps)(RolesListPage);
export default Roles;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Row,
  Col,
  // Tooltip,
  OverlayTrigger,
  // Button,
  Popover
} from "react-bootstrap";
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import PagesForm from "./PagesForm";
import { hasPermission } from 'helper/hasPermission';
import Confirmalertfordelete from '../../ThemeComponents/confirmAlertForDelete';
import { adminLabels } from '../../../redux/constant/admin-label-constant';
import OpenModalButton from '../../ThemeComponents/openModelButton';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import Pagination from '../../ThemeComponents/Pagination';

import { cmsService } from '../../../services/cms';

var Modal = require('react-bootstrap-modal');

class PagesListingPage extends Component{
  constructor(props){
  	super(props);
  	this.state = {
  	  addModalForm  : false,
      editModalForm : false,
      sorted   : '',
      filtered : '',
      pageSize : 10,
  	};

  	this.editRecord = this.editRecord.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
  	this.deleteConfirmModal = this.deleteConfirmModal.bind(this);
    this.getPaginationData  = this.getPaginationData.bind(this);
    this.filterSearchHandle     = this.filterSearchHandle.bind(this);
    this.submitForm= this.submitForm.bind(this);
  };

  submitForm(formData, formType) {
    if (formType === 'add'){
        cmsService.storePage(formData).then(res => {
            this.setState({addModalForm:false});
            this.pagination.dataCall();
          });
    }else if (formType === 'edit'){
        cmsService.editPage(formData, this.state.editRec.id).then(res => {
            this.setState({editModalForm:false});
            this.pagination.dataCall();
          });
    }
  }

  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }

  editRecord(id)
  {
    cmsService.getPage(id).then(res => {
        console.log(res);
        this.setState({editRec:res.data}, ()=>{
            this.setState({editModalForm:true});
        });
      });
  }

  deleteRecord(recID)
  {
    cmsService.deletePage(recID).then(res => {
      this.pagination.dataCall();
    });
  }

  deleteConfirmModal(recID)
  {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Record" description="Are you sure to delete the Record?" onClosePro={onClose} deleteType={() => this.deleteRecord(recID)} />
        )
      }
    })
  }


getPaginationData(page, filter, sort, pageSize, status)
{
    this.setState({ pageSize: pageSize }, function () {
      cmsService.paginationData(page, filter, sort, pageSize, status);
    });
}

updateStatus = recID => (e) => {
// return false;
cmsService.updatePageStatus(recID, { is_active: e.target.checked ? 1 : 0 }).then(
    response => {
    cmsService.paginationData(this.props.current_page, '', '', this.state.pageSize, this.state.rec_status);
    this.pagination.dataCall();
    }
);
}

  componentDidUpdate(prevProps, prevState)
  {
    if (this.state.rec_status !== prevState.rec_status) {
      this.table.state.onFetchData(this.table.state, this.table.instance)
    }

  }

  paginationCall = (data) => {
    return cmsService.paginationData(data.page, data.filter, data.sort, data.pageSize,this.state.rec_status);
  }




  render()
  {
  	const { filtered,editRec,types } = this.state;
    const columns = [
        {
          Header:"Page Listing",
          headerClassName:'headerPagination',
          columns: [
            {
              Header: "Title",
              accessor: "page_title",
              className: "justifyStart text-left",
              headerClassName: "justifyStart text-left"
            },
            {
                Header: "Slug",
                accessor: "page_slug",
                className: "justifyStart text-left",
                headerClassName: "justifyStart text-left"
              },
            {
              Header: "Status",
              accessor: "is_active",
              Cell: ({row}) => (
                  <label className="container-check">
                    &nbsp;
                    <input
                      type="checkbox" name="is_active" className="checkbox" value={row.is_active? 1 : 0} onChange={this.updateStatus(row.id)}  checked={row.is_active?1:0}
                     /><span className="checkmark"></span>
                  </label>
                ),
              className: "action-center",
              sortable:false
            },
            {
              Header:"Actions",
              accessor: "id",
              Cell: ({value}) => (
                <div>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Edit Page</Popover>}>
                  <span>
                  {
                    <button type='button' className="editIcon orderDetailsAction" onClick={() => this.editRecord(value)}>&nbsp;</button>
                  }

                  </span>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Delete Page</Popover>}>
                  <span>
                  {

                    <button type='button' className="deleteIcon orderDetailsAction" onClick={() => this.deleteConfirmModal(value)}>&nbsp;</button>
                  }
                  </span>

                </OverlayTrigger>
                </div>
                ),
              className: "justifyEnd text-right",
              headerClassName: "justifyEnd text-right",
              sortable:false
            }
          ]
        }
    ];

  	return(
  		<div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                	<div>
                  	<Grid fluid>
                      <Row>
                          <Col md={12}>
                            <div className="secHeading">Page Listing</div>
                          </Col>

                          <Col md={12}>
                            <div className="flexElem flexResponsive" style={{"alignItems": "flex-start", "justifyContent": "space-between"}}>
                              <div className="">
                                <OpenModalButton
                                  openModal={HF.openModal.bind(this, "addModalForm")}
                                  classButton={['backButton pt-sm no_radius pb-sm primary mt-none btn-block btn-info btn']}
                                  buttonName="Add Page"
                                  classIcon={['fa', 'fa-plus']}
                                />
                              </div>


                            </div>
                          </Col>

                          <Col md={12} className="mt-md">
                          <Pagination
                          ref={(p) => this.pagination = p}
                          showPagination={false}
                          columns={columns}
                          pageSize={20}
                          getDataCall={this.paginationCall}
                          filterView={true}
                          filterPlaceHolder={'pages'}
                          defaultSorted={
                            [
                              {
                                id: 'id',
                                desc: true
                              }
                            ]
                          }
                          noDataText='No Record found'
                          getRowProps={this.getRowProps}
                        />
                          </Col>
                      </Row>
                    </Grid>

                    { types !== null &&
                      <Modal backdrop={'static'} show={this.state.addModalForm} onHide={HF.closeModal.bind(this, "addModalForm")} aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                          <Modal.Title id='ModalHeader' className="headerTitle">Add Page</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12">
                              <Card bsClass={["innerCard mb-none"]} content={
                                <PagesForm
                                  closeModel={HF.closeModal.bind(this, "addModalForm")}
                                  formTypePro="add"
                                  recPro={OC.PAGES}
                                  submitForm= { this.submitForm }
                                  componentPro="PagesListing"
                                  types={types}
                                />
                              } />
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    }
                    {/*Add Record Modal End*/}

              		  {/*Edit Record Modal start*/}
                    { editRec &&
                      <Modal backdrop={'static'} show={this.state.editModalForm} onHide={HF.closeModal.bind(this, "editModalForm")} aria-labelledby="ModalHeader" >
                      <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Edit Page</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-md-12">
                          <Card bsClass={["innerCard mb-none"]} content={
                            <PagesForm
                              closeModel={HF.closeModal.bind(this, "editModalForm")}
                              formTypePro="edit"
                              recPro={editRec}
                              submitForm= { this.submitForm }
                              types={types}
                            />
                          } />
                          </div>
                        </div>
                      </Modal.Body>
                      </Modal>
                    }
                    {/*Edit Record Modal End*/}
                	</div>
                } />
            </Col>
          </Row>
        </Grid>
      </div>
  	);
  };
}
const PagesListing = PagesListingPage;
export default PagesListing;

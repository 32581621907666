
import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';
import { FaRegImage } from "react-icons/fa";
import { RiVideoLine } from 'react-icons/ri';
import { FaFileDownload } from "react-icons/fa";
import { FaFileExcel } from "react-icons/fa";
import LicenseForm from "./LicenseForm";
import { hasPermission } from 'helper/hasPermission';
import Confirmalertfordelete from '../../ThemeComponents/confirmAlertForDelete';
import { adminLabels } from '../../../redux/constant/admin-label-constant';
import OpenModalButton from '../../ThemeComponents/openModelButton';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import Pagination from '../../ThemeComponents/Pagination';
import DatePicker from "react-datepicker";
import { splashService } from '../../../services/splash';
import { licenseService } from '../../../services/license';
import ReactValidator from "simple-react-validator";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "react-switch";
import Eye from "../../../assets/img/eye.svg"
import CaretLogo from "../../../assets/img/logo_frontEnd.png";



var cloneDeep = require('lodash.clonedeep');
var Modal = require('react-bootstrap-modal');
const moment = window.moment;

class LicenseListingPage extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props.match.params;
    const user = JSON.parse(localStorage.getItem('user'));
    // ====================================
    this.dateValidator = new ReactValidator();
    var today = new Date();
    today.setDate(today.getDate() + 1);
    var lastmonth = new Date();
    lastmonth.setMonth(lastmonth.getMonth() - 1);
    var startDate = lastmonth.getFullYear() + '-' + (lastmonth.getMonth() + 1) + '-' + lastmonth.getDate();
    var endDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    // ====================================
    this.state = {
      addModalForm: false,
      editModalForm: false,
      openVideoModal: false,
      campaignModal: false,
      campaignData: OC.CAMPAIGN,
      video_url: '',
      selectedVideo: [],
      sorted: '',
      filtered: '',
      pageSize: 10,
      userID: id,
      statusList: [],
      // search: { a: 1 },
      search:{
        fromDate : startDate,
        toDate: endDate,
        status_id:false,
        // is_reported:false
      },
    };

    this.editRecord = this.editRecord.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
    this.deleteConfirmModal = this.deleteConfirmModal.bind(this);
    this.getPaginationData = this.getPaginationData.bind(this);
    this.filterSearchHandle = this.filterSearchHandle.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.getUsersCall = this.getUsersCall.bind(this);
    this.videoModal = this.videoModal.bind(this);
    this.closeModal = HF.closeModal.bind(this);
    this.callFunction = this.callFunction.bind(this);
  };

  callFunction(formData, formType) {
    this.setState({ campaignData: false });
  }

  videoModal(url) {
    this.setState({video_url:url}, ()=>{
        console.log('video_url',this.state.video_url);
        this.setState({openVideoModal:true});
        console.log('uopenVideoModalrl', this.state.openVideoModal);
        console.log('url',this.state.video_url);
    });

  }
  
  submitForm(formData, formType) {
    if (formType === 'add') {
      licenseService.storeLicense(formData).then(res => {
        this.setState({ addModalForm: false });
        this.pagination.dataCall();
      });
    } else if (formType === 'edit') {
      licenseService.editLicense(formData, this.state.editRec.id).then(res => {
        this.setState({ editModalForm: false });
        this.pagination.dataCall();
      });
    }
  }

  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }

  editRecord(id) {
    licenseService.getLicense(id).then(res => {
      console.log(res);
      this.setState({ editRec: res.data }, () => {
        this.setState({ editModalForm: true });
      });
    });
  }

  // showCampaignModal = (id, user_id) => {

  //   let cmpdata = { ...this.state.campaignData };
  //   cmpdata['user_id'] = user_id;
  //   cmpdata['ad_id'] = id;
  //   this.setState({ campaignData: cmpdata });
  //   this.setState({ campaignModal: true });
  // }


  deleteRecord(recID) {
    licenseService.deleteLicense(recID).then(res => {
      this.pagination.dataCall();
    });
  }

  deleteConfirmModal(recID) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Record" description="Are you sure to delete the Record?" onClosePro={onClose} deleteType={() => this.deleteRecord(recID)} />
        )
      }
    })
  }


  getPaginationData(page, filter, sort, pageSize, status) {
    this.setState({ pageSize: pageSize }, function () {
      licenseService.paginationData(this.state.userID, page, filter, sort, pageSize, status, this.state.search);
    });
  }

  updateStatus = recID => (e) => {
    // return false;
    licenseService.updateLicenseStatus(recID, { is_active: e.target.checked ? 1 : 0 }).then(
      response => {
        licenseService.paginationData(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
        this.pagination.dataCall();
      }
    );
  }

  updateBlocked = recID => (e) => {
    // return false;
    splashService.blockRecord(recID).then(
      response => {
        licenseService.paginationData(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
        this.pagination.dataCall();
      }
    );
  }



  

  componentDidUpdate(prevProps, prevState) {
    if (this.state.rec_status !== prevState.rec_status) {
      this.table.state.onFetchData(this.table.state, this.table.instance)
    }

  }

  paginationCall = (data) => {
    return licenseService.paginationData(this.state.userID, data.page, data.filter, data.sort, data.pageSize, this.state.rec_status, this.state.search);
  }


  getUsersCall() {
    licenseService.getUsers().then(res => {
      console.log('Users', res);
      this.setState({ users: res.data });
    });
  }

  componentDidMount() {
    this.getUsersCall();
    this.getStatusCall();
  }


  getStatusCall = (val) => {
    licenseService.getStatus(val).then(res => {
        console.log('Status', res);
        this.setState({statusList:res.data});
        });
    }


  handleUpdateLicenseStatus = (license_id, status_id) => {
    const data = {
      license_id: license_id,
      status_id: status_id
    };
    // console.log("data", data)
    licenseService.licenseStatus(data).then((res) => {
      // console.log(res)
      this.pagination.dataCall();
    });
  }

  DateChangeHandler = (value, field) => {
    const temp = cloneDeep(this.state.search);
    temp[field] = value === null ? '' : moment(value).format("YYYY-MM-DD");

    this.setState({ search : temp  }, () => {

        if (this.dateValidator.allValid()) {
          licenseService.paginationData(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
          this.pagination.dataCall();
        } else {
            this.dateValidator.showMessages();
            this.forceUpdate();
        }
    })
  }
  dateElement(label, name, value, rules) {
    var selectedDate =  this.state.search[name] !== '' ? new Date(this.state.search[name]) :'';
    const { fromDate, toDate } = this.state.search;
    console.log(value);
    return (
        <div className='form-group fullCalendar' id={name}>
              <label>{label}</label>
              <DatePicker className="form-control calender_icon"  selected={  selectedDate } name={name} onChange={ (event) => this.DateChangeHandler(event, name) } />
              {
                  ( fromDate !== '' && toDate !== '' ) &&
                  this.dateValidator.message(name, value, rules)
              }
        </div>
    );
  }

  handleCheckPending = (checked) => {
    const temp = cloneDeep(this.state.search);
    temp['status_id'] = checked;
    this.setState({ search : temp  }, () => {
      licenseService.paginationData(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
      this.pagination.dataCall();
    });
  }



  render() {
    const { filtered, editRec, users, campaignData } = this.state;
    const { toDate, fromDate } = this.state.search;

    const columns = [
      {
        Header: "Splash Listing",
        headerClassName: 'headerPagination',
        columns: [
          {
            Header: "My Logo",
            accessor: "video_url",
            Cell: ({ row, original }) => (
              // console.log('original', original.caret_logo),
              original.caret_logo ? (

                <div style={{ cursor: 'pointer', fontSize: '20px' }} >
                  <img style={{hight:"25px", width:"40px"}} src={original.caret_logo} />
                </div>
              ) : (
                <div>
                <button type='button' className="caretIcon orderDetailsAction">
                    &nbsp;</button>
              </div>
              )
            ),
            sortable: false
          },
          {
            Header: "My ^Caret",
            accessor: "caret_title",
            className: "",
            sortable: false
          },
          {
            Header: "Company Name",
            accessor: "company_name",
            className: "",
            sortable: false
          },
          {
            Header: "License Type",
            accessor: "title",
            Cell: ({ original }) => (
              original.pricing ? (
                <div>{original.pricing.title}</div>
              ) : (
                <div>N/A</div>
              )
            ),
            className: "",
            sortable: false
          },
          {
            Header: "User",
            accessor: "user",
            Cell: ({ value }) => {
              const email = value ? value.email : "";
              return (
                <div>
                  {email}
                </div>
              );
            },
            className: "",
            sortable:false
          },
          {
            Header: "Start Date",
            accessor: "startedOn",
            Cell: ({ row, original }) => (
              console.log('original', original.payments),
              <div>
                {original.payments && original.payments[0] ? HF.TStoDate(original.payments[0].current_period_start) : ''}
              </div>
            ),
            sortable: false
          },

          {
            Header: "End Date",
            Cell: ({ value, original }) => (
              <div>
                {original.payments && original.payments[0] ? HF.TStoDate(original.payments[0].current_period_end) : ''}
                </div>
            ),
            sortable: false
          },
          {
            Header: "Status",
            Cell: ({ row, original}) => {
              console.log('row', row);
              const currentStatusId = row._original ? row._original.status_id : '';
              console.log('currentStatusId', currentStatusId);

              return (
                <div>
                  <select
                    style={{ cursor: 'pointer', width: "90%", border: "none" }}
                    value={currentStatusId}
                    onChange={(e) => {
                      const newStatus = parseInt(e.target.value);
                      this.handleUpdateLicenseStatus(row._original.id, newStatus);
                    }}
                  >
                    {this.state.statusList
                      .filter(status => currentStatusId === 1 || status.value !== 1)
                      .map((status) => (
                        <option key={status.value} value={status.value}>
                          {status.label}
                        </option>
                      ))}
                  </select>
                </div>
              );
            },
            sortable: false
          },
          {
            Header: "Certificate",
            accessor: "id",
            Cell: ({ row, original, value }) => (
              console.log('value', original),
              <div>
                {original && original.subscription_status === 'active' ?(
                  <Link to={{
                  pathname: `/Admin/clm-certificate/${value}`,
                  // state: {cId: original.id, title: original.caret_title, type:original.plan && original.plan.title, startDate: original.payments && original.payments[0] ? HF.TStoDateForm(original.payments[0].current_period_start) : '', endDate: original.payments && original.payments[0] ? HF.TStoDateForm(original.payments[0].current_period_end) : '' },
                  state: {cId: original.id, title: original.caret_title, type:original.pricing && original.pricing.title, startDate: original.payments && original.payments[0] ? HF.TStoDateForm(original.payments[0].current_period_start) : '', endDate: original.payments && original.payments[0] ? HF.TStoDateForm(original.payments[0].current_period_end) : '' },
                  
                  }}>
                  <FaFileDownload  size={30}/>
                  </Link>

                   ):(

                    <FaFileExcel size={30}/>
  
                  )
               }
              </div>
            ),
            sortable: false

          },

       
        
          // {
          //   Header: "Actions",
          //   accessor: "id",
          //   Cell: ({ row, original, value }) => (
          //     <div>

          //       <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Default Selection</Popover>}>
          //         <Link to={'/CLM/CaretDefaultSelection/' + value}>
          //           <button type='button' className="editIcon orderDetailsAction">&nbsp;</button>
          //         </Link>
          //       </OverlayTrigger>

          //       <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Delete License</Popover>}>
          //         <span>
          //           {
          //             // <button type='button' className="deleteIcon orderDetailsAction" onClick={() => this.deleteConfirmModal(row.id)}>&nbsp;</button>
          //           }
          //         </span>

          //       </OverlayTrigger>
          //     </div>
          //   ),
          //   className: "justifyEnd text-right",
          //   headerClassName: "justifyEnd",
          //   sortable: false
          // }


        ]
      }
    ];

    return (
      <div className="content pl-2-5F">
        <Row>
          <Col md={6}>
            <div className="secHeading">My ^Caret Licenses</div>
          </Col>
          <Col md={6}>
            <div className="justifyEnd flexElem flexResponsive mb-lg" style={{ "alignItems": "flex-start", "justifyContent": "space-between" }}>
              {/* <div className="">
                <OpenModalButton
                  openModal={HF.openModal.bind(this, "addModalForm")}
                  classButton={['backButton pt-sm no_radius pb-sm primary mt-none btn-block btn-info btn']}
                  buttonName="Add Licanse"
                  classIcon={['fa', 'fa-plus']}
                />
              </div> */}


            </div>
          </Col>

          <div className='col-md-12 mt-1'>

          <Col md={2}>
              <div className='row flexElem flexResponsive align-items-center' >
                  <div className="col-md-12" >
                  {
                      this.dateElement('Date From', 'fromDate', fromDate!=='' && moment(fromDate, 'YYYY-MM-DD'), [{ before_or_equal: moment(this.state.search.toDate) }])
                  }
                  </div>
              </div>
          </Col>
          <Col md={2} >
              <div className='row flexElem flexResponsive align-items-center' >
                  <div className="col-md-12" >
                  {
                      this.dateElement('Date To', 'toDate', toDate!=='' && moment(toDate, 'YYYY-MM-DD'), [{ after_or_equal: moment(this.state.search.fromDate) }])
                  }
                  </div>

              </div>
          </Col>

          <Col md={2} >
            <div className='row flexElem flexResponsive align-items-center' >
                <div className="col-md-12" >
                <label>
                    <span>Pending Status</span>
                </label>
                </div>
                <div className="col-md-12 mt-sm" >
                <Switch onChange={this.handleCheckPending} checked={this.state.search.status_id} />
                </div>
            </div>
        </Col>
        </div>


          <Col md={12} className="mt-md">
            <Pagination
              ref={(p) => this.pagination = p}
              showPagination={false}
              columns={columns}
              pageSize={20}
              getDataCall={this.paginationCall}
              filterView={true}
              filterPlaceHolder={'License'}
              defaultSorted={
                [
                  {
                    id: 'id',
                    desc: true
                  }
                ]
              }
              // downloadData={true}
              // downloadFileName={'Orders'}
              // lowerContent = {null}
              // filterPlaceHolder = {'Orders'}
              noDataText='No Record found'
              getRowProps={this.getRowProps}
            // showAllToggle={true}
            />
          </Col>
        </Row>
        {users !== null &&
          <Modal backdrop={'static'} show={this.state.addModalForm} onHide={HF.closeModal.bind(this, "addModalForm")} aria-labelledby="ModalHeader" className="modalLG modal-lg mx-auto">
            <Modal.Header closeButton>
              <Modal.Title id='ModalHeader' className="headerTitle">Add Splash</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <Card bsClass={["innerCard mb-none"]} content={
                    <LicenseForm
                      closeModel={HF.closeModal.bind(this, "addModalForm")}
                      formTypePro="add"
                      recPro={OC.LICENSE}
                      user_id={this.state.userID}
                      submitForm={this.submitForm}
                      componentPro="AdListing"
                      users={users}
                    />
                  } />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        }
        {/*Add Record Modal End*/}

        {/*Edit Record Modal start*/}
        {editRec &&
          <Modal backdrop={'static'} show={this.state.editModalForm} onHide={HF.closeModal.bind(this, "editModalForm")} aria-labelledby="ModalHeader" className="modalLG modal-lg mx-auto">
            <Modal.Header closeButton>
              <Modal.Title id='ModalHeader' className="headerTitle">Edit Splash</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <Card bsClass={["innerCard mb-none"]} content={
                    <LicenseForm
                      closeModel={HF.closeModal.bind(this, "editModalForm")}
                      formTypePro="edit"
                      recPro={editRec}
                      user_id={this.state.userID}
                      submitForm={this.submitForm}
                      users={users}
                    />
                  } />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        }


                    {
                      <Modal backdrop={'static'} show={this.state.openVideoModal} onHide={HF.closeModal.bind(this, "openVideoModal")} aria-labelledby="ModalHeader" >
                      <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Video</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-md-12" style={{textAlignVertical: "center",textAlign: "center"}}>
                          <video key={this.state.video_url} id="playVid" controls style={{width: 'auto', maxHeight: 'calc(100vh - 150px)'}} ><source src={this.state.video_url} type="video/mp4" /></video>
                          </div>
                        </div>
                      </Modal.Body>
                      </Modal>
                    }

      </div>
    );
  };
}
const LicenseListing= LicenseListingPage;
export default LicenseListing;



import React from 'react';
import {
  Button
} from "react-bootstrap";


import SimpleReactValidator from 'simple-react-validator';
import { clmPlanService } from '../../../../services/CLMplan';

import Select from 'react-select';
import { helperFunctions as HF } from '../../../../helper/helperFunctions';
import { type } from 'jquery';

class CaretPricingForm extends React.Component {
	constructor(props){
		super(props);
		this.validatorForm = new SimpleReactValidator({autoForceUpdate: this, locale: 'en'});
		this.state = {
			catPri: {
				...this.props.recPro,
                plan_id: this.props.recPro ? this.props.recPro.plan_id : '',
           
			},
		    submitted: false,
            plans:[],
		    formType: this.props.formTypePro 	|| '',
		};

		this.handleRec = this.handleRec.bind(this);
        this.handleRecSelect = this.handleRecSelect.bind(this);
  		this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.getPlansCall= this.getPlansCall.bind(this);

	}

    handleTypeChange(selectedOption) {
        this.setState({
            catPri: {
            ...this.state.catPri,
            plan_id: selectedOption ? selectedOption.value : '',
          },
        });
      }


	handleRec(e){
	    const { name, value, type } = e.target;
        const recState = { ...this.state.catPri };
	    recState[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
	    this.setState({catPri: recState});
	}

    handleRecSelect(selectedOptionType, info) {
        console.log("selectedOptionType ######## ",selectedOptionType, info)
        if (info['action'] === 'select-option') {
          let recState = { ...this.state.catPri };
          recState[info['name']] = selectedOptionType['value'];
          console.log(recState);
          this.setState({catPri: recState});
        }
    }

	handleSubmitForm(e){
	    e.preventDefault();
	    if (this.validatorForm.allValid()) {
			this.setState({submitted: true}, function(){
				this.props.submitForm(this.state.catPri, this.state.formType);
			});
		} else {
			this.setState({submitted: false});
			this.validatorForm.showMessages();
			this.forceUpdate();
		}
	}

    componentDidMount() {
        this.getPlansCall();
      }

    getPlansCall(){
        clmPlanService.getAllPlan().then(res => {
            console.log('plans',res.data);
            this.setState({plans:res.data});
          });
      }

	render(){
		const { catPri,formType, allowedFormatsStringMime, allowedFormatsStringExt, allowedFormatsVideoMime, allowedFormatsVideoExt} = this.state;
		const { plans } = this.state;
        console.log('keyword',plans);
    
		return(
			<form onSubmit={this.handleSubmitForm}>
                 { formType === 'edit' &&
			        	<input type="hidden" name="caretPricing_id" className="form-control"  value={catPri.id}/>
			    }
                <div className="row">
                    <div className="col-md-6">
                        <div className='form-group'>
                            <label htmlFor="duration">Title<span className="requiredClass">*</span></label>
                            <input type="text" name="title" className="form-control"  value={catPri.title} onChange={this.handleRec} />
                            {this.validatorForm.message('title', catPri.title, 'required|max:250')}

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className=''>
                            <label className="container-check checkbox_down"> Is Active?
                            <input type="checkbox" name="is_active" className="checkbox" value={catPri.is_active? 1 : 0} onChange={this.handleRec} checked={catPri.is_active?1:0} />
                            <span className="checkmark"></span>
                            </label>
                            {/* {this.validatorPermission.message('is_active', catPri.is_active, 'required')} */}
                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-md-6">
                        <div className='form-group'>
                            <label htmlFor="duration">Contact Amount<span className="requiredClass"></span></label>
                            <input type="text" name="contact_amount" className="form-control"  value={catPri.contact_amount} onChange={this.handleRec} />
                            {/* {this.validatorForm.message('contact_amount', catPri.title, 'required|max:250')} */}

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className=''>
                            <label className="container-check checkbox_down"> Contact Sales?
                            <input type="checkbox" name="contact_sales" className="checkbox" value={catPri.contact_sales? 1 : 0} onChange={this.handleRec} checked={catPri.contact_sales?1:0} />
                            <span className="checkmark"></span>
                            </label>
                            {/* {this.validatorPermission.message('is_active', catPri.is_active, 'required')} */}
                        </div>
                    </div>
                </div>

			<div className="row">
                <div className="col-md-6">
                    <div className='form-group'>
                        <label htmlFor="networth">Net Worth</label>
                        <input type="text" name="networth" className="form-control" value={catPri.networth} onChange={this.handleRec} />
                        {/* {this.validatorForm.message('networth', catPri.networth, 'max:255')} */}
                    </div>
                </div>
                    <div className="col-md-6">
                    <div className='form-group'>
                        <label htmlFor="instagram_followers">Instagram Followers</label>
                        <input type="text" name="instagram_followers" className="form-control" value={catPri.instagram_followers} onChange={this.handleRec} />
                        {/* {this.validatorForm.message('instagram_followers', catPri.instagram_followers, 'max:255')} */}
                    </div>
                </div>     
            </div>

			<div className="row">
                <div className="col-md-6">
                    <div className='form-group'>
                        <label htmlFor="twitter_followers">Twitter Followers</label>
                        <input type="text" name="twitter_followers" className="form-control" value={catPri.twitter_followers} onChange={this.handleRec} />
                        {/* {this.validatorForm.message('twitter_followers', catPri.twitter_followers, 'max:255')} */}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className='form-group'>
                        <label htmlFor="google_searches">Google Searches</label>
                        <input type="text" name="google_searches" className="form-control" value={catPri.google_searches} onChange={this.handleRec} />
                        {/* {this.validatorForm.message('google_searches', catPri.google_searches, 'max:255')} */}
                    </div>
                </div>     
            </div>

			<div className="row">
                <div className="col-md-6">
                    <div className='form-group'>
                        <label htmlFor="one_year_license">1 Year License</label>
                        <input type="text" name="one_year_license" className="form-control" value={catPri.one_year_license} onChange={this.handleRec} />
                        {/* {this.validatorForm.message('one_year_license', catPri.one_year_license, 'numeric')} */}
                    </div>
                </div>
                <div className="col-md-6">
                <div className='form-group'>
                    <label htmlFor="two_year_license">2 Year License</label>
                    <input type="text" name="two_year_license" className="form-control" value={catPri.two_year_license} onChange={this.handleRec} />
                    {/* {this.validatorForm.message('two_year_license', catPri.two_year_license, 'numeric')} */}
                </div>
                </div>     
            </div>

			<div className="row">
                <div className="col-md-6">
                    <div className='form-group'>
                        <label htmlFor="three_year_license">3 Year License</label>
                        <input type="text" name="three_year_license" className="form-control" value={catPri.three_year_license} onChange={this.handleRec} />
                        {/* {this.validatorForm.message('three_year_license', catPri.three_year_license, 'numeric')} */}
                    </div>
                    
                </div>
                <div className="col-md-6">
                    <div className='form-group'>
                        <label htmlFor="special_instructions">Special Instructions</label>
                        <input type='text' name="special_instructions" className="form-control" value={catPri.special_instructions} onChange={this.handleRec} />
                        {/* {this.validatorForm.message('special_instructions', catPri.special_instructions, 'max:500')} */}
                    </div>
                    
                </div>     
            </div>
			<div className="row">
                <div className="col-md-12">
                        <div className='form-group'>
                            <label htmlFor="plan">Select Plan<span className="requiredClass">*</span></label>
                            <Select
                                name="typeSelect"
                                options={plans}
                                className="basic-single"
                                classNamePrefix="select"
                                value={plans.find(plan => plan.value === this.state.catPri.plan_id)} // Set selected value
                                onChange={this.handleTypeChange} // Handle dropdown change
                                />
                        </div>
                    
                </div>  
            </div>

               
			  <div className="row">
			    <div className="col-md-12">
			      <div className='text-center'>
			        <Button  type='submit' bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default " >Save</Button>
			        <Button bsSize="large" bsStyle="info" onClick={()=>this.props.closeModel() } className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info " >Cancel</Button>
			      </div>
			    </div>
			  </div>
			</form>
		);
	}
}


export default CaretPricingForm;

import React, { Component } from 'react';
import Login from 'components/Advertiser/Login';

class AdvertiserLogin extends Component{
 	render(){
    require('bootstrap/dist/css/bootstrap.min.css')
    require('../../assets/css/animate.min.css')
    require('../../assets/sass/light-bootstrap-dashboard.css?v=1.2.0')
    require('../../assets/css/demo.css')
    require('../../assets/css/pe-icon-7-stroke.css')
    require('../../assets/fonts/robotoFont.css')
  	return(
    	<div className="container">
        <div className="content">
          <div className="row">
            <Login />
          </div>
        </div>
      </div>
  	);
  }
}
export default AdvertiserLogin;

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { appuserService } from '../../services/appuser';
import appBackgourndImg from "assets/img/logo_frontEnd.png";
import MessageCom from '../../layouts/Advertiser/Message';
import caretFooterLogo from '../../assets/img/logo_white.png'
import user from '../../assets/img/user.png'
import { Button } from 'reactstrap';
class Test extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        email: ''
      },
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    console.log("asdfkjhasdkf askdfh ");
  }

  handleChange = (e) => {
    const { name, value, type } = e.target;
    const recState = { ...this.state.formData };
    recState[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
    this.setState({formData: recState});
    }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });
    const { email} = this.state.formData;
    if (email) {
        appuserService.sendPasswordResetLink(this.state.formData).then(res => {
            console.log("sendPasswordResetLink",res);
            // window.location.replace("/Advertiser-Login");
            window.location.replace("/");
          });
    }
  }

  render() {
    const { email} = this.state.formData;
    return (
      <Fragment>
        <div className='fixLoginWrap'>
        <h1>Test</h1>
                <p>Testaaaaaaaaaaaaaaaaaaaaaaaaaa</p>
                <p>Testbbbbbbbbbbbbbbbbbbbbbbbb</p>
        </div>
      </Fragment>
    );
  }
}


export default Test;

import { SC } from '../helper/ServerCall';

export const cmsService = {
    paginationData,
    storePage,
    getPage,
    editPage,
    deletePage,
    updatePageStatus,
}
function paginationData(page, filter, sort, pageSize,status) {
    return SC.getCall('cms/pages?page=' + page + '&filter=' + filter + '&sort=' + sort + '&pageSize=' + pageSize);
}
function storePage(catData) {
    return SC.postCall('cms/pages', catData);
}

function getPage(id) {

    return SC.getCall('cms/pages/' + id);
}

function editPage(data, PageId) {
    data['page_id'] = PageId;
    return SC.postCall('cms/pages', data);
}

function deletePage(catId) {
    return SC.deleteCall('cms/pages/' + catId);
}

function updatePageStatus(catId, data) {
    data['page_id'] = catId;
    return SC.postCall('cms/pages/status',data);
}


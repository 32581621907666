import React from "react";
import Dropzone from "react-dropzone";
import Confirmalert from "../ThemeComponents/confirmAlert";
import { confirmAlert } from "react-confirm-alert";
import { helperFunctions as HF } from "../../helper/helperFunctions";
// var findOrientation = require('exif-orientation');
// import EXIF from "exif-js";
// window.EXIF = EXIF;
// var fixRotation = require('fix-image-rotation')
class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            warningMsg: "",
            showAlert: true
        };
        this.replaceImageAlert = this.replaceImageAlert.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.dropzoneRef = React.createRef();
    }
    toggle = () => {
        this.setState({ showAlert: !this.state.showAlert });
    };
    extraHtml = () => {
        return (
            <span>
                <i>Dont Show this pop up again</i>
                <input type="checkbox" onClick={() => this.toggle()} />
            </span>
        );
    };
    replaceImageAlert(index, fileObject) {
        var self = this;
        return new Promise(function(resolve, reject) {
            const options = {
                customUI: ({ onClose }) => (
                    <Confirmalert
                        header="Replace image with same name"
                        description={
                            "Are you sure to replace image " +
                            fileObject.name +
                            "?"
                        }
                        onClosePro={onClose}
                        extraHtml={self.extraHtml}
                        onNo={() => {
                            resolve({ replace: false });
                        }}
                        onYes={() => {
                            resolve({ replace: true });
                        }}
                    />
                ),
                closeOnEscape: false,
                closeOnClickOutside: false
            };
            confirmAlert(options);

            // confirmAlert({
            //   title: 'Confirm to submit',
            //   message: 'Are you sure to do this.',
            //   buttons: [
            //     {
            //       label: 'Yes',
            //       onClick: () => {
            //         resolve({replace:true});
            //       }
            //     },
            //     {
            //       label: 'No',
            //       onClick: () => {
            //         resolve({replace:false});
            //       }
            //     }
            //   ]
            // });
        });
    }
    async onDrop(accepted, rejected) {
        if (Object.keys(rejected).length !== 0) {
            const message = "Please submit valid file type";
            this.setState({ warningMsg: message });
        } else {
            // this.props.addFilePreview(accepted); // add file for preview
            this.setState({ warningMsg: "" });
            const generateFile = (count, fileObject) => {
                return new Promise((resolve, reject) => {
                    // let myRotationFunction = async function (fileObject) {
                    //   console.log('one', fileObject);
                    //   let blobOfArray = await fixRotation.fixRotation(fileObject)
                    //   console.log('two', blobOfArray);
                    //   return blobOfArray
                    // }
                    // console.log('three', myRotationFunction);

                    const reader = new window.FileReader();
                    reader.readAsDataURL(fileObject);
                    reader.onloadend = () => {
                        const base64data = reader.result;
                        fileObject["file"] = base64data;
                        // let ArrayOfFilesToBeRotated = fileObject
                        // console.log('ArrayOfFilesToBeRotated 1', ArrayOfFilesToBeRotated);
                        // let myRotationFunction = async function (ArrayOfFilesToBeRotated) {
                        //   console.log('ArrayOfFilesToBeRotated 2', ArrayOfFilesToBeRotated);
                        //   let blobOfArray = await fixRotation.fixRotation(ArrayOfFilesToBeRotated)
                        //   console.log('blobOfArray 1111', blobOfArray);

                        //   return blobOfArray;
                        // }
                        // console.log('ArrayOfFilesToBeRotated 3', myRotationFunction);
                        resolve({ index: count, fileObject: fileObject });
                    };
                });
            };
            if (this.props.multiple) {
                for (var i = 0; i < accepted.length; i++) {
                    var temp = accepted[i];
                    if (this.props.allowDuplicate) {
                        generateFile(i, temp).then(value => {
                            this.props.setResourceFile(
                                this.props.type ? this.props.type : null,
                                value.fileObject
                            ); //add file data to file aray
                        });
                    } else {
                        var chechDuplicate = HF.removeDuplicateImage(
                            temp,
                            this.props.files
                        );
                        if (
                            chechDuplicate.duplicate &&
                            this.props.askToUpdate
                        ) {
                            if (this.state.showAlert) {
                                const confirmBox = await this.replaceImageAlert(
                                    chechDuplicate.index,
                                    chechDuplicate.val
                                );
                                if (confirmBox.replace) {
                                    generateFile(
                                        chechDuplicate.index,
                                        chechDuplicate.val
                                    ).then(value => {
                                        this.props.updateResourceFile(
                                            value.index,
                                            value.fileObject
                                        ); //add file data to file aray
                                    });
                                }
                            } else {
                                generateFile(
                                    chechDuplicate.index,
                                    chechDuplicate.val
                                ).then(value => {
                                    this.props.updateResourceFile(
                                        value.index,
                                        value.fileObject
                                    ); //add file data to file aray
                                });
                            }

                            // var confirmBox=window.confirm("Are you sure to update the file "+temp.name);

                            // if(confirmBox)
                            // {
                            //   generateFile(chechDuplicate.index, temp).then((value)=>{
                            //     this.props.updateResourceFile(value.index, value.fileObject); //add file data to file aray
                            //   });
                            // }
                        } else if (
                            chechDuplicate.duplicate &&
                            this.props.updateWithoutAsking
                        ) {
                            generateFile(chechDuplicate.index, temp).then(
                                value => {
                                    this.props.updateResourceFile(
                                        value.index,
                                        value.fileObject
                                    ); //add file data to file aray
                                }
                            );
                        } else {
                            generateFile(i, temp).then(value => {
                                this.props.setResourceFile(
                                    this.props.type ? this.props.type : null,
                                    value.fileObject
                                ); //add file data to file aray
                            });
                        }
                    }
                }
            } else {
                generateFile(0, accepted[0]).then(value => {
                    this.props.setResourceFile(
                        this.props.type ? this.props.type : null,
                        value.fileObject
                    ); //add file data to file aray
                });
            }
        }
    }

    open() {
        if (this.dropzoneRef.current) {
            this.dropzoneRef.current.open();
        }
    }

    render() {
        const {
            allowedFormatsStringMimePro,
            allowedFormatsStringExtPro,
            multiple,
            title
        } = this.props;
        // console.log('allowedFormatsStringMimePro', allowedFormatsStringMimePro);
        // console.log('allowedFormatsStringExtPro', allowedFormatsStringExtPro);
        // console.log('multiple', multiple);
        // console.log('title', title);
        const render = (
            <p className="hello small">
                Please drop {title ? title : "files"} here with format{" "}
                {allowedFormatsStringExtPro}
            </p>
        );

        return (
            <div>
                <p>{this.state.warningMsg}</p>
                <Dropzone
                    ref={this.dropzoneRef}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "10vh",
                        border: " 2px dashed",
                        objectFit: "cover",
                        objetPosition: "center"
                    }}
                    multiple={multiple}
                    accept={allowedFormatsStringMimePro}
                    onDrop={(accepted, rejected) =>
                        this.onDrop(accepted, rejected)
                    }
                >
                    {({
                        isDragAccept,
                        isDragReject,
                        acceptedFiles,
                        rejectedFiles
                    }) => {
                        if (isDragReject) return "Please submit a valid file";
                        return render;
                    }}
                </Dropzone>
            </div>
        );
    }
}

export default FileUpload;

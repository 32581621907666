import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Header from "components/FrontEnd/Header/Header";
import Footer from "components/FrontEnd/Footer/Footer";
import FrontEndRoutes from "routes/FrontEndRoutes.jsx";
import { connect } from 'react-redux'
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/fonts/sourceSansPro.css";
import "../../assets/css/owl.carousel.css";
import "../../assets/css/owl.theme.css";
import "../../assets/css/owl.transitions.css";
import "../../assets/css/reset.css";
import "../../assets/css/style.css";
import MessageCom from "../Dashboard/Message";
import { loadProgressBar } from 'axios-progress-bar'
import User from "../../helper/User";
import { alertActions } from "../../redux/actions/alert-actions";
import { Roles } from "../../constants/Roles";
import { frontEndUserActions } from "../../redux/actions/front-end-action";
import Login from "../../components/FrontEnd/Login";
import { accountActions } from "../../redux/actions/account-action";
import { loadReCaptcha } from 'react-recaptcha-v3';

class Frontend extends Component {

  constructor(props) {
    super(props)
    
  }


  redirect = () => {
    this.props.sendNotification();
    if (User.hasRole(Object.values(Roles))) {
      return <Redirect to='/dashboard' />;
    } else {
      return <Redirect to='/' />
    }
  }

  componentDidMount() {
    // loadReCaptcha("6LfghcQUAAAAAEAubLXMLiiJU7xwBd7W3b3KPIAN");
  }

  getComponent = (prop) => {
    if (User.isAuthenticated()) {
      if ('role' in prop) {
        if (User.hasRole(prop.role)) {
          if ('needUploadApproved' in prop && 'needCameraShipment' in prop) {
            if ((User.getProperty('upload_approved') === prop.needUploadApproved) && (User.getProperty('cameraShipped') === prop.needCameraShipment)) {
              return prop.component
            } else {
              return this.redirect
            }
          }
          // if (User.hasRole(Roles.PHOTO_ADMIN) && 'needCameraShipment' in prop) {
          //   if (User.getProperty('cameraShipped') && prop.needCameraShipment) {
          //     return prop.component
          //   } else if (!User.getProperty('cameraShipped') && !prop.needCameraShipment) {
          //     return prop.component
          //   } else {
          //     return this.redirect
          //   }
          // }
          return prop.component
        } else {
          return this.redirect
        }
      } else {
        return prop.component
      }
    } else {
      return Login
    }
  }


  render() {
    return (
      <div className="section-frontEnd">
        <Header {...this.props} />
        <MessageCom />
        {
          loadProgressBar()
        }
        <div className="" ref="mainPanel">
          <Switch>
            {
              FrontEndRoutes.map((prop, key) => {
                if ('auth' in prop) {
                  if (prop.name === 'Logout') {
                    if (this.props.location.pathname === '/logout') {
                      this.props.logout()
                    }
                  } else if (!('render' in prop)) {
                    return (
                      <Route path={prop.path} component={
                        this.getComponent(prop)
                      } key={key} />
                    )
                  }
                } else {
                  if (prop.childNav) {
                    return prop.childNav.map((prop, key) => {
                      return (
                        <Route path={prop.path} component={prop.component} key={key} />
                      );
                    });
                  }
                  else {
                    return (
                      <Route path={prop.path} component={prop.component} key={key} />
                    );
                  }
                }
                return null;
              })
            }
          </Switch>
          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { User } = state.frontEndUserReducer;
  return {
    User,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    sendNotification: () => { dispatch(alertActions.error('You don\'t have permission to access this page')) },
    logout: () => { dispatch(frontEndUserActions.logout()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Frontend);

import { SC } from "../helper/ServerCall";

export const hashCategoryService = {
    paginationData,
    store,
    get,
    update,
    destroy,
    updateStatus,
   
};
function paginationData(page, filter, sort, pageSize, status) {
    return SC.getCall(
        "hashCategory/listing?page=" +
            page +
            "&filter=" +
            filter +
            "&sort=" +
            sort +
            "&pageSize=" +
            pageSize
    );
}



function store(catData) {
    return SC.postCall("hashCategory/listing", catData);
}

function get(id) {
    return SC.getCall("hashCategory/listing/" + id);
}

function update(data, Id) {
    data["id"] = Id;
    return SC.postCall("hashCategory/listing", data);
}

function destroy(catId) {
    return SC.deleteCall("hashCategory/listing/" + catId);
}

function updateStatus(catId, data) {
    data["id"] = catId;
    return SC.postCall("hashCategory/status", data);
}

import React, { Fragment } from "react";
import { Button, Grid, Row, Col } from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import SimpleReactValidator from "simple-react-validator";
import Select, { createFilter } from "react-select";
import AsyncSelect from "react-select/async";
import { helperFunctions as HF } from "../../../helper/helperFunctions";
import { cardService } from "../../../services/card";
import { campaignService } from "../../../services/campaign";
import NoData from '../../../assets/img/noData.png';
import Payment from "payment";
import Pagination from '../../ThemeComponents/Pagination';
// import masterCard from "../../../assets/img/mastercard.png"
import masterCard from "../../../assets/img/sampleCard.svg"

import visaCard from "../../../assets/img/visa.png"

class CampaignForm4 extends React.Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem("user"));
    //const { id } = this.props.match.params;
    this.validatorForm = new SimpleReactValidator({
      autoForceUpdate: this,
      locale: "en",
    });
    this.state = {
      card: {
        user_id: user.data ? user.data.id : null,
        card_number: "",
        card_expiry: "",
        card_cvc: "",
        // campaign_id :id,


      },


      // campaign_id: this.props.id,
      usersList: [],
      submitted: false,
      formType: "add",
      campaignDetails: [],
      DataAds: [],
      ExCardData: [],
      totalAmount: 0,
      CardForm: false,
      user_id: user.data ? user.data.id : null,
    };

    this.handleRec = this.handleRec.bind(this);
    this.handleRecSelect = this.handleRecSelect.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);



    campaignService.getCampaign(this.props.id).then((res) => {
      this.setState({ campaignDetails: res.data });
    });
    campaignService.getDataAds(this.props.id).then((res) => {
      this.setState({ DataAds: res.data.pagination.data });
    });



    cardService.showExistingCardData(this.state.user_id).then((res) => {
      console.log("ExCardData", res.data.pagination.data)
      this.setState({ ExCardData: res.data.pagination.data });

    });

  }


  // #################


  handleAddCardClick = () => {
    this.setState({ CardForm: true });

  };

  handleUseExistingCard = (card_id) => {
    cardService.storeExistingCard(card_id).then((res) => {
      console.log('After', res)
      if (this.props.setStateOfForm3) {
        this.props.setStateOfForm3(true, res.data.id);
      }
    });

  }
  // #################






  getUsersCall = async (input) => {
    const res = await campaignService.getUsers(input);
    this.setState({ usersList: res.data });
    if (this.state.card.user_id) {
      this.getAdsCall(this.state.card.user_id);
    }
    return res.data;
  };

  userOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.getUsersCall(inputValue));
      }, 1000);
    });

  handleRec(e) {
    const { name, value, type } = e.target;
    const recState = { ...this.state.card };
    let retV = "";

    if (name === "card_number") {
      retV = this.formatCreditCardNumber(value);
    } else if (name === "card_expiry") {
      retV = this.formatExpirationDate(value);
    } else if (name === "card_cvc") {
      retV = this.formatCVC(value);
    } else {
      retV = value;
    }

    recState[name] = type === "checkbox" ? (e.target.checked ? 1 : 0) : retV;
    this.setState({ card: recState });
  }

  handleRecSelect = (selectedOptionType, info) => {
    console.log("selectedOptionType", selectedOptionType);
    if (info["action"] === "select-option") {
      let recState = { ...this.state.card };
      recState[info["name"]] = selectedOptionType["value"];
      console.log(recState);
      this.setState({ card: recState });
    }
  };

  handleSubmitForm(e) {
    e.preventDefault();
    if (this.validatorForm.allValid()) {
      this.setState({ submitted: true }, function () {
        this.submitForm(this.state.card, this.state.formType);
      });
    } else {
      this.setState({ submitted: false });
      this.validatorForm.showMessages();
      this.forceUpdate();
    }
  }

  submitForm = (formData, formType) => {
    if (formType === "add") {
      cardService.storeCard(formData).then((res) => {
        if (this.props.setStateOfForm3) {
          this.props.setStateOfForm3(true, res.data.id);
        }
        // window.location.replace("/Advertiser/campaign-details/"+this.state.card.campaign_id);
      });
    } else if (formType === "edit") {
      cardService.editCard(formData, this.state.card.id).then((res) => {
        // window.location.replace("/Advertiser/campaign-details/"+this.state.card.campaign_id);
      });
    }
  };
  clearNumber = (value = "") => {
    return value.replace(/\D+/g, "");
  };

  formatCreditCardNumber = (value) => {
    if (!value) {
      return value;
    }

    const issuer = Payment.fns.cardType(value);
    const clearValue = this.clearNumber(value);
    let nextValue;

    switch (issuer) {
      case "amex":
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10,
        )} ${clearValue.slice(10, 15)}`;
        break;
      case "dinersclub":
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10,
        )} ${clearValue.slice(10, 14)}`;
        break;
      default:
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          8,
        )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
        break;
    }

    return nextValue.trim();
  };

  formatCVC = (value, prevValue, allValues = {}) => {
    const clearValue = this.clearNumber(value);
    let maxLength = 4;

    if (allValues.number) {
      const issuer = Payment.fns.cardType(allValues.number);
      maxLength = issuer === "amex" ? 4 : 3;
    }

    return clearValue.slice(0, maxLength);
  };

  formatExpirationDate = (value) => {
    const clearValue = this.clearNumber(value);

    if (clearValue.length >= 3) {
      return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }

    return clearValue;
  };

  render() {
    const { card, formType, campaignDetails, DataAds, CardForm, ExCardData } = this.state;
    const filterConfig = {
      ignoreCase: true,
      matchFrom: "start",
    };
    const totalAmount = DataAds.reduce(
      (total, ad) => total + parseFloat(ad.plan.amount),
      0,
    );
    const increasedAmount = totalAmount * 1.00;
    const totalAmountWithText = `${increasedAmount.toFixed(2)}`;
    console.log(totalAmountWithText);
    return (
      <div className="content">
        <div className="main_login1 w-100 py-1">
          <h3 className="text-left font-bold mb-xl">Payment Information</h3>
          <div className="campaignTitleWrap">
            <div>
              <strong>Campaign Title:</strong>
              <span>&nbsp;{this.state.campaignDetails.campaign_title}</span>
            </div>
            <div className="campaignTarget">
              <strong>Target Audience:</strong>
              <span>
                {this.state.campaignDetails.details &&
                  this.state.campaignDetails.details.gender
                  ? "Gender: " +
                  this.state.campaignDetails.details.genders
                    .title + "\n"
                  : ""}
              </span>
              <span>
                {this.state.campaignDetails.details &&
                  this.state.campaignDetails.details.ages
                  ? "Age: " +
                  this.state.campaignDetails.details.age.title +
                  " Years" +
                  "\n"
                  : ""}
              </span>
              <span>
                {this.state.campaignDetails.details
                  ? "Region: " +
                  this.state.campaignDetails.details.location
                  : ""}
              </span>
            </div>
          </div>
          <div className="selectedAdView">
            {DataAds && DataAds.length > 0 ? (
              <div className="flexElem alignStart gap10p flexColumn w-100">
                {DataAds.map((ad) => (
                  <div key={ad.id} className="flexElem alignCenter flexWrap gap10p borderB gap25 pb-md mb-md w-100">
                    <video width="120" height="96" controls>
                      <source
                        src={ad.ad.video_url}
                        type="video/mp4"
                      />
                    </video>
                    <div className="flexElem alignCenter gap25">
                      <h4 className="mt-0 mb-0 text-primary text-16 font-bold">Ad Title: {ad.ad.ad_title}</h4>
                      <h4 className="text-dark text-capitalize mt-0 mb-0 text-16 font-bold">
                        {"Ad Run Duration: " + ad.plan.title}
                      </h4>
                      <h4 className="text-dark text-capitalize mt-0 mb-0 text-16 font-bold">{"Ad position on feed: " + ad.plan.position}</h4>
                      {/* <h4 className="text-dark text-capitalize mt-0 mb-0 text-16 font-bold">{" $" + ad.plan.amount}</h4> */}
                    </div>
                  </div>
                ))}
                <div className="flexElem justifyEnd alignCenter gap25 text-primary ml-auto w-100 pb-md">
                  <span className="text-16 font-bold">Sub Total:</span> <h3 className="m-0 text-dark text-18 font-bold">${totalAmount.toFixed(2)}</h3>
                </div>
                <div className="flexElem justifyEnd alignCenter gap25 text-primary ml-auto w-100 pb-md">
                  <span className="text-16 font-bold">Sales Tax:</span> <h3 className="m-0 text-dark text-18 font-bold">0.00%</h3>
                </div>
                <div className="flexElem justifyEnd alignCenter gap25 text-primary ml-auto borderB w-100 pb-md">
                  <span className="text-16 font-bold">Grand Total:</span> <h3 className="m-0 text-dark text-18 font-bold">${totalAmountWithText}</h3>
                </div>
              </div>
            ) : (
              <div className='text-center'>
                <img src={NoData} alt={NoData} width={84} />
                <h3 className='font-bold text-16 mt-md'>No Ad Selected</h3>
              </div>
            )}
          </div>
          <Row>
            <Col md={12}>
              <h3 className="text-left font-bold mb-xl">Payment Methods</h3>
            </Col>
            <Col md={6}>
              <div className="mt-md">
                {/* <div className="flexElem ml-auto pb-xl">
                  <Button bsStyle="info" className="flexElem" variant="info" onClick={this.handleAddCardClick}>Add New Card</Button>
                </div> */}
                <h5 className="">&nbsp;</h5>
                <form onSubmit={this.handleSubmitForm}>
                  {formType === "edit" && (
                    <input
                      type="hidden"
                      name="card_id"
                      className="form-control"
                      value={card.id}
                    />
                  )}
                  <div className="form-group">
                    <label htmlFor="card_number" className="text_14">Card Number</label>
                    <input
                      type="text"
                      name="card_number"
                      className="form-control"
                      pattern="[0-9\s]{13,19}"
                      value={card.card_number}
                      onChange={this.handleRec}
                    />
                    {this.validatorForm.message(
                      "card_number",
                      card.card_number,
                      "required|card_num",
                    )}
                  </div>
                  <Row>
                    {/* <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="cardName" className="text_14">Name on Card</label>
                        <input
                          type="text"
                          name="cardName"
                          className="form-control"
                          placeholder="MM/YY"
                          pattern="\d\d/\d\d"
                        />

                      </div>
                    </Col> */}
                    <Col xs={6} md={8}>
                      <div className="form-group">
                        <label htmlFor="card_expiry" className="text_14">Expiry</label>
                        <input
                          type="text"
                          name="card_expiry"
                          className="form-control"
                          placeholder="MM/YY"
                          pattern="\d\d/\d\d"
                          value={card.card_expiry}
                          onChange={this.handleRec}
                        />
                        {this.validatorForm.message(
                          "card_expiry",
                          card.card_expiry,
                          "required|card_exp",
                        )}
                      </div>
                    </Col>
                    <Col xs={6} md={4}>
                      <div className="form-group">
                        <label htmlFor="card_cvc" className="text_14">CVC</label>
                        <input
                          type="text"
                          name="card_cvc"
                          className="form-control"
                          pattern="\d{3,4}"
                          value={card.card_cvc}
                          onChange={this.handleRec}
                        />
                        {this.validatorForm.message(
                          "card_cvc",
                          card.card_cvc,
                          "required|max:3",
                        )}
                      </div>
                    </Col>
                  </Row>
                  <div className="savePayment">
                    <label className="container-check checkbox_down">
                      <span>Save Payment Method?</span>
                      <input
                        type="checkbox"
                        name="set_default"
                        className="checkbox"
                        value={card.set_default ? 1 : 0}
                        onChange={this.handleRec}
                        checked={card.set_default ? 1 : 0}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="flexElem justifyEnd alignCenter gap25 text-primary">
                    <span className="text-14 font-bold">Total:</span> <h3 className="m-0 text-dark text-18 font-bold">${totalAmount.toFixed(2)}</h3>
                  </div>
                  <Row>
                    <Col md={12}>
                      <Button
                        type="submit"
                        bsSize="large"
                        className="backButton btn-lg btnDark btn-block py-1 mt-lg">
                        Submit
                      </Button>
                    </Col>
                    {/* <Col md={6}>
                      <Button
                        type="submit"
                        bsSize="large"
                        className="btn-lg btn-block btnDanger py-1 mt-lg">
                        Cancel
                      </Button>
                    </Col> */}
                  </Row>
                </form>
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-md">
                <h5 className="">My Cards</h5>
                {(!CardForm && ExCardData.length > 0) && (
                  <div className="mt-md">
                    {ExCardData.map((card, index) => (
                      <div key={index} className="paymentCards">
                        <div className="flexElem spaceBetween alignCenter gap10p">
                          <img src={masterCard} alt={masterCard} height={20} />
                          <Button className="btnInfoCustom mb-md" onClick={() => this.handleUseExistingCard(card.id)}>Use This Card</Button>
                        </div>
                        {/* <br></br> */}
                        {/* <h5>{card.user.name}</h5> */}
                        <div className="flexElem spaceBetween alignCenter gap10p">
                          <div className="">
                            {card.card_number}
                          </div>
                          <div className="">
                            <strong>Exp: </strong> &nbsp; {card.card_expiry}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default CampaignForm4;

import React from 'react';
import {
    Button
} from "react-bootstrap";



class AdPolicies extends React.Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {

    }




    render() {

        return (
           <div>Testing</div>
        );
    }
}


export default AdPolicies;

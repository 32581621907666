import React from "react";
import Home from "components/FrontEnd/Home";
import DeleteAccount from "components/FrontEnd/DeleteAccount";
import Login from "components/FrontEnd/Login"
import { Roles } from "../constants/Roles";
import { baseUrl } from "../helper/ApiCall";
import User from "../helper/User";

const FrontEndRoutes = [

  {
    path: `/Home`,
    name: `Home`,
    component: Home,
    showMenu: false,
    number: 1,
  },
  {
    path: `/account-delete`,
    name: `DeleteAccount`,
    component: DeleteAccount,
    showMenu: false,
    number: 2,
  },
  {
    path: `/Login/:username?/:password?`,
    name: `Login`,
    component: Login,
    showMenu: true,
    number: 4,
    navClass: `loginIcon`,
  },

  // {
  //   path: `/Signup`,
  //   name: `Sign Up`,
  //   component: Signup,
  //   showMenu: true,
  //   number: 3,
  //   navClass: `signUp`,
  // },

  {
    path: `/logout`,
    name: `Logout`,
    auth: true,
    role: [...Object.values(Roles)],
    navIcon: `flaticon-log-out`,
  },
  {
    path: `/`,
    name: `Home`,
    component: Home,
    showMenu: false,
    number: 0,
  }
];
export default FrontEndRoutes;

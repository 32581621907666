import React, { Fragment } from 'react';
import {
  Button, Grid,
  Row,
  Col
} from "react-bootstrap";


import { Card } from "components/Card/Card.jsx";
import SimpleReactValidator from 'simple-react-validator';
import Select, { createFilter } from 'react-select';
import AsyncSelect from 'react-select/async';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import { cardService } from '../../../services/card';
import { campaignService } from '../../../services/campaign';
import Payment from "payment";
import NoData from '../../../assets/img/noData.png';
import CapaignActivation from '../../../assets/img/capaign-activation.svg';
class CampaignForm5 extends React.Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem('user'));
    //const { id } = this.props.match.params;
    this.validatorForm = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
    this.state = {
      card: {
        user_id: user.data ? user.data.id : null,
        campaign_id: this.props.id,
      },
      usersList: [],
      submitted: false,
      formType: 'add',
      campaignDetails: [],
      DataAds: [],
      totalAmount: 0
    };
    campaignService.getDataAds(this.props.id).then(res => {
      console.log("getDataAds", res.data.pagination.data);
      this.setState({ DataAds: res.data.pagination.data });
    });
    campaignService.getCampaign(this.props.id).then(res => {
      console.log("getCampaignDetails", res.data);
      this.setState({ campaignDetails: res.data });
    });
  }
  handleContinue = () => {
    console.log('Continue button clicked!');
    window.location.replace("/Advertiser/campaign-details/" + this.props.id);

  };
  render() {
    const { card, formType, campaignDetails, DataAds } = this.state;
    const filterConfig = {
      ignoreCase: true,
      matchFrom: 'start',
    };
    const totalAmount = DataAds.reduce((total, ad) => total + parseFloat(ad.plan.amount), 0);
    const increasedAmount = totalAmount * 1.00;
    // const totalAmountWithText = `Total Amount: ${increasedAmount.toFixed(2)} (includes a 10% increase)`;
    const totalAmountWithText = `${increasedAmount.toFixed(2)}`;

    console.log(totalAmountWithText);
    return (
      <Fragment>
        <div className="main_login1 w-100 py-1">
          <h3 className="text-left font-bold mb-xl">Active Campaign</h3>
          <Row>
            <Col md={12}>
              <div className="flexElem alignCenter">
                <div className="text-center" style={{ background: '#DDF3F8', padding: 25 }}>
                  <img src={CapaignActivation} alt={CapaignActivation} className='mb-lg' />
                  <p className='text-18 text-primary font-bold' style={{ lineHeight: 1.8 }}>
                    Thank you for submitting your advertising campaign.
                    We are reviewing your campaign. Your account will not be charged until
                    your campaign has been approved and activated. If you have any
                    questions or concerns, please feel free to contact us
                  </p>
                </div>
              </div>
              <button className='btn btnDark mt-lg btn-block' onClick={this.handleContinue}>Continue</button>
            </Col>
            <Col md={12} className='mt-lg'>
              <div className="campaignTitleWrap">
                <div>
                  <strong>Campaign Title:</strong>
                  <span>&nbsp;{this.state.campaignDetails.campaign_title}</span>
                </div>
                <div className="campaignTarget">
                  <strong>Target Audience:</strong>
                  <span>
                    {this.state.campaignDetails.details && this.state.campaignDetails.details.gender ? "Gender: " + this.state.campaignDetails.details.genders.title + "\n" : ''}
                  </span>
                  <span>
                    {this.state.campaignDetails.details && this.state.campaignDetails.details.ages ? "Age: " + this.state.campaignDetails.details.age.title + " Years" + "\n" : ''}
                  </span>
                  <span>
                    {this.state.campaignDetails.details ? "Region: " + this.state.campaignDetails.details.location : ''}
                  </span>
                </div>
              </div>
              <div className="selectedAdView">
                <div className="flexElem alignStart gap10p flexColumn w-100">
                  {DataAds && DataAds.length > 0 ? (
                    <div className="flexElem alignStart gap10p flexColumn w-100">
                      {DataAds.map((ad) => (
                        <div key={ad.id} className="flexElem alignCenter flexWrap gap10p borderB gap25 pb-md mb-md w-100">
                          <video width="120" height="96" controls>
                            <source
                              src={ad.ad.video_url}
                              type="video/mp4"
                            />
                          </video>
                          <div className='flexElem alignCenter gap25'>
                            <h4 className="mt-0 mb-0 text-primary text-16 font-bold">Ad Title: {ad.ad.ad_title}</h4>
                            <h4 className="text-dark text-capitalize mt-0 mb-0 text-16 font-bold">
                              {"Ad Run Duration: " + ad.plan.title}
                            </h4>
                            <h4 className="text-dark text-capitalize mt-0 mb-0 text-14 font-bold">{"Ad position on feed: " + ad.plan.position}</h4>
                            {/* <h4 className="text-dark text-capitalize mt-0 mb-0 text-14 font-bold">{" $" + ad.plan.amount}</h4> */}
                          </div>
                        </div>
                      ))}
                        <div className="flexElem justifyEnd alignCenter gap25 text-primary ml-auto w-100 pb-md">
                          <span className="text-16 font-bold">Sub Total:</span> <h3 className="m-0 text-dark text-18 font-bold">${totalAmount.toFixed(2)}</h3>
                        </div>
                        <div className="flexElem justifyEnd alignCenter gap25 text-primary ml-auto w-100 pb-md">
                          <span className="text-16 font-bold">Sales Tax:</span> <h3 className="m-0 text-dark text-18 font-bold">0.00%</h3>
                        </div>
                        <div className="flexElem justifyEnd alignCenter gap25 text-primary ml-auto borderB w-100 pb-md">
                          <span className="text-16 font-bold">Grand Total:</span> <h3 className="m-0 text-dark text-18 font-bold">${totalAmountWithText}</h3>
                      </div>
                    </div>
                  ) : (
                    <div className='text-center'>
                      <img src={NoData} alt={NoData} width={84} />
                      <h3 className='font-bold text-14 mt-md'>No Ad Selected</h3>
                    </div>
                  )}
                  <div style={{ display: 'none' }}>
                    {DataAds && DataAds.length > 0 ? (
                      <ul>
                        {DataAds.map(ad => (

                          <li key={ad.id}>

                            <div>
                              <div>
                                <strong>Ad Title:</strong> {ad.ad.ad_title}
                              </div>

                              <video width="150" height="120" controls>
                                <source src={ad.ad.video_url} type="video/mp4" />
                              </video>
                            </div>

                            <div>
                              <div>
                                {/* {"Ad Run Duration: " + ad.plan.title + "\n" + "Ad position on feed: " + ad.plan.position + "\nAd cost / " + ad.plan.duration + ": $" + ad.plan.amount} */}
                                {"Ad Run Duration: " + ad.plan.title}<br />{"Ad position on feed: " + ad.plan.position}<br />{" $" + ad.plan.amount}

                              </div>
                            </div>
                          </li>


                        ))}
                        <div>
                          Total: ${totalAmount.toFixed(2)}
                        </div>
                      </ul>
                    ) : (
                      <p>No campaign ads available.</p>
                    )}
                  </div>
                  <div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }
}
export default CampaignForm5;

import React from 'react';
import {
	Button
} from "react-bootstrap";

import upload from "../../../assets/img/upload.svg"
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import FileUpload from "components/Upload/FileUpload";
import ImagePreview from "components/Upload/ImagePreview";
import App from "../License/ImageCroping1";
import { licenseService } from '../../../services/license';	


class SplashForm extends React.Component {
	constructor(props) {
		super(props);
		this.validatorForm = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
		this.state = {
			video: {
				...this.props.recPro,
				user_id: this.props.user_id,
				license_id: this.props.recPro ? this.props.recPro.license_id : ''
			},
			error: '',
			// videoPreviewUrl: this.props.recPro ? this.props.recPro.videoFile : null,
			filePreviewUrl: this.props.recPro ? this.props.recPro.videoFile : null,
			videos: this.props.recPro ? this.props.recPro.video_url : null,
			images: this.props.recPro ? this.props.recPro.image_url : null,
			submitted: false,
			formType: this.props.formTypePro || '',
			allowedFormatsStringMime: 'image/jpeg, image/png',
			allowedFormatsStringExt: 'jpg, png',
			allowedFormatsVideoMime: 'video/x-flv, video/mp4',
			allowedFormatsVideoExt: 'flv, mp4',
			videoFileName: '',
			fileName: '',
			videoAdded: false,
			fileAdded: false,
			imagePreviewUrl: null,

			imageFile: null,
			imageFileData: null,

			license: [],
		};

		this.handleRec = this.handleRec.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleChangeCategory = this.handleChangeCategory.bind(this);
		this.getLicenseCall = this.getLicenseCall.bind(this);
	}
	handleChangeCategory(selectedOption, name) {
		if (name.action === 'select-option') {
			this.setState(prevState => ({
				video: {
					...prevState.video,
					users: [...prevState.video.users, name.option.value]
				}
			}));
		}
		if (name.action === 'remove-value') {
			const catTemp = this.state.video.users;
			catTemp.splice(catTemp.indexOf(name.removedValue.value), 1);
			this.setState(prevState => ({
				video: {
					...prevState.video,
					users: catTemp
				}
			}))
		}
	}

	componentDidMount() {
        this.getLicenseCall();
      }

    getLicenseCall(){
		licenseService.getCaretLicense().then(res => {
            console.log('license',res.data);
            this.setState({license:res.data});
          });
      }

	  handleTypeChange = (selectedOption) => {
		// console.log("selectedOption ==========> ", selectedOption);
		this.setState((prevState) => ({
			video: {
				...prevState.video,
				license_id: selectedOption ? selectedOption.value : '',
			},
		}));
	};
	handleRec(e) {
		const { name, value, type } = e.target;
		const recState = { ...this.state.video };
		recState[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
		this.setState({ video: recState });
	}
	handleSubmitForm(e) {
		e.preventDefault();

		if (this.validatorForm.allValid()) {
			this.setState({ submitted: true }, function () {
				this.props.submitForm(this.state.video, this.state.formType);
			});
		} else {
			this.setState({ submitted: false });
			this.validatorForm.showMessages();
			this.forceUpdate();
		}
	}


	setImageFile = (event) => {
		const file = event.target.files[0];
		const fileData = {};
		fileData["name"] = file["name"];
		fileData['size'] = file['size'];
		fileData['extension'] = HF.returnExtFromImage(file['name']);
		fileData['type'] = file['type'];
		fileData['lastModified'] = file['lastModified'];
		const reader = new FileReader();
		reader.onload = () => {
			fileData['file'] = reader.result;
			const imagePreviewUrl = URL.createObjectURL(file);
			fileData['preview'] = imagePreviewUrl;
			this.setState({
				imagePreviewUrl: imagePreviewUrl,
				imageFile: fileData
			});
		};
		reader.readAsDataURL(file);
	};
	setVideoFile = (event) => {
		const file = event.target.files[0];
		const fileData = {};
		fileData["name"] = file["name"];
		fileData['name'] = file['name'];
		fileData['size'] = file['size'];
		fileData['extension'] = HF.returnExtFromImage(file['name']);
		fileData['type'] = file['type'];
		fileData['lastModified'] = file['lastModified'];
		const video = document.createElement('video');
		const reader = new FileReader();
		video.onloadedmetadata = () => {
			const duration = Math.floor(video.duration);
			if (duration > 10) {
				this.setState({ error: 'Video duration exceeds the maximum allowed (10 seconds)' });

			} else if (file.size > 300 * 1024 * 1024) {
				this.setState({ error: 'Video size exceeds the maximum allowed (300 MB)' });

			} else {
				this.setState({ error: '' });
				const videoPreviewUrl = URL.createObjectURL(file);
				fileData['preview'] = videoPreviewUrl;
				this.setState({ videoDuration: duration, error: null });
				this.setState({ videoPreviewUrl: null },
					() => {
						this.setState({ videoPreviewUrl: videoPreviewUrl });

					})
				this.setState({ videoAdded: true });
				this.setState({ videoFileName: fileData["name"] });
			}
		};
		reader.readAsDataURL(file);
		reader.onload = () => {
			fileData['file'] = reader.result;
		};
		video.src = URL.createObjectURL(file);
		this.setState({
			video: { ...this.state.video, videoFile: fileData },
		});
	};

	handleCrop = (imageFile) => {
    console.log('croppedImageFile on Crop', imageFile);
    // this.setState({ imageFile: croppedImageFile });
    this.setState({ imageFile,
		video: { ...this.state.video, videoFile: imageFile, duration: 2 }

	});
    
  };

	handleFileUpload = (event) => {
		const file = event.target.files[0];
		if (!file) return;

		if (this.state.filePreviewUrl) {
			URL.revokeObjectURL(this.state.filePreviewUrl);
		}

		const fileData = {
			name: file.name,
			size: file.size,
			type: file.type,
			lastModified: file.lastModified,
		};
		const fileExtension = file.name.split('.').pop().toLowerCase();
		let fileType = file.type.includes('video') ? 'video' :
			file.type.includes('image') ? 'image' : null;

		if(fileExtension== 'heic'){
			fileType = 'image';
			fileData.type = 'image/heic';
		}
		if (fileType === 'video') {
			const video = document.createElement('video');
			const reader = new FileReader();

			video.onloadedmetadata = () => {
				const duration = Math.floor(video.duration);
				if (duration > 2) {
					this.setState({ error: 'Video duration exceeds the maximum allowed (2 seconds)' });
				} else if (file.size > 300 * 1024 * 1024) { // 300 MB
					this.setState({ error: 'Video size exceeds the maximum allowed (300 MB)' });
				} else {
					this.setState({ filePreviewUrl: null, error: '', fileDuration: null });
					const filePreviewUrl = URL.createObjectURL(file);
					fileData.preview = filePreviewUrl;
					this.setState({
						filePreviewUrl,
						fileType: 'video',
						fileDuration: duration,
						fileName: file.name,
						fileAdded: true,
						error: null,
						video: { ...this.state.video, videoFile: fileData, duration: duration }
					});
				}
			};

			reader.onload = () => {
				fileData.file = reader.result;
			};

			reader.readAsDataURL(file);
			video.src = URL.createObjectURL(file);

		} else if (fileType === 'image') {
			//   if (fileExtension !== 'png') {
			// 	this.setState({ error: 'Only PNG images are allowed' });
			// 	return;
			//   }
			const allowedImageExtensions = ['png', 'jpeg', 'gif', 'jpg', 'heic'];
			if (!allowedImageExtensions.includes(fileExtension)) {
				this.setState({ error: 'Only PNG, JPEG, GIF, JPG, and heic images are allowed' });
				return;
			}

			const filePreviewUrl = URL.createObjectURL(file);
			const reader = new FileReader();

			reader.onload = () => {
				fileData.file = reader.result;
				this.setState({ fileDuration: null });
				this.setState({
					filePreviewUrl,
					fileType: 'image',
					fileName: file.name,
					fileAdded: true,
					error: null,
					video: { ...this.state.video, videoFile: fileData, duration: 2 }
				});
				this.setState({
					imageFileData:file
				})
			};

			reader.readAsDataURL(file);

		} else {
			this.setState({ error: 'Unsupported file type' });
		}
	};

	//   Drag and Drop
	handleDragOver = (event) => {
		event.preventDefault();
		this.setState({ isDragging: true });
	};

	handleDragLeave = () => {
		this.setState({ isDragging: false });
	};

	handleDrop = (event) => {
		event.preventDefault();
		const file = event.dataTransfer.files[0];
		if (file) {
			this.handleFileUpload({ target: { files: [file] } });
		}
		this.setState({ isDragging: false });
	};



	render() {
		const { license, video, formType, allowedFormatsStringMime, allowedFormatsStringExt, allowedFormatsVideoMime, allowedFormatsVideoExt } = this.state;
		const { users } = this.props;
		const { videoFileName, videoAdded, fileAdded, fileName, isDragging } = this.state;

		console.log('users', users);
		return (
			<form onSubmit={this.handleSubmitForm}>
				{formType === 'edit' &&
					<input type="hidden" name="video_id" className="form-control" value={video.id} />
				}
				<div className="row">
					<div className="col-md-7">
						<div className='form-group'>
							<label htmlFor="splash_title">Splash Title<span className="requiredClass">*</span></label>
							<input type="text" name="splash_title" className="form-control" value={video.splash_title} onChange={this.handleRec} />
							{this.validatorForm.message('splash_title', video.splash_title, 'required|min:5')}
						</div>
						<div className=''>
							<label className="container-check checkbox_down"> Is Active?
								<input type="checkbox" name="is_active" className="checkbox" value={video.is_active ? 1 : 0} onChange={this.handleRec} checked={video.is_active ? 1 : 0} />
								<span className="checkmark"></span>
							</label>
							{/* {this.validatorPermission.message('is_active', video.is_active, 'required')} */}
						</div>
						<div className=''>
                            <label htmlFor="plan">Select License</label>
                            <Select
                                name="typeSelect"
                                options={license}
                                className="basic-single"
                                classNamePrefix="select"
                                value={license.find(ls => ls.value === video.license_id)} 
                                onChange={this.handleTypeChange}
                                />
                        </div>

					</div>
					<div className="col-md-5">
						<div className=''>
							<label>Select File</label>
							{/* <div className='uploadBtn'> */}
							<div
								className={`uploadBtn ${isDragging ? 'dragging' : ''}`}
								onDragOver={this.handleDragOver}
								onDragLeave={this.handleDragLeave}
								onDrop={this.handleDrop}
							>
								<label htmlFor="upload" id='upload-btn' className={`${fileAdded ? "chosen" : ""}`}>
									<img src={upload} alt={upload} width={20} />
									<span id="text">
										{fileName ? fileName : "Drop / Upload file"}
									</span>
								</label>
								<input
									type="file"
									id="upload"
									accept="image/png, image/jpeg, image/jpg, image/gif, image/heic, video/mp4, video/quicktime, video/x-flv, video/webm" // Accept both PNG and MP4
									onChange={this.handleFileUpload}
								/>
							</div>
							{this.state.error && <p className='srv-validation-message'>{this.state.error}</p>}
							{this.state.filePreviewUrl && (
								<div className='text-center'>

									{(this.state.fileType === 'video' || this.state.videos) ? (
										<video controls style={{ maxWidth: '100%', height: 'auto' }}>
											<source src={this.state.filePreviewUrl} type="video/mp4" />
											Your browser does not support the video tag.
										</video>
									) : 
									(
										// <img src={this.state.filePreviewUrl} alt="preview" style={{ maxWidth: '100%', height: 'auto' }} />
										<App  
										imageFileData={this.state.imageFileData}
										log={console.log("imagePreviewUrl aaaaaaaaaaa ",this.state.imagePreviewUrl)}
										imagePreviewUrl={this.state.imagePreviewUrl}
										onCrop={this.handleCrop} 
										/>
									)}	
								</div>
							)}
							{this.state.fileDuration && <p className='text-primary mt-1 text-center'>Video duration: {this.state.fileDuration} seconds</p>}
						</div>
					</div>
				</div>
				<div className='flexElem alignCenter justifyCenter gap25'>
					<Button type='submit' bsSize="large" bsStyle="info" className="btn btnPurple text-white w-autoF">Save</Button>
					<Button bsSize="large" bsStyle="info" onClick={() => this.props.closeModel()} className="btn btnDark text-white w-autoF">Cancel</Button>
				</div>
			</form>
		);
	}
}


export default SplashForm;

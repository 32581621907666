import {
	adminConstants
} from '../constant/admin-constant';

const initialState = {
    loading: false, employees:{}, editEmployee:{},
	employeesData: [],
};

export function resumeReducer(state = initialState, action) {

	switch (action.type) {

       
		
            
        case adminConstants.GET_RESUME_EMPLOYEES_REQUEST:
            return { ...state, loading:true, employees: {}, editEmployee:'', storedEmployee:'', updatedEmployee:'', deleteEmployee:false };
        case adminConstants.GET_RESUME_EMPLOYEES_SUCCESS:
            return { ...state, employeesData:action.payload.employees.data, pages:action.payload.employees.last_page, loading: false, current_page:action.payload.employees.current_page };
        case adminConstants.GET_RESUME_EMPLOYEES_FAILURE:
            return { ...state, loading:false, error : action.payload };

        case adminConstants.STORE_RESUME_EMPLOYEES_FAILURE:
            return { ...state, loading:true, editEmployee:'', storedEmployee: '' }
        case adminConstants.STORE_RESUME_EMPLOYEES_SUCCESS:
            return { ...state, loading:false, storedEmployee: action.payload.employee};
        case adminConstants.STORE_RESUME_EMPLOYEES_FAILURE:
            return { ...state, loading:false, error:action.payload };
        
        case adminConstants.GET_RESUME_EMPLOYEE_REQUEST:
            return { ...state, loading:true };
        case adminConstants.GET_RESUME_EMPLOYEE_SUCCESS:
            return { ...state, loading:false, editEmployee : action.payload.employee };
        case adminConstants.GET_RESUME_EMPLOYEE_FAILURE:
            return { ...state, loading:false, error : action.payload };


        case adminConstants.EDIT_RESUME_EMPLOYEE_REQUEST:
			return { ...state, loading:true, updatedEmployee:'' };
		case adminConstants.EDIT_RESUME_EMPLOYEE_SUCCESS:
			return { ...state, loading:false , updatedEmployee:action.payload.user};
		case adminConstants.EDIT_RESUME_EMPLOYEE_FAILURE:
			return { ...state, loading:false, error : action.payload };

		case adminConstants.DELETE_RESUME_EMPLOYEE_REQUEST:
			return { ...state, loading:true, deleteEmployee: false }
		case adminConstants.DELETE_RESUME_EMPLOYEE_SUCCESS:
			return { ...state, loading:false, deleteEmployee: true};
		case adminConstants.DELETE_RESUME_EMPLOYEE_FAILURE:
			return { ...state, loading:false, error:action.payload };


		default:
			return {
				...state
			};
	}
}
import React, { Component } from 'react';
import V3 from '../../../assets/img/carets-logo-v3.jpg'
import V4 from '../../../assets/img/carets-logo-v4.jpg'
import Sign from '../../../assets/img/signature1.jpg'
import Sign2 from '../../../assets/img/signature2.jpg'
import Sign3 from '../../../assets/img/signature3.jpg'
import Sign4 from '../../../assets/img/signature4.jpg'
import Premium from '../../../assets/img/premium-carets.jpg'
import Award from '../../../assets/img/carets-award.jpg'

import "./emailpdf.css"
const baseURL = 'http://devadmin.carets.tv/'
class EmailPDF extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className='bodyWrap'>
        <table className='certificateTable'>
          <tbody>
            <tr>
              <td style={{ width: '12.5%' }}><img src={V3} alt='logo1' className='image' /></td>
              <td style={{ width: '75%' }}>
                <h1 className='textCenter' style={{ marginTop: 50 }}>CERTIFICATE</h1>
                <h6 className='textCenter'>Licensing ^Carets</h6>
              </td>
              <td style={{ width: '12.5%' }}>&nbsp;</td>
            </tr>
            <tr style={{ paddingTop: 20 }}>
              <td></td>
              <td><h6 className='textCenter'>This is to certify that ^Carets</h6>
                <h1 className='textCenter borderTB title'>^Funny</h1>
                <p className='textCenter'>has been registered by Fun Store on behalf of Mark Anthony</p></td>
              <td>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <div style={{ display: 'table', width: '100%' }}>
                  <div className='textCenter' style={{ verticalAlign: 'middle', display: 'table-cell' }}>
                    <img src={Sign} alt='logo1' className='image' />
                    <div className='textCenter' style={{ marginTop: 15 }}>
                      <h6 style={{ margin: 0, borderBottom: '1px solid #333', fontSize: 13 }}>John Martina</h6>
                      <p style={{ fontSize: 12, margin: 0 }}>President Director</p>
                    </div>
                  </div>
                  <div className='textCenter' style={{ verticalAlign: 'middle', display: 'table-cell', textAlign: 'center', padding: 8 }}>
                    <img src={Premium} alt='premiumCarets' className='image' /></div>
                  <div className='textCenter' style={{ verticalAlign: 'middle', display: 'table-cell' }}>
                    <img src={Sign2} alt='logo1' className='image' />
                    <div className='textCenter' style={{ marginTop: 15 }}>
                      <h6 style={{ margin: 0, borderBottom: '1px solid #333', fontSize: 13 }}>Alex Martina</h6>
                      <p style={{ fontSize: 12, margin: 0 }}>Genral Manager</p>
                    </div>
                  </div>
                </div>
              </td>
              <td style={{ verticalAlign: 'bottom' }}>
                <img src={V3} alt='logo1' className='image' />
              </td>
            </tr>
          </tbody>
        </table>
        <table className='certificateTable' style={{ marginTop: 50 }}>
          <thead style={{ background: '#1b1c43' }}>
            <tr>
              <th style={{ width: '60%' }}>
                <h1 className='' style={{ color: '#fbc60b' }}>Certificate</h1>
                <h3 style={{ color: 'white' }} >Licensing ^Carets</h3>
                <p style={{ color: 'white' }} >This Certificate Is Proudly Presented To</p>
              </th>
              <th colSpan={2} style={{ textAlign: 'end' }}><img src={V4} alt='logo1' style={{ maxWidth: 200, }} /></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={2} style={{ width: '75%' }}>
                <small style={{ color: '#e5165f' }}>This is to certify that ^Carets</small>
                <h1 style={{ marginTop: 0, color: '#e5165f' }}>^Funny</h1>
                <p>has been registered by Fun Store on behalf of Mark Anthony</p>
              </td>
              <td style={{ width: '25%', textAlign: 'center' }}><img src={Award} alt='logo1' className='image' /></td>
            </tr>
            <tr>
              <td colSpan={2} style={{ width: '60%' }}>
                Date <strong>July, 17 2024</strong>
              </td>
              <td style={{ width: '40%' }}>
                <div style={{ display: 'table', width: '100%' }}>
                  <div className='textCenter' style={{ verticalAlign: 'top', display: 'table-cell', paddingRight: 5 }}>
                    <img src={Sign3} alt='logo1' style={{ maxHeight: 48 }} />
                    <div className='textCenter' style={{ marginTop: 15 }}>
                      <h6 style={{ margin: 0, paddingTop: 10, borderTop: '1px solid #333', fontSize: 13 }}>Daniel Andrean</h6>
                    </div>
                  </div>
                  <div className='textCenter' style={{ verticalAlign: 'top', display: 'table-cell', paddingLeft: 5 }}>
                    <img src={Sign4} alt='logo1' style={{ maxHeight: 48 }} />
                    <div className='textCenter' style={{ marginTop: 15 }}>
                      <h6 style={{ margin: 0, paddingTop: 10, borderTop: '1px solid #333', fontSize: 13 }}>Greed Astro</h6>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <img src={V3} alt='logo1' style={{ maxWidth: 160 }} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default EmailPDF;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Row, Col, OverlayTrigger, Popover } from "react-bootstrap";
import { objectsConstants as OC } from "redux/constant/objects-constant";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Card } from "components/Card/Card.jsx";
import "react-table/react-table.css";
import HashAddForm from "./HashAddForm";
import { hasPermission } from "helper/hasPermission";
import Confirmalertfordelete from "../../ThemeComponents/confirmAlertForDelete";
import { adminLabels } from "../../../redux/constant/admin-label-constant";
import OpenModalButton from "../../ThemeComponents/openModelButton";
import { helperFunctions as HF } from "../../../helper/helperFunctions";
import { ThemeFunctions as TF } from "helper/ThemeFunctions";
import Pagination from "../../ThemeComponents/Pagination";
import { RiVideoLine } from "react-icons/ri";

import { hashService } from "../../../services/hash";

var Modal = require("react-bootstrap-modal");

class HashListingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addModalForm: false,
            editModalForm: false,
            openHashModal: false,
            sound_url: "",
            sorted: "",
            filtered: "",
            pageSize: 10,
            categories: []
        };

        this.editRecord = this.editRecord.bind(this);
        this.deleteRecord = this.deleteRecord.bind(this);
        this.deleteConfirmModal = this.deleteConfirmModal.bind(this);
        this.getPaginationData = this.getPaginationData.bind(this);
        this.filterSearchHandle = this.filterSearchHandle.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.HashModal = this.HashModal.bind(this);
    }
    HashModal(url) {
        this.setState({ sound_url: url }, () => {
            this.setState({ openHashModal: true });
            console.log("url", this.state.sound_url);
        });
    }
    submitForm(formData, formType) {
        if (formType === "add") {
            hashService.storeHash(formData).then(res => {
                this.setState({ addModalForm: false });
                this.pagination.dataCall();
            });
        } else if (formType === "edit") {
            hashService.editHash(formData, this.state.editRec.id).then(res => {
                this.setState({ editModalForm: false });
                this.pagination.dataCall();
            });
        }
    }

    filterSearchHandle(e) {
        this.setState({
            filtered: e.target.value
        });
    }

    editRecord(id) {
        hashService.getHash(id).then(res => {
            console.log(res);
            this.setState({ editRec: res.data }, () => {
                this.setState({ editModalForm: true });
            });
        });
    }

    deleteRecord(recID) {
        hashService.deleteHash(recID).then(res => {
            this.pagination.dataCall();
        });
    }

    deleteConfirmModal(recID) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <Confirmalertfordelete
                        typeName="Record"
                        description="Are you sure to delete the Record?"
                        onClosePro={onClose}
                        deleteType={() => this.deleteRecord(recID)}
                    />
                );
            }
        });
    }

    getPaginationData(page, filter, sort, pageSize, status) {
        this.setState({ pageSize: pageSize }, function() {
            hashService.paginationData(page, filter, sort, pageSize, status);
        });
    }

    updateStatus = recID => e => {
        // return false;
        hashService
            .updateHashStatus(recID, { is_active: e.target.checked ? 1 : 0 })
            .then(response => {
                hashService.paginationData(
                    this.props.current_page,
                    "",
                    "",
                    this.state.pageSize,
                    this.state.rec_status
                );
                this.pagination.dataCall();
            });
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.rec_status !== prevState.rec_status) {
            this.table.state.onFetchData(this.table.state, this.table.instance);
        }
    }

    paginationCall = data => {
        return hashService.paginationData(
            data.page,
            data.filter,
            data.sort,
            data.pageSize,
            this.state.rec_status
        );
    };

    getCategoriesCall() {
        hashService.getCategories().then(res => {
            console.log("categories", res.data);
            this.setState({ categories: res.data });
        });
    }

    componentDidMount() {
        // this.getPaginationData();
        this.getCategoriesCall();
    }

    render() {
        const { filtered, editRec, categories } = this.state;
        const columns = [
            {
                Header: "Hash Listing",
                headerClassName: "headerPagination",
                columns: [
                    {
                        Header: "Title",
                        accessor: "title",
                        className: "justifyStart text-left",
                        headerClassName: "justifyStart text-left",
                        minWidth: 200
                    },

                    {
                        Header: "Category",
                        accessor: "hash_categories",
                        Cell: ({ original }) => (
                            <span>
                                {original.hash_categories
                                    ? original.hash_categories.title
                                    : ""}
                            </span>
                        ),
                        className: "",
                        headerClassName: "action-center",
                        minWidth: 200
                    },

                    {
                        Header: "Status",
                        accessor: "is_active",
                        Cell: ({ row }) => (
                            <label className="container-check">
                                &nbsp;
                                <input
                                    type="checkbox"
                                    name="is_active"
                                    className="checkbox"
                                    value={row.is_active ? 1 : 0}
                                    onChange={this.updateStatus(row.id)}
                                    checked={row.is_active ? 1 : 0}
                                />
                                <span className="checkmark"></span>
                            </label>
                        ),
                        className: "action-center",
                        sortable: false
                    },
                    {
                        Header: "Actions",
                        accessor: "id",
                        Cell: ({ value }) => (
                            <div>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Popover id="tooltip">
                                            Edit Hash
                                        </Popover>
                                    }
                                >
                                    <span>
                                        {
                                            <button
                                                type="button"
                                                className="editIcon orderDetailsAction"
                                                onClick={() =>
                                                    this.editRecord(value)
                                                }
                                            >
                                                &nbsp;
                                            </button>
                                        }
                                    </span>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Popover id="tooltip">
                                            Delete Hash
                                        </Popover>
                                    }
                                >
                                    <span>
                                        {
                                            <button
                                                type="button"
                                                className="deleteIcon orderDetailsAction"
                                                onClick={() =>
                                                    this.deleteConfirmModal(
                                                        value
                                                    )
                                                }
                                            >
                                                &nbsp;
                                            </button>
                                        }
                                    </span>
                                </OverlayTrigger>
                            </div>
                        ),
                        className: "justifyEnd text-right",
                        headerClassName: "justifyEnd text-right",
                        sortable: false
                    }
                ]
            }
        ];

        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                ctTableResponsive
                                content={
                                    <div>
                                        <Grid fluid>
                                            <Row>
                                                <Col md={12}>
                                                    <div className="secHeading">
                                                        Hash Listing
                                                    </div>
                                                </Col>

                                                <Col md={12}>
                                                    <div
                                                        className="flexElem flexResponsive"
                                                        style={{
                                                            alignItems:
                                                                "flex-start",
                                                            justifyContent:
                                                                "space-between"
                                                        }}
                                                    >
                                                        <div className="">
                                                            <OpenModalButton
                                                                openModal={HF.openModal.bind(
                                                                    this,
                                                                    "addModalForm"
                                                                )}
                                                                classButton={[
                                                                    "backButton pt-sm no_radius pb-sm primary mt-none btn-block btn-info btn"
                                                                ]}
                                                                buttonName="Add Hash"
                                                                classIcon={[
                                                                    "fa",
                                                                    "fa-plus"
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col md={12} className="mt-md">
                                                    <Pagination
                                                        ref={p =>
                                                            (this.pagination = p)
                                                        }
                                                        showPagination={false}
                                                        columns={columns}
                                                        pageSize={20}
                                                        getDataCall={
                                                            this.paginationCall
                                                        }
                                                        filterView={true}
                                                        filterPlaceHolder={
                                                            "Hashs"
                                                        }
                                                        defaultSorted={[
                                                            {
                                                                id: "title",
                                                                desc: "false"
                                                            },
                                                            {
                                                                id: "is_active",
                                                                desc: "false"
                                                            }
                                                        ]}
                                                        noDataText="No Record found"
                                                        getRowProps={
                                                            this.getRowProps
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Grid>

                                        {categories !== null && (
                                            <Modal
                                                backdrop={"static"}
                                                show={this.state.addModalForm}
                                                onHide={HF.closeModal.bind(
                                                    this,
                                                    "addModalForm"
                                                )}
                                                aria-labelledby="ModalHeader"
                                            >
                                                <Modal.Header closeButton>
                                                    <Modal.Title
                                                        id="ModalHeader"
                                                        className="headerTitle"
                                                    >
                                                        Add Hash
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <Card
                                                                bsClass={[
                                                                    "innerCard mb-none"
                                                                ]}
                                                                content={
                                                                    <HashAddForm
                                                                        closeModel={HF.closeModal.bind(
                                                                            this,
                                                                            "addModalForm"
                                                                        )}
                                                                        formTypePro="add"
                                                                        recPro={
                                                                            OC.HASH
                                                                        }
                                                                        submitForm={
                                                                            this
                                                                                .submitForm
                                                                        }
                                                                        componentPro="MusicListing"
                                                                        categories={
                                                                            categories
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </Modal.Body>
                                            </Modal>
                                        )}
                                        {/*Add Record Modal End*/}

                                        {/*Edit Record Modal start*/}
                                        {editRec && (
                                            <Modal
                                                backdrop={"static"}
                                                show={this.state.editModalForm}
                                                onHide={HF.closeModal.bind(
                                                    this,
                                                    "editModalForm"
                                                )}
                                                aria-labelledby="ModalHeader"
                                            >
                                                <Modal.Header closeButton>
                                                    <Modal.Title
                                                        id="ModalHeader"
                                                        className="headerTitle"
                                                    >
                                                        Edit Hash
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <Card
                                                                bsClass={[
                                                                    "innerCard mb-none"
                                                                ]}
                                                                content={
                                                                    <HashAddForm
                                                                        closeModel={HF.closeModal.bind(
                                                                            this,
                                                                            "editModalForm"
                                                                        )}
                                                                        formTypePro="edit"
                                                                        recPro={
                                                                            editRec
                                                                        }
                                                                        submitForm={
                                                                            this
                                                                                .submitForm
                                                                        }
                                                                        categories={
                                                                            categories
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </Modal.Body>
                                            </Modal>
                                        )}
                                        {/*Edit Record Modal End*/}

                                        {
                                            <Modal
                                                backdrop={"static"}
                                                show={this.state.openHashModal}
                                                onHide={HF.closeModal.bind(
                                                    this,
                                                    "openHashModal"
                                                )}
                                                aria-labelledby="ModalHeader"
                                            >
                                                <Modal.Header closeButton>
                                                    <Modal.Title
                                                        id="ModalHeader"
                                                        className="headerTitle"
                                                    >
                                                        Hash
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className="row">
                                                        <div
                                                            className="col-md-12"
                                                            style={{
                                                                textAlignVertical:
                                                                    "center",
                                                                textAlign:
                                                                    "center"
                                                            }}
                                                        >
                                                            <audio
                                                                key={
                                                                    this.state
                                                                        .sound_url
                                                                }
                                                                id="playVid"
                                                                controls
                                                                style={{
                                                                    width:
                                                                        "100%"
                                                                }}
                                                            >
                                                                <source
                                                                    src={
                                                                        this
                                                                            .state
                                                                            .sound_url
                                                                    }
                                                                />
                                                            </audio>
                                                        </div>
                                                    </div>
                                                </Modal.Body>
                                            </Modal>
                                        }
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}
const HashListing = HashListingPage;
export default HashListing;

export function hasPermission(permission) {
    // return authorization header with jwt token
    let AdminUser = JSON.parse(localStorage.getItem('user'));

    if (AdminUser && AdminUser.token && AdminUser.data.role_permissions.includes(permission)) {
        return true;
    } else {
        return false;
    }
}

import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch } from 'react-router-dom'
import indexRoutes from "routes/index.jsx";
import { history } from './helper/history';
import store from "./redux/store/index";
import { Provider } from "react-redux";

ReactDOM.render(
	<Provider store={store}>
		<Router history={history} >
			<Switch>
				{indexRoutes.map((prop, key) => {
					return <Route exact={prop.exactPro} path={prop.path} component={prop.component} key={key} />;
				})}
			</Switch>
		</Router>
	</Provider>,
	document.getElementById("root")
);

import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import { CLMUserActions } from "redux/actions/CLM-user-action";
import { Grid, Row, Col, Button } from "react-bootstrap";
import appBackgourndImg from "assets/img/logo_frontEnd.png";
import MessageCom from "../../layouts/Advertiser/Message";
import caretFooterLogo from "../../assets/img/logo_white.png";
import caretLogo from "../../assets/img/caretLogo.svg";
import Appstore from "../../assets/img/appleLogo.svg";
import Playstore from "../../assets/img/playstore.svg";
import logInOut from "../../assets/img/log-in-out-black.svg";
import licenserLogin from "../../assets/img/licenser-login.svg";
import advertiserLogin from "../../assets/img/advertiser-login.svg";
import videoPreview from "../../assets/img/videoPreview.png";
import videoSample from "../../assets/img/businessIMG.png";
import { Link, useLocation, withRouter } from "react-router-dom";
import { history } from "helper/history";
import MarketIcon from "../../assets/img/market-icon.svg";
import CampaignIcon from "../../assets/img/campaign-icon.svg";
import BrandAdIcon from "../../assets/img/brandAd-icon.svg";
import AdplacementIcon from "../../assets/img/adplacement-icon.svg";
import PricingIcon from "../../assets/img/pricing-icon.svg";
import LaunchcampiagnIcon from "../../assets/img/launchcampiagn-icon.svg";

import InfluencerPlan from "../../assets/img/influencer-plan.svg";
import IndivudalPlan from "../../assets/img/indivudal-plan.svg";
import CorporatePlan from "../../assets/img/corporate-plan.svg";
import StartAdvertising from "../../assets/img/start-advertising.svg";

import Video1 from "../../assets/img/video1.png";
import Video2 from "../../assets/img/video2.png";
import Video3 from "../../assets/img/video3.png";
import Video4 from "../../assets/img/video4.png";
import fig1 from "../../assets/img/licensing-module/fig1.png";
import fig2 from "../../assets/img/licensing-module/fig2.png";
import fig3 from "../../assets/img/licensing-module/fig3.png";
import fig4 from "../../assets/img/licensing-module/fig4.png";
import fig5 from "../../assets/img/licensing-module/fig5.png";
import promo from "../../assets/img/licensing-module/promo.png";
import playIcon from "../../assets/img/licensing-module/play-icon.svg";
import caretFavicon from "../../assets/img/caretFavicon.svg";
import Audio from "../../assets/img/licensing-module/audio.svg";
import videoDownloadIcon from "../../assets/img/licensing-module/video-play-download-icon.svg";
import publishIcon from "../../assets/img/licensing-module/publish-icon.png";


class TutorialPage extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Fragment>
        <div className="wrapperOverride tutorialPage">
          <div className="yellowBar" />
          <div className="py-50 px-1 bg-whiteF flexElem w-full alignCenter flexWrap borderB">
            <img
              src={caretLogo}
              alt={caretLogo}
              height={36}
              className="mx-auto mb-1 mb-md-0 ms-md-0"
            />
            <div className="ml-auto flexElem gap10p">
              <a
                href="https://apps.apple.com/us/app/carets/id1571154682"
                target="_blank"
                className="flexElem gap10p text-white py-50 px-2 roundedFull hover:scale-105 transition ease transform scale-100"
                style={{ background: "#12064b" }}
              >
                <img src={Appstore} alt="Appstore" width={18} />
                <span className="flexElem flexColumn">
                  <small className="text-10">
                    Download from
                  </small>
                  <span className="text-16">App Store</span>
                </span>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.carets.tv"
                target="_blank"
                className="flexElem gap10p text-white py-50 px-2 roundedFull hover:scale-105 transition ease transform scale-100"
                style={{ background: "#12064b" }}
              >
                <img
                  src={Playstore}
                  alt="Playstore"
                  width={18}
                />
                <span className="flexElem flexColumn">
                  <small className="text-10">
                    Download from
                  </small>
                  <span className="text-16">Google Play</span>
                </span>
              </a>
            </div>
          </div>
          <div className="LMmainSection">
            <div className="main_login1 flexRowF">
              <MessageCom />
              <div className="content container">
                <Row className="LMheroSection">
                  <Col md={12}>
                    <h1 className="text-white text-48 font-weight-bold text-center">
                      Licensing Module Tutorial
                    </h1>
                    <h3 className="text-white text-36 text-center">
                    Learn how to build new videos 
                    <br />with your licensed content.

                    </h3>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <section className="bg-lightGray">
            <div className="content container py-4r">
              <h2 class="text_36 text-center mb-2 mt-1">
              When app users tag their videos with your ^Caret you can use that video to create new content.
              </h2>
              <div className="LMvideoGrid">
                {/* <div className="appuser px-1">
                  <span>App user Video 1</span>
                  <img
                    src={ArrowRight}
                    alt="ArrowRight"
                    width={36}
                  />
                </div> */}
                <div>
                  <img
                    className="appuser-videoBanner"
                    src={Video1}
                    alt="Video 1"
                  />
                </div>
                <div>
                  <img
                    className="appuser-videoBanner"
                    src={Video2}
                    alt="Video 2"
                  />
                </div>
                {/* <div className="appuser-reverse px-1">
                  <img
                    src={ArrowLeft}
                    alt="ArrowLeft"
                    width={36}
                  />{" "}
                  <span>App user Video 2</span>{" "}
                </div> 
                <div className="appuser px-1">
                  <span>App user Video 3 </span>
                  <img
                    src={ArrowRight}
                    alt="ArrowRight"
                    width={36}
                  />
                </div>*/}
                <div>
                  <img
                    className="appuser-videoBanner"
                    src={Video3}
                    alt="Video 3"
                  />
                </div>
                <div>
                  <img
                    className="appuser-videoBanner"
                    src={Video4}
                    alt="Video 4"
                  />
                </div>
                {/* <div className="appuser-reverse px-1">
                  <img
                    src={ArrowLeft}
                    alt="ArrowLeft"
                    width={36}
                  />{" "}
                  <span>
                    User Stock image / video (Company Ad)
                  </span>{" "}
                </div> */}
              </div>
            </div>
          </section>
          <section className="licensedCarets py-4r">
            <div className="container">
              <h3 className="text-36 text-center">
              Get started in three simple steps.
              </h3>
              <section className="mb-4r">
                <div className="stepOne relative">
                  <h4 className="text-24 text-center">
                    Upload custom content
                  </h4>
                  <div className="currentStep">Step: 1</div>
                  <div className="BLGridStep">
                    <div>
                      <figure className="text-center relative">
                        <img src={fig1} alt="Figure One" />
                        <figcaption className="pt-2">Splash</figcaption>
                      </figure>
                    </div>
                    <div>
                      <figure className="text-center relative">
                        <img src={fig2} alt="Figure Two" />
                        <figcaption className="relative pt-2">Videos</figcaption>
                        <div className="assetVideo">
                          <img src={playIcon} alt="Play" width={18} />
                        </div>
                      </figure>
                    </div>
                    <div>
                      <figure className="text-center relative">
                        <img src={fig3} alt="Figure Three" />
                        <figcaption className="relative pt-2">Watermark</figcaption>
                        <div className="assetWatermark">
                          <img src={caretFavicon} alt="Audio" width={48} />
                        </div>
                      </figure>
                    </div>
                    <div>
                      <figure className="text-center relative">
                        <img src={fig4} alt="Figure Four" />
                        <figcaption className="relative pt-2">Custom Audio</figcaption>
                        <div className="assetAudio">
                          <img src={Audio} alt="Audio" width={40} />
                        </div>
                      </figure>
                    </div>
                    <div>
                      <figure className="text-center relative">
                        <img src={fig5} alt="Figure Five" />
                        <figcaption className="relative pt-2">Promotions</figcaption>
                        <div className="assetPromo">
                          <img src={promo} alt="Audio" width={72} />
                        </div>
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="stepTitle">
                  <figcaption className="relative pt-2">Splash</figcaption>
                  <figcaption className="relative pt-2">Videos</figcaption>
                  <figcaption className="relative pt-2">Watermark</figcaption>
                  <figcaption className="relative pt-2">Custom Audio</figcaption>
                  <figcaption className="relative pt-2">Promotions</figcaption>
                </div>
              </section>
              <section className="mb-4r">
                <div className="stepOne stepTwo relative">
                  <h4 className="text-24 text-center mb-2">
                    Assemble a new licensed ^Carets video.
                  </h4>
                  <div className="currentStep">Step: 2</div>
                  <div className="BLGridStep">
                    <div>
                      <figure className="text-center relative">
                        <img src={fig1} alt="Figure One" />
                        <Button className="mt-1 btn btnPurple text-white" style={{ maxWidth: 110 }}>Selected</Button>
                        <figcaption className="pt-2">Splash</figcaption>
                      </figure>
                    </div>
                    <div>
                      <figure className="text-center relative">
                        <img src={fig2} alt="Figure Two" />
                        <Button className="mt-1 btn btnPurple text-white" style={{ maxWidth: 110 }}>Selected</Button>
                        <figcaption className="relative pt-2">Videos</figcaption>
                        <div className="assetVideo">
                          <img src={playIcon} alt="Play" width={18} />
                        </div>
                      </figure>
                    </div>
                    <div>
                      <figure className="text-center relative">
                        <img src={fig3} alt="Figure Three" />
                        <Button className="mt-1 btn btnPurple text-white" style={{ maxWidth: 110 }}>Selected</Button>
                        <figcaption className="relative pt-2">Watermark</figcaption>
                        <div className="assetWatermark">
                          <img src={caretFavicon} alt="Audio" width={48} />
                        </div>
                      </figure>
                    </div>
                    <div>
                      <figure className="text-center relative">
                        <img src={fig4} alt="Figure Four" />
                        <Button className="mt-1 btn btnPurple text-white" style={{ maxWidth: 110 }}>Selected</Button>
                        <figcaption className="relative pt-2">Custom Audio</figcaption>
                        <div className="assetAudio">
                          <img src={Audio} alt="Audio" width={40} />
                        </div>
                      </figure>
                    </div>
                    <div>
                      <figure className="text-center relative">
                        <img src={fig5} alt="Figure Five" />
                        <Button className="mt-1 btn btnPurple text-white" style={{ maxWidth: 110 }}>Selected</Button>
                        <figcaption className="relative pt-2">Promotions</figcaption>
                        <div className="assetPromo">
                          <img src={promo} alt="Audio" width={72} />
                        </div>
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="stepTitle">
                  <figcaption className="relative pt-2">Select Splash</figcaption>
                  <figcaption className="relative pt-2">Select Videos</figcaption>
                  <figcaption className="relative pt-2">Select Watermark</figcaption>
                  <figcaption className="relative pt-2">Select Audio</figcaption>
                  <figcaption className="relative pt-2">Select Promotion</figcaption>
                </div>
              </section>
              <section>
                <div className="stepOne stepThree relative">
                  <h4 className="text-24 text-center mb-2">
                    Use your new licensed ^Caret videos.
                  </h4>
                  <div className="currentStep">Step: 3</div>
                  <div className="BLGridStep">
                    <div>
                      <figure className="text-center relative">
                        <img src={fig1} alt="Figure One" />
                        <figcaption className="pt-2">Splash</figcaption>
                        <div className="publishApp">
                          <img src={publishIcon} alt="publishIcon" width={60} />
                        </div>
                      </figure>
                    </div>
                    <div>
                      <figure className="text-center relative">
                        <img src={fig2} alt="Figure Two" />
                        <figcaption className="relative pt-2">Videos</figcaption>
                        <div className="downloadApp">
                          <img src={videoDownloadIcon} alt="video Download Icon" width={60} />
                        </div>
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="stepTitle">
                  <figcaption className="relative pt-2">Publish in the Carets App</figcaption>
                  <figcaption className="relative pt-2">Download for your use</figcaption>
                </div>
              </section>
            </div>
          </section>
        </div>
      </Fragment>
    );
  }
}

const Tutorial = TutorialPage;
export default Tutorial;

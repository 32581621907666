import React, { Fragment } from 'react';
import { Button, Row, Col, Input } from "reactstrap";
import SimpleReactValidator from 'simple-react-validator';
import Select, { createFilter } from 'react-select';
import AsyncSelect from 'react-select/async';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import { campaignService } from '../../../services/campaign';
import CampaignForm3 from "./CampaignForm3";
import { Card } from "components/Card/Card.jsx";
import { objectsConstants as OC } from 'redux/constant/objects-constant';
// import { Grid, } from "react-bootstrap";
import Placeholder from '../../../assets/img/slider_placeholder.jpg';
import search from '../../../assets/img/search-purple.png';
import NoData from '../../../assets/img/noData.png';
import createAd from '../../../assets/img/create-new-ad.svg';
import Checked from '../../../assets/img/checked.svg';
var Modal = require('react-bootstrap-modal');

class CampaignForm2 extends React.Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem('user'));
    // const { id } = this.props.match.params;
    // const { id } = this.props.id;
    this.validatorForm = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
    this.state = {
      campaign: {
        ...OC.CAMPAIGNADS,
        user_id: user.data ? user.data.id : null,
        // campaign_id: this.props.id,
        campaign_id: this.props.campaign_id,
        status_id: 1,
      },

      adsList: [],
      plansList: [],
      statusList: [],
      // adsData: OC.CAMPAIGNADS.adsData,
      adsData: [],
      xid: 0,
      submitted: false,
      formType: 'add',
      createNewAd: false,
      createModalForm: false,

      plansSelected: [],
      video_url: [],
      ad_title: [],
      ad_link: [],
      ad_description: [],

      showAdModal: false,
      selectedAd: null,
      showPlanModal: false,
      selectedPlan: null,
      selectedPlanData: null,

      //  loader:true,
      loaderId: 0,

    };
    this.handleRec = this.handleRec.bind(this);
    this.handleRecSelect = this.handleRecSelect.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.handleNewAdClick = this.handleNewAdClick.bind(this);
  }

  // ######################################
  handleAdModalClose = () => {
    let idx = this.state.adsData.length - 1 > 0 ? this.state.adsData.length - 1 : 0;
    this.handleRemoveAds(idx)()
    this.setState({ showAdModal: false });
  };
  handlePlanModalClose = () => {
    this.setState({ showPlanModal: false });
  };


  handleSelectAdClick = (idx) => {
    this.setState({ showAdModal: true });
    this.setState({ xid: idx });
  }
  handleCreateAdModalClose = () => {
    let idx = this.state.adsData.length - 1 > 0 ? this.state.adsData.length - 1 : 0;
    this.handleRemoveAds(idx)()
    this.setState({ createModalForm: false });
  };

  handleAdSelect = (selectedOptionType) => {
    const newShareholders = this.state.adsData.map((shareholder, sidx) => {
      if (this.state.xid !== sidx) return shareholder;
      return { ...shareholder, ad_id: selectedOptionType['value'] };
    });
    const filtered = this.state.adsList.filter(obj => {
      return obj.value === selectedOptionType['value'];
    });

    if (filtered) {
      let recState = { ...this.state.video_url };
      recState[this.state.xid] = filtered[0].video_url;

      let resTitle = { ...this.state.ad_title };
      resTitle[this.state.xid] = filtered[0].label;

      let resLink = { ...this.state.ad_link };
      resLink[this.state.xid] = filtered[0].ad_button_link;

      let resDesp = { ...this.state.ad_description };
      resDesp[this.state.xid] = filtered[0].ad_description;

      this.setState({ video_url: recState, loader: false });
      this.setState({ ad_title: resTitle, ad_link: resLink, ad_description: resDesp });
    }
    this.setState({ showAdModal: false });
    this.setState({ showPlanModal: true });
    this.setState({ adsData: newShareholders }, () => { this.updatePropsData(); });
  };

  handlePlanSelect = (selectedOptionType) => {
    // console.log('plan', selectedOptionType)
    const newShareholders = this.state.adsData.map((shareholder, sidx) => {
      if (this.state.xid !== sidx) return shareholder;
      return { ...shareholder, plan_id: selectedOptionType['value'] };
    });
    const filtered = this.state.plansList.filter(obj => {
      return obj.value === selectedOptionType['value'];
    });

    let recState = { ...this.state.plansSelected };
    // recState[this.state.xid] =  " Ad Run Duration: 1 " + filtered[0].duration + "\n"+" Ad Position On Feed: " + filtered[0].position + "\n $ " + filtered[0].amount;
    recState[this.state.xid] = " Position: " + filtered[0].position + " / Duration: 1 " + filtered[0].duration + " / Amount $" + filtered[0].amount;

    this.setState({ plansSelected: recState });

    this.setState({ adsData: newShareholders }, () => { this.updatePropsData(); });

  };

  handleSavePlan = () => {
    const { plansSelected } = this.state;
    // const plan_title = selectedPlan.title; 
    this.setState({ plansSelected: plansSelected });
    this.handlePlanModalClose();
  };
  // ######################################
  
  componentDidMount() {
    this.getAdsCall(this.state.campaign.user_id);
    this.getPlansCall();
    this.getStatusCall();

  }
  getAdsCall = (val) => {
    campaignService.getAds(val).then(res => {
      // console.log(' adsList:', res.data)
      this.setState({ adsList: res.data });
    });
  }
  getPlansCall = (val) => {
    campaignService.getPlans(val).then(res => {
      this.setState({ plansList: res.data });
    });
  }
  getStatusCall = (val) => {
    campaignService.getStatus(val).then(res => {
      this.setState({ statusList: res.data });
    });
  }

  handleRec(e) {
    const { name, value, type } = e.target;
    const recState = { ...this.state.campaign };
    recState[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
    this.setState({ campaign: recState });
  }

  handleRecSelect = (selectedOptionType, info) => {
    console.log("selectedOptionType", selectedOptionType)
    if (info['action'] === 'select-option') {
      let recState = { ...this.state.campaign };
      recState[info['name']] = selectedOptionType['value'];
      console.log(recState);
      this.setState({ campaign: recState });
    }
    console.log("campaign", this.state.campaign);
  }

  handleSubmitForm(e) {
    e.preventDefault();
    if (this.validatorForm.allValid()) {
      this.setState({ submitted: true }, function () {
        this.submitForm(this.state.campaign, this.state.formType);

      });
    } else {
      this.setState({ submitted: false });
      this.validatorForm.showMessages();
      this.forceUpdate();
    }
  }

  submitForm = (formData, formType) => {
    campaignService.updateCampaignMultiAds(formData).then(res => {
      this.props.closeModel();
      if (this.props.callFunction) {
        this.props.callFunction(formData, 'add');
      }
    });
  }



  // #############################################

  updatePropsData() {
    const campaignState = { ...this.state.campaign };
    campaignState['adsData'] = this.state.adsData;
    this.setState({ campaign: campaignState });
  }

  handleNewAdClick = (idx) => {
    this.setState({ createNewAd: true });
    this.setState({ createModalForm: true });
    this.setState({ xid: idx });
  }
  handleNewAd = (result) => {
    // console.log("TESTING 2", result.data);

    this.setState({ createNewAd: false, });
    this.setState({ createModalForm: false });
    this.setState({ showPlanModal: true });
    this.getAdsCall(this.state.campaign.user_id);

    const newShareholders = this.state.adsData.map((shareholder, sidx) => {
      if (this.state.xid !== sidx) return shareholder;
      return { ...shareholder, ad_id: result.data.id };
    });

    let recState = { ...this.state.video_url };
    recState[this.state.xid] = result.data.video_url;

    let recTitle = { ...this.state.ad_title };
    recTitle[this.state.xid] = result.data.ad_title;

    let recLink = { ...this.state.ad_link };
    recLink[this.state.xid] = result.data.ad_button_link;

    let recDisp = { ...this.state.ad_description };
    recDisp[this.state.xid] = result.data.ad_description;

    setTimeout(() => {
      this.setState({ video_url: recState, loader: false });
    }, 15000);
    this.setState({ ad_title: recTitle, ad_link: recLink, ad_description: recDisp });
    this.setState({ adsData: newShareholders }, () => { this.updatePropsData(); });
  }

  handleAdsChange = idx => evt => {
    const newShareholders = this.state.adsData.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      if (evt.target.name === 'ad_id')
        return { ...shareholder, ad_id: evt.target.value };
      if (evt.target.name === 'plan_id')
        return { ...shareholder, ad_id: evt.target.value };
    });

    this.setState({ adsData: newShareholders }, () => { this.updatePropsData(); });
  };

  // handleAdsSelect(selectedOptionType, info, idx) {
  //   const newShareholders = this.state.adsData.map((shareholder, sidx) => {
  //     if (idx !== sidx) return shareholder;

  //     if (info['name'] === 'ad_id')
  //       return { ...shareholder, ad_id: selectedOptionType['value'] };

  //     if (info['name'] === 'plan_id') {

  //       return { ...shareholder, plan_id: selectedOptionType['value'] };
  //     }
  //   });

  //   if (info['name'] === 'ad_id') {
  //     const filtered = this.state.adsList.filter(obj => {
  //       return obj.value === selectedOptionType['value'];
  //     });

  //     if (filtered) {
  //       let recState = { ...this.state.video_url };
  //       recState[idx] = filtered[0].video_url;
  //       console.log(recState);
  //       this.setState({ video_url: recState });
  //     }
  //   }

  //   if (info['name'] === 'plan_id') {
  //     const filtered = this.state.plansList.filter(obj => {
  //       return obj.value === selectedOptionType['value'];
  //     });

  //     let recState = { ...this.state.plansSelected };
  //     recState[idx] = " Position: " + filtered[0].position + " / Duration: " + filtered[0].duration + " / Amount: $" + filtered[0].amount;
  //     this.setState({ plansSelected: recState });
  //   }

  //   this.setState({ adsData: newShareholders }, () => { this.updatePropsData(); });
  // }

  handleAddAds = () => {
    this.setState({
      adsData: [...this.state.adsData.concat([{ ad_id: "", plan_id: "" }])]
    }, () => {
      this.updatePropsData();
      console.log('ad handleAddAds Latest', this.state.adsData.length - 1)
      let idx = this.state.adsData.length - 1 > 0 ? this.state.adsData.length - 1 : 0;
      this.handleSelectAdClick(idx);
      this.setState({ loader: true, loaderId: idx });
    });
  };

  handleAddAdsCreat = () => {
    this.setState({
      adsData: [...this.state.adsData.concat([{ ad_id: "", plan_id: "" }])]
    }, () => {
      this.updatePropsData();
      console.log('ad handleAddAdsCreate Latest', this.state.adsData.length - 1)
      let idx = this.state.adsData.length - 1 > 0 ? this.state.adsData.length - 1 : 0;
      this.handleNewAdClick(idx);
      this.setState({ loader: true, loaderId: idx });
    });
  };



  handleRemoveAds = idx => () => {
    // Remove the data associated with the given index
    let newAdTitle = { ...this.state.ad_title };
    let newVideoUrl = { ...this.state.video_url };
    let newAdLink = { ...this.state.ad_link };
    let newAdDescription = { ...this.state.ad_description };
    let newPlansSelected = { ...this.state.plansSelected };

    delete newAdTitle[idx];
    delete newVideoUrl[idx];
    delete newAdLink[idx];
    delete newAdDescription[idx];
    delete newPlansSelected[idx];

    // Re-index the remaining data
    const reindexedAdTitle = {};
    const reindexedVideoUrl = {};
    const reindexedAdLink = {};
    const reindexedAdDescription = {};
    const reindexedPlansSelected = {};

    Object.keys(newAdTitle).forEach((key, index) => {
      reindexedAdTitle[index] = newAdTitle[key];
      reindexedVideoUrl[index] = newVideoUrl[key];
      reindexedAdLink[index] = newAdLink[key];
      reindexedAdDescription[index] = newAdDescription[key];
      reindexedPlansSelected[index] = newPlansSelected[key];
    });

    // Update the state with re-indexed data
    this.setState({
      ad_title: reindexedAdTitle,
      video_url: reindexedVideoUrl,
      ad_link: reindexedAdLink,
      ad_description: reindexedAdDescription,
      plansSelected: reindexedPlansSelected
    });

    // Remove the ad from the adsData array
    this.setState({
      adsData: this.state.adsData.filter((s, sidx) => idx !== sidx)
    }, () => {
      this.updatePropsData();
    });
    this.setState({ loader: false })
  };


  // handleRemoveAds = idx => () => {
  //   this.setState({
  //     adsData: this.state.adsData.filter((s, sidx) => idx !== sidx)
  //   }, () => {
  //      this.updatePropsData(); 
  //     });
  // };



  // #############################################
  videoModal(url) {
    this.setState({ video_url: url }, () => {
      this.setState({ openVideoModal: true });
      // console.log('url', this.state.video_url);
    });
  }
  render() {
    const { campaign, formType } = this.state;
    const filterConfig = {
      ignoreCase: true,
      matchFrom: 'start',
    };
    return (
      <Fragment>
        <div className="main_login1 w-100 py-1">
          <div className="">
            <form onSubmit={this.handleSubmitForm}>
              {formType === 'edit' &&
                <input type="hidden" name="ad_id" className="form-control" value={campaign.id} />
              }
              <div className="flexElem gap-2 items-center">
                {/* <h3 className="text-left font-bold m-0">Campaign Ads</h3> */}
                <div className="flexElem ml-auto gap-2" style={{ gap: 10 }}>
                  {/* <a
                    type="button"
                    className="btn btnLightBlue font-boldF text-16"
                    onClick={this.handleAddAds}
                  >
                    Add Another Advertisement
                  </a> */}

                  <Button variant="success" disabled={this.state.loader} onClick={this.handleAddAds}>
                    Select an Ad
                  </Button>

                  {this.state.adsData.length > 0 && (
                  <Button variant="info" disabled={this.state.loader} onClick={this.handleAddAdsCreat}>
                    Create New Ad
                  </Button>
                  )}
                  <Button
                    type="submit"
                    className="btnDark px-5 font-boldF text-16">
                    Save Your Ads
                  </Button>

                </div>
              </div>
              {/* ######## Buttons Here ########### */}
              <div>

                {this.state.adsData.length === 0 && (
                  <div className='text-center mt-xl'>
                    {/* <img src={NoData} alt={NoData} width={84} className='mt-xl' /> */}
                    <img src={createAd} alt={createAd} width={84} className='mt-xl' style={{cursor:"pointer"}} onClick={this.handleAddAdsCreat}/>
                    <h3 className='font-bold text-14 mt-md'>No ads available. Please select an existing ad or create a new one.</h3>
                  </div>
                )}

                {[...this.state.adsData].map((shareholder, idx) => (
                  <div key={this.state.adsData.length - 1 - idx}>
                    {/* {idx === 0 && (
                      <div className='flexElem alignCenter justifyEnd gap10p mt-2'>
                        <Button variant="success" onClick={() => this.handleSelectAdClick(this.state.adsData.length - 1 - idx)}>
                          Select an Ad
                        </Button>
                        <Button variant="info" onClick={() => this.handleNewAdClick(this.state.adsData.length - 1 - idx)}>
                          Create New Ad
                        </Button>
                        <a type='button' className='btn text-success' onClick={() => this.handleNewAdClick(this.state.adsData.length - 1 - idx)}>
                          Create New Ad
                        </a>
                      </div>
                    )} */}
                    <div>
                      <Row>
                        <Col md={12}></Col>
                        <Col md={12}>
                          <h3 className='text-16'>Selected Ad</h3>
                        </Col>
                        <Col md={7}>
                          <div className='border'>
                            <div className={`p-1`}>
                              <div className="flexElem alignCenter gap25">
                                <div className="text-primary font-weight-bold text-16"> Selected Ad Title:</div>
                                <div className="ml-auto text-16 text-right">
                                  {this.state.ad_title[this.state.adsData.length - 1 - idx] ? this.state.ad_title[this.state.adsData.length - 1 - idx] : ""}
                                </div>
                              </div>
                            </div>
                            <div className={`p-1`}>
                              <div className="flexElem alignCenter gap25">
                                <div className="text-primary font-weight-bold text-16"> Link To:</div>
                                <div className="ml-auto text-right text-16">
                                  {this.state.ad_link[this.state.adsData.length - 1 - idx] ? this.state.ad_link[this.state.adsData.length - 1 - idx] : ""}                                                </div>
                              </div>
                            </div>
                            <div className={`p-1`}>
                              <div className="flexElem alignCenter gap25">
                                <div className="text-primary font-weight-bold text-16"> Diecription: </div>
                                <div className="ml-auto text-right text-16">
                                  {this.state.ad_description[this.state.adsData.length - 1 - idx] ? this.state.ad_description[this.state.adsData.length - 1 - idx] : ""}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='mt-xl'>
                            <h3 className='text-16'>Advertising Plan</h3>
                            <div className='flexElem alignEnd gap25'>
                              <div className='boxPlan p-15 borderPurple rounded'>
                                {
                                  this.state.plansSelected[this.state.adsData.length - 1 - idx] ?
                                    this.state.plansSelected[this.state.adsData.length - 1 - idx]
                                    // <div className='choosedPlan'>
                                    //   {/* {
                                    //     this.state.plansList.map((plan, idx) => {

                                    //     })
                                    //   } */}
                                    //   {console.log("PLAN ========== ", this.state.plansSelected)}

                                    //   <h4 className='mt-sm font-bold'>Weekly Plan</h4>
                                    //   <p>Ad Run Duration: 1 Month</p>
                                    //   <p>Ad Position on Feed: 3rd</p>
                                    //   <div className='flexElem alignCenter gap10 mt-lg'>
                                    //     <h3 className='my-md font-bold'>$40</h3>
                                    //     <img src={Checked} alt={Checked} width={40} className='ml-auto' />
                                    //   </div>
                                    // </div>
                                    :
                                    <div className='text-center'>
                                      <img src={NoData} alt={NoData} width={84} />
                                      <h3 className='font-bold text-14 mt-md'>No Plan Selected</h3>
                                    </div>
                                }
                              </div>
                              <div className='ml-auto'>
                                <Button onClick={this.handleRemoveAds(this.state.adsData.length - 1 - idx)} className='btn-danger'> Remove Ad </Button>
                              </div>
                            </div>
                          </div>
                          {/* <div style={{ marginTop: '10px', height: '120px', width: "50%" }}>
                            <div className='shadow'>
                              <div className={`p-1`}>
                                <div className="flexElem alignCenter">
                                  <div className="text-primary font-weight-bold text-16">Advertising Plan:</div>
                                  <div>
                                    {this.state.plansSelected[this.state.adsData.length - 1 - idx] ? this.state.plansSelected[this.state.adsData.length - 1 - idx] : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </Col>
                        <Col md={1}></Col>
                        <Col md={4}>
                          <div>
                            {(this.state.loader && this.state.loaderId === this.state.adsData.length - 1 - idx) ? (
                              <div className="loader">
                                Loading...
                              </div>
                            ) : (
                              <video key={this.state.video_url[this.state.adsData.length - 1 - idx]} id="playVid" controls className='videoPreviewSmall'>
                                <source src={this.state.video_url[this.state.adsData.length - 1 - idx]} type="video/mp4" />
                              </video>
                            )}
                          </div>
                          {/* <div>
                            <video key={this.state.video_url[this.state.adsData.length - 1 - idx]} id="playVid" controls className='videoPreviewSmall'><source src={this.state.video_url[this.state.adsData.length - 1 - idx]} type="video/mp4" /></video>
                          </div> */}
                        </Col>
                      </Row>
                    </div>
                  </div>
                ))}
              </div>
            </form>
            {/* @@@@@@@@@@@@ Ads model Here @@@@@@@@@@@@ */}
            <Modal show={this.state.showAdModal} onHide={this.handleAdModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h3 className='font-bold text-center m-0'>Select an Ad</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* <Button variant="info" className='btnDark px-5 font-boldF text-16 mb-20'>
                  Create New Ad
                </Button>
                <div className='mt-20 mb-20 text-center font-bold'>OR</div> */}
                <div className='flexElem alignCenter spaceBetween mb-20'>
                  {/* <h5 className='m-0 font-bold'>Select an Ad:</h5> */}
                  {/* <div className='flexElem alignCenter border'>
                    <Input placeholder="username" style={{ border: 'none' }} />
                    <Button style={{ border: 'none' }}><img src={search} alt={search} /></Button>
                  </div> */}
                </div>
                <div className='gridAd'>
                  {this.state.adsList.map((ad, index) => (
                    <div className='adWrap' onClick={() => this.handleAdSelect(ad)}>
                      {(index % 4 === 0)}
                      <video src={ad.video_url} alt={Placeholder} className='adImage' />
                      <div className='adContent flexElem flexColumn mt-auto gap10p' style={{ wordBreak: 'break-word' }}>
                        <div style={{ marginTop: 'auto' }}>
                          <h6 className='text-primary m-0' style={{ textTransform: 'capitalize', fontSize: 11 }}>Ad Title:</h6>
                          <h5 className='m-0'>{ad.label}</h5></div>
                        <div>
                          <h6 className='text-primary m-0' style={{ textTransform: 'capitalize', fontSize: 11 }}>Link to:</h6>
                          <a href={ad.ad_button_link} className='text-dark'>{ad.ad_button_link}</a>
                        </div>
                      </div>
                      {/* <div className='adContent'>{ad.ad_description}</div> */}
                      {(index % 3 === 2 || index === this.state.adsList.length - 1)}
                    </div>
                  ))}
                </div>
              </Modal.Body>
              <Modal.Footer>
              </Modal.Footer>
            </Modal>
            {/* ####### plan Model ########## */}
            <Modal show={this.state.showPlanModal} onHide={this.handlePlanModalClose} className="modal800" size="lg">
              <Modal.Header closeButton>
                <Modal.Title><h3 className='font-bold m-0'>Select Ad Packege</h3></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='packagePlan'>
                  {this.state.plansList.map((plan, idx) => (
                    <div className={`package p-15 borderPurple rounded ${this.state.plansSelected[this.state.xid] === " Position: " + plan.position + " / Duration: 1 " + plan.duration + " / Amount $" + plan.amount ? 'current' : ''}`}
                      key={plan.value} onClick={() => this.handlePlanSelect(plan, idx)}>
                      <h4 className='font-bold mt-md'>{plan.duration} Plan</h4>
                      {/* {plan.label} */}
                      {/* {`${plan.label} Ad Run Duration: ${plan.duration} Ad position on feed: ${plan.position} ${plan.amount}`} */}
                      <h6 className='font-bold text-capitalize mt-sm mb-sm'>Ad Run Duration: 1 {plan.duration}</h6>
                      <h6 className='font-bold text-capitalize mt-sm mb-sm'>Ad position on feed: {plan.position}</h6>
                      <h3 className='font-bold text-primary'>${plan.amount}</h3>
                      {/* <h3 className='font-bold text-primary'>${plan.amount}</h3> */}
                    </div>
                  ))}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={this.handleSavePlan} className='btnDark px-5 font-boldF text-16 btn-block'>
                  Save
                </Button>
              </Modal.Footer>
            </Modal>

            {/* @@@@@@@@@@@@@@@@@@@@@@@@ */}

            {/* ####### Create new ad ########## */}

            {this.state.createNewAd &&
              <Modal backdrop={'static'} show={this.state.createModalForm} onHide={this.handleCreateAdModalClose}aria-labelledby="ModalHeader" className="modalLG modal-lg mx-auto">
                <Modal.Header closeButton>
                  <Modal.Title id='ModalHeader' className="headerTitle">Create Ad</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12">
                      <Card bsClass={["mb-none borderNone shadowNone"]} content={
                        <CampaignForm3
                          closeModel={this.handleCreateAdModalClose}
                          formTypePro="add"
                          recPro={OC.ADV}
                          user_id={this.state.campaign.user_id}
                          callFunction={this.handleNewAd}
                        />
                      } />
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            }

          </div>
        </div>
      </Fragment>
    );
  }
}


export default CampaignForm2;

import { SC } from 'helper/ServerCall';

export const advertiserUserService = {
    login,
    logout,
    dashboard,
    dashboardMenu,
}
function login(email, password, role=null) {
    return SC.postCallLoginAdvertiser('auth/advlogin', { email, password, role});
}

function logout() {
    return SC.getCall('logout');
}

function dashboard() {
    return SC.getCall('dashboard');
}

function dashboardMenu() {
    return SC.getCall('getAdvertiserMenu');
}

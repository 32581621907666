export const adminLabels = {
	DONT_ALLOW : 'You are not allowed to do this action.',
	MIN:'Select at-least one option.',
	DELETE:'Are you sure you want to delete this ',
	IMAGESHOW:["image/jpeg", "image/jpg", "image/png"],
	VIDEOSHOW:["video/mp4"],
	UPLOADED_IMAGES:'Original Upload Images',
	EDITOR:'Editor',
	PRODUCTION_MANAGER:'Production-Manager',
	QUALITY_ASSURANCE:'Quality-Assurance',
}

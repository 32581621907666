import { SC } from '../helper/ServerCall';

export const videoService = {
    paginationData,
    paginationCLMData,
    storeVideo,
    getVideo,
    editVideo,
    deleteVideo,
    updateVideoStatus,
    getUsers,
    getCatsPagination,
    storeCat,
    getCat,
    editCat,
    updateCatStatus,
    deleteCat,
    blockRecord,
    uploadtest,
}
function paginationData(page, filter, sort, pageSize,status,search=null) {
    const data = {'page': page,'filter': filter,'sort': sort,'pageSize': pageSize,'status': status,'search':search};
    return SC.postCall('video/data',data);
}
function paginationCLMData(page, filter, sort, pageSize,status,search=null) {
    const data = {'page': page,'filter': filter,'sort': sort,'pageSize': pageSize,'status': status,'search':search};
    return SC.postCall('video/clmdata',data);
}
function getUsers() {
    return SC.getCall('video/getUsers');
}
function storeVideo(catData) {
    return SC.postCall('video/listing', catData);
}
function uploadtest(catData) {
    return SC.postCall('video/listing', catData);
}
function getVideo(id) {

    return SC.getCall('video/listing/' + id);
}

function editVideo(data, VideoId) {
    data['video_id'] = VideoId;
    return SC.postCall('video/listing', data);
}

function deleteVideo(catId) {
    return SC.deleteCall('video/listing/' + catId);
}

function updateVideoStatus(catId, data) {
    data['video_id'] = catId;
    return SC.postCall('video/listing/status',data);
}
function blockRecord(catId) {
    return SC.getCall('video/block/status/'+catId);
}
function getCatsPagination(page, filter, sort, pageSize,status) {
    return SC.getCall('music/categories?page=' + page + '&filter=' + filter + '&sort=' + sort + '&pageSize=' + pageSize);
}


function storeCat(catData) {
    return SC.postCall('music/addCategory', catData);
}
function getCat(catId) {

    return SC.getCall('music/category/' + catId);
}
function updateCatStatus(catId, data) {
    data['category_id'] = catId;
    return SC.postCall('music/category/status',data);
}

function deleteCat(catId) {
    return SC.getCall('music/category/delete/' + catId);
}


function editCat(catData, userId) {
    return SC.postCall('music/addCategory', catData);
}



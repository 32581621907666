import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Row,
  Col,
  // Tooltip,
  OverlayTrigger,
  Button,
  Popover
} from "react-bootstrap";
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import AduserForm from "./AduserForm";
import { hasPermission } from 'helper/hasPermission';
import Confirmalertfordelete from '../../ThemeComponents/confirmAlertForDelete';
import { adminLabels } from '../../../redux/constant/admin-label-constant';
import OpenModalButton from '../../ThemeComponents/openModelButton';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import Pagination from '../../ThemeComponents/Pagination';
import Dummy_image from "../../../assets/img/carets-logo-v3.jpg";

import { appuserService } from '../../../services/appuser';
import { roleService } from '../../../services/roles';
import { Link } from 'react-router-dom';

var Modal = require('react-bootstrap-modal');

class AdUserListPage extends Component{
  constructor(props){
  	super(props);
  	this.state = {
  	  addModalUser  : false,
      editModalUser : false,
      sorted   : '',
      filtered : '',
      pageSize : 10,
  	};

  	this.callEditUser = this.callEditUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  	this.deleteConfirmUser = this.deleteConfirmUser.bind(this);
    this.usersPaginationData  = this.usersPaginationData.bind(this);
    this.filterSearchHandle     = this.filterSearchHandle.bind(this);
    this.handleUserSubmit= this.handleUserSubmit.bind(this);
    this.getAllRolesCall= this.getAllRolesCall.bind(this);
  };

  handleUserSubmit(userData, formType) {
    if (formType === 'add'){
        console.log("this.state.user",userData);
        appuserService.storeAdvertiser(userData).then(res => {
            this.setState({addModalUser:false});
            this.pagination.dataCall();
          });
    }else if (formType === 'edit'){
        appuserService.updateAdvertiser(userData, this.state.editUser.id).then(res => {
            this.setState({editModalUser:false});
            this.pagination.dataCall();
          });
    }
  }

  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }
  getAllRolesCall(){
    roleService.getUserRoles().then(res => {
        console.log('roles',res);
        this.setState({roles:res.userRoles});
      });
  }
  callEditUser(id) {
    //appuserService.getUser(id);
    appuserService.getUser(id).then(res => {
        console.log(res);
        this.setState({editUser:res.user}, ()=>{
            this.setState({editModalUser:true});
        });
      });
  }

  deleteUser(userId) {
    appuserService.deleteAdvertiser(userId).then(res => {
      this.pagination.dataCall();
    });
  }

  deleteConfirmUser(userId) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="User" description="Are you sure to delete the admin user?" onClosePro={onClose} deleteType={() => this.deleteUser(userId)} />
        )
      }
    })
  }

//   showPasswordModal = (userId) => {
//     this.props.showPasswordCall(userId).then(response => {
//       confirmAlert({
//         customUI: ({ onClose }) => {
//           return (
//             <ShowPassword description={`Password for ${response.user.username} : `} user={response.user} onClosePro={onClose} />
//           )
//         }
//       })
//     })
//   }

  usersPaginationData(page, filter, sort, pageSize, status) {
    this.setState({ pageSize: pageSize }, function () {
      appuserService.getAdvertisersPagination(page, filter, sort, pageSize, status);
    })
  }

  handleUpdateUser = userId => (e) => {
    // return false;
    appuserService.updateUserStatus(userId, { is_active: e.target.checked ? 1 : 0 }).then(
      response => {
        appuserService.getAdvertisersPagination(this.props.current_page, '', '', this.state.pageSize, this.state.user_status);
        this.pagination.dataCall();
      }
    );
  }

  handleBlocked= userId => (e) => {
    // return false;
    appuserService.updateBlocked(userId, { is_blocked: e.target.checked ? 1 : 0 }).then(
      response => {
        appuserService.getAdvertisersPagination(this.props.current_page, '', '', this.state.pageSize, this.state.user_status);
        this.pagination.dataCall();
      }
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.editUser !== this.props.editUser) && !this.props.loading) {
      this.setState({ editModalUser: true });
    }
    if ((prevProps.storedUser !== this.props.storedUser) && !this.props.loading) {
      this.setState({ addModalUser: false }, function () {
        appuserService.getAdvertisersPagination(this.props.current_page, '', '', this.state.pageSize, this.state.user_status);
      });
    }
    if ((prevProps.updatedUser !== this.props.updatedUser) && !this.props.loading) {
      this.setState({ editModalUser: false }, function () {
        appuserService.getAdvertisersPagination(this.props.current_page, '', '', this.state.pageSize, this.state.user_status);
      });
    }
    if ((prevProps.deleteUser !== this.props.deleteUser) && !this.props.loading) {
      appuserService.getAdvertisersPagination(this.props.current_page, '', '', this.state.pageSize, this.state.user_status);
    }
    if (this.state.user_status !== prevState.user_status) {
      this.table.state.onFetchData(this.table.state, this.table.instance)
    }

  }

  componentDidMount() {
    this.getAllRolesCall();
  }

  paginationCall = (data) => {
    return appuserService.getAdvertisersPagination(data.page, data.filter, data.sort, data.pageSize,this.state.user_status);
  }
  
      handleLoginAsClmUser = (clmUserId) => {
        appuserService.generateAdvUserToken(  { adv_user_id: clmUserId} )
            .then((response) => {
                console.log(response.token);
                const clmUser = response;
               
                
                // Save the current admin session temporarily
                const currentUser = localStorage.getItem("user");
                localStorage.setItem("admin_session", currentUser);
    
                // Replace with the CLM user session
                const clmUserSession = JSON.stringify({
                    ...clmUser,
                    // token: token,
                    // role: role,
                    // role_permissions: role_permissions,
                });
                console.log(clmUserSession);
                localStorage.setItem("user", clmUserSession);
    
                // Redirect to CLM dashboard
                window.location.href = "/advertiser/dashboard";
            })
            .catch((error) => {
                console.error("Error logging in as CLM user:", error);
                alert("Failed to log in as CLM user.");
            });
    };
  render(){
  	const { filtered,editUser,roles } = this.state;
    const columns = [
        {
          Header:"Users Listing",
          headerClassName:'headerPagination',
          columns: [{
            Header: "Photo",
            accessor: "profile",
            Cell: ({row}) => (
              <div>
                { row.profile && row.profile.user_photo ?(
                  row.profile?<img className="initialsLogo" alt="initialLogo" src={row.profile.user_photo} />:TF.initialsLogo(row.username) + row.business_name
                  ):(
                    <img src={Dummy_image} className="roundedFull audioAVT" alt="Audio" />
                  )
                }
              </div>
              ),
            className: "action-center"
          },
            {
              Header: "Email",
              accessor: "email",
              className: "justifyStart text-left",
              headerClassName: "justifyStart text-left"
            },
            {
                Header:"Ads",
                accessor: "id",
                Cell: ({ value }) => {
                    return <Link to={'/Admin/ads/' + value} >View</Link>
                  },
                  className: "action-center",
                sortable:false
              }, {
                Header:"Campaigns",
                accessor: "id",
                Cell: ({ value }) => {
                    return <Link to={'/Admin/campaigns/' + value} >View</Link>
                  },
                  className: "action-center",
                sortable:false
              }, {
                Header:"Cards",
                accessor: "id",
                Cell: ({ value }) => {
                    return <Link to={'/Admin/cards/' + value} >View</Link>
                  },
                  className: "action-center",
                sortable:false
              },  {
                Header:"Payments",
                accessor: "id",
                Cell: ({ value }) => {
                    return <Link to={'/Admin/payments/' + value} >View</Link>
                  },
                  className: "action-center",
                sortable:false
              },

            {
              Header: "Status",
              accessor: "is_active",
              Cell: ({row}) => (
                  <label className="container-check">
                    &nbsp;
                    <input
                      type="checkbox" name="is_active" className="checkbox" value={row.is_active? 1 : 0} onChange={this.handleUpdateUser(row.id)}  checked={row.is_active?1:0}
                     /><span className="checkmark"></span>
                  </label>
                ),
              className: "action-center",
              sortable:false
            },
            {
                Header: "Blocked",
                accessor: "is_blocked",
                Cell: ({row}) => (
                    <label className="container-check">
                      &nbsp;
                      <input
                        type="checkbox" name="is_blocked" className="checkbox" value={row.is_blocked? 1 : 0} onChange={this.handleBlocked(row.id)}  checked={row.is_blocked?1:0}
                       /><span className="checkmark"></span>
                    </label>
                  ),
                className: "action-center",
                sortable:false
              },
              {
                              Header: "Access",
                              accessor: "id",
                              Cell: ({ row, original, value }) => (
                                // console.log('value', original),
                               
                                <div>
                                  <Button bsSize="small" 
                                  className='btn btnPurple text-white w-autoF'
                                  onClick={() => {
                                    this.handleLoginAsClmUser(original.id);
                                  }}>
                                    Login as Advertiser
                                  </Button>
                                </div>
                               
                              ),
                              sortable: false
                            }, 
              

            {
              Header:"Actions",
              accessor: "id",
              Cell: ({value}) => (
                <div>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{ hasPermission('user-edit')?'Edit Advertiser': adminLabels.DONT_ALLOW  }</Popover>}>
                  <span>
                  {
                    hasPermission('user-edit') &&
                    <button type='button' className="editIcon orderDetailsAction" onClick={() => this.callEditUser(value)}>&nbsp;</button>
                  }
                  {
                    !hasPermission('user-edit') &&
                    <button type='button' className="editIcon orderDetailsAction button_disabled" >&nbsp;</button>
                  }
                  </span>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{ hasPermission('user-delete')?'Delete Advertiser': adminLabels.DONT_ALLOW  }</Popover>}>
                  <span>
                  {
                    hasPermission('user-delete') &&
                    <button type='button' className="deleteIcon orderDetailsAction" onClick={() => this.deleteConfirmUser(value)}>&nbsp;</button>
                  }
                  {
                    !hasPermission('user-delete') &&
                    <button type='button' className="deleteIcon orderDetailsAction button_disabled" >&nbsp;</button>
                  }
                  </span>

                </OverlayTrigger>
                </div>
                ),
              className: "justifyEnd text-right",
              headerClassName: "justifyEnd text-right",
              sortable:false
            }
          ]
        }
    ];

  	return(
  		<div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                	<div>
                  	<Grid fluid>
                      <Row>
                          <Col md={12}>
                            <div className="secHeading">Advertisers</div>
                          </Col>

                          <Col md={12}>
                            <div className="flexElem flexResponsive" style={{"alignItems": "flex-start", "justifyContent": "space-between"}}>
                              <div className="">
                                <OpenModalButton
                                  openModal={hasPermission('user-create')?HF.openModal.bind(this, "addModalUser"):null}
                                  classButton={['backButton pt-sm no_radius pb-sm primary mt-none btn-block btn-info btn', !hasPermission('user-create')?'button_disabled':'']}
                                  buttonName="Add Advertiser"
                                  tooltipText={hasPermission('user-create')?'Add Advertiser':adminLabels.DONT_ALLOW }
                                  classIcon={['fa', 'fa-plus']}
                                />
                              </div>


                            </div>
                          </Col>

                          <Col md={12} className="mt-md">
                          <Pagination
                          ref={(p) => this.pagination = p}
                          showPagination={false}
                          columns={columns}
                          pageSize={20}
                          getDataCall={this.paginationCall}
                          filterView={true}
                          filterPlaceHolder={'Advertisers'}
                          defaultSorted={
                            [
                              {
                                id: 'name',
                                desc: 'false'
                              },
                              {
                                id: 'is_active',
                                desc: 'false'
                              }
                            ]
                          }
                          // downloadData={true}
                          // downloadFileName={'Orders'}
                          // lowerContent = {null}
                          // filterPlaceHolder = {'Orders'}
                          noDataText='No advertiser found'
                          getRowProps={this.getRowProps}
                        // showAllToggle={true}
                        />
                          </Col>
                      </Row>
                    </Grid>

                    {/*Add User Modal Start*/}
                    { roles !== null &&
                      <Modal backdrop={'static'} show={this.state.addModalUser} onHide={HF.closeModal.bind(this, "addModalUser")} aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                          <Modal.Title id='ModalHeader' className="headerTitle">Add Advertiser</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12">
                              <Card bsClass={["innerCard mb-none"]} content={
                                <AduserForm
                                  closeModel={HF.closeModal.bind(this, "addModalUser")}
                                  formTypePro="add"
                                  userPro={OC.USER}
                                  handleUserSubmit= { this.handleUserSubmit }
                                  componentPro="AppUser"
                                  roles={roles}
                                />
                              } />
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    }

                    {/*Add User Modal End*/}

              		  {/*Edit User Modal start*/}
                    { editUser && roles !== null &&
                      <Modal backdrop={'static'} show={this.state.editModalUser} onHide={HF.closeModal.bind(this, "editModalUser")} aria-labelledby="ModalHeader" >
                      <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Edit Advertiser</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-md-12">
                          <Card bsClass={["innerCard mb-none"]} content={
                            <AduserForm
                              closeModel={HF.closeModal.bind(this, "editModalUser")}
                              formTypePro="edit"
                              userPro={editUser}
                              handleUserSubmit= { this.handleUserSubmit }
                              roles={roles}
                            />
                          } />
                          </div>
                        </div>
                      </Modal.Body>
                      </Modal>
                    }
                    {/*Edit User Modal End*/}
                	</div>
                } />
            </Col>
          </Row>
        </Grid>
      </div>
  	);
  };
}



  const AdUsers = AdUserListPage;
  export default AdUsers;

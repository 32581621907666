import React, { Component, Fragment } from 'react';
import { Grid, Row, Col } from "react-bootstrap";
import { Button, Badge } from "reactstrap";
import Footer from "../../FrontEnd/Footer/Footer"
import Header from "../../FrontEnd/Header/Header"
import Heart from "../../../assets/img/heart.svg"
import CartIcon from "../../../assets/img/cart.svg"
import Clip3 from "../../../assets/img/dashboard/finishing-clip-3.png"
import Clip1 from "../../../assets/img/dashboard/finishing-clip-1.png"
import Clip7 from "../../../assets/img/dashboard/finishing-clip-7.png"
import Splash7 from "../../../assets/img/dashboard/splash-video-7.png"
import Checked from "../../../assets/img/checkWhite.svg"
import { Link } from 'react-router-dom';

import { Card } from "components/Card/Card.jsx";
import SimpleReactValidator from "simple-react-validator";
import Select, { createFilter } from "react-select";
import AsyncSelect from "react-select/async";
import { helperFunctions as HF } from "../../../helper/helperFunctions";
import { cardService } from "../../../services/card";
import { licenseService } from "../../../services/license";
import { clmPlanService } from "../../../services/CLMplan";
import NoData from '../../../assets/img/noData.png';
import Payment from "payment";
import Pagination from '../../ThemeComponents/Pagination';
// import masterCard from "../../../assets/img/mastercard.png"
import masterCard from "../../../assets/img/sampleCard.svg"
import Swal from "sweetalert2";
import { get } from 'jquery';
import { toLower } from 'lodash';
var Modal = require('react-bootstrap-modal');




class LicenseCart extends Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem("user"));
    //const { id } = this.props.match.params;
    this.validatorForm = new SimpleReactValidator({
      autoForceUpdate: this,
      locale: "en",
    });
    this.state = {
      card: {
        user_id: user.data ? user.data.id : null,
        card_number: "",
        card_expiry: "",
        card_cvc: "",
        // campaign_id :id,
      },

      license: {
        user_id: user.data ? user.data.id : null,
        caret_title: '',
        subscription_plan: '',
        subscription_amount: "",
        subscription_stripe_id: '',
        status_id: 1,
      },
      usersList: [],
      usersList: [],
      submitted: false,
      formType: "add",
      campaignDetails: [],
      DataAds: [],
      ExCardData: [],
      getInfluencer: [],
      influencerTitle: '',
      influencerAmount: '',
      totalAmount: 0,
      CardForm: false,
      user_id: user.data ? user.data.id : null,
      storeCaret: 0,
      saveCard: 0,
      modalOpen: false,
      oneYear: '',
      twoYear: '',
      threeYear: '',
      selectedLicense: '',
      networth: '',
      google_search_volume: '',
      instagram: '',
      twitter: '',
      modalOpenInfo: false,
      resultsInfo: [],
      priceInfo: [],
      stripeIdInfo: '',
      one_year_license: '',
      two_year_license: '',
      three_year_license: '',
      selectedLicenseInfo: '',
      flag: false,
      stripe_id_one_year: '',
      stripe_id_two_year: '',
      stripe_id_three_year: '',
      stripeId: '',

    };

    this.handleRec = this.handleRec.bind(this);
    this.handleRecSelect = this.handleRecSelect.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.handleSubmitLicenseForm = this.handleSubmitLicenseForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);


    cardService.showExistingCardData(this.state.user_id).then((res) => {
      // console.log("ExCardData", res.data.pagination.data)
      this.setState({ ExCardData: res.data.pagination.data });
    });
  }



  componentDidMount() {
    const { state } = this.props.location;
    // console.log("state", state.stripe_id_one_year);
    if (state) {
      this.setState({
        license: {
          ...this.state.license,
          caret_title: state.keyword,
          subscription_plan: state.plan,
          subscription_amount: state.price,
          subscription_stripe_id: state.stripe_id_one_year
        },
        oneYear: state.price,
        twoYear: state.two_year_license,
        threeYear: state.three_year_license,
        selectedLicense: state.price,
        networth: state.networth,
        google_search_volume: state.googleSearch,
        instagram: state.instagram,
        twitter: state.twitter,
        stripe_id_one_year: state.stripe_id_one_year,
        stripe_id_two_year: state.stripe_id_two_year,
        stripe_id_three_year: state.stripe_id_three_year,
      });
      if (toLower(state.plan) === 'individual') {
        this.handleSubmit(state.keyword, 'influencer', state.networth, state.google_search_volume, state.instagram, state.twitter)
      }
    }

  }



  handleSubmit(keyword, classification, networth, google_search_volume, instagram, twitter) {
    licenseService.checkCaretAvailability(keyword, classification, networth, google_search_volume, instagram, twitter).then((res) => {
      // console.log("######### API => ", res);

      this.setState({
        resultsInfo: res,
        priceInfo: Array.isArray(res.price) && res.price.length > 0 ? res.price[0] : [],

      })
    })
  }

  handlePurchaseCaret = (e) => {
    e.preventDefault();
    if (this.state.saveCard === 1) {
      this.handleSubmitForm(e);
    }
    else if (this.state.storeCaret === 2) {
      this.handleSubmitLicenseForm(e);
    }

  }


  handleSubmitLicenseForm(e) {
    e.preventDefault();
    // this.props.submitForm(this.state.license, this.state.formType);
    if (this.state.formType === 'add') {
      licenseService.storeLicense(this.state.license).then(res => {
        // console.log('After====>', res)
        // window.location.replace("/CLM/License");
        this.handleUpdateLicenseStatus(res.data.id, 4);
      });
    }


  }
  // Active Liccense

  handleUpdateLicenseStatus = (license_id, status_id) => {
    const data = {
      license_id: license_id,
      status_id: status_id
    };
    
    // console.log("data", data)
    licenseService.licenseStatus(data).then((res) => {
      // console.log(res)
      window.location.replace("/CLM/License");
    });
  }



  // Card

  handleUseExistingCard = (card_id) => {
    cardService.storeExistingCard(card_id).then((res) => {
      this.setState({ storeCaret: 2 });
      // console.log('After', res)
      // if (this.props.setStateOfForm3) {
      //   this.props.setStateOfForm3(true, res.data.id);
      // }
    });

  }

  userOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.getUsersCall(inputValue));
      }, 1000);
    });

  handleRec(e) {
    const { name, value, type, checked } = e.target;
    const recState = { ...this.state.card };
    let retV = "";

    if (name === "card_number") {
      retV = this.formatCreditCardNumber(value);
    } else if (name === "card_expiry") {
      retV = this.formatExpirationDate(value);
    } else if (name === "card_cvc") {
      retV = this.formatCVC(value);
    } else {
      retV = value;
    }
    // recState[name] = type === "checkbox" ? (checked ? 1 : 0) : retV;
    // this.setState({ card: recState });

    recState[name] = type === "checkbox" ? (checked ? 1 : 0) : retV;
    this.setState({ card: recState }, () => {
      // Call API only when checkbox is checked
      if (name === "set_default" && type === "checkbox" && checked === true) {
        this.setState({ storeCaret: 1, saveCard: 1 });
      }
    });
  }

  handleRecSelect = (selectedOptionType, info) => {
    // console.log("selectedOptionType", selectedOptionType);
    if (info["action"] === "select-option") {
      let recState = { ...this.state.card };
      recState[info["name"]] = selectedOptionType["value"];
      // console.log(recState);
      this.setState({ card: recState });
    }
  };

  handleSubmitForm(e) {
    e.preventDefault();
    if (this.validatorForm.allValid()) {
      this.setState({ submitted: true }, function () {
        this.submitForm(this.state.card, this.state.formType);
      });
    } else {
      this.setState({ submitted: false });
      this.validatorForm.showMessages();
      this.forceUpdate();
    }
  }

  submitForm = (formData, formType) => {
    if (formType === "add") {
      cardService.storeCard(formData).then((res) => {
        this.setState({
          card: {
            card_number: "",
            card_expiry: "",
            card_cvc: "",
          }
        }, () => {
          this.handleSubmitLicenseForm({ preventDefault: () => { } });
        });

        // window.location.replace("/Advertiser/campaign-details/"+this.state.card.campaign_id);
      });
    } else if (formType === "edit") {
      cardService.editCard(formData, this.state.card.id).then((res) => {
        // window.location.replace("/Advertiser/campaign-details/"+this.state.card.campaign_id);
      });
    }
  };
  clearNumber = (value = "") => {
    return value.replace(/\D+/g, "");
  };

  formatCreditCardNumber = (value) => {
    if (!value) {
      return value;
    }

    const issuer = Payment.fns.cardType(value);
    const clearValue = this.clearNumber(value);
    let nextValue;

    switch (issuer) {
      case "amex":
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10,
        )} ${clearValue.slice(10, 15)}`;
        break;
      case "dinersclub":
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10,
        )} ${clearValue.slice(10, 14)}`;
        break;
      default:
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          8,
        )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
        break;
    }

    return nextValue.trim();
  };

  formatCVC = (value, prevValue, allValues = {}) => {
    const clearValue = this.clearNumber(value);
    let maxLength = 4;

    if (allValues.number) {
      const issuer = Payment.fns.cardType(allValues.number);
      maxLength = issuer === "amex" ? 4 : 3;
    }

    return clearValue.slice(0, maxLength);
  };

  formatExpirationDate = (value) => {
    const clearValue = this.clearNumber(value);

    if (clearValue.length >= 3) {
      return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }

    return clearValue;
  };


  handleInfoModalOpen = () => {
    this.setState({ modalOpenInfo: true });
  }
  handleInfoModalClose = () => {
    this.setState({ modalOpenInfo: false });
  };

  handleLicenseInfoChange = (e) => {
    const selectedLicenseInfo = e.target.value; // price
    const stripeId = e.target.getAttribute('stripeId');

    // console.log("handleLicenseChange", selectedLicenseInfo, stripeId);
    this.setState({
      selectedLicenseInfo: selectedLicenseInfo,
      stripeIdInfo: stripeId
    });
  };

  handleUpgradeClick = () => {
    const { stripeIdInfo, selectedLicenseInfo } = this.state;

    this.setState(prevState => ({
      license: {
        ...prevState.license,
        subscription_plan: this.state.resultsInfo.plan_type,
        subscription_amount: selectedLicenseInfo,
        subscription_stripe_id: stripeIdInfo
      },
      flag: true
    }));
    this.handleInfoModalClose();
  }

  handleLicenseChange = (e) => {
    const selectedLicense = e.target.value; // price
    const stripeId = e.target.getAttribute('stripeId');

    // console.log("handleLicenseChange", selectedLicense, stripeId);
    this.setState({
      selectedLicense: selectedLicense,
      stripeId: stripeId
    });
  };
  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  }
  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  handleUpgradeDuration = () => {
    this.setState(prevState => ({
      license: {
        ...prevState.license,
        subscription_stripe_id: this.state.stripeId,
        subscription_amount: this.state.selectedLicense

      }
    }));
    this.handleModalClose();
  };

  render() {
    const { card, formType, CardForm, ExCardData, resultsInfo, priceInfo } = this.state;
    const filterConfig = {
      ignoreCase: true,
      matchFrom: "start",
    };
    return (
      <Fragment>
        {/* <Header /> */}
        <main className='content pl-2-5F pt-2'>
          <h3 className='secHeading'>Your ^Carets Summary</h3>
          <p className='mb-1'>Depending on the target market will drive the level of ^Caret purchase automation.  For larger customers we may not automate because we will sell a more complex product and a large number of ^Carets.</p>
          <Row>
            <Col xs={12} md={8}>
              <Row>
                <Col md={12}>
                  <h3 className="secHeading mt-0 mb-xl">Payment Methods</h3>
                </Col>
                <Col md={6}>
                  <div className="mt-md">
                    <h5 className="">Card Details</h5>
                    <form>
                      {formType === "edit" && (
                        <input
                          type="hidden"
                          name="card_id"
                          className="form-control"
                          value={card.id}
                        />
                      )}
                      <div className="form-group">
                        <label htmlFor="card_number" className="text_14">Card Number</label>
                        <input
                          type="text"
                          name="card_number"
                          className="form-control"
                          pattern="[0-9\s]{13,19}"
                          value={card.card_number}
                          onChange={this.handleRec}
                        />
                        {this.validatorForm.message(
                          "card_number",
                          card.card_number,
                          "required|card_num",
                        )}
                      </div>
                      <Row>
                        <Col xs={6} md={8}>
                          <div className="form-group">
                            <label htmlFor="card_expiry" className="text_14">Expiry</label>
                            <input
                              type="text"
                              name="card_expiry"
                              className="form-control"
                              placeholder="MM/YY"
                              pattern="\d\d/\d\d"
                              value={card.card_expiry}
                              onChange={this.handleRec}
                            />
                            {this.validatorForm.message(
                              "card_expiry",
                              card.card_expiry,
                              "required|card_exp",
                            )}
                          </div>
                        </Col>
                        <Col xs={6} md={4}>
                          <div className="form-group">
                            <label htmlFor="card_cvc" className="text_14">CVC</label>
                            <input
                              type="text"
                              name="card_cvc"
                              className="form-control"
                              pattern="\d{3,4}"
                              value={card.card_cvc}
                              onChange={this.handleRec}
                            />
                            {this.validatorForm.message(
                              "card_cvc",
                              card.card_cvc,
                              "required|max:3",
                            )}
                          </div>
                        </Col>
                      </Row>
                      <div className="savePayment">
                        <label className="container-check">
                          <span>Save Payment Method?</span>
                          <input
                            type="checkbox"
                            name="set_default"
                            className="checkbox"
                            value={card.set_default ? 1 : 0}
                            onChange={this.handleRec}
                            checked={card.set_default ? 1 : 0}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <Row>
                      </Row>
                    </form>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mt-md">
                    <h5 className="">My Cards</h5>
                    {(!CardForm && ExCardData.length > 0) && (
                      <div className="mt-md">
                        {ExCardData.map((card, index) => (
                          <div key={index} className="paymentCards">
                            <div className="flexElem spaceBetween alignStart gap10p">
                              <img src={masterCard} alt={masterCard} height={20} />
                              <Button className="btnPurple w-autoF mb-md" onClick={() => this.handleUseExistingCard(card.id)}>Use This Card</Button>
                            </div>
                            {/* <br></br> */}
                            {/* <h5>{card.user.name}</h5> */}
                            <div className="flexElem spaceBetween alignStart gap10p">
                              <div className="">
                                {card.card_number}
                              </div>
                              <div className="">
                                <strong>Exp: </strong> &nbsp; {card.card_expiry}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={4}>
              <div className='altBG py-1 px-2 mb-20'>
                <h4 className='mt-0 font-boldF'>^Carets: {this.state.license.caret_title}</h4>
                <div className='flexElem alignCenter spaceBetween mb-1'>
                  <h5 className='m-0'>Plan:</h5>
                  <h5 className='m-0 capitalize'>{this.state.license.subscription_plan}</h5>
                </div>
                <div className='flexElem alignCenter spaceBetween mb-2'>
                  <h5 className='m-0'>Total:</h5>
                  <h5 className='text-primary m-0 font-boldF'>${this.state.license.subscription_amount}</h5>
                </div>
                <Button block color='primary' className='btn btnDark text-white' disabled={this.state.storeCaret === 0} onClick={this.handlePurchaseCaret}>
                  Purchase Caret
                </Button>
              </div>
              <dev className="flexElem flexColumn gap10p altBG mb-20 py-1 px-2">
                {!this.state.flag &&
                  <Fragment>
                    <h5 className='mb-1'>Are you interested to upgrade two/three years subscription plan?</h5>
                    <button
                      className="btn btnDark text-white"
                      onClick={() => {
                        this.handleModalOpen();
                      }}>Choose Plan
                    </button>
                  </Fragment>
                }
              </dev>
              <dev className="flexElem flexColumn gap10p altBG mb-20 py-1 px-2">
                {resultsInfo && (resultsInfo.plan_type === "influencer" && resultsInfo.status === "available" && priceInfo.stripe_id_one_year && priceInfo.stripe_id_two_year && priceInfo.stripe_id_three_year) &&
                  <Fragment>
                    <h5 className='mb-1'>Are you interested to upgrade your subscription plan?</h5>
                    <button
                      className="btn btnDark text-white"
                      onClick={() => {
                        this.handleInfoModalOpen();
                      }}>Upgrade to influencer
                    </button>
                  </Fragment>
                }
              </dev>
            </Col>
          </Row>

          {/* ============== yearly License Modal ========= */}
          <Modal show={this.state.modalOpen} onHide={this.handleModalClose} className="modal800" size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Choose License</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col md={6}>
                  <label className="container-check">
                    <span>One Year License - ${this.state.oneYear}</span>
                    <input
                      type="radio"
                      name="licenseDuration"
                      value={this.state.oneYear}
                      stripeId={this.state.stripe_id_one_year}
                      onChange={this.handleLicenseChange}
                      checked={this.state.selectedLicense === this.state.oneYear}
                    />
                    <span className="checkmark"></span>
                  </label>
                </Col>
                <Col md={6}>
                  <label className="container-check">
                    <span>Two Year License - ${this.state.twoYear}</span>
                    <input
                      type="radio"
                      name="licenseDuration"
                      value={this.state.twoYear}
                      stripeId={this.state.stripe_id_two_year}
                      onChange={this.handleLicenseChange}
                      checked={this.state.selectedLicense === this.state.twoYear}
                    />
                    <span className="checkmark"></span>
                  </label>
                </Col>
                <Col md={6}>
                  <label className="container-check">
                    <span>Three Year License - ${this.state.threeYear}</span>
                    <input
                      type="radio"
                      name="licenseDuration"
                      value={this.state.threeYear}
                      stripeId={this.state.stripe_id_three_year}
                      onChange={this.handleLicenseChange}
                      checked={this.state.selectedLicense === this.state.threeYear}
                    />
                    <span className="checkmark"></span>
                  </label>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={this.handleUpgradeDuration} className='btnDark px-5 font-boldF text-16 btn-block'>
                Save
              </Button>
            </Modal.Footer>
          </Modal>

          {/* ========== influencer Modal ========== */}
          <Modal show={this.state.modalOpenInfo} onHide={this.handleInfoModalClose} className="modal800" size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Upgrade Duration</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col md={6}>
                  <label className="container-check">
                    <span>One Year License - ${priceInfo.one_year_license}</span>
                    <input
                      type="radio"
                      name="licenseDuration"
                      value={priceInfo.one_year_license}
                      stripeId={priceInfo.stripe_id_one_year}
                      onChange={this.handleLicenseInfoChange}
                      checked={this.state.selectedLicenseInfo === priceInfo.one_year_license}
                    />
                    <span className="checkmark"></span>
                  </label>
                </Col>
                <Col md={6}>
                  <label className="container-check">
                    <span>Two Year License - ${priceInfo.two_year_license}</span>
                    <input
                      type="radio"
                      name="licenseDuration"
                      value={priceInfo.two_year_license}
                      stripeId={priceInfo.stripe_id_two_year}
                      onChange={this.handleLicenseInfoChange}
                      checked={this.state.selectedLicenseInfo === priceInfo.two_year_license}
                    />
                    <span className="checkmark"></span>
                  </label>
                </Col>
                <Col md={6}>
                  <label className="container-check">
                    <span>Three Year License - ${priceInfo.three_year_license}</span>
                    <input
                      type="radio"
                      name="licenseDuration"
                      value={priceInfo.three_year_license}
                      stripeId={priceInfo.stripe_id_three_year}
                      onChange={this.handleLicenseInfoChange}
                      checked={this.state.selectedLicenseInfo === priceInfo.three_year_license}
                    />
                    <span className="checkmark"></span>
                  </label>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={this.handleUpgradeClick} className='btnDark px-5 font-boldF text-16 btn-block'>
                Save
              </Button>
            </Modal.Footer>
          </Modal>

        </main>
        {/* <Footer /> */}
      </Fragment >
    );
  }
}

export default LicenseCart;

import { adminConstants } from "./../constant/admin-constant";
import { moduleService } from "./../../services/module";
import { alertActions } from "./alert-actions";

export const moduleActions = {
    getAllModules,
    storeModule
}

function getAllModules(id) {
    return dispatch => {
        moduleService.getAllModules(id).then(response => {
            dispatch(request(response))
        });
    }
    function request(response) {
        return { type: adminConstants.GET_ALL_MODULES, payload: response }
    }
}

function storeModule(module) {
    return dispatch => {
        dispatch(request());
        return moduleService.storeModule(module).then(
            response => {
                dispatch(success());
                dispatch(alertActions.success(response.message))
                return response
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
                return Promise.reject(error)
            }
        )
    };

}
function request() { return { type: adminConstants.STORE_MODULE_REQUEST } };
function success(response) { return { type: adminConstants.STORE_MODULE_SUCCESS, payload: response } };
function failure(error) { return { type: adminConstants.STORE_MODULE_FAILURE, payload: error } };

// function permissionChange(Pindex, Mindex) {

//     return (dispatch, state) => {
//         state = { ...state().moduleReducer };
//         const modules = [...state.modules];
//         const permissions = [...state.permissions];
//         const permission = modules[Mindex]['permissions'][Pindex];

//         permission['checked'] = !permission['checked'];
//         const checked = permission['checked'];

//         if (checked) {
//             permissions.push(permission);
//         } else {
//             const index = permissions.findIndex(function (element) {
//                 return element.id === permission.id;
//             });
//             permissions.splice(index, 1);
//         }
//         dispatch(request(modules, permissions));
        
//     }
//     function request(modules, permissions) { return { type: adminConstants.CHANGE_MODULE_PERMISSIONS, modules: modules, permissions: permissions } }
// }
import React, { Component } from "react";
import { Grid } from "react-bootstrap";
// import {basePathApi} from 'helper/basePathApi';
import { basePathApi } from 'helper/basePathApi';

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <Grid fluid>
          <p className="copyright pull-right">
            &copy; {new Date().getFullYear()}{" "}
            <a href="https://carets.tv/" target="_blank">Carets Corporation</a>
          </p>
        </Grid>
      </footer>
    );
  }
}

export default Footer;

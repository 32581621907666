import React, { Component, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import MessageCom from "layouts/Dashboard/Message";
import { Step, Stepper, StepLabel as span, StepContent as div, FormStep, FormStepper } from 'react-form-stepper';
import { frontEndUserActions } from 'redux/actions/front-end-action';
import caretFooterLogo from '../../assets/img/logo_white.png'
import appBackgourndImg from "assets/img/logo_frontEnd.png";
import user from '../../assets/img/user.png'
import { Button, Form, FormGroup, Label, Input, InputGroup, InputGroupText, FormText, Row, Col } from 'reactstrap';
class IndustryReg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      formData: {
        name: '',
        email: '',
        password: '',
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { email, password } = this.state;
    if (email && password) {
      this.props.loginCall(email, password);
    }
  }
  // const[activeStep, setActiveStep] = useState(0);
  // handleNext = () => {
  //   this.setState(prevState => ({
  //     step: prevState.step + 1,
  //   }));
  // };

  // handlePrev = () => {
  //   this.setState(prevState => ({
  //     step: prevState.step - 1,
  //   }));
  // };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };
  render() {
    const { email, password, submitted } = this.state;
    const { loggingIn } = this.props;
    const { step, formData } = this.state;
    return (
      <Fragment>
        <div className='fixLoginWrap'>
          <header>
            <div className='flexElem alignCenter p-1'>
              {/* <p className="text-center logo_container"> */}
              <img className="img-responsive logoImageLogin1" src={caretFooterLogo} alt="backGroundImage" width={72} />
              {/* </p> */}
              <div className='ml-auto'>
                <a href='/Advertiser-Login' className='text-info'> Login </a>
              </div>
            </div>
          </header>
          <div className="main_login w-100">
            <div className="container">
              <h2 className="text-center text-white">Tell us about your Industry</h2>
              <form name="form" className='industryForm w-100' onSubmit={this.handleSubmit}>
                <Row>
                  <Col md={6}>
                    <div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                      <Label className='mb-50 h6 text-white text-capitalize'>Business Name</Label>
                      <input type="text" placeholder="Business Name" className="form-control email_ctrl inputTransparentRound" name="email" />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                      <Label className='mb-50 h6 text-white text-capitalize'>Industry</Label>
                      <Input type="select" name="select" id="exampleSelect" className='inputTransparentRound py-1'>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Input>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label className='mb-50 h6 text-white text-capitalize'>Country/Region</Label>
                      <Input type="select" name="select" id="exampleSelect" className='inputTransparentRound py-1'>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className='flexElem alingnCenter gap-1 flexColumn'>
                      <Label className='mb-50 h6 text-white text-capitalize'>Currency</Label>
                      <InputGroup className='flexElem alingnCenter gap-1 inputGroup'>
                        <InputGroupText className=''>
                          $
                        </InputGroupText>
                        <Input placeholder="USD" className='inputTransparentRound mb-0' />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label className='mb-50 h6 text-white text-capitalize'>Time Zone</Label>
                      <Input type="select" name="select" id="exampleSelect" className='inputTransparentRound py-1'>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className='flexElem alingnCenter gap-1 flexColumn'>
                      <Label className='mb-50 h6 text-white text-capitalize'>Phone</Label>
                      <InputGroup className='flexElem alingnCenter gap-1 inputGroup2'>
                        <InputGroupText className=''>
                          PAK
                        </InputGroupText>
                        <InputGroupText className=''>
                          +92
                        </InputGroupText>
                        <Input placeholder="USD" className='inputTransparentRound mb-0' />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <FormGroup check inline className='text-white mb-1'>
                      <Label check className='text-white' for='policies'>
                        <Input type="checkbox" className='me-50' name='policies' />
                        I agree to <a href='/Advertiser/Policies'>App policies</a> and <a href='/Advertiser/Terms'>terms & conditions</a>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <div className='flexElem alignCenter spaceBetween'>
                  <Button color='primary' block className='roundedFull py-1'>CONTINUE</Button>
                </div>
              </form>
            </div>
          </div>
          <MessageCom />
          <div className='copyRights text-white flexElem alignCenter gap10p p-1'>
            <p className='font-weight-light small'>Copyright © 2023, Carets Corporation, All Rights Reserved.</p>
            <img src={caretFooterLogo} alt='footer logo' className='ml-auto' width={64} />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { loading, frontEndUser, loggedIn } = state.frontEndUserReducer;
  return {
    loading, frontEndUser, loggedIn
  };
}

const mapDispatchToProps = dispatch => {
  return {
    loginCall: (email, password) => { dispatch(frontEndUserActions.login(email, password)) },
    logoutCall: () => { dispatch(frontEndUserActions.logout()) }
  }
}

const Signup = connect(mapStateToProps, mapDispatchToProps)(IndustryReg);
export default IndustryReg;
import React, { createRef } from 'react';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  convertToPixelCrop,
} from 'react-image-crop';

import { MdZoomIn } from "react-icons/md";
import { MdZoomOut } from "react-icons/md";
import { MdCropRotate } from "react-icons/md";

import { canvasPreview } from './canvasPreview';
// import { useDebounceEffect } from './useDebounceEffect';

import 'react-image-crop/dist/ReactCrop.css';

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 60,

        // New crop sizes
        // unit: 'px',
        // width: 540,
        // height: 960,
       
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: '',
      crop: undefined,
      completedCrop: undefined,
      scale: 1,
      rotate: 0,
      aspect: 16/9,
      // aspect: 9/16,
      croppedImageFile: null,
      isDragging: false
    };
    this.previewCanvasRef = createRef();
    this.imgRef = createRef();
    this.hiddenAnchorRef = createRef();
    this.blobUrlRef = '';
  }

  handleRotateClick = () => {
    this.setState((prevState) => ({
      rotate: (prevState.rotate + 90) % 360, // Increment by 45 degrees and reset at 360
    }));
  };

  handleZoomIn = () => {
    this.setState((prevState) => ({
      scale: Math.min(prevState.scale + 0.1, 3), // Max scale limit of 3 for zooming in
    }));
  };

  handleZoomOut = () => {
    this.setState((prevState) => ({
      scale: Math.max(prevState.scale - 0.1, 0.5), // Min scale limit of 0.5 for zooming out
    }));
  };

  
  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      this.setState({ crop: undefined });
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ imgSrc: reader.result && reader.result.toString() || '' })
      );
      // console.log('reader', reader);
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoad = (e) => {
    if (this.state.aspect) {
      const { width, height } = e.currentTarget;
      this.setState({ crop: centerAspectCrop(width, height, this.state.aspect) });
    }
  };



  async onDownloadCropClick() {
    const { completedCrop } = this.state;
    // console.log('completedCrop', completedCrop);
    const image = this.imgRef.current;
    const previewCanvas = this.previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist');
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    );
    const ctx = offscreen.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    );

    const blob = await offscreen.convertToBlob({
      type: 'image/png',
    });

    const timestamp = new Date().getTime();
    const uniqueFileName = `cropped-image-${timestamp}.png`;


    const file = new File([blob], uniqueFileName, { type: 'image/png' });

     // Convert the blob to a base64 string
     const reader = new FileReader();
     reader.readAsDataURL(blob);
     reader.onloadend = () => {
         const base64data = reader.result;

    // Extracting file data
    const fileData = {
        name: file.name,
        lastModified: file.lastModified,
        size: file.size,
        type: file.type,
        extension: file.name.split('.').pop(), // Get the file extension
        preview: URL.createObjectURL(blob), // Generate a preview URL
        file:base64data,
    };

    // console.log('Cropped File Data:', fileData);
    if (this.props.onCrop) {
        this.props.onCrop(fileData);
      }

    // Set the file data in the state
    this.setState({ croppedImageFile: fileData, imgSrc: null });
    // console.log('croppedImageFile', fileData);

    }
  


         this.setState({ imgSrc: null });


    // if (this.blobUrlRef) {
    //   URL.revokeObjectURL(this.blobUrlRef);
    // }
    // this.blobUrlRef = URL.createObjectURL(blob);

    // if (this.hiddenAnchorRef.current) {
    //   this.hiddenAnchorRef.current.href = this.blobUrlRef;
    //   this.hiddenAnchorRef.current.click();
    // }
  }

  componentDidUpdate(_, prevState) {
    const { completedCrop, scale, rotate } = this.state;
    if (
      (completedCrop && completedCrop.width) &&
      (completedCrop && completedCrop.height) &&
      this.imgRef.current &&
      this.previewCanvasRef.current &&
      (completedCrop !== prevState.completedCrop ||
        scale !== prevState.scale ||
        rotate !== prevState.rotate)
    ) {
      canvasPreview(
        this.imgRef.current,
        this.previewCanvasRef.current,
        completedCrop,
        scale,
        rotate,
      );
    }
  }


  handleToggleAspectClick = () => {
    this.setState((prevState) => {
      const newAspect = prevState.aspect ? undefined : 16 / 9;

      if (this.imgRef.current) {
        const { width, height } = this.imgRef.current;
        const newCrop = centerAspectCrop(width, height, 16 / 9);
        return {
          aspect: newAspect,
          crop: newCrop,
          completedCrop: convertToPixelCrop(newCrop, width, height),
        };
      }

      return { aspect: newAspect };
    });
  };

  handleDragOver = (event) => {
		event.preventDefault();
		if (!this.state.isDragging) {
			this.setState({ isDragging: true });
		}
    
	};

	handleDragLeave = () => {
		this.setState({ isDragging: false });
	};

	handleDrop = (event) => {
		event.preventDefault();
		const file = event.dataTransfer.files[0];
		if (file) {
			this.onSelectFile({ target: { files: [file] } });
		}
		this.setState({ isDragging: false });
	};

  render() {
    const { isDragging, imgSrc, crop, completedCrop, scale, rotate, aspect } = this.state;
    const { imagePreviewUrl } = this.props;

    return (
      <div className="App">
        <div className="Crop-Controls">
         <div className={`img-container ${isDragging ? 'dragging' : ''}`}
         onDragOver={this.handleDragOver}
         onDragLeave={this.handleDragLeave}
         onDrop={this.handleDrop}
         >
          <div className='inputFile'>
          <label htmlFor="file" className="file-input-label">Choose Image</label>
          <input type="file" id="file" className="file-input" accept="image/png" 
           
            onChange={this.onSelectFile} 
            />
          </div>
          </div>
      
          {imgSrc &&
            <div className='flexElem alignEnd gap10p w-100'>

              <div onClick={this.handleZoomIn} disabled={!imgSrc}>
                <MdZoomIn size={30} />
                {/* Zoom In */}
              </div>
              <div onClick={this.handleZoomOut} disabled={!imgSrc}>
                {/* Zoom Out */}
                <MdZoomOut size={30} />
              </div>
           <div>
      
            <div onClick={this.handleRotateClick} disabled={!imgSrc}>
              {/* Rotate */}
              <MdCropRotate size={24} />
            </div>
          </div>
                <div className='ml-auto'>
                  <button onClick={this.handleToggleAspectClick} className='w-100 btn-sm btnBlue'>
                    Toggle aspect {aspect ? 'off' : 'on'}
                  </button>
                </div>
                </div>
              }
            </div>

        <div className='flexElem flexColumn alignStart gap10p mt-20 mb-20'>
        {!!imgSrc && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => this.setState({ crop: percentCrop })}
            onComplete={(c) => this.setState({ completedCrop: c })}
            aspect={aspect}
            // new Code
            // locked={true} // Lock crop so it can't be adjusted
          >
            <img
              ref={this.imgRef}
              alt="Crop me"
              src={imgSrc}
              style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
              onLoad={this.onImageLoad}
            />
          </ReactCrop>
        )}
        {!!completedCrop && (
          <div className='flexElem gap10p'>
            <div className='w-100'>
              <canvas
                ref={this.previewCanvasRef}
                // log={console.log('canvas', this.previewCanvasRef)}
                style={{
                  border: '1px solid black',
                  objectFit: 'contain',
                  width: completedCrop.width,
                  height: completedCrop.height,
                }}
              />
            </div>
            <div>
              { imgSrc &&
              <button className='btn-sm btnBlue' type="button" onClick={() => this.onDownloadCropClick()}>
                Crop                
              </button>
              }
              {/* <div style={{ fontSize: 12, color: '#666' }}>
                If you get a security error when downloading try opening the
                Preview in a new tab (icon near top right).
              </div> */}
              <a
                href="#hidden"
                ref={this.hiddenAnchorRef}
                download
                style={{
                  position: 'absolute',
                  top: '-200vh',
                  visibility: 'hidden',
                }}
              >
                Hidden download
              </a>
            </div>
          </div>
        )}
      </div>
      </div>

    );
  }
}

export default App;

import { SC } from '../helper/ServerCall';



export const settingsService = {
    settingsTypes,
    paginationData,
    storeSetting,
    getSetting,
    editSetting,
    deleteSetting,
    updateSettingStatus,
    getSalesAmount,
}

function paginationData(page, filter, sort, pageSize,status) {
    return SC.getCall('settings/listing?page=' + page + '&filter=' + filter + '&sort=' + sort + '&pageSize=' + pageSize);
}

function settingsTypes() {
    return SC.getCall('settings/settingsTypes');
}
function getSalesAmount() {
    return SC.getCall('v1/getSalesAmount');
}

function storeSetting(catData) {
    return SC.postCall('settings/listing', catData);
}

function getSetting(id) {

    return SC.getCall('settings/listing/' + id);
}

function editSetting(data, SettingId) {
    data['setting_id'] = SettingId;
    return SC.postCall('settings/listing', data);
}

function deleteSetting(catId) {
    return SC.deleteCall('settings/listing/' + catId);
}

function updateSettingStatus(catId, data) {
    data['setting_id'] = catId;
    return SC.postCall('settings/listing/status',data);
}


import React from 'react';
import {
  Button
} from "react-bootstrap";


import SimpleReactValidator from 'simple-react-validator';
import Select, { createFilter } from 'react-select';
import AsyncSelect from 'react-select/async';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import { campaignService } from '../../../services/campaign';

class CampaignForm extends React.Component {
	constructor(props){
		super(props);
		this.validatorForm = new SimpleReactValidator({autoForceUpdate: this, locale: 'en'});
		this.state = {
			campaign: {
				...this.props.recPro,
			},
            usersList:[],
            agesList:[],
            gendersList:[],
		    submitted: false,
		    formType: this.props.formTypePro 	|| '',
		};

		this.handleRec = this.handleRec.bind(this);
		this.handleDetails = this.handleDetails.bind(this);
        this.handleRecSelect = this.handleRecSelect.bind(this);
  		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}

    componentDidMount() {
        this.getAgesCall();
        this.getGendersCall();
    }

    getUsersCall = async (input) => {
        const res = await campaignService.getUsers(input);
        this.setState({usersList:res.data});
        return res.data;
    }
    getAgesCall = async (input) => {
        const res = await campaignService.getAges(input);
        this.setState({agesList:res.data});
        return res.data;
    }
    getGendersCall = async (input) => {
        const res = await campaignService.getGenders(input);
        this.setState({gendersList:res.data});
        return res.data;
    }

    userOptions = (inputValue) =>
        new Promise ((resolve) => {
            setTimeout(() => {
            resolve(this.getUsersCall(inputValue));
            }, 1000);
        });


	handleRec(e){
	    const { name, value, type } = e.target;
        const recState = { ...this.state.campaign };
	    recState[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
	    this.setState({campaign: recState});
	}

    handleDetails(e){
	    const { name, value, type } = e.target;
        const recState = { ...this.state.campaign };
	    recState['details'][name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
	    this.setState({campaign: recState});
	}


    handleRecSelect = (selectedOptionType, info) => {
        console.log("selectedOptionType",selectedOptionType)
        if (info['action'] === 'select-option') {
          let recState = { ...this.state.campaign };
          recState[info['name']] = selectedOptionType['value'];
          console.log(recState);
          this.setState({campaign: recState});
        }
    }

    handleDetailsSelect = (selectedOptionType, info) => {
        if (info['action'] === 'select-option') {
            let recState = { ...this.state.campaign };
            recState['details'][info['name']] = selectedOptionType['value'];
            console.log(recState);
            this.setState({ campaign: recState });
        }

        if (info['action'] === 'clear') {
            let recState = { ...this.state.campaign };
            recState['details'][info['name']] = null;
            this.setState({ campaign: recState });
        }

    }


	handleSubmitForm(e){
	    e.preventDefault();
	    if (this.validatorForm.allValid()) {
			this.setState({submitted: true}, function(){
				this.submitForm(this.state.campaign, this.state.formType);
				this.props.callFunction(this.state.campaign, this.state.formType);
			});
		} else {
			this.setState({submitted: false});
			this.validatorForm.showMessages();
			this.forceUpdate();
		}
	}

    submitForm = (formData, formType) => {
        if (formType === 'add'){
            campaignService.storeCampaign(formData).then(res => {
                //this.setState({addModalForm:false});
              });
        }else if (formType === 'edit'){
            campaignService.editCampaign(formData, this.state.campaign.id).then(res => {
                //this.setState({editModalForm:false});
            });
        }
      }


	render(){
		const { campaign,formType} = this.state;
		const { durations } = this.props;
		const filterConfig = {
            ignoreCase : true,
            matchFrom  : 'start',
          };
		return(
			<form onSubmit={this.handleSubmitForm}>
                 { formType === 'edit' &&
			        	<input type="hidden" name="ad_id" className="form-control"  value={campaign.id}/>
			    }
                 <div className="row">
                    <div className="col-md-6">
                        <div className='form-group'>
                            <label htmlFor="campaign_title">Title<span className="requiredClass">*</span></label>
                            <input type="text" name="campaign_title" className="form-control"  value={campaign.campaign_title} onChange={this.handleRec} />
                            {this.validatorForm.message('campaign_title', campaign.campaign_title, 'required|max:250')}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className='form-group'>
                            <label htmlFor="duration">Select User<span className="requiredClass">*</span></label>
                            <AsyncSelect
                            cacheOptions
                            defaultOptions
                            name='user_id'
                            loadOptions={this.userOptions}
                            value={this.state.usersList.find((option) => option.value === campaign.user_id)}
                            onChange={this.handleRecSelect} />
                        </div>
                    </div>

                </div>
                <div className='row'>

                        <div className="col-md-3">
                            <div className='form-group'>
                                <label htmlFor="duration">Select Age</label>
                                <Select
                                value={HF.returnLabelWithValue(campaign.details.ages, this.state.agesList)}
                                onChange={(event, info) => this.handleDetailsSelect(event, info)}
                                options={this.state.agesList}
                                isSearchable={true}
                                isMulti={false}
                                name='ages'
                                isClearable={true}
                                placeholder={'Select Age'}
                                filterOption={createFilter(filterConfig)}
                            />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className='form-group'>
                                <label htmlFor="duration">Select Gender</label>
                                <Select
                                value={HF.returnLabelWithValue(campaign.details.gender, this.state.gendersList)}
                                onChange={(event, info) => this.handleDetailsSelect(event, info)}
                                options={this.state.gendersList}
                                isSearchable={true}
                                isMulti={false}
                                name='gender'
                                isClearable={true}
                                placeholder={'Select Gender'}
                                filterOption={createFilter(filterConfig)}
                            />
                            </div>
                        </div>
                        <div className="col-md-6">
                        <div className='form-group'>
                            <label htmlFor="location">location</label>
                            <input type="text" name="location" className="form-control"  value={campaign.details.location} onChange={this.handleDetails} />
                            {this.validatorForm.message('location', campaign.details.location, 'required|max:250')}
                        </div>
                    </div>

                </div>

			  <div className="row">
			    <div className="col-md-12">
			      <div className='text-center'>
			        <Button  type='submit' bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default " >Save</Button>
			        <Button bsSize="large" bsStyle="info" onClick={()=>this.props.closeModel() } className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info " >Cancel</Button>
			      </div>
			    </div>
			  </div>
			</form>
		);
	}
}


export default CampaignForm;

import React, { Component } from "react";
import {
	OverlayTrigger,
	Popover,
	// Tooltip,
} from "react-bootstrap";

class openModalButton extends Component {
	render() {
		const { classButton, tooltipText, buttonName, classIcon } = this.props;
		return (
			<OverlayTrigger placement="bottom" trigger={['hover']} overlay={<Popover id="tooltip"> {tooltipText} </Popover>}>
				<button disabled={this.props.disabled} type="button" className={classButton.join(' ')} onClick={this.props.openModal}>
					{
						classIcon.length > 0 &&
						<i className={classIcon.join(' ')} />
					}
					{buttonName}
				</button>
			</OverlayTrigger>
		);
	}
}

export default openModalButton;
import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import Pagination from '../../ThemeComponents/Pagination';
import { paymentService } from '../../../services/payment';
import "react-datepicker/dist/react-datepicker.css";
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import Logo from "../../../assets/img/caretLogo.svg"
import campaign from "../../../assets/img/capaign-activation.svg"
import Eye from "../../../assets/img/eye.svg"

var cloneDeep = require('lodash.clonedeep');
var Modal = require('react-bootstrap-modal');
const moment = window.moment;

class PaymentListingPage extends Component{
  constructor(props){
  	super(props);
    const { id } = this.props.match.params;
  	this.state = {
  	  addModalForm  : false,
      editModalForm : false,
      sorted   : '',
      filtered : '',
      pageSize : 10,
      userID:id,
      // userID: user.data ? user.data.id : null,
      showModal: false,
      amount:'',
      plan:'',
      startDate:'',
      billId:'',
      adTitle:'',
      position:''
  	};


    this.getPaginationData  = this.getPaginationData.bind(this);
    this.filterSearchHandle     = this.filterSearchHandle.bind(this);
  };





  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }




getPaginationData(page, filter, sort, pageSize, status)
{
    this.setState({ pageSize: pageSize }, function () {
      paymentService.paginationData(this.state.userID, page, filter, sort, pageSize, status,this.state.search);
    });
}





  componentDidUpdate(prevProps, prevState)
  {
    if (this.state.rec_status !== prevState.rec_status) {
      this.table.state.onFetchData(this.table.state, this.table.instance)
    }

  }

  paginationCall = (data) => {
    return paymentService.paginationData(this.state.userID, data.page, data.filter, data.sort, data.pageSize,this.state.rec_status,this.state.search);
  }

  toggleModal = (value) => {
    console.log("inside functions", value)
    this.setState({amount: value.amount, plan: value.plan_interval, billId:value.transaction_id })
    this.setState({ startDate: HF.TStoDate(value.start_date) });
    this.setState({ adTitle: value ? (value.campaign && value.campaign.ad && value.campaign.ad.ad_title) || '' : '' });
    this.setState({ position: value ? (value.campaign && value.campaign.plan && value.campaign.plan.position) || '' : '' });
    
    this.setState((prevState) => ({
      showModal: !prevState.showModal
    }));
  };


  render()
  {
  	const { filtered,editRec,durations } = this.state;
    const columns = [
        {
          Header:"Payments Listing",
          headerClassName:'headerPagination',
          columns: [
            {
                Header: "Invoice",
                accessor: "latest_invoice",
                Cell: ({ value }) => (
                  value ? (
                    <span className='invoiceData'>
                      {value}
                    </span>
                  ) : null
                ),
                sortable: false
              },
              // {
              //   Header: "User",
              //   accessor: "user",
              //   Cell: ({value}) => (
              //     <div>
              //      {value.email}
              //     </div>
              //     ),
              //   className: "",
              //   sortable:false
              // },
              {
                Header: "User",
                accessor: "user",
                Cell: ({ value }) => {
                  const email = value ? value.email : "";
                  return (
                    <div>
                      {email}
                    </div>
                  );
                },
                className: "",
                sortable:false
              },
              {
                Header: "Campaign",
                accessor: "campaign",
                Cell: ({value}) => (
                    // <div>
                    //  {value?value.campaign.campaign_title:''}
                    //   {console.log("value ===> ", value?value.campaign.id:"")}
                    // </div>

                    value && value.campaign ? (
                      <Link to={'/Admin/campaign-details/' + value.campaign.id}>
                          {value.campaign.campaign_title}
                      </Link>
                    ) : null
                    ),
                    className: "",
                    sortable:false
              },
              {
                Header: "Date",
                accessor: "created_at",
                Cell: ({value}) => (
                    <div>
                     {HF.dateFormatMy(value)}
                    </div>
                    ),
                className: "",
                sortable: false
            },
            {
                Header: "Amount",
                accessor: "amount",
                className: "",
                sortable: false
            },
            {
              Header: "Transaction",
              accessor: "transaction_type",
              sortable: false
            },
            {
                Header: "Status",
                accessor: "status",
                className: "",
                sortable: false
            },
            {
              Header: "Invoice",
              accessor: "",
              Cell: ({ value }) => {
                // console.log("value", value);
                return (
                  <a href='javascript:void;' onClick={() => this.toggleModal(value)}>
                    <img src={Eye} alt='Logo' height={14} />
                  </a>
                );
              },
              className: "invoiceCell",
              sortable: false
            },
          ]
        }
    ];

  	return(
  		<div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                	<div>
                  	<Grid fluid>
                      <Row>
                          <Col md={12}>
                            <div className="secHeading">Payment Transactions</div>
                          </Col>


                          <Col md={12} className="mt-md">
                          <Pagination
                          ref={(p) => this.pagination = p}
                          showPagination={false}
                          columns={columns}
                          pageSize={20}
                          getDataCall={this.paginationCall}
                          filterView={true}
                          filterPlaceHolder={'Payments'}
                          defaultSorted={
                            [
                              {
                                id: 'id',
                                desc: true
                              }
                            ]
                          }
                          // downloadData={true}
                          // downloadFileName={'Orders'}
                          // lowerContent = {null}
                          // filterPlaceHolder = {'Orders'}
                          noDataText='No Record found'
                          getRowProps={this.getRowProps}
                        // showAllToggle={true}
                        />
                          </Col>
                      </Row>
                    </Grid>







                	</div>
                } />
            </Col>
          </Row>
        </Grid>

        {this.state.showModal && (
          <div className="paymentReceipt">
            <div className='modalHeader'>
              <span className="close" onClick={this.toggleModal}>&times;</span>
              <img src={Logo} alt='Logo' height={40} />
              <h5>Payment Receipt</h5>
            </div>
            <div className='titalArea'>
              <span>Date: {this.state.startDate}</span>
              {/* <span>Billing ID: {this.state.billId}</span> */}
              <span>Payment Method: Credit Card</span>
            </div>
            <div className="modalContent">
              <div className='text-center'><img src={campaign} alt='campaign' height={80} className='mx-auto' /></div>
              <h3 className='text-center mb-2 font-weight-bold'>Payment Details!</h3>
              <div className='payment'>
                <h5>Ad Title</h5>
                <h5>{this.state.adTitle}</h5>
              </div>
              <div className='payment'>
                <h5>Duration</h5>
                <h5>Ad Run Duration: 1 {this.state.plan}</h5>
              </div>
              <div className='payment'>
                <h5>Position</h5>
                <h5>Ad Position On Feed: {this.state.position}</h5>
              </div>
              <div className='payment'>
                <h5>Payment Amount</h5>
                <h3 className='font-weight-bold'>${this.state.amount}</h3>
              </div>
            </div>
          </div>
        )}
      </div>
  	);
  };
}
const PaymentListing = PaymentListingPage;
export default PaymentListing;

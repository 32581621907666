import React from 'react';
import {
  // Button,
  Modal,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import { helperFunctions as HF } from '../../../helper/helperFunctions';

import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
import { hasPermission } from 'helper/hasPermission';
import ModuleForm from '../Module/ModuleForm';

class PermissionForm extends React.Component {
  constructor(props) {
    super(props);
    this.validatorPermission = new SimpleReactValidator({autoForceUpdate: this, locale: 'en'});
    this.state = {
      permission: { ...this.props.permissionPro },
      submitted: false,
      formType: this.props.formTypePro || '',
      ModuleModalShow: false
    };
    this.handlePermission = this.handlePermission.bind(this);
    this.handlePermissionSubmit = this.handlePermissionSubmit.bind(this);
    this.handleChangePermissionType = this.handleChangePermissionType.bind(this);
    this.handleChangeParentLabel = this.handleChangeParentLabel.bind(this);
    this.handleChangeOrder = this.handleChangeOrder.bind(this);
    this.handleIsmenuCheckBox = this.handleIsmenuCheckBox.bind(this);
    this.handleModuleChange = this.handleModuleChange.bind(this);
    this.openModalModule = this.openModalModule.bind(this);
    this.closeModalModule = this.closeModalModule.bind(this);
  }

  handleIsmenuCheckBox(e) {
    const { value } = e.target;
    const permissionStatePer = { ...this.state.permission };
    permissionStatePer['is_menu'] = value === 'on' ? 0 : 1;
    this.setState({ permission: permissionStatePer });
  }
  handleChangePermissionType(selectedOptionType) {
    const permissionState = { ...this.state.permission };
    if (selectedOptionType.value === 'label') {
      permissionState['permission_type'] = selectedOptionType.value;
      permissionState['parent_label'] = null;
    } else {
      permissionState['permission_type'] = selectedOptionType.value;
    }
    this.setState({ permission: permissionState }, function () {
    });
  }
  handleChangeParentLabel(selectedOptionParent, name) {
    const permissionState = { ...this.state.permission };
    if (name.action === 'select-option') {
      permissionState['parent_label'] = selectedOptionParent.value;
    }
    if (name.action === 'clear') {
      permissionState['parent_label'] = '';
    }
    this.setState({ permission: permissionState }, function () {

    });
  }
  handleChangeOrder(selectedOptionOrder) {
    const permissionState = { ...this.state.permission };
    permissionState['order'] = selectedOptionOrder.value;
    this.setState({ permission: permissionState }, function () {
    });
  }
  handlePermission(e) {
    const { name, value } = e.target;
    const permissionState = { ...this.state.permission };
    permissionState[name] = value;
    this.setState({ permission: permissionState });

  }
  handlePermissionSubmit(e) {
    e.preventDefault();
    if (this.validatorPermission.allValid()) {
      this.setState({ submitted: true }, function () {
        this.props.handlePermissionSubmit(this.state.permission, this.state.formType);
      });
    } else {
      this.setState({ submitted: false });
      this.validatorPermission.showMessages();
      this.forceUpdate();
    }
  }

  /**
   * 
   * @param {selectedOption} selected 
   * Handle Module dropdown change
   */
  handleModuleChange(selected) {
    if ('select-option') {
      const permission = { ...this.state.permission };
      permission['module_id'] = selected;
      this.setState({
        permission: permission
      }, function(){
        console.log('permission state', this.state.permission)
      });
    }
  }

  /**
   * Open modal to create new module
   */
  openModalModule() {
    this.setState({
      ModuleModalShow: true
    })
  }

  /**
   * Close Modal module 
   */
  closeModalModule() {
    this.setState({
      ModuleModalShow: false
    });
  }

  render() {
    this.validatorPermission.purgeFields();
    const { permission, formType } = this.state;
    const { moduleOptions, optionsParent, optionsOrder, optionsType } = this.props;
    return (
      <div>
        <form onSubmit={this.handlePermissionSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className='form-group'>
                <label htmlFor="name">Permission Name<span className="requiredClass">*</span></label>
                <input type="text" name="name" className="form-control" value={permission.name} onChange={this.handlePermission} />
                {this.validatorPermission.message('permissionName', permission.name, 'required')}
              </div>
            </div>
            <div className="col-md-6">
              <div className='form-group'>
                <label htmlFor="name">Label<span className="requiredClass">*</span></label>
                <input type="text" name="label" className="form-control" value={permission.label} onChange={this.handlePermission} />
                {this.validatorPermission.message('label', permission.label, 'required')}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className=''>
                <label className="container-check checkbox_down"> Is Menu?
                  <input type="checkbox" name="is_menu" className="checkbox" value={permission.is_menu ? 'on' : 'off'} onChange={this.handleIsmenuCheckBox} checked={permission.is_menu} />
                  <span className="checkmark"></span>
                </label>
                {/* {this.validatorPermission.message('is_menu', permission.is_menu, 'required')} */}
              </div>
            </div>
            <div className="col-md-6">
              <div className='form-group'>
                <label htmlFor="name">Icon</label>
                <input type="text" name="icon" className="form-control" value={permission.icon} onChange={this.handlePermission} />
                { /* this.validatorPermission.message('icon', permission.icon, 'required') */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className='form-group'>
                <label>Select Module<span className="requiredClass">*</span></label>
                <Select
                  value={permission.module_id}
                  options={moduleOptions}
                  isSearchable={true}
                  onChange={this.handleModuleChange}
                  isMulti={false}
                  placeholder={'Select Module'}
                />
                {this.validatorPermission.message('module', permission.module_id, 'required')}
              </div>
            </div>
            <div className='col-md-4' >
              {hasPermission('permission-create') &&
                <OverlayTrigger trigger="focus" placement="bottom" overlay={
                  <Popover id="tooltip">Add Module</Popover>
                }>
                  <button type='button' style={{ "marginTop": '22px' }} className="backButton pt-sm no_radius pb-sm primary btn btn-block btn-sm btn-info" onClick={this.openModalModule} ><i className="fa fa-plus" />Module</button>
                </OverlayTrigger>
              }
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className='form-group'>
                <label htmlFor="name">Description?<span className="requiredClass">*</span></label>
                <textarea type="text" name="description" className="form-control" value={permission.description} onChange={this.handlePermission}></textarea>
                {this.validatorPermission.message('description', permission.description, 'required')}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className='form-group'>
                <label htmlFor="name">Select Permission Type<span className="requiredClass">*</span></label>
                <Select
                  value={HF.returnLabelWithValue(permission.permission_type, optionsType)}
                  onChange={this.handleChangePermissionType}
                  options={optionsType}
                  isSearchable={true}
                  isMulti={false}
                  placeholder={'Select Permission Type'}
                />
                {this.validatorPermission.message('permission_type', permission.permission_type, 'required')}
              </div>
            </div>
            {permission.is_menu === 1 &&
              <div className="col-md-6">
                <div className='form-group'>
                  <label htmlFor="name">Select replace/new order<span className="requiredClass">*</span></label>
                  <Select
                    value={HF.returnLabelWithValue(permission.order, optionsOrder)}
                    onChange={this.handleChangeOrder}
                    options={optionsOrder}
                    isSearchable={true}
                    isMulti={false}
                    placeholder={'Select Order'}
                  />
                  {
                    this.validatorPermission.message('order', permission.order, permission.is_menu === 1 ? 'required' : "in:'null'")
                  }
                </div>
              </div>
            }
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className='form-group'>
                <label htmlFor="name">Select Parent Label</label>
                <Select
                   value={HF.returnLabelWithValue(permission.parent_label, optionsParent)}
                  onChange={this.handleChangeParentLabel}
                  options={optionsParent}
                  isSearchable={true}
                  isMulti={false}
                  isClearable={true}
                  placeholder={'Select Permission'}
                />
              </div>
            </div>
            {permission.permission_type === 'url' &&
              <div className="col-md-6">
                <div className='form-group'>
                  <label htmlFor="path">Path<span className="requiredClass">*</span></label>
                  <input type="text" name="path" className="form-control" value={permission.path} onChange={this.handlePermission} />
                  {this.validatorPermission.message('path', permission.path, 'required')}
                </div>
              </div>
            }
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className='text-center'>
                <button type='submit' className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default" >Save</button>
                {formType === 'edit' &&
                  <button type='button' onClick={() => this.props.closeModel()} className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info" >Cancel</button>
                }
                {formType === 'add' &&
                  <button type='button' onClick={() => this.props.closeModel()} className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info" >Cancel</button>
                }
              </div>
            </div>
          </div>
        </form>
        <Modal backdrop={'static'} show={this.state.ModuleModalShow} onHide={this.closeModalModule} >
          <Modal.Header closeButton >
            <Modal.Title>Add Module</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ModuleForm closed={this.closeModalModule} />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default PermissionForm;
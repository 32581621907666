import { SC } from '../helper/ServerCall';

export const adService = {
    paginationData,
    storeAd,
    getAd,
    editAd,
    deleteAd,
    updateAdStatus,
    getUsers,
    blockRecord,
    getData,
    getAdsData,
    getAdsDataRandom
}
function paginationData(user_id, page, filter, sort, pageSize,status,search=null) {
    const data = {'page': page,'filter': filter,'sort': sort,'pageSize': pageSize,'status': status,'search':search};
    return SC.postCall('ads/data/'+user_id,data);
}
function getData(user_id) {
    return SC.postCall('ads/data/'+user_id);
}
function getAdsData(user_id, licenseId,filter) {
    // return SC.getCall('ads/getAdsData/'+user_id);
    const params = new URLSearchParams({ licenseId,filter }).toString();
    return SC.getCall(`ads/getAdsData/${user_id}?${params}`);
}
function getAdsDataRandom(data, filter) {
    const params = new URLSearchParams({ filter }).toString();
    return SC.getCall(`ads/getAdsDataRandom/${data}?${params}`);
}
function getUsers() {
    return SC.getCall('ads/getUsers');
}
function storeAd(catData) {
    return SC.postCall('ads/listing', catData);
}

function getAd(id) {

    return SC.getCall('ads/listing/' + id);
}

function editAd(data, AdId) {
    data['ad_id'] = AdId;
    return SC.postCall('ads/listing', data);
}

function deleteAd(catId) {
    return SC.deleteCall('ads/listing/' + catId);
}

function updateAdStatus(catId, data) {
    data['ad_id'] = catId;
    return SC.postCall('ads/listing/status',data);
}
function blockRecord(catId) {
    return SC.getCall('ads/block/status/'+catId);
}


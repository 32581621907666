import Dashboard from "layouts/Dashboard/Dashboard.jsx";
import Frontend from "layouts/Frontend/Frontend.jsx";
import AdminLogin from "layouts/Dashboard/AdminAuth.jsx";
import AdvertiserLogin from "layouts/Advertiser/AdvertiserAuth.jsx";
import AdvertiserSignup from "../components/Advertiser/Signup";
import ResetPassword from "../components/Advertiser/ResetPassword";
import IndustryReg from "../components/Advertiser/IndustryReg";

// import IndustryRegEdit from "../components/Advertiser/IndustryRegEdit";
import Advertiser from "layouts/Advertiser/Advertiser.jsx";
import Policies from "components/Advertiser/Policies";
import Terms from "components/Advertiser/Terms";
import ForgotPass from "components/Advertiser/ForgotPass";
// import ForgotPassEmail from "components/Advertiser/ForgotPassEmail";
import VerifyEmail from "components/Advertiser/VerifyEmail";
import Email from "components/FrontEnd/pdf/Email";
// import EditProfile from "components/Advertiser/Ads/EditProfile";
// import FormBase from "components/Advertiser/Ads/FormBase";
// ============ CLM ============
import CLMVerifyEmail from "components/CLM/VerifyEmail";
import CLMForgotPass from "components/CLM/ForgotPass";
import CLMSignup from "../components/CLM/Signup";
import CLMLogin from "layouts/CLM/CLMAuth.jsx";
import CLM from "layouts/CLM/CLM.jsx";
import Test from "components/CLM/Test";
import Search from "../components/CLM/Search";
import Cart from "../components/CLM/Cart/Cart";
import CartCheckout from "../components/CLM/Cart/Checkout";
import CartSuccess from "../components/CLM/Cart/Success";

// ===========================
var indexRoutes = [
	{
		path: "/advertiser-policies",
		name: "Policies",
		component: Policies,
		exactPro: false
	},
	{
		path: "/advertiser-terms",
		name: "Terms",
		component: Terms,
		exactPro: false
	},

	{
		path: "/Advertiser-login",
		name: "AdvertiserLogin",
		component: AdvertiserLogin,
		exactPro: false
	},
	{
		path: "/Advertiser-signup",
		name: "AdvertiserSignup",
		component: AdvertiserSignup,
		exactPro: false
	},
	{
		path: "/IndustryReg",
		name: "IndustryReg",
		component: IndustryReg,
		exactPro: false
	},
	{
		path: "/resetPassword/:tkn?",
		name: "ResetPassword",
		component: ResetPassword,
		exactPro: false
	},
	// { path: "/Advertiser/IndustryRegEdit", name: "IndustryRegEdit", component: IndustryRegEdit, exactPro: false },
	{
		path: "/Advertiser",
		name: "Advertiser",
		component: Advertiser,
		exactPro: false
	},
	// { path: "/Advertiser/EditProfile", name: "EditProfile", component: EditProfile, exactPro: false },

	{
		path: "/ForgotPassword",
		name: "ForgotPassword",
		component: ForgotPass,
		exactPro: false
	},
	// { path: "/getpassword", name: "ForgotPassEmail", component: ForgotPassEmail, exactPro: false },
	{
		path: "/verify-email",
		name: "VerifyEmail",
		component: VerifyEmail,
		exactPro: false
	},
	{
		path: "/Admin-login",
		name: "AdminLogin",
		component: AdminLogin,
		exactPro: false
	},

	{ path: "/email", name: "Email", component: Email, exactPro: false },

	// =================== CLM ==============
	{ path: "/CLM-verify-email", name: "CLMVerifyEmail", component: CLMVerifyEmail, exactPro: false },
	{ path: "/CLM-ForgotPassword", name: "CLMForgotPassword", component: CLMForgotPass, exactPro: false },
	{ path: "/CLM-signup", name: "CLMSignup", component: CLMSignup, exactPro: false },
	{ path: "/CLM-login/:keyword?", name: "CLMLogin", component: CLMLogin, exactPro: false },
	{ path: "/CLM-search", name: "Search", component: Search, exactPro: false },
	{ path: "/CLM-cart", name: "Cart", component: Cart, exactPro: false },
	{ path: "/CLM-cart-checkout", name: "Cart", component: CartCheckout, exactPro: false },
	{ path: "/CLM-cart-success", name: "Cart", component: CartSuccess, exactPro: false },

	{ path: "/CLM", name: "CLM", component: CLM, exactPro: false },
	// { path: "/formBase", name: "FormBase", component: FormBase, exactPro: false },
	{ path: "/Admin", name: "Home", component: Dashboard, exactPro: false },
	{ path: "/Test", name: "Terms", component: Test, exactPro: false },
	{ path: "/", name: "Frontend", component: Frontend, exactPro: false }
];

export default indexRoutes;

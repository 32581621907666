import React, { Component } from "react";
import {CSVLink} from "react-csv";

class reactDataToCSV extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dataToDownload: []
		}
		this.download = this.download.bind(this);
	}

	download(event) {
		const { currentRecordsRef, columnsData } = this.props;
	   	const currentRecordsData = currentRecordsRef.getResolvedState().sortedData;
	    var data_to_download = []
	    for (var index = 0; index < currentRecordsData.length; index++) {
	       let record_to_download = {}
	       var temp = currentRecordsData[index]; 
	       for(var colIndex = 0; colIndex < columnsData.length ; colIndex ++) {
	       		if (columnsData[colIndex].excExp) 
	        		record_to_download[columnsData[colIndex].Header] = temp[columnsData[colIndex].accessor]
	       }
	       data_to_download.push(record_to_download)
	    }
	    this.setState({ dataToDownload: data_to_download }, () => {
	       this.csvLink.link.click()
	    })
  	} 
	render() {
		const { fileName } = this.props;
		const { dataToDownload } = this.state;
		return (
			<React.Fragment>
				<button onClick={this.download}>
                	Download
              	</button>
              	<CSVLink
                	data={dataToDownload}
                	filename={fileName+".csv"}
                	className="hidden"
                	ref={(r) => this.csvLink = r}
                	target="_blank"
              	/>
			</React.Fragment>
		);
	}
}

export default reactDataToCSV;
import React, { Fragment } from "react";
import { Button, Grid, Row, Col } from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import SimpleReactValidator from "simple-react-validator";
import Select, { createFilter } from "react-select";
import AsyncSelect from "react-select/async";
import { helperFunctions as HF } from "../../../helper/helperFunctions";
import { cardService } from "../../../services/card";
import { splashService } from "../../../services/splash";
import { licenseService } from "../../../services/license";
import { campaignService } from "../../../services/campaign";

import NoData from '../../../assets/img/noData.png';
import Payment from "payment";
import Pagination from '../../ThemeComponents/Pagination';
// import masterCard from "../../../assets/img/mastercard.png"
import masterCard from "../../../assets/img/sampleCard.svg"

import visaCard from "../../../assets/img/visa.png"
import { videoService } from "services/video";

class MyCaretsForm5 extends React.Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem("user"));
    const licenseId = localStorage.getItem("license_id");
    //const { id } = this.props.match.params;
    this.validatorForm = new SimpleReactValidator({
      autoForceUpdate: this,
      locale: "en",
    });
    this.state = {

      // video: {
      // video_title: "",
      // video_description: "",
      // },

      // campaign_id: this.props.id,
      licenseList: [],
      usersList: [],
      video_url: [],
      xid: 0,
      endSpalshId: [],
      splashList: [],
      splash_title: [],
      submitted: false,
      formType: "add",
      campaignDetails: [],
      DataAds: [],
      ExCardData: [],
      totalAmount: 0,
      CardForm: false,
      user_id: user.data ? user.data.id : null,
      CaretId: [],
      license_id: licenseId,
      searchSplash: '',
      tab: {
        form1: true,
        form2: false,
        form3: false,

      }

    };
    this.handleRec = this.handleRec.bind(this);

  }


  // #################


  handleAddCardClick = () => {
    this.setState({ CardForm: true });

  };


  // #################



  handleRec(e) {
    const { name, value, type } = e.target;
    const recState = { ...this.state.video };
    recState[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
    this.setState({ video: recState });
  }


  getUsersCall = async (input) => {
    const res = await campaignService.getUsers(input);
    this.setState({ usersList: res.data });
    if (this.state.card.user_id) {
      this.getAdsCall(this.state.card.user_id);
    }
    return res.data;
  };

  userOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.getUsersCall(inputValue));
      }, 1000);
    });

  componentDidMount() {
    this.getSplashCall(this.state.user_id, this.state.license_id, this.state.searchSplash);
    this.getLicenseCall(this.state.user_id);
  }

  getLicenseCall = (val) => {
    licenseService.getLicenseData(val).then(res => {
      // console.log('licenseList:', res.data)
      this.setState({ licenseList: res.data });
    });
  }

  getSplashCall = (val, val2, searchSplash) => {
    splashService.getSplashes(val, val2, searchSplash).then(res => {
      // console.log('splashList:', res.data)
      this.setState({ splashList: res.data });
    });
  }
  handleSearchSplashChange = (e) => {
    const searchSplash = e.target.value;
    this.setState({ searchSplash });
    this.getSplashCall(this.state.user_id, this.state.license_id, searchSplash);
  };

  handleSplashSelect = (selectedOptionType) => {

    // console.log("selectedOptionType", selectedOptionType)

    const filtered = this.state.splashList.filter(obj => {
      return obj.value === selectedOptionType['value'];
    });

    if (filtered) {
      let recState = { ...this.state.endSpalshId };
      recState[this.state.xid] = filtered[0].value;
      // console.log("this.state.video_url ########## ", recState);
      this.setState({ endSpalshId: recState, });
    }
  };



  handleNext = () => {
    this.setState({ tab: { form1: false, form2: true } })
  }

  handleNextToDetails = () => {
    this.setState({ tab: { form1: false, form2: false, form3: true } })
  }


  handleNextToSaveCaret = (e) => {
    e.preventDefault();
    // if (this.validatorForm.allValid() && (this.state.error === '' || this.state.error == null)) {

    const { video_title, video_description } = this.props.video;
    const data = {
      splash_start_id: this.props.start_Splash_Id,
      ad_id: this.props.ad_id,
      video_ids: this.props.video_id,
      splash_end_id: this.state.endSpalshId[this.state.xid],
      license_id: this.props.license_id,
      user_id: this.state.user_id,
      video_title: video_title,
      video_description: video_description,
      sound_id: this.props.sound_id,

    }
    splashService.createCustumCaret(data).then(res => {
      // console.log("res", res);
      localStorage.removeItem('planType');
      localStorage.removeItem('license_id');
      setTimeout(() => {
        window.location.replace('/CLM/myCaretsVideos');
      }, 1000)
      // window.location.replace('/CLM/myCaretsVideos');
    })
    // }else{
    //   // this.setState({ submitted: false });
    //   this.validatorForm.showMessages();
    //   this.forceUpdate();
    // }
  }

  render() {
    const { adList, formType, video, licenseList } = this.state;
    return (
      <div className="">
        <div>
          <div
            className="flexElem gap-2 items-center"
            style={{ marginBottom: "20px", marginTop: "20px" }}          >
            <h3 className="secHeading m-0">Select End Splash</h3>
            <div className="ml-auto w-25">
            <input
              type="text"
              placeholder="Search splash..."
              value={this.state.searchSplash}
              onChange={this.handleSearchSplashChange}
              className="form-control"
            />
          </div>
          </div>
          
          <div className="innerScrollWrap">
            <div className="gridSplash">
              {this.state.splashList &&
                this.state.splashList.map((splash, index) => (
                  <div>
                    <div className="splashWrap">
                      {/* <video src={splash.video_url} className="adImage" /> */}
                      <img src={splash.image_url} className="adImage" />
                      <div
                        className="adContent flexElem flexColumn mt-auto gap10p"
                        style={{ wordBreak: "break-word" }}
                      >
                        <div style={{ marginTop: "au to" }}>
                          <h6
                            className="text-primary m-0"
                            style={{ textTransform: "capitalize", fontSize: 11 }}
                          >
                            Splash Title:
                          </h6>
                          <h5 className="m-0">{splash.label}</h5>
                        </div>
                        <div></div>
                      </div>
                    </div>
                    <button
                      className={`mt-1 selectBtn ${this.state.endSpalshId[this.state.xid] === splash.value
                        ? "bcCelectBtn"
                        : "selectBtn"
                        }`}
                      onClick={() => this.handleSplashSelect(splash)}
                    >
                      Select
                    </button>
                  </div>
                ))}
            </div>
          </div>
          <div className="flexElem gap-2 pl-btn-fix" style={{ gap: 10 }}>
            <Button
              type="submit"
              className="btnDark px-5 font-boldF text-16 w-autoF"
              // disabled={this.state.endSpalshId.length === 0}
              onClick={(e) => this.handleNextToSaveCaret(e)}
            >
              Next
            </Button>
          </div>
        </div>

      
      </div>
    );

  }
}

export default MyCaretsForm5;

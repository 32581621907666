export const frontEndUserConstants = {
  LOGIN_FRONT_END_USER_REQUEST: "LOGIN_FRONT_END_USER_REQUEST",
  LOGIN_FRONT_END_USER_SUCCESS: "LOGIN_FRONT_END_USER_SUCCESS",
  LOGIN_FRONT_END_USER_FAILURE: "LOGIN_FRONT_END_USER_FAILURE",

  LOGOUT_FRONT_END_USER: "LOGOUT_FRONT_END_USER",

  GET_ADJUDICATORS_REQUEST: "GET_ADJUDICATORS_REQUEST",
  GET_ADJUDICATORS_SUCCESS: "GET_ADJUDICATORS_SUCCESS",
  GET_ADJUDICATORS_FAILURE: "GET_ADJUDICATORS_FAILURE",

  GET_ADJUDICATORS_RECORDINGS_REQUEST: "GET_ADJUDICATORS_RECORDINGS_REQUEST",
  GET_ADJUDICATORS_RECORDINGS_SUCCESS: "GET_ADJUDICATORS_RECORDINGS_SUCCESS",
  GET_ADJUDICATORS_RECORDINGS_FAILURE: "GET_ADJUDICATORS_RECORDINGS_FAILURE",

  FORGET_FRONT_END_USER_REQUEST: "FORGET_FRONT_END_USER_REQUEST",
  FORGET_FRONT_END_USER_SUCCESS: "FORGET_FRONT_END_USER_SUCCESS",
  FORGET_FRONT_END_USER_FAILURE: "FORGET_FRONT_END_USER_FAILURE",  
};

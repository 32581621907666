import { SC } from '../helper/ServerCall';

export const campaignService = {
    paginationData,
    paginationDataAds,
    paginationUserAds,
    storeCampaign,
    getCampaign,
    editCampaign,
    deleteCampaign,
    updateCampaignStatus,
    getDuration,
    blockRecord,
    getUsers,
    getAds,
    getPlans,
    getGenders,
    getAges,
    getCountries,
    getStatus,
    storeCampaignAds,
    updateCampaignMultiAds,
    getCampaignAds,
    editCampaignAds,
    deleteCampaignAds,
    storeCampaignMultiAds,
    getUser,
    getCampaignsSummary,
    getDataAds,
    updateAdsStatus,
    renewalAds
}
function paginationData(user_id, page, filter, sort, pageSize,status,search=null) {
    //const data = {'page': page,'filter': filter,'sort': sort,'pageSize': pageSize,'status': status,'search':search};
    return SC.getCall('campaigns/data/'+user_id+'?page=' + page + '&filter=' + filter + '&sort=' + sort + '&pageSize=' + pageSize);
}


function getDuration() {
    return SC.getCall('campaigns/durations');
}
function storeCampaign(catData) {
    return SC.postCall('campaigns/listing', catData);
}

function getCampaignsSummary(id) {

    return SC.getCall('campaigns/getCampaignsSummary/' + id);
}

function getCampaign(id) {

    return SC.getCall('campaigns/listing/' + id);
}

function editCampaign(data, campaignId) {
    data['campaign_id'] = campaignId;
    return SC.postCall('campaigns/listing', data);
}



function deleteCampaign(catId) {
    return SC.deleteCall('campaigns/listing/' + catId);
}



function updateCampaignStatus(catId, data) {
    data['campaign_id'] = catId;
    return SC.postCall('campaigns/listing/status',data);
}
function blockRecord(catId) {
    return SC.getCall('campaigns/block/status/'+catId);
}

function getUsers(filter) {
    const data = {'filter': filter};
    return SC.postCall('campaigns/getUsers',data);
}

function getPlans(filter) {
    const data = {'filter': filter};
    return SC.getCall('campaigns/getPlans',data);
}

function getStatus(filter) {
    const data = {'filter': filter};
    return SC.getCall('campaigns/getStatus',data);
}

function getAds(user_id) {
    const data = {'filter': null,'user_id': user_id};
    return SC.getCall('campaigns/getAds',data);
}
function getGenders(filter) {
    const data = {'filter': null};
    return SC.getCall('campaigns/getGenders',data);
}
function getAges(filter) {
    const data = {'filter': null};
    return SC.getCall('campaigns/getAges',data);
}
function getCountries(filter) {
    const data = {'filter': null};
    return SC.getCall('campaigns/getCountries',data);
}

/* ################## */
function paginationUserAds(user_id, page, filter, sort, pageSize,status,search=null) {
    const data = {'page': page,'filter': filter,'sort': sort,'pageSize': pageSize,'status': status,'search':search};
    return SC.getCall('campaigns/userads/'+user_id+'?page=' + page + '&filter=' + filter + '&sort=' + sort + '&pageSize=' + pageSize);
}

function paginationDataAds(campaign_id, page, filter, sort, pageSize,status,search=null) {
    const data = {'page': page,'filter': filter,'sort': sort,'pageSize': pageSize,'status': status,'search':search};
    return SC.getCall('campaigns/ads/'+campaign_id+'?page=' + page + '&filter=' + filter + '&sort=' + sort + '&pageSize=' + pageSize);
}

function storeCampaignAds(catData) {
    return SC.postCall('campaigns/ads/storeAds', catData);
}

function storeCampaignMultiAds(catData) {
    return SC.postCall('campaigns/ads/storeMultiAds', catData);
}
function updateCampaignMultiAds(catData) {
    return SC.postCall('campaigns/ads/updateMultiAds', catData);
}
function editCampaignAds(data, campaignId) {
    data['id'] = campaignId;
    return SC.postCall('campaigns/ads/storeAds', data);
}

function deleteCampaignAds(id) {
    return SC.getCall('campaigns/ads/destroyAds/' + id);
}

function getCampaignAds(id) {

    return SC.getCall('campaigns/ads/showAds/' + id);
}

function getUser(userId) {

    return SC.getCall('campaigns/user/' + userId);
}

// ===============================
function getDataAds(campaign_id) {
    return SC.getCall('campaigns/ads/'+ campaign_id);
}
function updateAdsStatus(data) {
    return SC.postCall('campaigns/ads/updateAdsStatus', data);
}

function renewalAds(data) {
    return SC.postCall('campaigns/ads/toggleAdsCancel ', data);
}
// ===============================
import React, { Component } from 'react';
import { advertiserUserActions } from 'redux/actions/advertiser-user-action';
import TargetMarket from 'components/Advertiser/TargetMarket';
import StrategicAdPlacement from './../Advertiser/StrategicAdPlacement';
import DelBanner from "../../assets/img/landing/del-banner.svg"
import del1 from "../../assets/img/landing/del-1.png"
import del2 from "../../assets/img/landing/del-2.png"
import del3 from "../../assets/img/landing/del-3.png"
import del4 from "../../assets/img/landing/del-4.png"
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover,
  Button
} from "react-bootstrap";
import { connect } from 'react-redux';
class DeleteAccount extends Component {
  constructor(props) {
    super(props);
    var checkLogin = localStorage.getItem('user');
    if (checkLogin) {
      localStorage.removeItem('user');
    }
    this.state = {
      email: '',
      password: '',
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { email, password } = this.state;
    const { dispatch } = this.props;
    if (email && password) {
      dispatch(advertiserUserActions.login(email, password));
    }
  }
  render() {
    const { email, password, submitted } = this.state;
    const { loggingIn } = this.props;
    return (
      <React.Fragment>
        <section className='deleteSection'>
          <div className="content container">
            <Row className='heroSection'>
              <Col md={6}>
                <h1 className='text-white text-48 font-weight-bold'>Delete Your<br />Account?</h1>
              </Col>
              <Col md={6}>
                <img src={DelBanner} alt='DelBanner' />
              </Col>
            </Row>
          </div>
        </section>
        <section className='container mx-auto' style={{ marginTop: '4.5rem', marginBottom: '4.5rem' }}>
          <h1 className='text-36 font-weight-bold text-center mb-2'>Accounts Delete Instructions</h1>
          <Row className='heroSection flexElem alignStart'>
            <Col sm={12} md={6} lg={3} className='mb-1 px-1 mt-20'>
              <img src={del1} alt='del1' className='flexElem mb-1 mx-mdAuto' />
              <p className='text_18 mt-20 px-md-1'>Login into the app</p>
            </Col>
            <Col sm={12} md={6} lg={3} className='mb-1 px-1 mt-20'>
              <img src={del2} alt='del2' className='flexElem mb-1 mx-mdAuto' />
              <p className='text_18 mt-20 px-md-1'>After successfully authorizing, you will be able to see your profile icon. You need to click on the icon and go to the setting page.</p>
            </Col>
            <Col sm={12} md={6} lg={3} className='mb-1 px-1 mt-20'>
              <img src={del3} alt='del3' className='flexElem mb-1 mx-mdAuto' />
              <p className='text_18 mt-20 px-md-1'>From the Profile Setting Screen, You Will See The Delete Icon At The Bottom. Click On The Icon To Open Your Delete Profile Page.</p>
            </Col>
            <Col sm={12} md={6} lg={3} className='mb-1 px-1 mt-20'>
              <img src={del4} alt='del4' className='flexElem mb-1 mx-mdAuto' />
              <p className='text_18 mt-20 px-md-1'>Click on Delete Button and you will see a confirmation dialog on the screen. Press YES to delete the account.</p>
            </Col>
          </Row>
        </section>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.advertiserReducer;
  return {
    loggingIn
  };
}

const AccountDel = connect(mapStateToProps)(DeleteAccount);
export default AccountDel;

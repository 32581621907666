import React, { Component } from "react";
// import { adminLabels } from '../../redux/constant/admin-label-constant';

class confirmAlert extends Component {
	render(){
		return(
			<div className='custom-ui'>
	            <div className="react-confirm-alert-body text-center">
	              	<div><i className="pe-7s-img" style={{"fontSize":"40px", "color":"red"}}></i></div>
	              	<h3 style={{"color":"#4f7bea", "fontSize":"20px"}}>{ this.props.header }</h3>
	              	<p className="marginFromTopPopUp" >{ this.props.description } </p>
	              	{
	              		this.props.extraHtml()
	              	}
	              	<div className="react-confirm-alert-button-group alignFlexButtons">
	                	<button onClick={()=>{
	                		if(this.props.onNo)
	                			this.props.onNo();
	                		this.props.onClosePro();
	                	}} className="btn btn-danger"><i className="pe-7s-close-circle"></i> No</button>
	                	<button className="btn btn-success" onClick={() => {
	                    	this.props.onYes()
	                    	this.props.onClosePro()
	                	}}>
	                	<i className="pe-7s-check"></i> Yes</button>
	              </div>
	            </div>
	        </div>
		);
	};
}



export default confirmAlert;

import React, { Component, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import MessageCom from "layouts/Dashboard/Message";
import { Step, Stepper, StepLabel as span, StepContent as div, FormStep, FormStepper } from 'react-form-stepper';
import { frontEndUserActions } from 'redux/actions/front-end-action';
import caretFooterLogo from '../../../assets/img/logo_white.png'
import user from '../../../assets/img/user.png';
import { Button, Form, FormGroup, Label, Input, InputGroup, InputGroupText, FormText, Row, Col } from 'reactstrap';


import SimpleReactValidator from 'simple-react-validator';
import Select, { createFilter } from 'react-select';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import { appuserService } from '../../../services/appuser';
import { campaignService } from '../../../services/campaign';

import { objectsConstants as OC } from 'redux/constant/objects-constant';

class EditProfile extends Component {
    constructor(props){
		super(props);
		this.validatorUser = new SimpleReactValidator({autoForceUpdate: this, locale: 'en'});
		this.state = {
			user: OC.USER,
            industryList:[],
		    submitted: false,
		    formType: this.props.formTypePro 	|| '',
			allowedFormatsStringMime : 'image/jpeg, image/png',
			allowedFormatsStringExt : 'jpg, png',
		};

        this.handleUser = this.handleUser.bind(this);
		this.handleProfile = this.handleProfile.bind(this);
  		this.handleUserSubmitForm = this.handleUserSubmitForm.bind(this);
  		this.handleChangeRole = this.handleChangeRole.bind(this);
        this.handleRecSelect = this.handleRecSelect.bind(this);
        this.callEditUser = this.callEditUser.bind(this);
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    this.getIndustryCall();
    this.callEditUser(user.data.id);
}
getIndustryCall = (val) => {
    appuserService.getIndustry(val).then(res => {
        this.setState({industryList:res.data});
        });
}
callEditUser = (id) => {
    //appuserService.getUser(id);
    campaignService.getUser(id).then(res => {
        // console.log('userprofile',res.user.profile);
        this.setState({user:res.user});
        
      });
  }

handleRecSelect = (selectedOptionType, info) => {
    // console.log("selectedOptionType",selectedOptionType)
    if (info['action'] === 'select-option') {
      let recState = { ...this.state.user };
      recState['profile'][info['name']] = selectedOptionType['value'];
      // console.log(recState);
      this.setState({user: recState});
    }
}

handleChangeRole(selectedOption, name){
    if (name.action === 'select-option'){
        this.setState(prevState => ({
            user: {
                ...prevState.user,
                roles:  [...prevState.user.roles, name.option.value]
            }
        }));
    }
    if (name.action === 'remove-value'){
        const rolesTemp = this.state.user.roles;
        rolesTemp.splice(rolesTemp.indexOf(name.removedValue.value), 1);
        this.setState(prevState => ({
            user: {
                ...prevState.user,
                roles: rolesTemp
            }
        }))
    }
}
handleUser(e){
    const { name, value, type } = e.target;
    const userState = { ...this.state.user };
    userState[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
    this.setState({user: userState});
}
handleProfile(e){
    const { name, value, type } = e.target;
    const userState = { ...this.state.user };
    userState['profile'][name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
    this.setState({user: userState});
    // console.log("this.state.user",this.state.user);
}
handleUserSubmitForm(e){
    e.preventDefault();
    if (this.validatorUser.allValid()) {
        this.setState({submitted: true}, function(){
            appuserService.editClmUser(this.state.user, this.state.user.id).then(res => {
                // console.log("submitted");
                window.location.replace("/CLM/dashboard");
              });
        });
    } else {
        this.setState({submitted: false});
        this.validatorUser.showMessages();
        this.forceUpdate();
    }
}
extractImageData =  (value) =>  {
    const file = {};
    file['file'] = value['file'];
    file['name'] = value['name'];
    file['size'] = value['size'];
    file['extension'] = HF.returnExtFromImage(value['name']);
    file['preview'] = value['preview'];
    file['type'] = value['type'];
    file['lastModified'] = value['lastModified'];
    return file;
}
setResourceFile = (key, value) => {
    this.setState({
        user: {...this.state.user, image:this.extractImageData(value) },
    });
}
removeFile = (key, index) =>{
    this.setState({
        user: {...this.state.user, image:null },
    });
}

  render() {
    const { user, formType, allowedFormatsStringMime, allowedFormatsStringExt } = this.state;
    const { roles } = this.props;
    const filterConfig = {
        ignoreCase : true,
        matchFrom  : 'start',
      };
    return (
      <Fragment>
        <div className="container main_login1 w-100 py-1">
          <div className="">
            <h2 className="text-center">User Profile</h2>
            <form name="form" className='industryFormEdit w-100' onSubmit={this.handleUserSubmitForm}>
              <Row>
                <Col md={6}>
                <div className='form-group'>
                    <Label className='mb-50 h6 text-capitalize'>First Name</Label>
                    <input type="text" autoComplete="off" name="first_name" className="form-control" value={user.profile?user.profile.first_name:''}  onChange={this.handleProfile}/>
                        { this.validatorUser.message('first_name', user.profile?user.profile.first_name:'', 'required')
                        }
                  </div>
                </Col>
                <Col md={6}>
                <div className='form-group'>
                    <Label className='mb-50 h6 text-capitalize'>Last Name</Label>
                    <input type="text" autoComplete="off" name="last_name" className="form-control" value={user.profile?user.profile.last_name:''}  onChange={this.handleProfile}/>
                        { this.validatorUser.message('last_name', user.profile?user.profile.last_name:'', 'required')
                        }
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                <div className='form-group'>
                    <Label className='mb-50 h6 text-capitalize'>Position/Title</Label>
                    <input type="text" autoComplete="off" name="user_title" className="form-control" value={user.profile?user.profile.user_title:''}  onChange={this.handleProfile}/>
                        { this.validatorUser.message('user_title', user.profile?user.profile.user_title:'', 'required')
                        }
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                <div className='form-group'>
                    <Label className='mb-50 h6 text-capitalize'>Business Name</Label>
                    <input type="text" autoComplete="off" name="business_name" className="form-control" value={user.profile?user.profile.business_name:''}  onChange={this.handleProfile}/>
                        { this.validatorUser.message('business_name', user.profile?user.profile.business_name:'', 'required')
                        }
                  </div>
                </Col>
                <Col md={6}>
                <div className='form-group'>
                    <Label className='mb-50 h6 text-capitalize'>Industry</Label>
                        <Select
                            value={HF.returnLabelWithValue(user.profile?user.profile.industry_id:'', this.state.industryList)}
                            onChange={(event, info) => this.handleRecSelect(event, info)}
                            options={this.state.industryList}
                            isSearchable={true}
                            isMulti={false}
                            name='industry_id'
                            isClearable={false}
                            placeholder={'Select Industry'}
                            filterOption={createFilter(filterConfig)}
                        />
                  </div>
                </Col>
              </Row>
              {/* <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label className='mb-50 h6 text-capitalize'>Country/Region</Label>
                    <Input type="select" name="select" id="exampleSelect" className='inputTransparentRound py-1'>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className='flexElem alingnCenter gap-1 flexColumn'>
                    <Label className='mb-50 h6 text-capitalize'>Currency</Label>
                    <InputGroup className='flexElem alingnCenter gap-1 inputGroup'>
                      <InputGroupText className=''>
                        $
                      </InputGroupText>
                      <Input placeholder="USD" className='inputTransparentRound mb-0' />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row> */}
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label className='mb-50 h6 text-capitalize'>Email Address</Label>
                    <input type="text" name="email" className="form-control email_icon" value={user.email} onChange={this.handleUser} readOnly='true' />
						  {this.validatorUser.message('email', user.email, 'required|email')}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className='flexElem alingnCenter gap-1 flexColumn mb-1'>
                    <Label className='mb-50 h6 text-capitalize'>Phone<span className="requiredClass">*</span></Label>
                    <input type="text" autoComplete="off" name="phone_number" className="form-control" value={user.profile.phone_number}  onChange={this.handleProfile}/>
                {
                  this.validatorUser.message('phone_number', user.profile.phone_number, 'required|min:5')
                }
                  </FormGroup>
                </Col>
              </Row>
              {/* <Row>
                <Col sm={12}>
                  <FormGroup check inline className='mb-1'>
                    <Label check className='' for='policies'>
                      <Input type="checkbox" className='me-50' name='policies' />
                      I agree to <a href='/Advertiser/Policies'>App policies</a> and <a href='/Advertiser/Terms'>terms & conditions</a>
                    </Label>
                  </FormGroup>
                </Col>
              </Row> */}
              <div className='flexElem alignCenter spaceBetween'>
                <Button type='submit' color='primary' block className='roundedFull py-1'>CONTINUE</Button>
              </div>
            </form>
          </div>
        </div>
        <MessageCom />
      </Fragment>
    );
  }
}
const mapStateToProps = state => {
  const { loading, frontEndUser, loggedIn } = state.frontEndUserReducer;
  return {
    loading, frontEndUser, loggedIn
  };
}

const mapDispatchToProps = dispatch => {
  return {
    loginCall: (email, password) => { dispatch(frontEndUserActions.login(email, password)) },
    logoutCall: () => { dispatch(frontEndUserActions.logout()) }
  }
}
// const Signup = connect(mapStateToProps, mapDispatchToProps)(IndustryRegEdit);
export default EditProfile;

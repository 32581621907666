import React from 'react';
import {
  Button
} from "react-bootstrap";


import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import FileUpload from "components/Upload/FileUpload";
import ImagePreview from "components/Upload/ImagePreview";
import fileUploadIcon from "../../../assets/img/upload.svg";


class MusicFormForm extends React.Component {
	constructor(props){
		super(props);
		this.validatorForm = new SimpleReactValidator({autoForceUpdate: this, locale: 'en'});
		this.state = {
			audio: {
				...this.props.recPro,
			},
			error:'',
			errorImg:'',
		    submitted: false,
		    formType: this.props.formTypePro 	|| '',
			allowedFormatsStringMime : 'image/jpeg, image/png',
			allowedFormatsStringExt : 'jpg, png',
			allowedFormatsAudioMime: 'audio/mpeg, audio/wav, audio/flac',
			allowedFormatsAudioExt: 'mp3, wav, flac',
			///////

			imagePreviewUrl:this.props.recPro ? this.props.recPro.image_url : null,
			audioPreviewUrl: this.props.recPro ? this.props.recPro.sound_url : null,
			audioAdded: false, 
			audioFileName: null,
			isDragging: false,
			isDraggingAudio: false
		};

		this.handleRec = this.handleRec.bind(this);
  		this.handleSubmitForm = this.handleSubmitForm.bind(this);
  		this.handleChangeCategory = this.handleChangeCategory.bind(this);
	}
	handleChangeCategory(selectedOption, name){
		if (name.action === 'select-option'){
			this.setState(prevState => ({
			    audio: {
			        ...prevState.audio,
			        categories:  [...prevState.audio.categories, name.option.value]
			    }
			}));
		}
		if (name.action === 'remove-value'){
			const catTemp = this.state.audio.categories;
			catTemp.splice(catTemp.indexOf(name.removedValue.value), 1);
			this.setState(prevState => ({
				audio: {
			        ...prevState.audio,
			        categories: catTemp
			    }
			}))
		}
	}
	handleRec(e){
	    const { name, value, type } = e.target;
        const recState = { ...this.state.audio };
	    recState[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
	    this.setState({audio: recState});
	}
	handleSubmitForm(e){
	    e.preventDefault();
	    if (this.validatorForm.allValid()) {
			this.setState({submitted: true}, function(){
				this.props.submitForm(this.state.audio, this.state.formType);
			});
		} else {
			this.setState({submitted: false});
			this.validatorForm.showMessages();
			this.forceUpdate();
		}
	}

	extractImageData =  (value) =>  {
		const file = {};
		file['file'] = value['file'];
		file['name'] = value['name'];
		file['size'] = value['size'];
		file['extension'] = HF.returnExtFromImage(value['name']);
		file['preview'] = value['preview'];
		file['type'] = value['type'];
		file['lastModified'] = value['lastModified'];
		return file;
	}
	setResourceFile = (key, value) => {
		this.setState({
			audio: {...this.state.audio, image:this.extractImageData(value) },
		});
	}
	removeFile = (key, index) =>{
		this.setState({
			audio: {...this.state.audio, image:null },
		});
	}


	// setAudioFile = (key, value) => {
	// 	this.setState({
	// 		audio: {...this.state.audio, audioFile:this.extractImageData(value) },
	// 	});
    // }
	// setAudioFile = (key, value) => {
	// 	const audio = new Audio();
	// 	audio.src = URL.createObjectURL(value); 
	
	// 	audio.onloadedmetadata = () => {
	// 		const durationInSeconds = audio.duration;
	
	// 		if (durationInSeconds >= 30) {
	// 			this.setState({
	// 				audio: {
	// 					...this.state.audio,
	// 					audioFile: this.extractImageData(value),
	// 				},
	// 				error: null,
	// 			});
	// 		} else {
	// 			this.setState({
	// 				error: "The audio file must be at least 30 seconds.",
	// 			})
	// 			// Handle the case where the audio is too short
	// 			console.error("The audio file must be at least 30 seconds long.");
	// 		}
	// 	};
	// };

	// removeAudioFile = (key, index) =>{
	// 	this.setState({
	// 		audio: {...this.state.audio, audioFile:null },
	// 	});
	// }

	// image
	setImageFile = (event) => {
		const file = event.target.files[0];
		const fileData = {};
		fileData["name"] = file["name"];
		fileData['size'] = file['size'];
		fileData['extension'] = HF.returnExtFromImage(file['name']).toLowerCase();
		fileData['type'] = file['type'];
		fileData['lastModified'] = file['lastModified'];
		const reader = new FileReader();

		// Define allowed extensions
		const allowedExtensions = ['png', 'jpeg', 'jpg', 'gif', 'heic'];
		const fileExtension = fileData['extension']; // Get the extension
		// Check if the file extension is allowed
		if (!allowedExtensions.includes(fileExtension)) {
			this.setState({ errorImg: 'Only PNG, JPEG, JPG, and GIF, images are allowed.' });
			return;
		}
		reader.onload = () => {
			fileData['file'] = reader.result;
			const imagePreviewUrl = URL.createObjectURL(file);
      this.setState({magePreviewUrl:null, errorImg: null})
			fileData['preview'] = imagePreviewUrl;
			this.setState({
				imagePreviewUrl: imagePreviewUrl,
				// imageFile: fileData,
				audio: { ...this.state.audio, image: fileData },
			});
		};
		reader.readAsDataURL(file);
	};

	handleInputChange = (event) => {
		const videoName = event.target.value.split("\\").pop();
		this.setState({ videoName });
	};

  handleDragOver = (event) => {
		event.preventDefault();
		this.setState({ isDragging: true });
	};

	handleDragLeave = () => {
		this.setState({ isDragging: false });
	};

	handleDrop = (event) => {
		event.preventDefault();		
		const file = event.dataTransfer.files[0];
		if (file) {
			this.setImageFile({ target: { files: [file] } });
		}
		this.setState({ isDragging: false });
	};
	

	// audio
	setAudioFile = (event) => {
		const file = event.target.files[0];
		if (!file) return;
	
		const fileData = {
			name: file.name,
			size: file.size,
			extension: HF.returnExtFromImage(file.name).toLowerCase(),
			type: file.type,
			lastModified: file.lastModified,
		};
	
		const audio = document.createElement('audio');
		const reader = new FileReader();
	
		audio.onloadedmetadata = () => {
			const duration = Math.floor(audio.duration);
			if (duration < 10) {
				this.setState({ error: 'Audio duration must be at least 10 seconds' });
			} else if (file.size > 100 * 1024 * 1024) { // Change this to your max audio size limit in MB
				this.setState({ error: 'Audio size exceeds the maximum allowed (100 MB)' });
			} else {
				this.setState({ error: '' });
				const audioPreviewUrl = URL.createObjectURL(file);
				fileData['preview'] = audioPreviewUrl;
	
				this.setState({ 
					audioDuration: duration, 
					audioPreviewUrl: null 
				}, () => {
					this.setState({ audioPreviewUrl: audioPreviewUrl });
				});
	
				this.setState({
					audioAdded: true,
					audioFileName: fileData["name"],
					audio: { ...this.state.audio, audioFile: fileData, duration: duration },
				});
			}
		};
	
		reader.onload = () => {
			fileData['file'] = reader.result;
		};
	
		reader.readAsDataURL(file);
		audio.src = URL.createObjectURL(file);
	
		this.setState({
			audio: { ...this.state.audio, audioFile: fileData },
		});
	};

	handleDragOverAudio = (event) => {
		event.preventDefault();
		this.setState({ isDraggingAudio: true });
	};

	handleDragLeaveAudio = () => {
		this.setState({ isDraggingAudio: false });
	};
	handleAudioDrop = (event) => {
		event.preventDefault();
		const file = event.dataTransfer.files[0];
		if (file) {
			this.setAudioFile({ target: { files: [file] } });
		}
		this.setState({ isDraggingAudio: false });
	};

	render(){
		const { audio,formType, allowedFormatsStringMime, allowedFormatsStringExt, allowedFormatsAudioMime, allowedFormatsAudioExt} = this.state;
		const { isDragging, upload, error, audioAdded, audioFileName,isDraggingAudio } = this.state
		const { categories } = this.props;
        console.log('categories',categories);
		return(
			<form onSubmit={this.handleSubmitForm}>
                 { formType === 'edit' &&
			        	<input type="hidden" name="sound_id" className="form-control"  value={audio.id}/>
			    }
			  <div className="row">
			    <div className="col-md-8">
					<div className='form-group'>
						<label htmlFor="sound_title">Title<span className="requiredClass">*</span></label>
						<input type="text" name="sound_title" className="form-control"  value={audio.sound_title} onChange={this.handleRec} />
						{this.validatorForm.message('sound_title', audio.sound_title, 'required|min:5')}
					</div>
			    </div>
			    <div className="col-md-4">
	              <div className=''>
	                <label className="container-check checkbox_down"> Is Active?
	                  <input type="checkbox" name="is_active" className="checkbox" value={audio.is_active? 1 : 0} onChange={this.handleRec} checked={audio.is_active?1:0} />
	                  <span className="checkmark"></span>
	                </label>
	                {/* {this.validatorPermission.message('is_active', audio.is_active, 'required')} */}
	              </div>
	            </div>
			  </div>
              <div className="row">
			    <div className="col-md-12">
			      <div className='form-group'>
			        <label htmlFor="sound_description">Description<span className="requiredClass">*</span></label>
			        <textarea name="sound_description" className="form-control"  onChange={this.handleRec}>
                    {audio.sound_description}
                    </textarea>
			        { formType === 'add' &&
			        	this.validatorForm.message('sound_description', audio.sound_description, 'required|min:5')
			        }
			      </div>
			    </div>
			  </div>

                <div className="row">
                    <div className="col-md-12">
                    <div className='form-group'>
                        <label htmlFor="name">Select Category<span className="requiredClass">*</span></label>
                        <Select
                            value={HF.returnLabelsWithValues(audio.categories, categories)}
                            onChange={this.handleChangeCategory}
                            options={categories}
                            isSearchable={true}
                            isMulti={true}
                            placeholder={'Select Category'}
                            isClearable={false}
                        />
                        {this.validatorForm.message('categories', audio.categories, 'required|min:1,array')}
                    </div>
                    </div>
                </div>

				<div className="row">

					<div className="col-md-5">
						<div className=''>
					<label>Select Image</label>
					{/* <div className='uploadBtn'> */}
						<div
						className={`uploadBtn ${isDragging ? 'dragging' : ''}`}
						onDragOver={this.handleDragOver}
						onDragLeave={this.handleDragLeave}
						onDrop={this.handleDrop}
						>
						<label for="upload-image" id='upload-image-btn'>
						<img src={fileUploadIcon} alt={fileUploadIcon} width={20} />
						<span id="text">
							{this.state.imageFile ? this.state.imageFile.name : "Drop / Upload image file"}
						</span>
						</label>
						<input
						type="file"
						accept="image/png, image/jpeg, image/jpg, image/gif"
						id="upload-image"
						onChange={this.setImageFile}
						/>
					</div>
					{this.state.errorImg && <p className='srv-validation-message'>{this.state.errorImg}</p>}
					{this.state.imagePreviewUrl && (
						<div className='text-center'>
						<img src={this.state.imagePreviewUrl} alt="Preview" style={{ maxWidth: '100%', height: 'auto' }} />
						</div>
					)}
						</div>
					</div>

					<div className="col-md-1"></div>
					
					<div className="col-md-5">

						<div className=''>
							<label>Select Audio</label>
							<div
								className={`uploadBtn ${isDraggingAudio ? 'dragging' : ''}`}
								onDragOver={this.handleDragOverAudio}
								onDragLeave={this.handleDragLeaveAudio}
								onDrop={this.handleAudioDrop} // Ensure separate drop handler for audio
							>
								<label htmlFor="upload-audio" id='upload-audio-btn' className={`${audioAdded ? "chosen" : ""}`}>
									<img src={fileUploadIcon} alt={fileUploadIcon} width={20} />
									<span id="text">
										{audioFileName ? audioFileName : "Drop / Upload audio file"}
									</span>
								</label>
								<input
									type="file"
									accept="audio/mpeg, audio/wav, audio/flac, audio/mp4"
									id="upload-audio"
									onChange={this.setAudioFile}
								/>
							</div>
							{this.validatorForm.message('audioFile', this.state.audioPreviewUrl, 'required')}
							{this.state.error && <p className='srv-validation-message'>{this.state.error}</p>}
							{console.log('AAAAAAAAAAA ',this.state.audioPreviewUrl)}
							{this.state.audioPreviewUrl && (
								<div className='text-center mt-3'>
									<audio controls>
										<source src={this.state.audioPreviewUrl} type={this.state.audio.type || 'audio/mp3'} />
										Your browser does not support the audio tag.
									</audio>
								</div>
							)}
							{this.state.audioDuration && <p className='text-primary mt-1 text-center'>Audio duration: {this.state.audioDuration} seconds</p>}
						</div>


					</div>

				</div>

                {/* <div className="row">
                <div className="col-md-4 pofilePicContainer">
				  {!this.state.audio.image ?
			      <div className='form-group dropzone_profileImage'>

					<FileUpload
						files={this.state.audio.image}
						setResourceFile={this.setResourceFile}
						allowedFormatsStringMimePro={allowedFormatsStringMime}
						allowedFormatsStringExtPro={allowedFormatsStringExt}
						multiple={false}
					/>
			      </div>
				  :
				  <ImagePreview
					  filesToPreview={this.state.audio.image}
					  removeFile={this.removeFile}
				  />
				  }
			    </div>

                <div className="col-md-4 pofilePicContainer">
				  {!this.state.audio.audioFile ?
			      <div className='form-group dropzone_profileImage'>

					<FileUpload
						files={this.state.audio.audioFile}
						setResourceFile={this.setAudioFile}
						allowedFormatsStringMimePro={allowedFormatsAudioMime}
						allowedFormatsStringExtPro={allowedFormatsAudioExt}
						multiple={false}
					/>
			      </div>
				  :
				  <ImagePreview
					  filesToPreview={this.state.audio.audioFile}
					  removeFile={this.removeAudioFile}
				  />
				  }
				{this.state.error && <p className='srv-validation-message'>{this.state.error}</p>}

			    </div>

			  </div> */}


			  <div className="row">
			    <div className="col-md-12">
			      <div className='text-center'>
			        <Button  type='submit' bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default " >Save</Button>
			        <Button bsSize="large" bsStyle="info" onClick={()=>this.props.closeModel() } className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info " >Cancel</Button>
			      </div>
			    </div>
			  </div>
			</form>
		);
	}
}


export default MusicFormForm;

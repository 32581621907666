import React, { Component, Fragment } from 'react';
import { Button } from "react-bootstrap";
import html2pdf from 'html2pdf.js';
import V3 from '../../../assets/img/carets-logo-v3.jpg'
import V4 from '../../../assets/img/carets-logo-v4.jpg'
import Sign from '../../../assets/img/signature1.jpg'
import Sign2 from '../../../assets/img/signature2.jpg'
import Sign3 from '../../../assets/img/signature3.jpg'
import Sign4 from '../../../assets/img/signature4.jpg'
import Premium from '../../../assets/img/premium-carets.jpg'
import Award from '../../../assets/img/carets-award.jpg'
import caretlogosm from '../../../assets/img/caretlogosm.jpg'
import caretsType from '../../../assets/img/carets-type.jpg'

import { IoMdDownload } from "react-icons/io";


import "./emailpdf.css"
const baseURL = 'http://devadmin.carets.tv/'
class EmailPDF extends Component {
  constructor(props) {
    super(props);
    this.pdfRef = React.createRef();
  }
  handleDownload = () => {
    const element = this.pdfRef.current;
    if (!element) {
      console.error('PDF element not found.');
      return;
    }
    const { title, date } = this.props.location.state;
    const options = {
      margin: 1,
      filename: `certificate_^${title}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    html2pdf().from(element).set(options).save().then(() => {
      console.log('PDF generated and downloaded.');
    }).catch(error => {
      console.error('Error generating PDF:', error);
    });
  };

  render() {
    const { cId, title, type, startDate, endDate } = this.props.location.state;

    return (
      <Fragment>
        <div className='mt-2-5'>
          <Button
            type="button"
            className="btnDark px-5 font-boldF text-16 me-1"
            onClick={this.handleDownload}
            style={{ width: 'auto', float: 'right' }}
          >
            <IoMdDownload />
          </Button>
          <div className='bodyWrap borderRound' ref={this.pdfRef} style={{ borderBottom: '15px solid #816aff', marginBottom: 15 }}>
            <table style={{ width: '100%' }}>
              <tr>
                <td className='textCenter' style={{ paddingTop: 15 }}>
                  <div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'center' }}>
                    <img src={caretlogosm} alt='caretlogosm' height={72} />
                    <h3 style={{ fontWeight: 600 }}>Certificate of License</h3>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={{ paddingTop: 15 }}><h6 className='textCenter'>This certifies that the ^Caret titled</h6></td>
              </tr>
              <tr>
                <td style={{ background: '#e4e4e3' }}>
                  <h1 className='textCenter title'>^{title}</h1>
                </td>
              </tr>
              <tr>
                <td className='textCenter' style={{ padding: 15 }}>
                  is registered by Fun Store. The holder of this certificate is granted the right to use video content contained within the Carets platform that has been tagged with the "Caret titled "^{title}". This license is subject to terms and conditions of the license agreement.
                </td>
              </tr>
              <tr>
                <td style={{ paddingBottom: 10 }}>
                  <div style={{ display: 'table', width: '100%' }}>
                    <div className='' style={{ verticalAlign: 'middle', display: 'table-cell', width: '33%', padding: 10 }}>
                      <h6 className='m0 textNormal' style={{ fontSize: 13, marginBottom: 5 }}>License No: c-{cId}</h6>
                      {/* <h6 className='m0 textNormal' style={{ fontSize: 13,marginBottom:5 }}>Type: {type}</h6> */}
                      <h6 className='m0 textNormal' style={{ fontSize: 13, marginBottom: 5 }}>Issue Date: {startDate}</h6>
                      <h6 className='m0 textNormal' style={{ fontSize: 13, marginBottom: 5 }}>Expiry Date: {endDate}</h6>
                    </div>
                    <div className='textCenter' style={{ verticalAlign: 'middle', display: 'table-cell', textAlign: 'center', padding: 8, width: '33%', padding: 10 }}>
                      <div style={{ position: 'relative' }}>
                        <img src={caretsType} alt='caretsType' className='image' />
                        <div style={{ position: 'absolute', top: '50%', left: '50%', width: '65px', fontSize: 13, textAlign: 'center', transform: 'translate(-50%, -50%)', fontWeight: 600, wordWrap: 'break-word', whiteSpace: 'normal' }}>
                          {type}
                        </div>
                      </div>
                    </div>
                    <div className='textCenter' style={{ verticalAlign: 'middle', display: 'table-cell', width: '33%', padding: 10 }}>
                      {/* <img src={Sign2} alt='logo1' className='image' /> */}
                      <div className='textCenter'>
                        <h6 style={{ margin: 0, fontSize: 13 }}>Carets Corporation</h6>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          
        </div>

      </Fragment>
    );
  }
}
// class EmailPDF extends Component {
//   constructor(props) {
//     super(props);
//     this.pdfRef = React.createRef();
//   }
//   handleDownload = () => {
//     const element = this.pdfRef.current;
//     if (!element) {
//       console.error('PDF element not found.');
//       return;
//     }
//     const { title, date } = this.props.location.state;
//     const options = {
//       margin: 1,
//       filename: `certificate_^${title}.pdf`,
//       image: { type: 'jpeg', quality: 0.98 },
//       html2canvas: { scale: 2 },
//       jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
//     };
//     html2pdf().from(element).set(options).save().then(() => {
//       console.log('PDF generated and downloaded.');
//     }).catch(error => {
//       console.error('Error generating PDF:', error);
//     });
//   };

//   render() {
//     const { title, date } = this.props.location.state;

//     return (
//       <div className='mt-2-5'>
//         {/* <button style={{ width: 'auto' }} onClick={this.handleDownload} className="btnDownload btnDark">
//           <IoMdDownload />
//         </button> */}
//         <Button
//           type="button"
//           className="btnDark px-5 font-boldF text-16 me-1"
//           onClick={this.handleDownload}
//           style={{ width: 'auto', float: 'right' }}
//         >
//           <IoMdDownload />
//         </Button>
//         <div className='bodyWrap' ref={this.pdfRef}>
//           <table className='certificateTable'>
//             <tbody>
//               <tr>
//                 <td style={{ width: '12.5%' }}><img src={V3} alt='logo1' className='image' /></td>
//                 <td style={{ width: '75%' }}>
//                   <h1 className='textCenter' style={{ marginTop: 50 }}>CERTIFICATE</h1>
//                   <h6 className='textCenter'>Licensing ^Carets</h6>
//                 </td>
//                 <td style={{ width: '12.5%' }}>&nbsp;</td>
//               </tr>
//               <tr style={{ paddingTop: 20 }}>
//                 <td></td>
//                 <td><h6 className='textCenter'>This is to certify that ^Carets</h6>
//                   <h1 className='textCenter borderTB title'>^{title}</h1>
//                   <p className='textCenter'>has been registered by Fun Store on behalf of Mark Anthony</p></td>
//                 <td>
//                 </td>
//               </tr>
//               <tr>
//                 <td></td>
//                 <td>
//                   <div style={{ display: 'table', width: '100%' }}>
//                     <div className='textCenter' style={{ verticalAlign: 'middle', display: 'table-cell' }}>
//                       <img src={Sign} alt='logo1' className='image' />
//                       <div className='textCenter' style={{ marginTop: 15 }}>
//                         <h6 style={{ margin: 0, borderBottom: '1px solid #333', fontSize: 13 }}>John Martina</h6>
//                         <p style={{ fontSize: 12, margin: 0 }}>President Director</p>
//                       </div>
//                     </div>
//                     <div className='textCenter' style={{ verticalAlign: 'middle', display: 'table-cell', textAlign: 'center', padding: 8 }}>
//                       <img src={Premium} alt='premiumCarets' className='image' /></div>
//                     <div className='textCenter' style={{ verticalAlign: 'middle', display: 'table-cell' }}>
//                       <img src={Sign2} alt='logo1' className='image' />
//                       <div className='textCenter' style={{ marginTop: 15 }}>
//                         <h6 style={{ margin: 0, borderBottom: '1px solid #333', fontSize: 13 }}>Alex Martina</h6>
//                         <p style={{ fontSize: 12, margin: 0 }}>Genral Manager</p>
//                       </div>
//                     </div>
//                   </div>
//                 </td>
//                 <td style={{ verticalAlign: 'bottom' }}>
//                   <img src={V3} alt='logo1' className='image' />
//                 </td>
//               </tr>
//             </tbody>
//           </table>
//         </div>
//       </div>
//     );
//   }
// }

export default EmailPDF;

import { SC } from '../helper/ServerCall';

export const keywordService = {
    paginationData,
    storeKeyword,
    getKeyword,
    editKeyword,
    deleteKeyword,
    updateKeywordStatus,
    blockCLMRecord,
    getInfluencer,
}
function paginationData(page, filter, sort, pageSize,status,search=null) {
    const data = {'page': page,'filter': filter,'sort': sort,'pageSize': pageSize,'status': status,'search':search};
    return SC.postCall('keyword/data', data);
}

function storeKeyword(catData) {
    return SC.postCall('keyword/listing', catData);
}

function getKeyword(id) {

    return SC.getCall('keyword/listing/' + id);
}

function editKeyword(data, Id) {
    data['keyword_id'] = Id;
    return SC.postCall('keyword/listing', data);
}

function deleteKeyword(catId) {
    return SC.deleteCall('keyword/listing/' + catId);
}

function updateKeywordStatus(catId, data) {
    data['keyword_id'] = catId;
    return SC.postCall('keyword/status',data);
}
function blockCLMRecord(catId) {
    return SC.getCall('plclmPlansans/block/status/'+catId);
}

function getInfluencer() {
    return SC.getCall('CLMPayments/getInfluencerPlan');
}


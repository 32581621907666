import $ from 'jquery';
import React from "react";
import arrowIcon from '../assets/img/arrow_down.png';
import {
	Button,
} from "react-bootstrap";
import clonedeep from 'lodash.clonedeep'
var dateFormat = require('dateformat');
var moment = require("moment");

export const helperFunctions = {
	getRole,
	focusInput,
	returnLabelWithValue,
	returnLabelsWithValues,
	returnLabelsWithValueOtherThenLabel,
	isEmpty,
	returnOptionWithDisble,
	returnFormats,
	returnExtFromImage,
	returnKeyWithColumnMatch,
	returnColumnValueWithColumnMatch,
	returnDollars,
	dateFormatMy,
	dateTimeMy,
	dateFormatSystem,
	leaf,
	updateStateVariableDynamic,
	hasChildren,
	TriggerHeader,
	returnKeys,
	intersectionOfObjectsWithNewValue,
	omitKeys,
	sortObjectWithDefineKeys,
	openModal,
	closeModal,
	closeModalAfterApi,
	showDate,
	overRideDuplicateImage,
	removeDuplicateImage,
	returnTimeStamp,
	hasRole,
	optionsHasRole,
	checkLoggedInUserById,
	toggleModal,
	inputChangeHandler,
	isLoggedIn,
	getInitials,
	colorForBardcodeWithDate,
	returnLabelWithValueGroup,
	clean,
	dateChangeHandler,
	updateState,
	contactChangeHandler,
	selectChangeHandler,
	appendCount,
	returnBoleanWithColumnMatch,
	appendValues,
	appendValuesWithMatchKeys,
	returnArrayOfMatchingKeyFromArray,
	returnColumnValueWithColumnMatchSA,
	changeText,
	returnLabelsWithValuesWithValueMAndLabelM,
	selectArrayChangeHandler,
	getFormattedDate,
	BytesToMb,
	createFormData,
	compareDates,
	isset,
	checkObjHasKeys,
	checkNested,
	lengthOfMultiArray,
	flatten,
	matchWithFlatten,
	getRandomInt,
	dateWithDayMy,
	returnDynamicURL,
	getSum,
	dateTimeWithAMPM,
	remove_duplicates,
	isEmptyCheck,
	dateYear,
    dateYearMonth,
    dateMMDDYYYY,
    TStoDate,
	TStoDateForm,
	truncate,
};
function TriggerHeader(heading, status) {
	return (
		<h4 className="headerTitle">{heading}
			<Button className="arrow_toggle" aria-controls="example-collapse-text" >
				{status ? <img src={arrowIcon} alt="Arrow" className="arrow_up" /> : <img src={arrowIcon} alt="Arrow" className="arrow_down" />}
			</Button>
		</h4>
	);
}
function hasChildren(obj, parent) {
	for (var k in obj) {
		if (typeof obj[k] === 'object' && obj[k] !== null) {
			parent.push(k);
			this.hasChildren(obj[k], parent);
		}
	}
	return parent;
}

//Path is the array of dynamic keys to update state
function updateStateVariableDynamic(stateClone, value, path) {
	const nestedObject = path.slice(0, -1)
		.reduce(
			(object, part) => (
				object === undefined ? undefined : object[part]
			),
			stateClone
		);

	if (nestedObject !== undefined) {
		/* Obtain last key in path */
		const [pathTail] = path.slice(-1);

		/* Update value of last key on target object to new value */
		nestedObject[pathTail] = value;
	}

	return stateClone;
}

function leaf(obj, path) {
	path = path.split('.');
	var res = obj;
	for (var i = 0; i < path.length; i++) {
		res = res[path[i]];
	};
	return res;
}

function TStoDate(date) {
    const ts = date ? new Date(date *  1000) : null;
	return ts ? dateFormat(ts, "ddd, mmm dd, yyyy") : "--";
}
function TStoDateForm(date) {
    const ts = date ? new Date(date * 1000) : null;
    return ts ? dateFormat(ts, "mm/dd/yyyy") : "--";
}

function dateFormatMy(date) {
	return date ? dateFormat(date, "mmm dd, yyyy") : '--';
}
function dateYear(date) {
	return date ? dateFormat(date, "yyyy") : '--';
}
function dateYearMonth(date) {
	return date ? dateFormat(date, "mmm yyyy") : 'Present';
}
function dateFormatSystem(date) {
	return date ? dateFormat(date, "yyyy-mm-dd") : '';
}
function dateMMDDYYYY(date) {
	return date ? dateFormat(date, "mm/dd/yyyy") : null;
}
function dateTimeMy(date){
	//var time = dateFormat(now, "hammerTime");
	return date ? dateFormat(date, "mmm dd yyyy - h:MM:ss") : '--';
}

function dateTimeWithAMPM(date){
	//var time = dateFormat(now, "hammerTime");
	return date ? dateFormat(date, "mmm dd yyyy h:MM TT") : '--';
}

function dateWithDayMy(date){
	//var time = dateFormat(now, "hammerTime");
	return date ? dateFormat(date, "ddd") : '--';
}

function returnDollars(num) {
	return num > 0 ? '$' + num : num;
}

function getRole() {
	var userAdmin = JSON.parse(localStorage.getItem('user'));
	if (userAdmin) {
		return userAdmin.data.roles;
	}
}

function focusInput(objectTemp) {
	let properties = Object.keys(objectTemp).reverse();
	for (var key in properties) {
		if (objectTemp[properties[key]] !== null) {


			var myEle = $("#" + properties[key]);
			// console.log('myEle', properties[key]);
			// console.log('myEle', myEle);
			if (myEle) {
				var scrollPos = $(myEle).offset().top;
				$(window).scrollTop(scrollPos);
			}


			// $("#"+properties[key]).focus();
		}
	}
}

function returnExtFromImage(imageName) {
	var ext = imageName.substr(imageName.lastIndexOf('.') + 1);
	return ext;
}

function returnOptionWithDisble(value, ObjectArray) {
	var newObject = [];
	for (var key in ObjectArray) {
		if (ObjectArray[key]['value'] === value) {
			newObject.push({
				value: ObjectArray[key]['value'],
				label: ObjectArray[key]['label'],
				disabled: true
			});
		} else {
			newObject.push({
				value: ObjectArray[key]['value'],
				label: ObjectArray[key]['label'],
			});
		}
	}
	return newObject;
}


function returnFormats(ObjectArray, columnName) {
	var newObject = [];
	for (var key in ObjectArray) {
		newObject.push(
			ObjectArray[key][columnName]
		);
	}
	return newObject;
}


function returnLabelWithValue(value, ObjectArray, defaultLabel = null) {
	// console.log('ObjectArray 456', ObjectArray)
	if (value) {
		for (var key in ObjectArray) {
			if (ObjectArray[key]['value'] === value) {
				return {
					value: ObjectArray[key]['value'], label: ObjectArray[key]['label']
				};
			}
		}
	}
	else if (defaultLabel) {
		for (var keyN in ObjectArray) {
			if (ObjectArray[keyN]['label'] === defaultLabel) {
				return {
					value: ObjectArray[keyN]['value'], label: ObjectArray[keyN]['label']
				};
			}
		}
	}
	return null;
}

function returnLabelWithValueGroup(value, ObjectArray) {
	if (value) {
		for (var key in ObjectArray) {
			for (var k in ObjectArray[key]['options']) {
				if (ObjectArray[key]['options'][k]['value'] === value) {
					return {
						value: ObjectArray[key]['options'][k]['value'], label: ObjectArray[key]['options'][k]['label']
					};
				}
			}
		}
	}
	return null;
}

function returnLabelsWithValues(values, ObjectArray) {
	var temp = [];

	if (values.length > 0) {
		for (var key in ObjectArray) {
			if (values.includes(ObjectArray[key]['value'])) {
				temp.push({ value: ObjectArray[key]['value'], label: ObjectArray[key]['label'] });
			}
		}
	} else {
		return null;
	}
	return temp;
}

/*return selected values with match value and label*/
function returnLabelsWithValuesWithValueMAndLabelM(values, ObjectArray, valueMatch, labelMatch) {
	var temp = [];
	if (values.length > 0) {
		for (var key in ObjectArray) {
			if (values.includes(ObjectArray[key][valueMatch])) {
				temp.push({ [valueMatch]: ObjectArray[key][valueMatch], [labelMatch]: ObjectArray[key][labelMatch] });
			}
		}
	} else {
		return null;
	}
	return temp;
}

function returnLabelsWithValueOtherThenLabel(value, ObjectArray, keyValue, keyLabel) {
	if (value) {
		for (var key in ObjectArray) {
			if (ObjectArray[key][keyValue] === value) {

				var data = {
					value: ObjectArray[key][keyValue], label: ObjectArray[key][keyLabel]
				};

				return data;
			}
		}
	}

	return null;
}

/*Return array key if key index match to define column otherwise null*/
function returnKeyWithColumnMatch(columnName, ObjectArray, value) {
	for (var key in ObjectArray) {
		if (ObjectArray[key][columnName] === value) {
			return key;
		}
	}
	return null;
}
function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key))
			return false;
	}
	return true;
}
/*return value for nested array after key match with value */
function returnColumnValueWithColumnMatch(ObjectArray, columnNameReturn, columnMatch, columnMatchName) {
	// console.log('ObjectArray', ObjectArray);
	// console.log('columnNameReturn',columnNameReturn);
	// console.log('columnMatch', columnMatch);
	// console.log('columnMatchName', columnMatchName);
	if (ObjectArray.length > 0) {
		for (var key in ObjectArray) {
			if (columnMatch) {
				if (columnMatch.includes(ObjectArray[key][columnMatchName])) {
					return ObjectArray[key][columnNameReturn];
				}
			}
		}
	}
	return null;
}

/*return value for single array after key match */
function returnColumnValueWithColumnMatchSA(ObjectArray, columnNameReturn) {
	for (var key in ObjectArray) {
		if (columnNameReturn) {
			if (key === columnNameReturn) {
				return ObjectArray[columnNameReturn];
			}
		}
	}
	return null;
}


function returnKeys(Obj) {
	var keys = [];
	for (var key in Obj) {
		if (typeof Obj[key] === 'object') {
			this.returnKeys(Obj[key]);
		}
	}
	return keys;
}
//b is the object from where to be copied and a is the object to be updated
function intersectionOfObjectsWithNewValue(a, b) {
	var newObj = {};
	for (var key in a) {

		if (Array.isArray(a[key])) {
			newObj[key] = b[key];
		}
		else if (typeof a[key] === 'object') {
			if (typeof b[key] !== 'undefined') {
				var obj = intersectionOfObjectsWithNewValue(a[key], b[key]);
				newObj[key] = obj;
			} else {
				newObj[key] = a[key];
			}

		} else if (b.hasOwnProperty(key)) {
			newObj[key] = b[key] !== null ? b[key] : '';
		} else if (!b.hasOwnProperty(key)) {
			newObj[key] = a[key] ? a[key] : '';
		}
	}
	return newObj;
}

function omitKeys(obj, not) {
	var result = Object.assign({}, obj);
	for (let n of not) delete result[n];
	return result;
}
function sortObjectWithDefineKeys(object, keysObject) {
	let tempObject = {}
	for (var key in keysObject) {

		if (object.hasOwnProperty(keysObject[key])) {

			tempObject[keysObject[key]] = object[keysObject[key]];
		}
	}
	return tempObject;
}


function openModal(name) {

	this.setState({
		[name]: true
	});
}
function closeModal(modalName) {
	// console.log('close modal call 789');
	this.setState({
		[modalName]: false
	})
}

function closeModalAfterApi(modalName, afterApi) {
	this.setState({
		[modalName]: false,
		[afterApi]: true
	})
}

function showDate(data) {
	const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
	const date = new Date(data);
	const d = date.getDate();
	let prefix;
	if (d > 3 && d < 21) {
		prefix = 'th'
	} else {
		switch (d % 10) {
			case 1:
				prefix = 'st';
				return prefix;
			case 2:
				prefix = 'nd';
				return prefix;
			case 3:
				prefix = 'rd'
				return prefix;
			default:
				break;
		}
	}
	return `${months[date.getMonth()]} ${date.getDate()}${prefix} ,${date.getFullYear()}`;
}

function overRideDuplicateImage(currentImgs, AllImgs) {
	// for(var i in currentImgs ) {
	// 	for(var j in AllImgs) {

	// 	}
	// }
}

function removeDuplicateImage(val, AllImgs) {
	for (var i in AllImgs) {
		if ((AllImgs[i].name === val.name)) {
			if ((AllImgs[i].file === val.file))
				return { val: val, duplicate: true, name: val.name, index: i, message: val.name + ' Image name and file is same' }
			else
				return { val: val, duplicate: true, name: val.name, index: i, message: val.name + ' Image file name is same' }
		}
	}
	return { duplicate: false, val: val, name: val.name };
}

function returnTimeStamp(dataString) {
	const dateTime = new Date(dataString).getTime();
	const timestamp = Math.floor(dateTime / 1000);
	return timestamp;
}

function hasRole(role) {
	let AdminUser = JSON.parse(localStorage.getItem('user'));
	if (AdminUser && AdminUser.token) {
		for (var i in AdminUser.data.roles) {
			var temp = AdminUser.data.roles[i];
			if (role.includes(temp.name)) {
				return true;
			}
		}
		return false;
	} else {
		return false;
	}
}

function optionsHasRole(options, roleName) {
	var temp = [];
	for (var i in options) {
		for (var j in options[i].roles) {
			if (options[i].roles[j].name === roleName) {
				temp.push(options[i]);
			}
		}
	}
	return temp;
}

function checkLoggedInUserById(id) {
	let AdminUser = JSON.parse(localStorage.getItem('user'));
	if (AdminUser && AdminUser.token) {
		return AdminUser.user.id === id ? true : false;
	} else {
		return false;
	}
}

function toggleModal(name) {
	// console.log('call toggle function 999')
	this.setState({
		[name]: !this.state[name]
	});
}



function isLoggedIn() {
	let AdminUser = JSON.parse(localStorage.getItem('user'));
	return (AdminUser && AdminUser.token) ? true : false;
}
function getInitials(name, delimeter) {

	if (name) {
		var array = name.split(delimeter);
		switch (array.length) {
			case 1:
				return array[0].charAt(0).toUpperCase();
			//break;
			default:
				return array[0].charAt(0).toUpperCase() + array[array.length - 1].charAt(0).toUpperCase();
		}
	}

	return false;

}

function colorForBardcodeWithDate(date, dateName) {
	var color = "";
	if (date && dateName === 'videoSpecialNeedDate') {
		color = 'barcode-color-red'
	} else if (date && dateName === 'lastDayOfSchool') {
		var diff = moment().diff(date, 'days');
		if (diff > 0 && diff <= 5) {
			color = 'barcode-color-yellow';
		}
	}
	return color;
}

function clean(prop, value = null, date = null) {
	if (prop) {
		if (date && prop) {
			return new Date(prop)
		} else if (date && value !== null) {
			return value
		}
		return prop
	} else {
		if (value !== null) {
			return value
		} else {
			return ''
		}
	}
}

function inputChangeHandler(e, pattern) {
	const checked = e.target.checked;
	if (pattern) {
		if (e.target.value.match(pattern) === null) {
			return
		}
	}
	const container = e.target.name;
	let value = e.target.value;
	if (value === 'true') {
		value = true
	} else if (value === 'false') {
		value = false;
	}
	const newState = updateState.bind(this);
	newState(container, value, checked);
}

function dateChangeHandler(value, dateType, defaultValue) {
	if (defaultValue !== undefined && value === '') {
		value = defaultValue
	}
	const newState = updateState.bind(this);
	newState(dateType, value);
}

function contactChangeHandler(e) {
	const { value } = e.target;
	if (value.match(/^\d*$/) === null) {
		return;
	};
	const container = e.target.name.split('.');
	let phone = ''.split('-');
	if (this.state[container[0]][container[1]][container[2]] !== null) {
		phone = this.state[container[0]][container[1]][container[2]].split('-')
	}
	phone[container[3]] = value;
	const newContainer = container[0] + "." + container[1] + "." + container[2];
	const newState = updateState.bind(this);
	const phoneLength = phone.reduce((total, current) => {
		return total = parseFloat(current.length) + parseFloat(total);
	}, 0);
	if (phoneLength > 0) {
		newState(newContainer, phone.join('-'))
	} else {
		newState(newContainer, '');
	}
}

function selectChangeHandler(selected, action, type) {
	const newState = updateState.bind(this);
	newState(action.name, selected.value)
}

function selectArrayChangeHandler(selected, action) {
	const container = action.name.split('.');
	const targetArray = this.state[container[0]][container[1]];
	if (action.action === 'select-option') {
		selected = [...targetArray, action.option.value];
	}
	if (action.action === 'remove-value') {
		selected = targetArray.filter(item => {
			return item.value === action.removedValue.value
		});
	}
	const newState = updateState.bind(this);
	newState(action.name, selected);
}

function updateState(containerState, value, checked = null) {
	const container = containerState.split('.');
	let currentState;
	currentState = clonedeep(this.state[container[0]])
	switch (container.length) {
		case 1:
			currentState = value
			break;
		case 2:
			const isArray = container[1].match(/(\[\])/g);
			if (isArray) {
				container[1] = container[1].replace('[]', '');
				if (currentState[container[1]]) {
					if (checked) {
						currentState[container[1]] = [...currentState[container[1]], parseInt(value, 10)]
					} else {
						currentState[container[1]] = currentState[container[1]].filter(item => {
							return item !== parseInt(value, 10)
						});
					}
				} else {
					currentState[container[1]] = [value]
				}
			} else {
				currentState[container[1]] = value
			}
			break;
		case 3:
			currentState[container[1]][container[2]] = value
			break;
		case 4:
			currentState[container[1]][container[2]][container[3]] = value
			break;
		default:
			break;
	}
	this.setState({
		[container[0]]: currentState
	});
}
/*Append count in an array*/
function appendCount(options) {
	for (var i in options) {
		options[i]['count'] = parseInt(i, 10);
	}
	return options;
}

/*Append values in an array*/
function appendValues(options, values) {
	// console.log('values', values);
	for (var i in options) {
		for (var j in values) {
			options[i][values[j]['column']] = values[j]['value'];
		}
	}
	return options;
}
/*Return true if key index match to define column otherwise false*/
function returnBoleanWithColumnMatch(options, index, value) {
	for (var i in options) {
		if (index in options[i]) {
			if (options[i][index] === value) {
				return true;
			}
		}
	}
	return false;
}
/*return array after adding new key (setcolumn) in object, if keysArray Array have matching key (matchColumn) value */
function appendValuesWithMatchKeys(options, keysArray, matchColumn, setColumn, setValue) {
	for (var i in options) {
		if (keysArray.includes(options[i][matchColumn])) {
			options[i][setColumn] = setValue;
		}
	}

	return options;
}

/*Return an array of matching key from array(options)*/
function returnArrayOfMatchingKeyFromArray(options, matchColumn) {
	// console.log('optiosn', options);
	// console.log('matchColumn', matchColumn);
	var temp = [];
	for (var i in options) {
		if (matchColumn in options[i]) {
			if (options[i][matchColumn]) {
				temp.push(options[i][matchColumn]);
			}
		}
	}
	return temp;
}

function changeText(attribute) {
	if (attribute.includes('item'))
		return 'item';
	else if (attribute.includes('inventory'))
		return 'inventory';
	// return 'hahaha';
}

function getFormattedDate(date) {
	if (date !== '' && date !== null && date !== undefined) {
		const formattedDate = new Date(date);
		return (formattedDate.getMonth() + 1) + '/' + formattedDate.getDate() + '/' + formattedDate.getFullYear()
	} else {
		return ''
	}
}

function compareDates(date1, date2, operator) {
	date1 = new Date(getFormattedDate(date1));
	date2 = new Date(getFormattedDate(date2));
	if (operator === '>') {
		if (date1 > date2) {
			return true
		} else {
			return false
		}
	}
	else if (operator === '<') {
		if (date1 < date2) {
			return true
		} else {
			return false
		}
	}
	else if (operator === '>=') {
		if (date1 >= date2) {
			return true
		} else {
			return false
		}
	}
	else if (operator === '<=') {
		if (date1 <= date2) {
			return true
		} else {
			return false
		}
	}
}

function BytesToMb(bytes) {
	var kb = 1024;
	// var ndx = Math.floor(Math.log(bytes) / Math.log(kb));

	return +(bytes / kb / kb).toFixed(2);
}

function createFormData(dataArray) {
	const fd = new FormData();
	for (var i in dataArray) {
		if (Array.isArray(dataArray[i])) {
			console.log('length of array', dataArray[i].length)
			if (dataArray[i].length > 0) {
				for (var j in dataArray[i]) {
					fd.append(i, dataArray[i][j]);
				}
			} else {
				fd.append(i, dataArray[i]);
			}
		} else {
			fd.append(i, dataArray[i]);
		}
	}
	return fd;
}

function isset(object, props) {
	var dump;
	try {
		console.log('x props', props);
		for (var x in props) {
			console.log('x props 1', props);
			if (x === 0) {
				console.log('x props test');
				dump = object[props[x]];
				return;
			}
			console.log('x data', x);
			dump = dump[props[x]];
		}
	} catch (e) {
		return false;
	}

	return true;
}

function checkObjHasKeys(obj, keys) {
	var success = true;
	keys.forEach(function (key) {
		if (!obj.hasOwnProperty(key)) {
			success = false;
		}
		obj = obj[key];
	})
	console.log(success ? 'yes 321' : 'no 321')
	return success;
}

function checkNested(obj, keys) {
	var args = Array.prototype.slice.call(keys);
	obj = args.shift();
	for (var i = 0; i < args.length; i++) {
		if (obj == null || !obj.hasOwnProperty(args[i])) {
			return false;
		}
		obj = obj[args[i]];
	}
	return true;
}

function lengthOfMultiArray(array) {
    var total = 0;
    for(var i in array) {
        if (Array.isArray(array[i])){
            total += lengthOfMultiArray(array[i]);
        } else {
            total++;
        }
    }

    return total;
}



function flatten(obj, prefix = '', res = {}) {
  return Object.entries(obj).reduce((r, [key, val]) => {
    const k = `${prefix}${key}`
    if(typeof val === 'object'){
      flatten(val, `${k}.`, r)
    } else {
      res[k] = val
    }
    return r
  }, res)
}
// function flatten(data){
//   return data.reduce(function(result,next){
//     result.push(next);
//     if(next.items){
//       result = result.concat(flatten(next.items));
//       next.items = [];
//     }
//     return result;
//   },[]);
// }
function truncate(str, length){
    if (!str) return '';
    if (str.length <= length) return str;
    return str.slice(0, length) + '...'; 
}

function matchWithFlatten(match, data){
	for(var i in data) {
		if (match === data) {
			return i;
		}
	}

	return null;
}
function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

function returnDynamicURL(domainSlug, email, password) {
	// const host = window.location.hostname.replace(/www./).split('.');
	let match, result;
	match = document.domain.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im);
	if (match) {
	    result = match[1]
	    match = result.match(/^[^\.]+\.(.+\..+)$/);
	    if ( match ) {
	        result = match[1]
	    }
	}

	return `http://${domainSlug}.${result}/login/${email}/${password}` ;
}

function getSum(val1, val2) {
  return parseInt(val1, 10) + parseInt(val2, 10) ;
}

function remove_duplicates(arr) {
    let s = new Set(arr);
    let it = s.values();
    return Array.from(it);
}

function isEmptyCheck(data) {
    if(typeof(data) === 'object'){
        if(JSON.stringify(data) === '{}' || JSON.stringify(data) === '[]'){
            return true;
        }else if(!data){
            return true;
        }
        return false;
    }else if(typeof(data) === 'string'){
        if(!data.trim()){
            return true;
        }
        return false;
    }else if(typeof(data) === 'undefined'){
        return true;
    }else{
        return false;
    }
}

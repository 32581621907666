import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Row,
  Col,
  // Tooltip,
  OverlayTrigger,
  // Button,
  Popover
} from "react-bootstrap";
import Select from 'react-select';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import MusicForm from "./CLMMusicForm";
import { hasPermission } from 'helper/hasPermission';
import Confirmalertfordelete from '../../ThemeComponents/confirmAlertForDelete';
import { adminLabels } from '../../../redux/constant/admin-label-constant';
import OpenModalButton from '../../ThemeComponents/openModelButton';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import Pagination from '../../ThemeComponents/Pagination';
import { RiVideoLine } from 'react-icons/ri';
import { MdAudiotrack } from "react-icons/md";
import { musicService } from '../../../services/music';
import { licenseService } from '../../../services/license';

var Modal = require('react-bootstrap-modal');

class MusicListingPage extends Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem('user'));
    this.state = {
      addModalForm: false,
      editModalForm: false,
      openAudioModal: false,
      sound_url: '',
      sorted: '',
      filtered: '',
      filter: '',
      license: [],
      pageSize: 10,
      userID: user.data ? user.data.id : null,
    };

    this.editRecord = this.editRecord.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
    this.deleteConfirmModal = this.deleteConfirmModal.bind(this);
    this.getPaginationData = this.getPaginationData.bind(this);
    this.filterSearchHandle = this.filterSearchHandle.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.getCategoriesCall = this.getCategoriesCall.bind(this);
    this.AudioModal = this.AudioModal.bind(this);
    this.getLicenseCall = this.getLicenseCall.bind(this);
  };
  AudioModal(url) {
    this.setState({ sound_url: url }, () => {
      this.setState({ openAudioModal: true });
      console.log('url', this.state.sound_url);
    });

  }
  submitForm(formData, formType) {
    if (formType === 'add') {
      musicService.storeAudio(formData).then(res => {
        this.setState({ addModalForm: false });
        this.pagination.dataCall();
      });
    } else if (formType === 'edit') {
      musicService.editAudio(formData, this.state.editRec.id).then(res => {
        this.setState({ editModalForm: false });
        this.pagination.dataCall();
      });
    }
  }

  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }

  editRecord(id) {
    musicService.getAudio(id).then(res => {
      console.log(res);
      this.setState({ editRec: res.data }, () => {
        this.setState({ editModalForm: true });
      });
    });
  }

  deleteRecord(recID) {
    musicService.deleteAudio(recID).then(res => {
      this.pagination.dataCall();
    });
  }

  deleteConfirmModal(recID) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Record" description="Are you sure to delete the Record?" onClosePro={onClose} deleteType={() => this.deleteRecord(recID)} />
        )
      }
    })
  }


  getPaginationData(page, filter, sort, pageSize, status) {
    this.setState({ pageSize: pageSize }, function () {
      musicService.paginationData(this.state.userID, page, filter, sort, pageSize, status);
    });
  }

  updateStatus = recID => (e) => {
    // return false;
    musicService.updateAudioStatus(recID, { is_active: e.target.checked ? 1 : 0 }).then(
      response => {
        musicService.paginationData(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status);
        this.pagination.dataCall();
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.rec_status !== prevState.rec_status) {
      this.table.state.onFetchData(this.table.state, this.table.instance)
    }

  }

  paginationCall = (data) => {
    return musicService.paginationData(this.state.userID, data.page, data.filter || this.state.filter, data.sort, data.pageSize, this.state.rec_status);
  }


  getCategoriesCall() {
    musicService.getCategories().then(res => {
      console.log('categories', res);
      this.setState({ categories: res.data });
    });
  }

  componentDidMount() {
    this.getCategoriesCall();
    this.getLicenseCall()
  }
  getLicenseCall(){
    licenseService.getCaretLicense().then(res => {
            console.log('license',res.data);
            this.setState({license:res.data});
          });
      }
  
    handleTypeChange = (selectedOption) => {
    // console.log("selectedOption ==========> ", selectedOption);
    this.setState({
      filter: selectedOption ? selectedOption.value : ''
      }, () => {
          // Ensure this runs only after the state update is complete
          this.pagination.dataCall();
      });
    };

  render() {
    const { filtered, editRec, categories } = this.state;
    const columns = [
      {
        Header: "Audio Listing",
        headerClassName: 'headerPagination',
        columns: [
          {
            Header: "Audio",
            accessor: "sound_url",
            Cell: ({ row, original }) => (
              original.image_url ? (
                <div style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => this.AudioModal(row.sound_url)}>
                  <img style={{ hight: "25px", width: "40px" }} src={original.image_url} />
                </div>
              ) : (
                <div style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => this.AudioModal(row.sound_url)}>
                  <MdAudiotrack size={40} />
                </div>
              )
            ),

            className: "action-center"
          },
          {
            Header: "Title",
            accessor: "sound_title",
            className: "justifyStart text-left",
            headerClassName: "justifyStart text-left",
            minWidth: 200
          },
          {
            Header: "License",
            accessor: "license",
            Cell: ({ original }) => {
              // console.log("original license", original.license);
              return (
                <div>
                  {original.license && original.license.length > 0
                    && original.license.map((item, index) => (
                        <div key={index}>{item.caret_title}</div>
                      ))
                    }
                </div>
              );
            },
            className: "",
            sortable: false
          },
          {
            Header: "Status",
            accessor: "is_active",
            Cell: ({ row }) => (
              <label className="container-check">
                &nbsp;
                <input
                  type="checkbox" name="is_active" className="checkbox" value={row.is_active ? 1 : 0} onChange={this.updateStatus(row.id)} checked={row.is_active ? 1 : 0}
                /><span className="checkmark"></span>
              </label>
            ),
            className: "action-center",
            sortable: false
          },
          {
            Header: "Actions",
            accessor: "id",
            Cell: ({ value }) => (
              <div>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Edit Audio</Popover>}>
                  <span>
                    {
                      <button type='button' className="editIcon orderDetailsAction" onClick={() => this.editRecord(value)}>&nbsp;</button>
                    }

                  </span>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Delete Audio</Popover>}>
                  <span>
                    {

                      <button type='button' className="deleteIcon orderDetailsAction" onClick={() => this.deleteConfirmModal(value)}>&nbsp;</button>
                    }
                  </span>

                </OverlayTrigger>
              </div>
            ),
            className: "justifyEnd text-right",
            headerClassName: "justifyEnd text-right",
            sortable: false
          }
        ]
      }
    ];

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                  <div>
                    <Grid fluid>
                      <Row>
                        <Col md={12}>
                          <div className="secHeading">Audio Listing</div>
                          <div className="">
                            <OpenModalButton
                              openModal={HF.openModal.bind(this, "addModalForm")}
                              classButton={['btn btnDark text-white w-autoF']}
                              buttonName="Add Audio"
                              classIcon={['fa', 'fa-plus']}
                            />
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="fixBotNegative">
                            <label htmlFor="plan"></label>
                            <Select
                              name="typeSelect"
                              options={[
                                { value: "", label: "All Licenses" }, // Add "ALL" option at the top
                                ...(this.state.license || []), // Spread the rest of the licenses
                              ]}
                              className="basic-single"
                              classNamePrefix="select"
                              placeholder="Select License"
                              value={
                                this.state.filter
                                  ? [{ value: "", label: "All Licenses" }, ...(this.state.license || [])].find(
                                      ls => ls.value === this.state.filter
                                    )
                                  : { value: "", label: "All Licenses" }
                              }
                              onChange={this.handleTypeChange}
                            />
                          </div>
                        </Col>
                        <Col md={12} className="mt-md">
                          <Pagination
                            ref={(p) => this.pagination = p}
                            showPagination={false}
                            columns={columns}
                            pageSize={20}
                            getDataCall={this.paginationCall}
                            filterView={true}
                            filterPlaceHolder={'Audios'}
                            defaultSorted={
                              [
                                {
                                  id: 'sound_title',
                                  desc: 'false'
                                },
                                {
                                  id: 'is_active',
                                  desc: 'false'
                                }
                              ]
                            }
                            // downloadData={true}
                            // downloadFileName={'Orders'}
                            // lowerContent = {null}
                            // filterPlaceHolder = {'Orders'}
                            noDataText='No Record found'
                            getRowProps={this.getRowProps}
                          // showAllToggle={true}
                          />
                        </Col>
                      </Row>
                    </Grid>
                    {categories !== null &&
                      <Modal backdrop={'static'} show={this.state.addModalForm} onHide={HF.closeModal.bind(this, "addModalForm")} aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                          <Modal.Title id='ModalHeader' className="headerTitle">Add Audio</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12">
                              <Card bsClass={["innerCard mb-none"]} content={
                                <MusicForm
                                  closeModel={HF.closeModal.bind(this, "addModalForm")}
                                  formTypePro="add"
                                  recPro={OC.AUDIO}
                                  user_id={this.state.userID}
                                  submitForm={this.submitForm}
                                  componentPro="MusicListing"
                                  categories={categories}
                                />
                              } />
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    }
                    {/*Add Record Modal End*/}

                    {/*Edit Record Modal start*/}
                    {editRec &&
                      <Modal backdrop={'static'} show={this.state.editModalForm} onHide={HF.closeModal.bind(this, "editModalForm")} aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                          <Modal.Title id='ModalHeader' className="headerTitle">Edit Audio</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12">
                              <Card bsClass={["innerCard mb-none"]} content={
                                <MusicForm
                                  closeModel={HF.closeModal.bind(this, "editModalForm")}
                                  formTypePro="edit"
                                  recPro={editRec}
                                  user_id={this.state.userID}
                                  submitForm={this.submitForm}
                                  categories={categories}
                                />
                              } />
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    }
                    {/*Edit Record Modal End*/}

                    {
                      <Modal backdrop={'static'} show={this.state.openAudioModal} onHide={HF.closeModal.bind(this, "openAudioModal")} aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                          <Modal.Title id='ModalHeader' className="headerTitle">Audio</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12" style={{ textAlignVertical: "center", textAlign: "center" }}>
                              <audio key={this.state.sound_url} id="playVid" controls style={{ width: '100%' }} ><source src={this.state.sound_url} /></audio>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    }


                  </div>
                } />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };
}
const MusicListing = MusicListingPage;
export default MusicListing;

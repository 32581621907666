import { SC } from '../helper/ServerCall';

export const musicService = {
    paginationData,
    storeAudio,
    getAudio,
    editAudio,
    deleteAudio,
    updateAudioStatus,
    getCategories,
    getCatsPagination,
    storeCat,
    getCat,
    editCat,
    updateCatStatus,
    deleteCat,
    getSoundsData,
    getSoundsDataRandom
   
}

function paginationData(user_id, page, filter, sort, pageSize,status,search=null) {
    return SC.getCall('music/data/'+user_id+'?page=' + page + '&filter=' + filter + '&sort=' + sort + '&pageSize=' + pageSize);
}

function getCategories() {
    return SC.getCall('music/getCategories');
}
function storeAudio(catData) {
    return SC.postCall('music/listing', catData);
}

function getAudio(id) {

    return SC.getCall('music/listing/' + id);
}

function editAudio(data, audioId) {
    data['sound_id'] = audioId;
    return SC.postCall('music/listing', data);
}

function deleteAudio(catId) {
    return SC.deleteCall('music/listing/' + catId);
}

function updateAudioStatus(catId, data) {
    data['sound_id'] = catId;
    return SC.postCall('music/listing/status',data);
}

function getCatsPagination(page, filter, sort, pageSize,status) {
    return SC.getCall('music/categories?page=' + page + '&filter=' + filter + '&sort=' + sort + '&pageSize=' + pageSize);
}


function storeCat(catData) {
    return SC.postCall('music/addCategory', catData);
}
function getCat(catId) {

    return SC.getCall('music/category/' + catId);
}
function updateCatStatus(catId, data) {
    data['category_id'] = catId;
    return SC.postCall('music/category/status',data);
}

function deleteCat(catId) {
    return SC.getCall('music/category/delete/' + catId);
}


function editCat(catData, userId) {
    return SC.postCall('music/addCategory', catData);
}

function getSoundsData(user_id, licenseId, filter) {
    // console.log('user_id',user_id)
    // return SC.getCall('music/getSoundsData/'+user_id);
    console.log('user_id', user_id, 'filter', filter);
    const params = new URLSearchParams({ licenseId, filter }).toString();
    return SC.getCall(`music/getSoundsData/${user_id}?${params}`);
}
function getSoundsDataRandom(data, filter) {
    const params = new URLSearchParams({ filter }).toString();
    return SC.getCall(`music/getSoundsDataRandom/${data}?${params}`);
}


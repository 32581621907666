import React from 'react';
import {
  Button
} from "react-bootstrap";

import SimpleReactValidator from 'simple-react-validator';

class CatFormForm extends React.Component {
	constructor(props){
		super(props);
		this.validatorForm = new SimpleReactValidator({autoForceUpdate: this, locale: 'en'});
		this.state = {
			cat: {
				...this.props.catPro,
			},
		    submitted: false,
		    formType: this.props.formTypePro 	|| '',
		};

		this.handleCat = this.handleCat.bind(this);
  		this.handleCatSubmitForm = this.handleCatSubmitForm.bind(this);
	}
	handleCat(e){
	    const { name, value, type } = e.target;
        const catState = { ...this.state.cat };
	    catState[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
	    this.setState({cat: catState});
	}
	handleCatSubmitForm(e){
	    e.preventDefault();
	    if (this.validatorForm.allValid()) {
			this.setState({submitted: true}, function(){
				this.props.handleCatSubmit(this.state.cat, this.state.formType);
			});
		} else {
			this.setState({submitted: false});
			this.validatorForm.showMessages();
			this.forceUpdate();
		}
	}
	render(){
		const { cat,formType} = this.state;
		return(
			<form onSubmit={this.handleCatSubmitForm}>
                 { formType === 'edit' &&
			        	<input type="hidden" name="category_id" className="form-control"  value={cat.id}/>
			    }
			  <div className="row">
			    <div className="col-md-8">
					<div className='form-group'>
						<label htmlFor="title">Title<span className="requiredClass">*</span></label>
						<input type="text" name="title" className="form-control"  value={cat.title} onChange={this.handleCat} />
						{this.validatorForm.message('title', cat.title, 'required|min:5')}
					</div>
			    </div>
			    <div className="col-md-4">
	              <div className=''>
	                <label className="container-check checkbox_down"> Is Active?
	                  <input type="checkbox" name="is_active" className="checkbox" value={cat.is_active? 1 : 0} onChange={this.handleCat} checked={cat.is_active?1:0} />
	                  <span className="checkmark"></span>
	                </label>
	                {/* {this.validatorPermission.message('is_active', cat.is_active, 'required')} */}
	              </div>
	            </div>
			  </div>

			  <div className="row">
			    <div className="col-md-12">
			      <div className='text-center'>
			        <Button  type='submit' bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default " >Save</Button>
			        <Button bsSize="large" bsStyle="info" onClick={()=>this.props.closeModel() } className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info " >Cancel</Button>
			      </div>
			    </div>
			  </div>
			</form>
		);
	}
}


export default CatFormForm;

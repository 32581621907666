import React, { Fragment } from 'react';
import {
  Grid,
  Row,
  Col,
  Button
} from "react-bootstrap";
import { CardHeader, CardBody, CardTitle } from 'reactstrap';
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import SimpleReactValidator from 'simple-react-validator';
import Select, { createFilter } from 'react-select';
import AsyncSelect from 'react-select/async';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { campaignService } from '../../../services/campaign';
import { adService } from '../../../services/ad';
import { splashService } from '../../../services/splash';
import { licenseService } from '../../../services/license';
import { musicService } from "services/music";
import video_clips from "../../../assets/img/video_clips.png";

import MyCaretsForm2 from './MyCaretsForm2';
import MyCaretsForm4 from './MyCaretsForm4';
import MyCaretsForm5 from './MyCaretsForm5';
import { files } from 'jszip';
import { FaSadCry } from 'react-icons/fa';

class MyCaretsForm1 extends React.Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem('user'));
    this.validatorForm = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
    this.state = {
      campaign: {
        ...OC.CAMPAIGN,
        // user_id: user.data ? user.data.id : null,
      },
      video: {
        video_title: "",
        video_description: "",
      },

      SplashData: [],
      usersList: [],
      splashList: [],
      xid: 0,
      startSplashId: [],
      splsh_title: [],
      video_url: [],

      submitted: false,
      formType: 'add',
      result: '',
      tabs: {
        form1: true,
        sound: false,
        form2: false,
        MyCaretsForm1: false,
        MyCaretsForm2: false,
        MyCaretsForm3: false,
        MyCaretsForm4: false
      },

      caretTitle: [],
      caretDesc: [],
      adId: [],
      user_id: user.data ? user.data.id : null,
      video_title: [],
      licenseList: [],
      caretId: [],
      licanseId: [],
      licenseName: [],
      planType: [],
      audioList: [],
      default_sound: [],
      previousTabs: [],
      searchTerm: '',
      searchSplash: '',
    
    };
    // console.log("campaign", this.state.campaign);


    this.handleRec = this.handleRec.bind(this);
  }

  

  handleBack = () => {
    this.setState(prevState => {

      if (prevState.previousTabs.length > 0) {
        const newPreviousTabs = [...prevState.previousTabs];
        const lastTabsState = newPreviousTabs.pop();

        return {
          tabs: lastTabsState,
          previousTabs: newPreviousTabs
        };
      } else {

        // console.log("No previous tabs to go back to");
        return prevState;
      }
    });
  }


  componentDidMount() {
    // this.getSplashCall(this.state.user_id, this.state.searchSplash);
    this.getLicenseCall(this.state.user_id);
    // this.getSoundsCall(this.state.user_id, this.state.searchTerm);
  }

  getLicenseCall = (val) => {
    licenseService.getLicenseData(val).then(res => {
      // console.log('licenseList:', res.data)
      this.setState({ licenseList: res.data });
    });
  }

  handleCaretSelect = (selectedOptionType) => {
    // console.log("handleCaretSelect ######## ", selectedOptionType)

    const filtered = this.state.licenseList.filter(obj => {
      return obj.value === selectedOptionType['value'];
    });
    // console.log("filtered", filtered)
    if (filtered) {
      localStorage.setItem('planType', filtered[0].plan.title);
      localStorage.setItem('license_id', filtered[0].value);
      let recState = { ...this.state.licanseId };
      recState[this.state.xid] = filtered[0].value;
      let recLic = { ...this.state.licenseName };
      recLic[this.state.xid] = filtered[0].label;

      let resPlanType = { ...this.state.planType };
      resPlanType[this.state.xid] = filtered[0].plan.title;
      // console.log("resPlanType", resPlanType)
      this.setState({ licanseId: recState, licenseName: recLic, planType: resPlanType },
        () => {
          this.getSoundsCall(this.state.user_id, recState[this.state.xid], this.state.searchTerm);
          this.getSplashCall(this.state.user_id, recState[this.state.xid], this.state.searchSplash);
          
        }
      );
      

    }
  };

  handleRec(e) {
    const { name, value, type } = e.target;
    const recState = { ...this.state.video };
    recState[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
    this.setState({ video: recState });
  }

  handleSearchSplashChange = (e) => {
    const searchSplash = e.target.value;
    this.setState({ searchSplash });
    this.getSplashCall(this.state.user_id, this.state.licanseId, searchSplash);
  };

  getSplashCall = (val, licenseId, searchSplash) => {
    splashService.getSplashes(val, licenseId, searchSplash).then(res => {
      // console.log('splashList:', res.data)
      this.setState({ splashList: res.data });
    });
  }

  handleSplashSelect = (selectedOptionType) => {
    // console.log("selectedOptionType", selectedOptionType)

    const filtered = this.state.splashList.filter(obj => {
      return obj.value === selectedOptionType['value'];
    });

    if (filtered) {
      let recState = { ...this.state.startSplashId };
      recState[this.state.xid] = filtered[0].value;

      // console.log("recState", recState)
      this.setState({ startSplashId: recState, });
    }
  };

  handleNext = () => {
    this.setState(prevState => ({
      previousTabs: [...prevState.previousTabs, prevState.tabs],
      tabs: {
        form1: false,
        sound: false,
        form2: false,
        MyCaretsForm1: false,
        MyCaretsForm2: true,
        MyCaretsForm3: false,
        MyCaretsForm4: false
      }
    }));
  }


  getUsersCall = async (input) => {
    const res = await campaignService.getUsers(input);
    // console.log('getUsersCall', res.data)
    this.setState({ usersList: res.data });
    return res.data;
  }

  handleSearchChange = (e) => {
    const searchTerm = e.target.value;
    this.setState({ searchTerm });
    this.getSoundsCall(this.state.user_id, this.state.licanseId, searchTerm);
  };

  getSoundsCall = (val, licenseId, searchTerm) => {
    musicService.getSoundsData(val, licenseId, searchTerm).then((res) => {
      // console.log("audioList:", res.data);
      this.setState({ audioList: res.data });
    });
  }

  handleAudioSelect = (selectedOptionType) => {
    // console.log("selectedOptionType", selectedOptionType);

    const filtered = this.state.audioList.filter((obj) => {
      return obj.value === selectedOptionType["value"];
    });
    console.log(filtered);

    if (filtered) {
      let recState = { ...this.state.default_sound };
      recState[this.state.xid] = filtered[0].value;
      // console.log("default sound id ########## ", recState);
      this.setState({ default_sound: recState });
    }
  };

  userOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.getUsersCall(inputValue));
      }, 1000);
    });


  setStateOfForm2 = (newValue, caret_id, Type, xid) => {
    // console.log("newValue", newValue ,caret_id, Type)
    let videoLength = Object.keys(caret_id).length;
    // console.log("videoLength", videoLength)
    this.setState({ caretId: caret_id });
    (( Type && Type.toLowerCase() === "corporate" || Type && Type.toLowerCase() === "influencer") && videoLength < 4) ?
      this.setState(prevState => ({
        previousTabs: [...prevState.previousTabs, prevState.tabs],
        tabs: {
          form1: false,
          sound: false,
          form2: false,
          MyCaretsForm1: false,
          MyCaretsForm2: false,
          MyCaretsForm3: newValue,
          MyCaretsForm4: false
        },
      }))
      :
      this.setState(prevState => ({
        previousTabs: [...prevState.previousTabs, prevState.tabs],
        tabs: {
          form1: false,
          sound: false,
          form2: false,
          MyCaretsForm1: false,
          MyCaretsForm2: false,
          MyCaretsForm3: false,
          MyCaretsForm4: newValue
        },
      }));

  }

  setStateOfForm3 = (newValue, ad_id) => {
    this.setState({ adId: ad_id });
    this.setState(prevState => ({
      previousTabs: [...prevState.previousTabs, prevState.tabs],
      tabs: {
        form1: false,
        sound: false,
        form2: false,
        MyCaretsForm1: false,
        MyCaretsForm2: false,
        MyCaretsForm3: false,
        MyCaretsForm4: newValue
      },
    }));
  }

  handleNextCaret = () => {

    if (this.validatorForm.allValid() && (this.state.error === '' || this.state.error == null)) {

      this.setState(prevState => ({
        previousTabs: [...prevState.previousTabs, prevState.tabs],
        tabs: {
          form1: false,
          form2: true,
          sound: false,
          MyCaretsForm1: false,
          MyCaretsForm2: false,
          MyCaretsForm3: false,
          MyCaretsForm4: false
        },
      }));
    } else {
      // this.setState({ submitted: false });
      this.validatorForm.showMessages();
      this.forceUpdate();
    }
  }

  
   handleNextToSound = () => {
    this.setState(prevState => ({
      previousTabs: [...prevState.previousTabs, prevState.tabs],
      tabs: {
        form1: false,
        form2: false,
        sound: true,
        MyCaretsForm1: false,
        MyCaretsForm2: false,
        MyCaretsForm3: false,
        MyCaretsForm4: false
      }
    }))
  }

  handleNextSplash = () => {

    this.state.planType[0] === "individual" ? (

      this.setState(prevState => ({
        previousTabs: [...prevState.previousTabs, prevState.tabs],
        tabs: {
          form1: false,
          sound: false,
          form2: false,
          MyCaretsForm1: false,
          MyCaretsForm2: true,
          MyCaretsForm3: false,
          MyCaretsForm4: false
        }
      }))
    ) : (
      this.setState(prevState => ({
        previousTabs: [...prevState.previousTabs, prevState.tabs],
        tabs: {
          form1: false,
          sound: false,
          form2: false,
          MyCaretsForm1: true,
          MyCaretsForm2: false,
          MyCaretsForm3: false,
          MyCaretsForm4: false
        }
      }))
    )
  }



  render() {
    const { campaign, formType, tabs, xid, licenseList, video, form2, form1, sound } = this.state;
    const filterConfig = {
      ignoreCase: true,
      matchFrom: 'start',
    };
    return (
      <Fragment>
        <div className="innerCustomContainer mt-2-5">
          <Grid fluid>
            <div ctTableResponsive className="mb-lg">
              <div className="w-100 pb-1 relative">
                {/*======= Backrendering ========= */}
                <div>
                  {!this.state.tabs.form1 && (
                    <Button className="backBtn px-5 font-boldF text-16" onClick={this.handleBack}>
                      Back
                    </Button>
                  )}
                  <div>
                    {this.state.tabs.form1 && (
                      <div>
                        {form1}
                      </div>
                    )}
                     {this.state.tabs.form2 && (
                      <div>
                        {form2}
                      </div>
                    )}
                    {this.state.tabs.sound && (
                      <div>
                        {sound}
                      </div>
                    )}
                    {this.state.tabs.MyCaretsForm1 && (
                      <div>
                        {MyCaretsForm1}
                      </div>
                    )}
                    {this.state.tabs.MyCaretsForm2 && (
                      <div>
                        {MyCaretsForm2}
                      </div>
                    )}
                    {this.state.tabs.MyCaretsForm3 && (
                      <div>
                        {MyCaretsForm4}
                      </div>
                    )}
                    {this.state.tabs.MyCaretsForm4 && (
                      <div>
                        {MyCaretsForm5}
                      </div>
                    )}
                  </div>

                </div>
                {/* //////////////impliment new Design for Campaigns 26-1-2024 /////////////// */}

                {this.state.tabs.form1 && (
                  <div>
                    <div
                      className={`flexElem gap-2 items-center ${!this.state.tabs.form1 && "backBtnFix"}`}
                      style={{ marginBottom: "20px" }}
                    >
                      <h3 className="secHeading m-0">Video Details</h3>
                      {/* <div className="flexElem ml-auto gap-2" style={{ gap: 10 }}>
                        <Button
                          type="submit"
                          className="btnDark px-5 font-boldF text-16"
                          onClick={() => this.handleNextToSound()}
                        >
                          Proceed to Next
                        </Button>
                      </div> */}
                    </div>

                    <form className='innerScrollWrap'>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="video_title">
                              Video Title<span className="requiredClass">*</span>
                            </label>
                            <input
                              type="text"
                              name="video_title"
                              className="form-control"
                              value={video.video_title}
                              onChange={this.handleRec}
                            />
                            {
                              // formType === 'add' &&
                              this.validatorForm.message(
                                "video_title",
                                video.video_title,
                                "required||max:250"
                              )
                            }
                          </div>
                          <div className="form-group">
                            <label htmlFor="video_description">
                              Description<span className="requiredClass">*</span>
                            </label>
                            <textarea
                              name="video_description"
                              className="form-control"
                              onChange={this.handleRec}
                            >
                              {video.video_description}
                            </textarea>
                            {formType === "add" &&
                              this.validatorForm.message(
                                "video_description",
                                video.video_description,
                                "required"
                              )}
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="flexElem gap-2" style={{ gap: 10 }}>
                      <Button
                        type="submit"
                        className="btnDark px-5 font-boldF text-16 w-autoF"
                        onClick={() => this.handleNextCaret()}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                )}

              {this.state.tabs.form2 && (
                  <div>
                    <div
                      className="flexElem gap-2 items-center backBtnFix"
                      style={{ marginBottom: "20px" }}
                    >
                      <h3 className="secHeading m-0">Select Caret</h3>
                    </div>
                    <div className="flexElem flexWrap gap25 innerScrollWrap">
                      {licenseList &&
                        licenseList.map((license, index) => (
                          <div
                            key={index}
                            className={`selectCaret ${this.state.licanseId[this.state.xid] === license.value
                              ? "selectedCaret"
                              : ""
                              }`}
                            onClick={() => this.handleCaretSelect(license)}
                          >
                            <h5 className="m-0">{license.label}</h5>
                          </div>
                        ))}
                    </div>
                    <div className="flexElem pl-btn-fix gap-2" style={{ gap: 10 }}>
                      <Button
                        type="submit"
                        className="btnDark px-5 font-boldF text-16 w-autoF"
                        disabled={this.state.licanseId.length === 0}
                        onClick={() => this.handleNextToSound()}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                )}


                {this.state.tabs.sound && (
                  <div>
                    <div className="">
                      <div
                        className="flexElem gap-2 items-center"
                        style={{ marginBottom: "20px" }}
                      >
                        <h3 className="secHeading m-0">Select Audio</h3>
                        <div className="ml-auto w-25">
                        <input
                          type="text"
                          placeholder="Search audio..."
                          value={this.state.searchTerm}
                          onChange={this.handleSearchChange}
                          className="form-control"
                        />
                      </div>
                      </div>

                      <div className="row mb-2 innerScrollWrap">
                        {this.state.audioList.map((audio, index) => (
                          <div className='col-12 col-md-6 col-lg-4 mb-2'>
                            <div className='audioBG'>
                            <div className="splashWrap1">
                              <div className="flexElem gap10p alignCenter mb-1">
                            {
                              audio.image_url?
                              (<img src={audio.image_url} className="roundedFull audioAVT" alt="Audio" />):
                              (
                                <img src={video_clips} className="roundedFull audioAVT" alt="Audio" />
                                
                              )
                            }
                            <div className='flexElem flexColumn gap5'>
                                <h6
                                  className="text-primary m-0"
                                  style={{
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Audio Title:
                                </h6>
                                <h6
                                  className="text-primary m-0"
                                  style={{
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {audio.label}
                                </h6>
                              </div>
                              </div>
                              <audio src={audio.sound_url} className="w-100" controls style={{ height: 48 }}/>
                              <div
                                className="adContent flexElem flexColumn mt-auto gap10p"
                                style={{ wordBreak: "break-word" }}
                              ></div>
                            </div>
                            <button
                              className={`mt-1 selectBtn ${this.state.default_sound[this.state.xid] ===
                                audio.value
                                ? "bcCelectBtn"
                                : "selectBtn"
                                }`}
                              onClick={() => this.handleAudioSelect(audio)}
                            >
                              Select
                            </button>
                          </div>
                          </div>
                        ))}
                      </div>
                      <div className="flexElem gap-2 pl-btn-fix" style={{ gap: 10 }}>
                        <Button
                          type="submit"
                          className="btnDark px-5 font-boldF text-16 w-autoF"
                          // disabled={this.state.default_sound.length === 0}
                          onClick={(e) => this.handleNextSplash(e)}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  </div>
                )}

                
                {/* ////////////////////////////// */}

                {tabs.MyCaretsForm1 && (
                  <div className="">
                    <div
                      className="flexElem gap-2 items-center"
                      style={{ marginBottom: "20px" }}
                    >
                      <h3 className="secHeading m-0">
                        Select Start Splash
                      </h3>
                      <div className="ml-auto w-25">
                        <input
                          type="text"
                          placeholder="Search splash..."
                          value={this.state.searchSplash}
                          onChange={this.handleSearchSplashChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                   
                    <div className="gridSplash innerScrollWrap">
                      {this.state.splashList.map((splash, index) => (
                        <div>
                          <div className="splashWrap">
                            {/* {(index % 8 === 0)} */}
                            {/* <video src={splash.video_url} className="adImage" /> */}
                            <img src={splash.image_url} className="adImage" />
                            <div
                              className="adContent flexElem flexColumn mt-auto gap10p"
                              style={{ wordBreak: "break-word" }}
                            >
                              <div style={{ marginTop: "au to" }}>
                                <h6
                                  className="text-primary m-0"
                                  style={{
                                    textTransform: "capitalize",
                                    fontSize: 11,
                                  }}
                                >
                                  Splash Title:
                                </h6>
                                <h5 className="m-0">{splash.label}</h5>
                              </div>
                              <div></div>
                            </div>
                          </div>
                          <button
                            className={`mt-1 selectBtn ${this.state.startSplashId[this.state.xid] ===
                              splash.value
                              ? "bcCelectBtn"
                              : "selectBtn"
                              }`}
                            onClick={() => this.handleSplashSelect(splash)}
                          >
                            Select
                          </button>
                          {/* {(index % 3 === 2 || index === this.state.splashList.length - 1)} */}
                        </div>
                      ))}
                    </div>
                    <div className="flexElem gap-2 pl-btn-fix" style={{ gap: 10 }}>
                      <Button
                        type="submit"
                        className="btnDark px-5 font-boldF text-16 w-autoF"
                        onClick={() => this.handleNext()}
                        // disabled={this.state.startSplashId.length === 0}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                )}

                {tabs.MyCaretsForm2 && (
                  <MyCaretsForm2
                    user_id={this.state.campaign.user_id}
                    license_id={this.state.licanseId[xid]}
                    video={this.state.video}
                    sound_id={this.state.default_sound[xid]}
                    license_name={this.state.licenseName[xid]}
                    setStateOfForm2={this.setStateOfForm2}
                  />
                )}

                {tabs.MyCaretsForm3 && (
                  <MyCaretsForm4
                    id={this.state.result.id}
                    user_id={this.state.campaign.user_id}
                    setStateOfForm3={this.setStateOfForm3}
                  />
                )}

                {tabs.MyCaretsForm4 && (
                  <MyCaretsForm5
                    start_Splash_Id={this.state.startSplashId[xid]}
                    ad_id={this.state.adId[xid]}
                    video_id={this.state.caretId}
                    id={this.state.result.id}
                    user_id={this.state.campaign.user_id}
                    license_id={this.state.licanseId[xid]}
                    video={this.state.video}
                    sound_id={this.state.default_sound[xid]}

                  />
                )}
              </div>
            </div>
          </Grid>
        </div>
      </Fragment>
    );

  }
}


export default MyCaretsForm1;

import React, { Component } from "react";
import { NavItem, Nav } from "react-bootstrap";
import { connect } from 'react-redux';
import logoutIcon from "assets/img/logout.svg";
import { CLMUserActions } from 'redux/actions/CLM-user-action';
import Pusher from "pusher-js";
import { authHeader } from 'helper/authHeader';
import { baseUrl } from 'helper/ApiCall';
import { helperFunctions as HF } from 'helper/helperFunctions';
import { alertActions } from 'redux/actions/alert-actions';
import userIcon from '../../../assets/img/user.svg';
import { history } from 'helper/history';

class HeaderLinksPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      adminSession: null
    }
  }
  componentDidMount() {
    const adminSession = localStorage.getItem("admin_session");
    this.setState({ adminSession });

    Pusher.logToConsole = true;
    const pusher = new Pusher('817d7356d8726f318e88', {
      cluster: 'ap2',
      forceTLS: true,
      authEndpoint: baseUrl + 'pusher/auth',
      auth: {
        headers: authHeader()
      }
    });

    const channel = pusher.subscribe('private-notification');
    channel.bind('pusher:subscription_succeeded', data => {
      console.log('successfully subscribed!', data);
    });
    channel.bind('broadCast-notification', data => {
      console.log('type', data.notification_type);
      if (data.notification_type === "notification") {
        if (!HF.checkLoggedInUserById(data.created_by) && HF.hasRole(data.role))
          this.props.messageCall(data);
      } else if (data.notification_type === "logout") {
        localStorage.removeItem('user');
        history.push('/CLM/CLM-login');
        // history.push('/');
      }

    });
  }

  handleRestoreAdminSession = () => {
    const adminSession = localStorage.getItem("admin_session");

    if (adminSession) {
        // Restore the admin session
        localStorage.setItem("user", adminSession);
        localStorage.removeItem("admin_session"); // Clean up temporary session

        // Redirect back to admin dashboard
        window.location.href = "/Admin/clmUsers";
    } else {
        console.error("No admin session found.");
        alert("Unable to restore admin session.");
    }
};
  render() {
    const { loggedIn } = this.props;
    const user = JSON.parse(localStorage.getItem('user'));
    let nameCapitalized;
    if (user) {
      nameCapitalized = user.data.name // user.data.name.charAt(0).toUpperCase() + user.data.name.slice(1);
    } else {
      nameCapitalized = null;
    }
    return (
      <div>
        <Nav pullRight>
          {loggedIn &&
            <React.Fragment>
              <div className="dropdown headerLogin">
                <div style={{ color: '#fff' }} className='dropdown-toggle' data-toggle="dropdown">
                  <img src={userIcon} alt="user icon" className="headerUserIcon" />
                  <span style={{ marginLeft: '5px' }}>{nameCapitalized} Licenser</span>
                  <span className="caret"></span>

                </div>
                <ul className="dropdown-menu text-center topNav" style={{ backgroundColor: '#2f4576' }}>
                  <NavItem href="/CLM/EditProfile">User Profile</NavItem>
                  <NavItem href="/CLM/cards">Payement Methods</NavItem>
                  <NavItem href="/CLM/changePassword">Change Password</NavItem>
                  {this.state.adminSession &&
                  <NavItem onClick={() => {this.handleRestoreAdminSession() }}>Back to admin</NavItem>}
                  <NavItem onClick={() => { this.props.onLogoutClick() }}>Logout {/*<img src={logoutIcon} alt="logoutIcon" />  */}</NavItem>
                </ul>
              </div>
            </React.Fragment>
          }
        </Nav>
      </div>
    );
  }
};


function mapStateToProps(state) {
  const { userAdmin, loggedIn } = state.CLMReducer;
  return {
    userAdmin, loggedIn
  };
};
function mapDispatchToProps(dispatch) {
  return dispatch => {
    return {
      onLogoutClick: () => { dispatch(CLMUserActions.logout()) },
      messageCall: (message) => { dispatch(alertActions.success(message, 'notification')) }

    }
  }
}
const HeaderLinks = connect(mapStateToProps, mapDispatchToProps)(HeaderLinksPage);
export default HeaderLinks;

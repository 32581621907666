import axios from 'axios';
// import React, { Component } from "react";
// import { connect } from 'react-redux';
// import store from "../redux/store/index";
import { alertActions } from '../redux/actions/alert-actions';
//import { adminUserActions } from 'redux/actions/admin-user-action';
//import { frontEndUserActions } from '../redux/actions/front-end-action';
// import store from "../redux/store/index";
export const int = {
  setupInterceptors
}

function setupInterceptors(store, history, props) {
  const { dispatch } = store;
  axios.interceptors.response.use(response => {
    return response.data;
  },
    (error) => {
      if (axios.isCancel(error)) {
          
      } else {
        if (error.response.status === 401) {
          // store.dispatch(logoutUser());
          localStorage.removeItem('user');
          // dispatch(frontEndUserActions.logout());
          // dispatch(adminUserActions.logout(false));
        }

        // if (error.response.status === 404) {
        //   // console.log('404');
        //    //history.push('/not-found');
        // }
        // console.log(error.config)
        if ('errors' in error.response.data) {
          return Promise.reject(error.response.data.errors);
        } else {
          return Promise.reject(error.response.data.error);
        }
      }
    });

  axios.interceptors.request.use((config) => {
    // const { dispatch } = props;
    dispatch(alertActions.clear());

    return config;
  },
    (error) => {
      console.log('error 2', error);
      return Promise.reject(error);
    });
}
import React from "react";
import Error from "../../assets/img/error.jpg";
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import {
  Button
} from "react-bootstrap";
import { alertActions } from 'redux/actions/alert-actions';

// import { history } from 'helper/history';
class ErrorBoundaryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      message: null,
      errorInfo: null,
    };
    this.jumpToDashboard = this.jumpToDashboard.bind(this);
  }

  jumpToDashboard() {
    console.log(this.state.error);
    this.setState({ error: false, message: "" }, function () {
      window.location.assign("/Advertiser/Dashboard");
      //history.push('/Advertiser/dashboard');
    });
  }
  componentDidCatch(err, errInfo) {
    console.log('error 123', err);
    // console.log('error information', errInfo);
    // console.log('error information', errInfo.componentStack.toString());
    this.setState({
      error: true,
      message: err,
      errInfo: errInfo,
    }, () => {
      console.log('message', this.state);
      this.props.errorNotifyCall({ error: this.state.message });
    });
  }

  render() {
    if (this.state.error) {
      return (
        <div className="content">
          <div>
            <img
              src={Error}
              style={{ height: 'auto', width: '100%' }}
              alt="error handler"
            />
          </div>
          <div className="text-center">
            <span style={{ textAlign: "center", fontSize: 16, padding: 10 }}>
              Something Went Wrong. Try Again Later
            </span>
            <Button type='button' className="outOfTableButton customButton btn btn-info" onClick={this.jumpToDashboard} >Dashboard</Button>
          </div>
          {
            /*
            <details style={{ textAlign: "center", fontSize: 8, padding: 10 }}>
                {this.state.message}
            </details>
            */
          }
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

// const styles = {
//   errorContainer: {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center"
//   }
// };



const mapDispatchToProps = dispatch => {
  return ({

    errorNotifyCall: (error) => { return dispatch(alertActions.errorNotify(error)) },

  });
};

function mapStateToProps(state) {

  return {};
};

const ErrorBoundary = connect(mapStateToProps, mapDispatchToProps)(ErrorBoundaryPage);
export default ErrorBoundary;

import { adminConstants } from '../constant/admin-constant';
import { settingsService } from '../../services/settings';
import { alertActions } from './alert-actions';
import { frontEndUserConstants } from '../constant/front-end-constant';
import { toast } from 'react-toastify';
import { generalActions } from './generalActions';
var FileSaver = require('file-saver');

export const settingsActions = {
    getAgencyType,
    getCountries,
    getStatesPrimary,
    getStatesSecondary,
    getStates,
    getSalesRep,
    getAgencySalesRep,
    getProgramType,
    getMainProgramType,
    getProgramItem,
    getInvoicing,
    getSettingValue,
    getUserPreferences,
    storeOrUpdateUserPreferences,
    clearSettings,
    getSettingValueByModelAndId,
    storeSettingsData,
    updateSettingsData,
    getAllAddtionalItems,
    storeItem,
    getSingleAdditionalItem,
    updateAdditionalItem,
    deleteAdditionalService,
    showLoader,
    getShippingSettings,
    getItemTypes,
    getItemCategories,
    getInvLocations,
    getDestinations,
    imageProcessingAndDownload,
    getRanges,
    getUiSettings,
    getOrderStatuses,
    getDefaultPreference,
    storeAdvanceSearch,
    getInvoicePeriod
};


function getAgencyType() {
    return dispatch => {
        dispatch(request());
        settingsService.getAgencyType()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_AGENCY_TYPE_REQUEST } }
    function success(response) { return { type: adminConstants.GET_AGENCY_TYPE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_AGENCY_TYPE_FAILURE, payload: error } }
}

function getCountries() {
    return dispatch => {
        dispatch(request());
        settingsService.getCountries()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_COUNTRIES_REQUEST } }
    function success(response) { return { type: adminConstants.GET_COUNTRIES_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_COUNTRIES_FAILURE, payload: error } }
}

function getStatesPrimary(countryId) {
    return dispatch => {
        dispatch(request());
        settingsService.getStatesPrimary(countryId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_PRIMARY_STATES_REQUEST } }
    function success(response) { return { type: adminConstants.GET_PRIMARY_STATES_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_PRIMARY_STATES_FAILURE, payload: error } }
}

function getStates(countryId, type) {
    return dispatch => {
        dispatch(request());
        settingsService.getStates(countryId, type)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_STATES_REQUEST } }
    function success(response) { return { type: adminConstants.GET_STATES_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_STATES_FAILURE, payload: error } }
}

function getStatesSecondary(countryId) {
    return dispatch => {
        dispatch(request());
        settingsService.getStatesSecondary(countryId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_SECONDARY_STATES_REQUEST } }
    function success(response) { return { type: adminConstants.GET_SECONDARY_STATES_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_SECONDARY_STATES_FAILURE, payload: error } }
}

function getSalesRep() {
    return dispatch => {
        dispatch(request());
        settingsService.getSalesRep()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_SALES_REP_REQUEST } }
    function success(response) { return { type: adminConstants.GET_SALES_REP_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_SALES_REP_FAILURE, payload: error } }
}

function getAgencySalesRep(id) {
    return dispatch => {
        dispatch(request());
        settingsService.getAgencySalesRep(id)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_AGENCY_SALES_REP_REQUEST } }
    function success(response) { return { type: adminConstants.GET_AGENCY_SALES_REP_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_AGENCY_SALES_REP_FAILURE, payload: error } }
}

function getProgramType() {
    return dispatch => {
        dispatch(request());
        settingsService.getProgramType()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_PROGRAM_TYPE_REQUEST } }
    function success(response) { return { type: adminConstants.GET_PROGRAM_TYPE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_PROGRAM_TYPE_FAILURE, payload: error } }
}

function getMainProgramType() {
    return dispatch => {
        dispatch(request());
        settingsService.getMainProgramType()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_MAIN_PROGRAM_TYPE_REQUEST } }
    function success(response) { return { type: adminConstants.GET_MAIN_PROGRAM_TYPE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_MAIN_PROGRAM_TYPE_FAILURE, payload: error } }
}

function getProgramItem(programType) {
    return dispatch => {
        dispatch(request());
        settingsService.getProgramItem(programType)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_PROGRAM_ITEM_REQUEST } }
    function success(response) { return { type: adminConstants.GET_PROGRAM_ITEM_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_PROGRAM_ITEM_FAILURE, payload: error } }
}
function getInvoicing() {
    return dispatch => {
        dispatch(request());
        settingsService.getInvoicing()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_INVOICING_REQUEST } }
    function success(response) { return { type: adminConstants.GET_INVOICING_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_INVOICING_FAILURE, payload: error } }
}

function getSettingValue(key, state_name) {
    return dispatch => {
        dispatch(request());
        settingsService.getSettingValue(key, state_name)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_SETTING_VALUE_REQUEST } }
    function success(response) { return { type: adminConstants.GET_SETTING_VALUE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_SETTING_VALUE_FAILURE, payload: error } }
}

function getUserPreferences() {
    return dispatch => {
        dispatch(request());
        settingsService.getUserPreferences()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_USER_PREFERENCE_REQUEST } }
    function success(response) { return { type: adminConstants.GET_USER_PREFERENCE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_USER_PREFERENCE_FAILURE, payload: error } }
}

function storeOrUpdateUserPreferences(preferenceData, searchId) {
    return dispatch => {
        dispatch(request());
        return settingsService.storeOrUpdateUserPreferences(preferenceData, searchId)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    return response
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    return Promise.reject(error)
                }
            );
    }

    function request() { return { type: adminConstants.STORE_PREFERENCE_REQUEST } }
    function success(response) { return { type: adminConstants.STORE_PREFERENCE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_PREFERENCE_FAILURE, payload: error } }
}

function clearSettings() {
    return dispatch => {
        dispatch(request());
    }
    function request() { return { type: adminConstants.CLEAR_SETTINGS_REQUEST } }
}

function getSettingValueByModelAndId(modelName, modelId, key) {
    return dispatch => {
        dispatch(request());
        return settingsService.getSettingValueByModelAndId(modelName, modelId, key)
            .then(
                response => {
                    dispatch(success(response));
                    return response
                },
                error => {
                    dispatch(failure(error));
                    return Promise.reject(error)
                }
            );
    }

    function request() { return { type: adminConstants.GET_SETTING_BY_MODEL_ID_REQUEST } }
    function success(response) { return { type: adminConstants.GET_SETTING_BY_MODEL_ID_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_SETTING_BY_MODEL_ID_FAILURE, payload: error } }
}

function updateSettingsData(model_id, key, settingData) {
    return dispatch => {
        dispatch(request());
        return settingsService.updateSettingsData(model_id, key, settingData)
            .then(
                response => {
                    dispatch(success(response));
                    return response
                },
                error => {
                    dispatch(failure(error));
                    return Promise.reject(error)
                }
            );
    }

    function request() { return { type: adminConstants.UPDATE_SETTINGS_DATA_REQUEST } }
    function success(response) { return { type: adminConstants.UPDATE_SETTINGS_DATA_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.UPDATE_SETTINGS_DATA_FAILURE, payload: error } }
}

function storeSettingsData(settingsData) {
    return dispatch => {
        dispatch(request());
        return settingsService.storeSettingsData(settingsData)
            .then(
                response => {
                    dispatch(success(response));
                    return response
                },
                error => {
                    dispatch(failure(error));
                    return Promise.reject(error)
                }
            );
    }

    function request() { return { type: adminConstants.STORE_SETTINGS_DATA_REQUEST } }
    function success(response) { return { type: adminConstants.STORE_SETTINGS_DATA_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_SETTINGS_DATA_FAILURE, payload: error } }
}

function getAllAddtionalItems(page, filter, sort, pageSize) {
    return dispatch => {
        settingsService.getAllAddtionalItems(page, filter, sort, pageSize).then(
            response => {
                dispatch(success(response))
            },
            error => {
                dispatch(failure(error))
            }
        )
    }
    function success(response) { return { type: adminConstants.GET_ADDITIONAL_SERVICES_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ADDITIONAL_SERVICES_FAILURE, payload: error } }
}

function storeItem(data) {
    return dispatch => {
        return settingsService.storeItem(data).then(
            response => {
                dispatch(alertActions.success(response.message))
                dispatch(getAllAddtionalItems())
                return response;
            },
            error => {
                dispatch(alertActions.error(error))
                return Promise.reject(error)
            }
        )
    }
    // function success(response) { return { type: adminConstants.GET_ADDITIONAL_SERVICES_SUCCESS, payload: response } }
    // function failure(error) { return { type: adminConstants.GET_ADDITIONAL_SERVICES_FAILURE, payload: error } }
}

function getSingleAdditionalItem(id) {
    return dispatch => {
        settingsService.getSingleAdditionalItem(id).then(
            response => {
                dispatch(success(response))
            },
            error => {
                dispatch(failure(error))
            }
        )
    }
    function success(response) { return { type: adminConstants.GET_SINGLE_ADDITIONAL_ITEM_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_SINGLE_ADDITIONAL_ITEM_FAILURE, payload: error } }
}

function updateAdditionalItem(data, editableItem) {
    return dispatch => {
        return settingsService.updateAdditionalItem(data, editableItem.id).then(
            response => {
                dispatch(success(response, editableItem.index))
                dispatch(alertActions.success(response.message))
                return response
            }, error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
                return Promise.reject(error)
            }
        )
    }
    function success(response, index) { return { type: adminConstants.UPDATE_SINGLE_ADDITIONAL_ITEM_SUCCESS, payload: response, index: index } }
    function failure(error) { return { type: adminConstants.UPDATE_SINGLE_ADDITIONAL_ITEM_FAILURE, payload: error } }
}

function deleteAdditionalService(id, data) {
    return dispatch => {
        return settingsService.deleteAdditionalService(id).then(
            response => {
                dispatch(success(data))
                dispatch(alertActions.success(response.message))
            }, error => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
            }
        )
    }
    function success(data) { return { type: adminConstants.DELETE_SINGLE_ADDITIONAL_ITEM_SUCCESS, payload: data } }
    function failure(error) { return { type: adminConstants.DELETE_SINGLE_ADDITIONAL_ITEM_FAILURE, payload: error } }
}

function showLoader(status) {
    return dispatch => {
        dispatch(success());
    }
    function success() { return { type: adminConstants.SET_GLOBAL_LOADER, payload: status } }
}

function getShippingSettings() {
    return dispatch => {
        settingsService.getShippingSettings().then(
            res => {
                dispatch(success(res))
            },
            err => {
                dispatch(failure(err))
            }
        )
    }
    function success(response) { return { type: frontEndUserConstants.GET_SHIPPING_SETTINGS_SUCCESS, payload: response } }
    function failure(error) { return { type: frontEndUserConstants.GET_SHIPPING_SETTINGS_FAILURE, payload: error } }
}

function getItemCategories() {
    return dispatch => {
        dispatch(request());
        settingsService.getItemCategory()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_ITEM_CATEGORY_REQUEST } }
    function success(response) { return { type: adminConstants.GET_ITEM_CATEGORY_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ITEM_CATEGORY_FAILURE, payload: error } }
}

function getItemTypes() {
    return dispatch => {
        dispatch(request());
        settingsService.getItemType()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_ITEM_TYPE_REQUEST } }
    function success(response) { return { type: adminConstants.GET_ITEM_TYPE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_ITEM_TYPE_FAILURE, payload: error } }
}

function getInvLocations() {
    return dispatch => {
        dispatch(request());
        settingsService.getInvLocations()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_INV_LOCATIONS_REQUEST } }
    function success(response) { return { type: adminConstants.GET_INV_LOCATIONS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_INV_LOCATIONS_FAILURE, payload: error } }
}


function getDestinations() {
    return dispatch => {
        dispatch(request());
        settingsService.getDestinations()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_DESTINATIONS_REQUEST } }
    function success(response) { return { type: adminConstants.GET_DESTINATIONS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_DESTINATIONS_FAILURE, payload: error } }
}

function imageProcessingAndDownload(processCase, data) {
    return dispatch => {
        let toastIdImg = null;
        if (toastIdImg === null) {
            toastIdImg = toast('Images processing in Progress ', {
                autoClose: false,
                //progress: percentCompleted / 100,
                type: toast.TYPE.INFO,
                position: "bottom-right",
            });
        }
        return settingsService.imageProcessingAndDownload(processCase, data).then(
            response => {
                toast.dismiss(toastIdImg);
                FileSaver.saveAs(response.filePath, 'pw_slate_with_credentials.jpg');
                dispatch(success(response));
                dispatch(alertActions.success(response.message))
                return response;
            },
            error => {
                toast.dismiss(toastIdImg);
                dispatch(alertActions.error(error))
                dispatch(failure(error));
            }
        )
    }


    // function request() { return { type: adminConstants.IMAGE_PROCESSING_DOWNLOAD_FILE_REQUEST } }
    function success(response) { return { type: adminConstants.IMAGE_PROCESSING_DOWNLOAD_FILE_SUCCESS } }
    function failure(error) { return { type: adminConstants.IMAGE_PROCESSING_DOWNLOAD_FILE_FAILURE } }
}

function getRanges() {
    return dispatch => {
        dispatch(request());
        settingsService.getRanges()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_RANGES_REQUEST } }
    function success(response) { return { type: adminConstants.GET_RANGES_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_DESTINATIONS_FAILURE, payload: error } }
}

function getUiSettings() {
    return dispatch => {
        dispatch(request());
        settingsService.getUiSettings()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_UI_SETTINGS_REQUEST } }
    function success(response) { return { type: adminConstants.GET_UI_SETTINGS_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_UI_SETTINGS_FAILURE, payload: error } }
}

function getOrderStatuses() {
    return dispatch => {
        settingsService.getOrderStatuses().then(
            res => {
                dispatch(generalActions.success(adminConstants.GET_ORDER_STATUSES_SUCCESS, res))
            },
            err => {
                dispatch(generalActions.failure(adminConstants.GET_ORDER_STATUSES_FAILURE, err))
            }
        )
    }
}
function getDefaultPreference() {
    return dispatch => {
        dispatch(request());
        settingsService.getDefaultPreference()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_DEFAULT_USER_PREFERENCE_REQUEST } }
    function success(response) { return { type: adminConstants.GET_DEFAULT_USER_PREFERENCE_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_DEFAULT_USER_PREFERENCE_FAILURE, payload: error } }
}

function storeAdvanceSearch(data) {
    return dispatch => {
        dispatch(request());
        settingsService.storeAdvanceSearch(data)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.STORE_ADVANCE_SEARCH_REQUEST } }
    function success(response) { return { type: adminConstants.STORE_ADVANCE_SEARCH_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.STORE_ADVANCE_SEARCH_FAILURE, payload: error } }
}

function getInvoicePeriod() {
    return dispatch => {
        dispatch(request());
        settingsService.getInvoicePeriod()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: adminConstants.GET_INVOICING_PERIOD_REQUEST } }
    function success(response) { return { type: adminConstants.GET_INVOICING_PERIOD_SUCCESS, payload: response } }
    function failure(error) { return { type: adminConstants.GET_INVOICING_PERIOD_FAILURE, payload: error } }
}

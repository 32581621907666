

import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover,
  Button
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import { RiVideoLine } from 'react-icons/ri';
import { FaRegImage } from "react-icons/fa";
import { FaFileDownload } from "react-icons/fa";
import { FaFileExcel } from "react-icons/fa";
import { toast } from 'react-toastify';
import { FiSettings } from "react-icons/fi";
import { FaAd } from "react-icons/fa";
import { FaRegFileAudio } from "react-icons/fa";
import { RiSmartphoneLine } from "react-icons/ri";
import { RiSmartphoneFill } from "react-icons/ri";
import LicenseForm from "./LicenseForm";
import { hasPermission } from 'helper/hasPermission';
import Confirmalertfordelete from '../../ThemeComponents/confirmAlertForDelete';
import { adminLabels } from '../../../redux/constant/admin-label-constant';
import OpenModalButton from '../../ThemeComponents/openModelButton';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import Pagination from '../../ThemeComponents/Pagination';
import DatePicker from "react-datepicker";
import { splashService } from '../../../services/splash';
import { licenseService } from '../../../services/license';

import ReactValidator from "simple-react-validator";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "react-switch";
import Eye from "../../../assets/img/eye.svg"
import Start from "../../../assets/img/spStartIcon.svg"
import End from "../../../assets/img/spEndIcon.svg"
import Splash from "../../../assets/img/splashFlow.svg";
import CaretLogo from "../../../assets/img/logo_frontEnd.png";
import fileUploadIcon from "../../../assets/img/upload.svg";
import { filter } from 'lodash';
import { Header } from 'react-bootstrap/lib/Modal';
import { duration } from 'moment';

import Test from "./ImageCroping";



var cloneDeep = require('lodash.clonedeep');
var Modal = require('react-bootstrap-modal');
const moment = window.moment;

class LicenseListingPage extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props.match.params;
    const user = JSON.parse(localStorage.getItem('user'));
    this.state = {
      addModalForm: false,
      editModalForm: false,
      openVideoModal: false,
      campaignModal: false,
      campaignData: OC.CAMPAIGN,
      video_url: '',
      selectedVideo: [],
      filter: id,
      sorted: '',
      filtered: '',
      // search: { a: 1 },
      search:{
        fromDate : '',
        toDate: '',
        status_id:false,
        // is_reported:false
      },
      pageSize: 10,
      userID: user.data ? user.data.id : null,
      imageFile: null,
      licenseId:'',

      error: '',
      errorImg: '',
			videoPreviewUrl: this.props.recPro ? this.props.recPro.videoFile : null,
			submitted: false,
			formType: this.props.formTypePro || '',
			allowedFormatsStringMime: 'image/jpeg, image/png',
			allowedFormatsStringExt: 'jpg, png',
			allowedFormatsVideoMime: 'video/x-flv, video/mp4',
			allowedFormatsVideoExt: 'flv, mp4',
			videoFileName: '',
			videoAdded: false,
			videoDuration: '',
			imagePreviewUrl: null,
      // upgrage
      modalOpen: false,
      userId: "",
      licenseId: "",
      newPlanId: "",
      newDuration: "",
      selectedLicense: '',
      caretPricing:[],
      newPrice: '',
      isDragging: false

    };

    this.editRecord = this.editRecord.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
    this.deleteConfirmModal = this.deleteConfirmModal.bind(this);
    this.getPaginationData = this.getPaginationData.bind(this);
    this.filterSearchHandle = this.filterSearchHandle.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.getUsersCall = this.getUsersCall.bind(this);
    this.logoModal = this.logoModal.bind(this);
    this.closeModal = HF.closeModal.bind(this);
    this.callFunction = this.callFunction.bind(this);
  };

  callFunction(formData, formType) {
    this.setState({ campaignData: false });
  }

  logoModal(id, url) {
    this.setState({licenseId:id, imagePreviewUrl:url}, ()=>{
        this.setState({openVideoModal:true});
    });

  }
  
  submitForm(formData, formType) {
    if (formType === 'add') {
      licenseService.storeLicense(formData).then(res => {
        this.setState({ addModalForm: false });
        this.pagination.dataCall();
      });
    } else if (formType === 'edit') {
      licenseService.editLicense(formData, this.state.editRec.id).then(res => {
        this.setState({ editModalForm: false });
        this.pagination.dataCall();
      });
    }
  }

  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }

  editRecord(id) {
    licenseService.getLicense(id).then(res => {
      // console.log(res);
      this.setState({ editRec: res.data }, () => {
        this.setState({ editModalForm: true });
      });
    });
  }

  // showCampaignModal = (id, user_id) => {

  //   let cmpdata = { ...this.state.campaignData };
  //   cmpdata['user_id'] = user_id;
  //   cmpdata['ad_id'] = id;
  //   this.setState({ campaignData: cmpdata });
  //   this.setState({ campaignModal: true });
  // }


  deleteRecord(recID) {
    licenseService.deleteLicense(recID).then(res => {
      this.pagination.dataCall();
    });
  }

  deleteConfirmModal(recID) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Record" description="Are you sure to delete the Record?" onClosePro={onClose} deleteType={() => this.deleteRecord(recID)} />
        )
      }
    })
  }


  getPaginationData(page, filter, sort, pageSize, status) {
    this.setState({ pageSize: pageSize }, function () {
      licenseService.paginationData(this.state.userID, page, filter, sort, pageSize, status, this.state.search);
    });
  }

  updateStatus = recID => (e) => {
    // return false;
    licenseService.updateLicenseStatus(recID, { is_active: e.target.checked ? 1 : 0 }).then(
      response => {
        licenseService.paginationData(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
        this.pagination.dataCall();
      }
    );
  }

  updateBlocked = recID => (e) => {
    // return false;
    splashService.blockRecord(recID).then(
      response => {
        licenseService.paginationData(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
        this.pagination.dataCall();
      }
    );
  }



  

  componentDidUpdate(prevProps, prevState) {
    if (this.state.rec_status !== prevState.rec_status) {
      this.table.state.onFetchData(this.table.state, this.table.instance)
    }

  }

  paginationCall = (data) => {
    return licenseService.paginationData(this.state.userID, data.page, data.filter || this.state.filter, data.sort, data.pageSize, this.state.rec_status, this.state.search);
  }


  getUsersCall() {
    licenseService.getUsers().then(res => {
      // console.log('Users', res);
      this.setState({ users: res.data });
    });
  }

  componentDidMount() {
    // this.getUsersCall();
  }

  handleRenewalLicense = (license_id, period_end) => {
    const data = {
      license_id: license_id,
      cancel_at_period_end: period_end == 1 ? 0 : 1
    };
    // console.log("data", data)
    licenseService.renewalLicense(data).then((res) => {
        this.pagination.dataCall();
    });
  }

    handleCrop = (imageFile) => {
    // console.log('croppedImageFile on Crop', imageFile);
    // this.setState({ imageFile: croppedImageFile });
    this.setState({ imageFile });
    
  };

  setImageFile = (event) => {
		const file = event.target.files[0];
		const fileData = {};
		fileData["name"] = file["name"];
		fileData['size'] = file['size'];
		fileData['extension'] = HF.returnExtFromImage(file['name']);
		fileData['type'] = file['type'];
		fileData['lastModified'] = file['lastModified'];
		const reader = new FileReader();

    // Define allowed extensions
		const allowedExtensions = ['png', 'jpeg', 'jpg', 'gif', 'heic'];
		const fileExtension = fileData['extension']; // Get the extension
		// Check if the file extension is allowed
		if (!allowedExtensions.includes(fileExtension)) {
			this.setState({ errorImg: 'Only PNG, JPEG, JPG, and GIF, images are allowed.' });
			return;
		}
		reader.onload = () => {
			fileData['file'] = reader.result;
			const imagePreviewUrl = URL.createObjectURL(file);
      this.setState({magePreviewUrl:null})
			fileData['preview'] = imagePreviewUrl;
			this.setState({
				imagePreviewUrl: imagePreviewUrl,
				imageFile: fileData
			});
		};
		reader.readAsDataURL(file);
	};

	handleInputChange = (event) => {
		const videoName = event.target.value.split("\\").pop();
		this.setState({ videoName });
	};

  //   Drag and Drop
	handleDragOver = (event) => {
		event.preventDefault();
    if (!this.state.isDragging) {
      // console.log("DragOver");
      this.setState({ isDragging: true });
  }
	};

	handleDragLeave = (event) => {
    event.preventDefault();
		this.setState({ isDragging: false });
	};

	handleDrop = (event) => {
		event.preventDefault();
		const file = event.dataTransfer.files[0];
		if (file) {
			this.setImageFile({ target: { files: [file] } });
		}
    this.setState({ isDragging: false });
	};


  handleClearLogoState = () => {
    this.setState({
      imageFile: null,
    });
  };


  handelUploadCaretLogo = (image, userid, license_id) => {
    // console.log("image", license_id)
    const data = {
      image: image,
      user_id: userid,
      license_id:license_id
      }

    licenseService.uploadCaretLogo(data).then((res) => {
        // console.log("res", res);
        this.setState({openVideoModal: false});
        this.setState({licenseId:'', imageFile: null, imagePreviewUrl: null, videoName: ''});
        this.pagination.dataCall();
      });

  }

 

  handlefetchCurrentPricing = (original) => {
    // console.log("original@@@@@@ ", original);
    licenseService.fetchCurrentPricing(original.subscription_stripe_id)
      .then((res) => {
        // console.log("Response from API:", res.data);
        let result = res.data;
        if (!result) {
           return;
        } else {
          this.setState({ caretPricing: result, modalOpen: true, selectedLicense: original.subscription_amount, userId: original.user_id, licenseId: original.id });

        }
      })
      .catch((error) => {
        console.error("Error fetching pricing:", error);
        toast.error("No matching license found");
        this.setState({ caretPricing: null });
      });
  }

  handleLicenseChange = (e) => {
    const selectedLicense = e.target.value; // price
    const id = e.target.getAttribute('id');

    let duration;
    if (selectedLicense == this.state.caretPricing.one_year_license) {
        duration = 1;
    } else if (selectedLicense == this.state.caretPricing.two_year_license) {
        duration = 2;
    } else if (selectedLicense == this.state.caretPricing.three_year_license) {
        duration = 3;
    }

    // console.log("handleLicenseChange", selectedLicense, id, duration);
    this.setState(
      {
        selectedLicense: selectedLicense,
        newDuration: duration,
        newPlanId: id,
      },
      () => {
        this.handleCalculatePaymentDifference(); // Call after state update
      }
    );

  };

  handleCalculatePaymentDifference = () => {
    const data = {
      user_id: this.state.userId,
      license_id: this.state.licenseId,
      new_plan_id: this.state.newPlanId,
      new_duration: this.state.newDuration
    };

    licenseService.calculatePaymentDifference(data).then((res) => {
      // console.log("Response from API:", res);
      this.setState({newPrice: res});
     
    }).catch(error => {
      console.error("Error:", error);
    });
  };

  handlecUpdateSubscriptionStatus = () => {
    const data = {
      license_id: this.state.licenseId,
      new_plan_id: this.state.newPlanId,
      new_plan_price: this.state.selectedLicense
    };
    licenseService.updateSubscriptionStatus(data).then((res) => {
      // console.log("Response from API:", res);
      toast.info(res)
      this.handleModalClose();

      setTimeout(() => {
        this.pagination.dataCall();
    }, 700); 

    }).catch(error => {
      console.error("Error:", error);
    });
  }

  handleModalClose = () => {
    this.setState({ 
      modalOpen: false, 
      userId: "", 
      licenseId: "", 
      newPlanId: "", 
      newDuration: "", 
      newPrice: "", 
      selectedLicense: "" });
  };




  render() {
    const { filtered, editRec, users, campaignData } = this.state;
    const { videoFileName, videoAdded, upload, caretPricing } = this.state;

    const columns = [
      {
        Header: "License Listing",
        headerClassName: 'headerPagination',
        columns: [
          {
            Header: "My Logo",
            accessor: "video_url",
            Cell: ({ row, original }) => (

              // console.log('original license', original.plan.title),
             original.pricing && (original.pricing.title.toLowerCase()) ===  "corporate" ? (
                original.caret_logo ? (

                  <div style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => this.logoModal(original.id, original.caret_logo)} title='edit logo'>
                    <img style={{hight:"25px", width:"40px"}} src={original.caret_logo} />
                  </div>
                ) : (
                  <div  style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => this.logoModal(original.id, original.caret_logo)} title='edit logo'>
                    <FaRegImage />
                  </div>
                )
              ):(
                <div>
                  <button type='button' className="caretIcon orderDetailsAction">
                      &nbsp;</button>
                </div>
                )
            ),
            sortable: false
          },
          {
            Header: "My ^Caret",
            accessor: "caret_title",
            Cell: ({ value , original}) => (
              // console.log('original', original),
              value && value ? (
                <Link to={'/CLM/myCaretsVideos/' + original.id}>
                  {original.caret_title}
                </Link>
              ) : null
            ),
            className: "",
            sortable: false
          },
          {
            Header: "Company Name",
            accessor: "company_name",
            className: "",
            sortable: false
          },
          {
            Header: "License Type",
            accessor: "title",
            Cell: ({ original }) => (
              original.pricing ? (
                <div>{original.pricing.title}</div>
              ) : (
                <div>N/A</div>
              )
            ),
            className: "",
            sortable: false
          },
          {
            Header: "Start Date",
            accessor: "startedOn",
            Cell: ({ row, original }) => (
              <div>
                {original.payments && original.payments[0] ? HF.TStoDate(original.payments[0].current_period_start) : ''}
              </div>
            ),
            sortable: false
          },

          {
            Header: "End Date",
            Cell: ({ value, original }) => (
              <div>
                { HF.TStoDate(original.current_period_end)}
                </div>
            ),
            sortable: false
          },
          {
            Header: "Auto Renewal",
            accessor: "cancel_at_period_end",
            Cell:({row})=>(
              <div>
                {
                (row._original.status_id == 3 || row._original.status_id == 4 || row._original.status_id == 6) &&
                <Switch onColor='#806bff' offColor='#2c3356' height={20} width={40} onChange={()=> this.handleRenewalLicense(row._original.id, row._original.cancel_at_period_end)} checked={row._original.cancel_at_period_end == 1?false:true}/>
                }
              </div>
            ),

            sortable: false
          },
          {
            Header: "Status",
            Cell: ({ value ,original }) => {
              const status = original.status ? original.status.title : "";
              return (
                <div>
                  {status}
                </div>
              );
            },
            className: "",
            sortable: false
          },
          {
            Header: "Certificate",
            accessor: "id",
            Cell: ({ row, original, value }) => (
              // console.log('value', original),
              <div>
                {original && original.subscription_status === 'active' ?(
                  <Link to={{
                  pathname: '/CLM/certificate',
                  state: {cId: original.id, title: original.caret_title, type:original.pricing && original.pricing.title, startDate: original.payments && original.payments[0] ? HF.TStoDateForm(original.payments[0].current_period_start) : '', endDate: original.payments && original.payments[0] ? HF.TStoDateForm(original.payments[0].current_period_end) : '' },
                  }}>
                  <FaFileDownload  size={30}/>
                  </Link>
                   ):(
                    <FaFileExcel size={30}/>  
                  )
               }
              </div>
            ),
            sortable: false
          },
          {
            Header: "Change Plan",
            accessor: "id",
            Cell: ({ row, original, value }) => (
              // console.log('value', original),
              !original.clm_request &&(
              <div>
                <Button bsSize="small" 
                className='btn btnPurple text-white w-autoF'
                onClick={() => {
                  this.handlefetchCurrentPricing(original);
                }}>
                  Change
                </Button>
              </div>
              )
            ),
            sortable: false
          }, 
          {
            Header: "Actions",
            accessor: "id",
            Cell: ({ row, original, value }) => (
              <div>

                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Audio</Popover>}>
                  <Link target="_blank" to={`/CLM/defaultAudio/${value}?caret_id=${original.caret_id}`}>
                  <FaRegFileAudio size={22} />&nbsp;
                  </Link>
                </OverlayTrigger>

                {(original.pricing && (original.pricing.title.toLowerCase()) === "corporate" || original.pricing && (original.pricing.title.toLowerCase()) === "influencer") && (

                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Intro</Popover>}>
                  <Link target="_blank" to={`/CLM/defaultIntro/${value}?caret_id=${original.caret_id}`}>
                  <RiSmartphoneLine size={25} />
                    &nbsp;
                  </Link>
                </OverlayTrigger>
                )}

                {(original.pricing && (original.pricing.title.toLowerCase()) === "corporate" || original.pricing && (original.pricing.title.toLowerCase()) === "influencer") && (
                  <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Finish</Popover>}>
                  <Link target="_blank" to={`/CLM/defaultFinish/${value}?caret_id=${original.caret_id}`}>
                  <RiSmartphoneFill size={25} />
                  &nbsp;
                  </Link>
                </OverlayTrigger>
                
                )}
                
                {(original.pricing && (original.pricing.title.toLowerCase()) === "corporate" || original.pricing && (original.pricing.title.toLowerCase()) === "influencer") && (
                   <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Ad</Popover>}>
                   <Link target="_blank" to={`/CLM/defaultAd/${value}?caret_id=${original.caret_id}`}>
                   <FaAd size={25} />&nbsp;
                   </Link>
                 </OverlayTrigger>
                )}
               

                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Delete License</Popover>}>
                  <span>
                    {
                      // <button type='button' className="deleteIcon orderDetailsAction" onClick={() => this.deleteConfirmModal(row.id)}>&nbsp;</button>
                    }
                  </span>

                </OverlayTrigger>
              </div>
            ),
            className: "justifyEnd text-right",
            headerClassName: "justifyEnd",
            sortable: false
          }

        ]
      }
    ];

    return (
      <div className="content pl-2-5F">
        <Row>
          <Col md={12}>
            <div className="secHeading">My ^Caret Licenses</div>
            <Link to="/CLM/search" className="btn btnDark text-white" style={{width:'auto'}}>Purchase Additional ^Carets</Link>
          </Col>
          {/* <Col md={6}>
            <div className="justifyEnd flexElem flexResponsive mb-lg" style={{ "alignItems": "flex-start", "justifyContent": "space-between" }}>             
              <div className="">
                <Link to="/CLM/search" className="btn btn-primary btn-block">Purchase Additional ^Carets</Link>
              </div>
              <div className="">
                <OpenModalButton
                  openModal={HF.openModal.bind(this, "addModalForm")}
                  classButton={['backButton pt-sm no_radius pb-sm primary mt-none btn-block btn-info btn']}
                  buttonName="Add Licanse"
                  classIcon={['fa'x, 'fa-plus']}
                />
              </div> 
            </div>
          </Col> */}
          <Col md={12} className="mt-md">
            <Pagination
              ref={(p) => this.pagination = p}
              showPagination={false}
              columns={columns}
              pageSize={20}
              getDataCall={this.paginationCall}
              filterView={true}
              filterPlaceHolder={'License'}
              defaultSorted={
                [
                  {
                    id: 'id',
                    desc: true
                  }
                ]
              }
              // downloadData={true}
              // downloadFileName={'Orders'}
              // lowerContent = {null}
              // filterPlaceHolder = {'Orders'}
              noDataText='No Record found'
              getRowProps={this.getRowProps}
            // showAllToggle={true}
            />
          </Col>
        </Row>

          {/* caret Logo Upload */}
          {/* {
            <Modal backdrop={'static'} show={this.state.openVideoModal} onHide={HF.closeModal.bind(this, "openVideoModal")} aria-labelledby="ModalHeader" >
            <Modal.Header closeButton>
              <Modal.Title id='ModalHeader' className="headerTitle">Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            
              <div className='row'>
                <div className='col-md-12'>
                  <label>Select Carets Logo</label>
                    <div
                      className={`uploadBtn ${this.state.isDragging ? 'dragging' : ''}`}
                      onDragOver={this.handleDragOver}
                      onDragLeave={this.handleDragLeave}
                      onDrop={this.handleDrop}
                    >
                    <label for="upload-image" id='upload-image-btn'>
                      <img src={fileUploadIcon} alt={fileUploadIcon} width={20} />
                      <span id="text">
                        {this.state.imageFile ? this.state.imageFile.name : "Drop / Upload image file"}
                      </span>
                    </label>
                    <input
                      type="file"
                      accept="image/jpeg, image/jpg, image/png"
                      id="upload-image"
                      onChange={this.setImageFile}
                    />
                  </div>
                  {this.state.errorImg && <p className='srv-validation-message'>{this.state.errorImg}</p>}
                  {this.state.imagePreviewUrl && (
                    <div className='text-center'>
                      <img src={this.state.imagePreviewUrl} alt="Preview" style={{ maxWidth: '100%', height: 'auto' }} />
                    </div>
                  )}
              </div>

              <div className='form-group'>
                <div className='text-center'>
                  <Button onClick={() => this.handelUploadCaretLogo(this.state.imageFile, this.state.userID, this.state.licenseId )} bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default " >Save</Button>
                  </div>
                </div>
              </div>
            </Modal.Body>
            </Modal>
          } */}

{
                      <Modal backdrop={'static'} show={this.state.openVideoModal} onHide={HF.closeModal.bind(this, "openVideoModal")} aria-labelledby="ModalHeader" >
                      <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Image</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>

                          <Test  
                          // log={console.log("imagePreviewUrl aaaaaaaaaaa ",this.state.imagePreviewUrl)}
                          imagePreviewUrl={this.state.imagePreviewUrl}
                          onCrop={this.handleCrop} 
                          />
                        <div className='form-group'>
                          {this.state.imageFile &&
                            <div className='text-center'>
                              <Button onClick={() => this.handelUploadCaretLogo(this.state.imageFile, this.state.userID, this.state.licenseId )} bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default " >Save</Button>
                            </div>
                          }
                        </div>
                      </Modal.Body>
                      </Modal>
                    }

          {/* Upgrade Plan model  */}
          <Modal show={this.state.modalOpen} onHide={this.handleModalClose} className="" size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Choose License</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col md={6}>
                    <label className="container-check">
                      <span>One Year License - ${caretPricing && caretPricing.one_year_license}</span>
                      <input
                        type="radio"
                        name="licenseDuration"
                        value={caretPricing && caretPricing.one_year_license}
                        id={caretPricing && caretPricing.stripe_id_one_year}
                        onChange={this.handleLicenseChange}
                        checked={this.state.selectedLicense === (caretPricing && caretPricing.one_year_license)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    </Col>
                    <Col md={6}>
                    <label className="container-check">
                      <span>Two Year License - ${caretPricing && caretPricing.two_year_license}</span>
                      <input
                        type="radio"
                        name="licenseDuration"
                        value={caretPricing && caretPricing.two_year_license}
                        id={caretPricing && caretPricing.stripe_id_two_year}
                        onChange={this.handleLicenseChange}
                        checked={this.state.selectedLicense === (caretPricing && caretPricing.two_year_license)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    </Col>
                    <Col md={6}>
                    <label className="container-check">
                      <span>Three Year License - ${caretPricing && caretPricing.three_year_license}</span>
                      <input
                        type="radio"
                        name="licenseDuration"
                        value={caretPricing && caretPricing.three_year_license}
                        id={caretPricing && caretPricing.stripe_id_three_year}
                        onChange={this.handleLicenseChange}
                        checked={this.state.selectedLicense === (caretPricing && caretPricing.three_year_license)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    </Col>
                  </Row>
                  { this.state.newPrice &&
                  <div className='text-right'>
                    <h4 className='mb-0'>Price Difference $<strong>{this.state.newPrice}</strong></h4>
                  </div>}            
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={this.handlecUpdateSubscriptionStatus} className='btnDark px-5 font-boldF text-16 btn-block'>
                  Save
                </Button>
              </Modal.Footer>
            </Modal>
        

      </div>
    );
  };
}
const LicenseListing= LicenseListingPage;
export default LicenseListing;
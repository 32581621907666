import React from 'react';
import {
    Button
} from "react-bootstrap";


import SimpleReactValidator from 'simple-react-validator';
import Select, { createFilter } from 'react-select';
import AsyncSelect from 'react-select/async';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import { campaignService } from '../../../services/campaign';

class CampaignAdsForm extends React.Component {
    constructor(props) {
        super(props);
        this.validatorForm = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
        this.state = {
            campaign: {
                ...this.props.recPro,
                user_id: this.props.user_id,
                campaign_id: this.props.campaign_id,
                status_id: 1,
            },

            adsList: [],
            plansList: [],
            statusList: [],
            statusList: [],
            submitted: false,
            formType: this.props.formTypePro || '',
            video_url: this.props.recPro.ad ? this.props.recPro.ad.video_url : '',
            openVideoModal: false,
        };
        console.log("PROPS", this.props, this.state.campaign)
        this.handleRec = this.handleRec.bind(this);
        this.handleRecSelect = this.handleRecSelect.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
    }
    videoModal = (url) => {
        this.setState({ video_url: url }, () => {
            this.setState({ openVideoModal: true });
            console.log('url', this.state.video_url);
        });
    }
    componentDidMount() {
        this.getAdsCall(this.state.campaign.user_id);
        this.getPlansCall();
        this.getStatusCall();
    }


    getAdsCall = (val) => {
        campaignService.getAds(val).then(res => {
            this.setState({ adsList: res.data });
        });
    }

    getPlansCall = (val) => {
        campaignService.getPlans(val).then(res => {
            this.setState({ plansList: res.data });
        });
    }

    getStatusCall = (val) => {
        campaignService.getStatus(val).then(res => {
            this.setState({ statusList: res.data });
        });
    }


    handleRec(e) {
        const { name, value, type } = e.target;
        const recState = { ...this.state.campaign };
        recState[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
        this.setState({ campaign: recState });
    }

    handleRecSelect = (selectedOptionType, info) => {
        console.log("selectedOptionType", selectedOptionType)
        if (info['action'] === 'select-option') {
            let recState = { ...this.state.campaign };
            recState[info['name']] = selectedOptionType['value'];
            console.log(recState);
            this.setState({ campaign: recState });
        }
        if(info['name'] == "ad_id"){

            const filtered = this.state.adsList.filter(obj => {
                return obj.value === selectedOptionType['value'];
              });
              this.setState({ video_url: filtered[0].video_url });
              console.log("videoModal", filtered[0].video_url);
        }
    }

    handleSubmitForm(e) {
        e.preventDefault();
        if (this.validatorForm.allValid()) {
            this.setState({ submitted: true }, function () {
                this.submitForm(this.state.campaign, this.state.formType);
                this.props.callFunction(this.state.campaign, this.state.formType);
            });
        } else {
            this.setState({ submitted: false });
            this.validatorForm.showMessages();
            this.forceUpdate();
        }
    }

    submitForm = (formData, formType) => {
        if (formType === 'add') {
            campaignService.storeCampaignAds(formData).then(res => {
                //this.setState({addModalForm:false});
                
            });
        } else if (formType === 'edit') {
            campaignService.editCampaignAds(formData, this.state.campaign.id).then(res => {
                //this.setState({editModalForm:false});
            });
        }
    }


    render() {
        const { campaign, formType } = this.state;
        const filterConfig = {
            ignoreCase: true,
            matchFrom: 'start',
        };
        return (
            <form onSubmit={this.handleSubmitForm}>
                {formType === 'edit' &&
                    <input type="hidden" name="ad_id" className="form-control" value={campaign.id} />
                }

                <div className="row">
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label htmlFor="duration">Select Ad<span className="requiredClass">*</span></label>
                            <Select
                                value={HF.returnLabelWithValue(campaign.ad_id, this.state.adsList)}
                                onChange={(event, info) => this.handleRecSelect(event, info)}
                                options={this.state.adsList}
                                isSearchable={true}
                                isMulti={false}
                                name='ad_id'
                                isClearable={false}
                                placeholder={'Select Ad'}
                                filterOption={createFilter(filterConfig)}
                            />
                             {this.validatorForm.message('ad_id', campaign.ad_id, 'required')}
                        </div>
                        <div className='form-group'>
                            <label htmlFor="duration">Select Plan<span className="requiredClass">*</span></label>
                            <Select
                                value={HF.returnLabelWithValue(campaign.plan_id, this.state.plansList)}
                                onChange={(event, info) => this.handleRecSelect(event, info)}
                                options={this.state.plansList}
                                isSearchable={true}
                                isMulti={false}
                                name='plan_id'
                                isClearable={false}
                                placeholder={'Select Plan'}
                                filterOption={createFilter(filterConfig)}
                            />
                             {this.validatorForm.message('plan_id', campaign.plan_id, 'required')}
                        </div>

                            <div className="col-md-12">
                                <div className='text-center'>
                                    <Button type='submit' bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default " >Save</Button>
                                    <Button bsSize="large" bsStyle="info" onClick={() => this.props.closeModel()} className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info " >Cancel</Button>
                                </div>
                            </div>

                    </div>
                    <div className='col-md-6'>
                        <video key={this.state.video_url} id="playVid" controls className='videoPreview'><source src={this.state.video_url} type="video/mp4" /></video>
                    </div>
                </div>

                {/* <div className="row">
                    <div className="col-md-6">
                            <div className='form-group'>
                                <label htmlFor="duration">Select Status<span className="requiredClass">*</span></label>
                                <Select
                                value={HF.returnLabelWithValue(campaign.status_id, this.state.statusList)}
                                onChange={(event, info) => this.handleRecSelect(event, info)}
                                options={this.state.statusList}
                                isSearchable={true}
                                isMulti={false}
                                name='status_id'
                                isClearable={false}
                                placeholder={'Select Status'}
                                filterOption={createFilter(filterConfig)}
                            />
                            </div>
                        </div>
                </div> */}

            </form>
        );
    }
}


export default CampaignAdsForm;

import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover,
  Button
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import Logo from "../../assets/img/logo_frontEnd.png"
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { RiVideoLine } from 'react-icons/ri';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import PaymentAds from "../../assets/img/paymentAds.svg";
import ActiveAds from "../../assets/img/activeAds.svg";
import CliksAds from "../../assets/img/cliksAds.svg";
import A3 from "../../assets/img/A3.svg";
import totalCompaign from "../../assets/img/totalCompaign.svg";
import Confirmalertfordelete from '../ThemeComponents/confirmAlertForDelete';
import OpenModalButton from '../ThemeComponents/openModelButton';
import { helperFunctions as HF } from '../../helper/helperFunctions';
import Pagination from '../ThemeComponents/Pagination';
import { campaignService } from '../../services/campaign';
import { adService } from '../../services/ad';
import ReactValidator from "simple-react-validator";
import "react-datepicker/dist/react-datepicker.css";

import Eye from "../../assets/img/eye.svg"
import eyeLogo from "../../assets/img/caretLogo.svg"


import AdVideoBanner from "../../assets/img/videoPreview.png"

var cloneDeep = require('lodash.clonedeep');
var Modal = require('react-bootstrap-modal');
const moment = window.moment;

class DashboardPage extends Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem('user'));
    const { id } = this.props.match.params;
    this.state = {
      addModalForm: false,
      editModalForm: false,
      openVideoModal: false,
      campaignModalForm: false,
      video_url: '',
      sorted: '',
      filtered: '',
      pageSize: 10,
      totalAds: 0,
      campaignID: id,
      userID: user.data ? user.data.id : null,
      campaignDetails: [],
    };

    this.editRecord = this.editRecord.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
    this.deleteConfirmModal = this.deleteConfirmModal.bind(this);
    this.getPaginationData = this.getPaginationData.bind(this);
    this.filterSearchHandle = this.filterSearchHandle.bind(this);
    this.callFunction = this.callFunction.bind(this);
    this.closeModal = HF.closeModal.bind(this);
    this.videoModal = this.videoModal.bind(this);
    this.handleNewAdClick = this.handleNewAdClick.bind(this);
  };


  handleNewAdClick = () => {
    this.setState({ campaignModalForm: true });
  }

  videoModal(url) {
    this.setState({ video_url: url }, () => {
      this.setState({ openVideoModal: true });
      console.log('url', this.state.video_url);
    });

  }

  componentDidMount() {
    this.getCampaignDetails();
  }

  getCampaignDetails = () => {
    campaignService.getCampaignsSummary(this.state.userID).then(res => {
      console.log("getCampaignDetails", res.data);
      this.setState({ campaignDetails: res.data });
      this.setState({ userID: res.data.user_id });
    });
  }

  getDurationsCall() {
    campaignService.getDuration().then(res => {
      console.log('Durations', res);
      this.setState({ durations: res.data });
    });
  }


  callFunction(formData, formType) {
    if (formType === 'add') {
      this.setState({ addModalForm: false });
      this.pagination.dataCall();
    } else if (formType === 'edit') {
      this.setState({ editModalForm: false });
      this.setState({ campaignModalForm: false });
      this.pagination.dataCall();
    }
  }

  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }

  editRecord(id) {
    campaignService.getCampaignAds(id).then(res => {
      console.log(res);
      this.setState({ editRec: res.data }, () => {
        this.setState({ editModalForm: true });
      });
    });
  }

  deleteRecord(recID) {
    campaignService.deleteCampaignAds(recID).then(res => {
      this.pagination.dataCall();
    });
  }

  deleteConfirmModal(recID) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Record" description="Are you sure to delete the Record?" onClosePro={onClose} deleteType={() => this.deleteRecord(recID)} />
        )
      }
    })
  }


  getPaginationData(page, filter, sort, pageSize, status) {
    this.setState({ pageSize: pageSize }, function () {
      campaignService.paginationData(this.state.userID, page, filter, sort, pageSize, status, this.state.search);
    });
  }

  updateStatus = recID => (e) => {
    // return false;
    campaignService.updateCampaignStatus(recID, { is_active: e.target.checked ? 1 : 0 }).then(
      response => {
        campaignService.paginationData(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
        this.pagination.dataCall();
      }
    );
  }

  updateBlocked = recID => (e) => {
    // return false;
    campaignService.blockRecord(recID).then(
      response => {
        campaignService.paginationData(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
        this.pagination.dataCall();
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.rec_status !== prevState.rec_status) {
      this.table.state.onFetchData(this.table.state, this.table.instance)
    }

  }

  paginationCall = (data) => {
    return campaignService.paginationData(this.state.userID, data.page, data.filter, data.sort, data.pageSize, this.state.rec_status, this.state.search);
  }

  render() {
    const { filtered, editRec, durations } = this.state;
    const columns = [
      {
        Header: "Campaigns Listing",
        headerClassName: 'headerPagination',
        columns: [
          {
            Header: "Campaign Title",
            accessor: "campaign_title",
            sortable: false
          },
          // {
          //   Header: "Audiance",
          //   accessor: "id",
          //   Cell: ({ value, original }) => (
          //     <div>
          //       {original.details.genders ? original.details.genders.title + " | " : ''}
          //       {
          //         original.details.age ? original.details.age.title + " | " : ''
          //       }
          //       {original.details.location ? original.details.location : ''}
          //     </div>
          //   ),
          //   sortable: false
          // },
          {
            Header: "Gender",
            accessor: "id",
            Cell: ({ value, original }) => (
              <div>
                {original.details.genders ? original.details.genders.title : ''}
              </div>
            ),
            sortable: false
          },
          {
            Header: "Age",
            accessor: "id",
            Cell: ({ value, original }) => (
              <div>
                {
                  original.details.age ? original.details.age.title : ''
                }
              </div>
            ),
            sortable: false
          },
          {
            Header: "Region",
            accessor: "id",
            Cell: ({ value, original }) => (
              <div>
              
                {original.details.location ? original.details.location : ''}
              </div>
            ),
            sortable: false
          },
          {
            Header: "Status",
            accessor: "is_active",
            Cell: ({ row }) => (
              // <label className="container-check">
              <label className={`${row.is_active ? "invoiceStatusActive" :  "invoiceStatus"}`}>
                {row.is_active ? "Active" : "In-Active"}
              </label>
            ),
            className: "",
            sortable: false
          },
          {
            Header: "View Campaign Ads",
            accessor: "id",
            Cell: ({ value }) => {
              return <Link to={'/Advertiser/campaign-details/' + value} >
                <img src={Eye} alt='eyeLogo' height={14} />
              </Link>
            },
            className: "",
            sortable: false
          },
          
        ]
      }
    ];

    return (
      <div className="content">
        <Grid fluid>
          <Row className="mt-1 mb-20">
            <Col md={3}>
              <div className='shadow cardH110'>
                <div className="p-1">
                  <div className="flexElem alignCenter">
                    <div className="text-primary font-weight-bold">Total Campaigns: <span className="text-dark">{this.state.campaignDetails.totalCampaigns}</span></div>
                  </div>
                  <div className="flexElem alignCenter">
                    <div className="text-primary font-weight-bold">Total Ads: <span className="text-dark">{this.state.campaignDetails.totalAds}</span></div>
                    {/* <img src={PaymentAds} alt='PaymentAds' width={36} className='ml-auto' /> */}
                    <img src={totalCompaign} alt='totalCompaign' width={36} className='ml-auto' />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="shadow cardH110">
                <div className="p-1">
                  <div className="flexElem alignCenter">
                    <div className="text-primary font-weight-bold">Active Ads: <span className="text-dark">{this.state.campaignDetails.activeAds}</span></div>
                  </div>
                  <div className="flexElem alignCenter my-1">
                    <p className="mb-0 small">Pending / Rejected {this.state.campaignDetails.inActiveAds}</p>
                    <img src={ActiveAds} alt='PaymentAds' width={36} className='ml-auto' />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="shadow cardH110">
                <div className="p-1">
                  <div className="flexElem alignCenter">
                    <div className="text-primary font-weight-bold">Total Views: <span className="text-dark">{this.state.campaignDetails.campaign_ads_count}</span>
                    </div>
                  </div>
                  <div className="flexElem alignCenter my-1">
                    {/* <img src={CliksAds} alt='PaymentAds' width={36} className='ml-auto' /> */}
                    <img src={A3} alt='PaymentAds' width={36} className='ml-auto' />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="shadow cardH110">
                <div className="p-1">
                  <div className="flexElem alignCenter">
                    <div className="text-primary font-weight-bold">Total Clicks: <span className="text-dark">{this.state.campaignDetails.totalClicks}</span>
                    </div>
                  </div>
                  <div className="flexElem alignCenter my-1">
                    <img src={CliksAds} alt='PaymentAds' width={36} className='ml-auto' />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col md={12} className="mt-md">
              <Pagination
                ref={(p) => this.pagination = p}
                showPagination={true}
                columns={columns}
                pageSize={20}
                getDataCall={this.paginationCall}
                filterView={false}
                filterPlaceHolder={'Campaign Ads'}
                defaultSorted={
                  [
                    {
                      id: 'id',
                      desc: true
                    }
                  ]
                }
                // downloadData={true}
                // downloadFileName={'Orders'}
                // lowerContent = {null}
                // filterPlaceHolder = {'Orders'}
                noDataText='No Record found'
                getRowProps={this.getRowProps}
              // showAllToggle={true}
              />
            </Col>
          </Row>
        </Grid>
      </div >
    );
  };
}
const Dashboard = DashboardPage;
export default Dashboard;

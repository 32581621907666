import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { advertiserUserActions } from 'redux/actions/advertiser-user-action';
import appBackgourndImg from "assets/img/logo_frontEnd.png";
import videoUp from "assets/img/videoUp.svg";
import videoPreviewSmall from "assets/img/videoPreviewSmall.png";
import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Tab, Button, Label, Input, FormGroup, InputGroup } from "reactstrap"
export default class TargetAudience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1'
    };
  }
  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }
  render() {
    return (
      <Fragment>
        <div className="container">
          <h3 className='font-weight-bold'>Select Target Audience</h3>
          <p>&nbsp;</p>
          <Row>
            <Col md={3} className="">
              <Nav tabs vertical className='verticalTabs flexElem flexColumn'>
                <NavItem className={this.state.activeTab === '1' ? "active" : ""}>
                  <NavLink onClick={() => this.toggleTab('1')}>
                    Select Audience
                  </NavLink>
                </NavItem>
                <NavItem className={this.state.activeTab === '2' ? "active" : ""}>
                  <NavLink onClick={() => this.toggleTab('2')}>
                    Select Ad Parameters
                  </NavLink>
                </NavItem>
                <NavItem className={this.state.activeTab === '3' ? "active" : ""}>
                  <NavLink onClick={() => this.toggleTab('3')}>
                    Create an Ad
                  </NavLink>
                </NavItem>
                <NavItem className={this.state.activeTab === '4' ? "active" : ""}>
                  <NavLink onClick={() => this.toggleTab('4')}>
                    Submit the Ad
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md={9} className="borderL">
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="Gender">
                          Gender
                        </Label>
                        <Input
                          id="Gender"
                          name="Gender"
                          type="select"
                        >
                          <option>
                            Male
                          </option>
                          <option>
                            Female
                          </option>
                          <option>
                            Don't Answer
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="Age">
                          Age
                        </Label>
                        <Input
                          id="Age"
                          name="Age"
                          type="select"
                        >
                          <option>
                            18
                          </option>
                          <option>
                            19
                          </option>
                          <option>
                            20
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="Language">
                          Language
                        </Label>
                        <Input
                          id="Language"
                          name="Language"
                          type="text"
                          placeholder='English'
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="Location">
                          Location
                        </Label>
                        <InputGroup className='flexElem alignCenter w-100'>
                          <Input id='Location' name='Location' type='text' placeholder='Enter example, a country, city region, or postal code' />
                          <Button color='dark' outline={false} className="font-weight-bold">
                            ADVANCED SEARCH
                          </Button>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className='mt-20'>
                    <Col md={6} className='mb-1'>
                      <Button color='light' block className='text-dark borderNone h5 m-0 py-1'>Cancel</Button>
                    </Col>
                    <Col md={6} className='mb-1'>
                      <Button color='primary' block className='borderNone h5 m-0 py-1'>Continue</Button>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col md={12} className='mb-1'>
                      <h5 className='mb-1 font-weight-bold'>
                        Add Title
                      </h5>
                      <Input id='title' name='title' type='text' placeholder='Sales one ad campaign' />
                    </Col>
                    <Col md={12}>
                      <h5 className='mb-1 font-weight-bold'>
                        Choose Video Position
                      </h5>
                      <div className='videoPosition mb-1'>
                        <div className='videoPositionCheck'>
                          <input
                            type="radio" name="position"
                            id="firstPos" class="input-hidden" />
                          <label for="firstPos" className='vidPos'>1st</label>
                        </div>
                        <div className='videoPositionCheck'>
                          <input
                            type="radio" name="position"
                            id="secPos" class="input-hidden" />
                          <label for="secPos" className='vidPos'>2nd</label>
                        </div>
                        <div className='videoPositionCheck'>
                          <input
                            type="radio" name="position"
                            id="thirdPos" class="input-hidden" />
                          <label for="thirdPos" className='vidPos'>3rd</label>
                        </div>
                        <div className='videoPositionCheck'>
                          <input
                            type="radio" name="position"
                            id="fourthPos" class="input-hidden" />
                          <label for="fourthPos" className='vidPos'>4th</label>
                        </div>
                      </div>
                      <div className='videoPosition secondRow flexNoWrap mb-1'>
                        <div className='videoPositionCheck'>
                          <input
                            type="radio" name="secondRow"
                            id="pos1" class="input-hidden" />
                          <label for="pos1" className='vidPos'>1st</label>
                        </div>
                        <div className='videoPositionCheck'>
                          <input
                            type="radio" name="secondRow"
                            id="pos2" class="input-hidden" />
                          <label for="pos2" className='vidPos'>2nd</label>
                        </div>
                        <div className='videoPositionCheck'>
                          <input
                            type="radio" name="secondRow"
                            id="pos3" class="input-hidden" />
                          <label for="pos3" className='vidPos'>3rd</label>
                        </div>
                        <div className='videoPositionCheck'>
                          <input
                            type="radio" name="secondRow"
                            id="pos4" class="input-hidden" />
                          <label for="pos4" className='vidPos'>4th</label>
                        </div>
                        <div className='videoPositionCheck'>
                          <input
                            type="radio" name="secondRow"
                            id="pos5" class="input-hidden" />
                          <label for="pos5" className='vidPos'>5th</label>
                        </div>
                        <div className='videoPositionCheck'>
                          <input
                            type="radio" name="secondRow"
                            id="pos6" class="input-hidden" />
                          <label for="pos6" className='vidPos'>6th</label>
                        </div>
                        <div className='videoPositionCheck'>
                          <input
                            type="radio" name="secondRow"
                            id="pos7" class="input-hidden" />
                          <label for="pos7" className='vidPos'>7th</label>
                        </div>
                      </div>
                      <div className='videoPosition thirdRow mb-1'>
                        <div className='videoPositionCheck'>
                          <input
                            type="radio" name="thirdRow"
                            id="pos3-1" class="input-hidden" />
                          <label for="pos3-1" className='vidPos'>1st</label>
                        </div>
                        <div className='videoPositionCheck wrapped'>
                          <input
                            type="radio" name="thirdRow"
                            id="pos3-2" class="input-hidden" />
                          <label for="pos3-2" className='vidPos'>2nd</label>
                        </div>
                        <div className='videoPositionCheck wrapped'>
                          <input
                            type="radio" name="thirdRow"
                            id="pos3-3" class="input-hidden" />
                          <label for="pos3-3" className='vidPos'>3rd</label>
                        </div>
                        <div className='videoPositionCheck wrapped'>
                          <input
                            type="radio" name="thirdRow"
                            id="pos3-4" class="input-hidden" />
                          <label for="pos3-4" className='vidPos'>4th</label>
                        </div>
                        <div className='videoPositionCheck wrapped'>
                          <input
                            type="radio" name="thirdRow"
                            id="pos3-5" class="input-hidden" />
                          <label for="pos3-5" className='vidPos'>5th</label>
                        </div>
                        <div className='videoPositionCheck wrapped'>
                          <input
                            type="radio" name="thirdRow"
                            id="pos3-6" class="input-hidden" />
                          <label for="pos3-6" className='vidPos'>6th</label>
                        </div>
                        <div className='videoPositionCheck wrapped'>
                          <input
                            type="radio" name="thirdRow"
                            id="pos3-7" class="input-hidden" />
                          <label for="pos3-7" className='vidPos'>7th</label>
                        </div>
                        <div className='videoPositionCheck wrapped'>
                          <input
                            type="radio" name="thirdRow"
                            id="pos3-8" class="input-hidden" />
                          <label for="pos3-8" className='vidPos'>8th</label>
                        </div>
                        <div className='videoPositionCheck wrapped'>
                          <input
                            type="radio" name="thirdRow"
                            id="pos3-9" class="input-hidden" />
                          <label for="pos3-9" className='vidPos'>9th</label>
                        </div>
                        <div className='videoPositionCheck'>
                          <input
                            type="radio" name="thirdRow"
                            id="pos3-10" class="input-hidden" />
                          <label for="pos3-10" className='vidPos'>10th</label>
                        </div>
                        <div className='videoPositionCheck'>
                          <input
                            type="radio" name="thirdRow"
                            id="pos3-11" class="input-hidden" />
                          <label for="pos3-11" className='vidPos'>11th</label>
                        </div>
                      </div>
                      <div className='mt-20'>
                        <h5 className='mb-1 font-weight-bold'>Choose Duration</h5>
                        <div>
                          <Input
                            type="range" min={1} max={30} step={1}
                          />
                        </div>
                      </div>
                      <div className='mt-20 text-right'>
                        <Label className='h5'> Ad Cost:</Label>
                        <div className='adCost'>$333</div>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-20'>
                    <Col md={6} className='mb-1'>
                      <Button color='light' block className='text-dark borderNone h5 m-0 py-1'>Cancel</Button>
                    </Col>
                    <Col md={6} className='mb-1'>
                      <Button color='primary' block className='borderNone h5 m-0 py-1'>Continue</Button>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row className='mt-20'>
                    <Col md={6} className='mb-1'>
                      <h5 className='mb-1 font-weight-bold'>
                        Create an Ad
                      </h5>
                      <div class="file-upload mb-20 w-100">
                        <input type="file" id="file-input" />
                        <label for="file-input">
                          <img src={videoUp} alt="video" className='mb-1' /><br />
                          Upload Video<br />
                          <small>(maximum of 20 videos)</small>
                        </label>
                      </div>
                      <FormGroup className='mt-10'>
                        <Label for="Title">
                          Title
                        </Label>
                        <Input id='Title' name='Title' type='text' placeholder='Enter Title' />
                      </FormGroup>
                      <FormGroup className='mt-10'>
                        <Label for="url">
                          URL
                        </Label>
                        <Input id='url' name='url' type='text' placeholder='carets/lorem ipsum' />
                      </FormGroup>
                      <FormGroup className='mt-10'>
                        <Label for="text">
                          Text
                        </Label>
                        <Input id='text' name='text' type='textarea' placeholder='Lorem Ipsum is simply dummy text of the printing and typesetting industry.' />
                      </FormGroup>
                      <FormGroup className='mt-10'>
                        <Label for="ctaurl">
                          CTA URL
                        </Label>
                        <Input id='ctaurl' name='ctaurl' type='text' placeholder='carets/downloadapp' />
                      </FormGroup>
                    </Col>
                    <Col md={6} className='mb-1'>
                      <h5 className='mb-1 font-weight-bold'>
                        Preview Ad
                      </h5>
                      <div className='flexElem alignCenter justifyCenter'>
                        <img src={videoPreviewSmall} alt='video preview' height={520} />
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-20'>
                    <Col md={6} className='mb-1'>
                      <Button color='light' block className='text-dark borderNone h5 m-0 py-1'>Cancel</Button>
                    </Col>
                    <Col md={6} className='mb-1'>
                      <Button color='primary' block className='borderNone h5 m-0 py-1'>Continue</Button>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="4">
                  <Row className='mt-20'>
                    <Col sm={12}>
                      <h5 className='mb-1 font-weight-bold'>
                        Submit
                      </h5>
                    </Col>
                    <Col md={6} className='mb-1'>
                      <div className='flexElem alignCenter justifyCenter'>
                        <img src={videoPreviewSmall} alt='video preview' height={520} />
                      </div>
                    </Col>
                    <Col md={6} className='mb-1'>
                      <div class="mb-20">
                        <Label className='text-muted'>Name</Label>
                        <h6 className='text-capitalize mt-0'>Amaze Fun Buying</h6>
                      </div>
                      <div class="mb-20">
                        <Label className='text-muted'>Campaign Name</Label>
                        <h6 className='text-capitalize mt-0'>Amaze Fun Buying</h6>
                      </div>
                      <div class="mb-20">
                        <Label className='text-muted'>Ad URL</Label>
                        <h6 className='text-lowercase mt-0'>carets/lorem ipsum123456789</h6>
                      </div>
                      <div class="mb-20">
                        <Label className='text-muted'>Text</Label>
                        <h6 className='text-capitalize mt-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h6>
                      </div>
                      <div class="mb-20">
                        <Label className='text-muted'>CTA URL</Label>
                        <h6 className='text-lowercase mt-0'>carets/downloadapp</h6>
                      </div>
                      <div class="mb-20">
                        <Label className='text-muted'>Ad Cost</Label>
                        <h6 className='text-capitalize mt-0'>$35.74</h6>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-20'>
                    <Col md={6} className='mb-1'>
                      <Button color='light' block className='text-dark borderNone h5 m-0 py-1'>Cancel</Button>
                    </Col>
                    <Col md={6} className='mb-1'>
                      <Button color='primary' block className='borderNone h5 m-0 py-1'>Continue</Button>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
          <p>&nbsp;</p>
        </div>
      </Fragment>
    )
  }
}
import { SC } from '../helper/ServerCall';
import axios from 'axios';

export const cartService = {
    paginationDataOrder,
    paginationDataOrderItem,
    cartListing,
    addToCart,
    editSubscriptionPlan,
    deleteItem,
    getSalesTax,
    associateUserId,
    checkoutCart,
    checkLicenseAvailability,
    deleteOrderItem
};

function paginationDataOrder(user_id, page, filter, sort, pageSize,status,search=null) {
    const data = {'page': page,'filter': filter,'sort': sort,'pageSize': pageSize,'status': status,'search':search};
    return SC.getCall('cart/orders/'+user_id+'?page=' + page + '&filter=' + filter + '&sort=' + sort + '&pageSize=' + pageSize);
}
function paginationDataOrderItem(order_id, page, filter, sort, pageSize,status,search=null) {
    const data = {'page': page,'filter': filter,'sort': sort,'pageSize': pageSize,'status': status,'search':search};
    return SC.getCall('cart/orderItems/'+order_id+'?page=' + page + '&filter=' + filter + '&sort=' + sort + '&pageSize=' + pageSize);
}


function cartListing(guestToken) {
    return SC.getCall(`v1/cart/list?guest_token=${guestToken}`);
}

function addToCart(catData) {
    return SC.postCall('v1/cart/add', catData);
}


function editSubscriptionPlan(data, itemId) {
    return SC.putCall('v1/cart/item/'+ itemId, data);
}

function deleteItem(itemId) {
    return SC.deleteCall('v1/cart/item/' + itemId);
}
function deleteOrderItem(itemId) {
    return SC.deleteCall('cart/orderItem/' + itemId);
}

function getSalesTax() {
    return SC.getCall('v1/getSalesTax');
}

function associateUserId(data) {
    return SC.postCall('cart/associateUser', data);
}

function checkoutCart(orderType, orderId) {
    let url = `cart/checkout?order_type=${orderType}`;
    
    // Add order_id only if it is not null or undefined
    if (orderId !== null && orderId !== undefined) {
        url += `&order_id=${orderId}`;
    }

    return SC.getCall(url);

    // return SC.getCall(`cart/checkout?order_type=${orderType}&order_id=${orderId}`);
}
function checkLicenseAvailability(keyword) {
    return SC.getCall(`cart/checkLicense?keyword=${keyword}`);
}


import { alertConstants } from '../constant/alert-constant';

const initialState = {type: '', message: ''};

export function alert(state = initialState, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: 'alert-success',
        message: action.message,
        notiType:action.notiType
      };
     case alertConstants.INFO:
      return {
        type: 'alert-info',
        message: action.message,
        notiType:action.notiType
      };
    case alertConstants.ERROR:
      return {
        type: 'alert-danger',
        message: action.message,
        notiType:action.notiType
      };

    case alertConstants.CLEAR:
      return {};
    default:
      return { ...state }
  }
}
import React, { Component, Fragment } from 'react';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover,
  Button
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import Logo from "../../assets/img/logo_frontEnd.png"
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { RiVideoLine } from 'react-icons/ri';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import { helperFunctions as HF } from '../../helper/helperFunctions';
import MarketIcon from "../../assets/img/market-icon.svg"
import CampaignIcon from "../../assets/img/campaign-icon.svg"
import BrandAdIcon from "../../assets/img/brandAd-icon.svg"
import AdplacementIcon from "../../assets/img/adplacement-icon.svg"
import PricingIcon from "../../assets/img/pricing-icon.svg"
import LaunchcampiagnIcon from "../../assets/img/launchcampiagn-icon.svg"
import StartAdvertising from "../../assets/img/start-advertising.svg"

var cloneDeep = require('lodash.clonedeep');
var Modal = require('react-bootstrap-modal');
const moment = window.moment;

class TargetMarket extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  };


  render() {

    return (
      <Fragment>
        <div className="content container">
          <Row className='mt-20'>
            <Col md={12}>
              <h2 class="text_36 font-weight-bold text-center mb-2 mt-1">Advertise On Carets</h2>
            </Col>
            <Col md={4}>
              <div className='px-2 py-2 mb-2 shadow'>
                <img src={MarketIcon} alt='MarketIcon' height={72} />
                <h3 className='font-boldF text-20 mb-1 mt-1'>Choose Your Target Market</h3>
                <span className='text-16'>Select your demographics of your targeted audience.</span>
              </div>
            </Col>
            <Col md={4}>
              <div className='px-2 py-2 mb-2 altBG'>
                <img src={CampaignIcon} alt='MarketIcon' height={72} />
                <h3 className='font-boldF text-20 mb-1 mt-1'>Create Your Ad Campaign</h3>
                <span className='text-16'>Create a campaign to promote multiple advertisements</span>
              </div>
            </Col>
            <Col md={4}>
              <div className='px-2 py-2 mb-2 shadow'>
                <img src={BrandAdIcon} alt='MarketIcon' height={72} />
                <h3 className='font-boldF text-20 mb-1 mt-1'>Create Brand New Ads</h3>
                <span className='text-16'>Create individual ads for each campaign for placement within the Carets app.</span>
              </div>
            </Col>
            <Col md={4}>
              <div className='px-2 py-2 mb-2 altBG'>
                <img src={AdplacementIcon} alt='MarketIcon' height={72} />
                <h3 className='font-boldF text-20 mb-1 mt-1'>Select Ad Placement</h3>
                <span className='text-16'>Select the placement of your advertisements in the video playback sequence. </span>
              </div>
            </Col>
            <Col md={4}>
              <div className='px-2 py-2 mb-2 shadow'>
                <img src={PricingIcon} alt='MarketIcon' height={72} />
                <h3 className='font-boldF text-20 mb-1 mt-1'>Simple Pricing</h3>
                <span className='text-16'>Our Fixed Rate Pricing Model is Predictable and Easy to Use.</span>
              </div>
            </Col>
            <Col md={4}>
              <div className='px-2 py-2 mb-2 altBG'>
                <img src={LaunchcampiagnIcon} alt='MarketIcon' height={72} />
                <h3 className='font-boldF text-20 mb-1 mt-1'>Launch Your Campaign</h3>
                <span className='text-16'>Launch and monitor campaign<br />activity.</span>
              </div>
            </Col>
          </Row>
        </div>
        <section className='caretAdvertising'>
          <div className='container'>
            <Row className='flexElem alignCenter flexWrap'>
              <Col md={7}>
                <h2 className='advertisingTitle'>Start Advertising on Carets<br />for as little as</h2>
                <h2 className='advertisingSubTitle'>$1.00 PER DAY.</h2>
              </Col>
              <Col md={5} className='text-center'>
                <img src={StartAdvertising} alt='StartAdvertising' />
              </Col>
            </Row>
          </div>
        </section>
      </Fragment>
    );
  };
}
export default TargetMarket;

import { SC } from '../helper/ServerCall';

export const paymentService = {
    paginationData,
    storePayment,
    getPayment,
    editPayment,
    deletePayment,
    updatePaymentStatus,
    getDuration,
    blockRecord,
    
}
function paginationData(user_id, page, filter, sort, pageSize,status,search=null) {
    const data = {'page': page,'filter': filter,'sort': sort,'pageSize': pageSize,'status': status,'search':search};
    return SC.getCall('payments/data/'+user_id+'?page=' + page + '&filter=' + filter + '&sort=' + sort + '&pageSize=' + pageSize);
}
function getDuration() {
    return SC.getCall('payments/durations');
}
function storePayment(catData) {
    return SC.postCall('payments/listing', catData);
}

function getPayment(id) {

    return SC.getCall('payments/listing/' + id);
}

function editPayment(data, PaymentId) {
    data['payment_id'] = PaymentId;
    return SC.postCall('payments/listing', data);
}

function deletePayment(catId) {
    return SC.deleteCall('payments/listing/' + catId);
}

function updatePaymentStatus(catId, data) {
    data['payment_id'] = catId;
    return SC.postCall('payments/listing/status',data);
}
function blockRecord(catId) {
    return SC.getCall('payments/block/status/'+catId);
}

import React from "react";
import { helperFunctions as HF } from './helperFunctions';
import {
	OverlayTrigger,
	Popover,
} from "react-bootstrap";
// import Mailto from 'reactv16-mailto';
import { objectsConstants as OC } from 'redux/constant/objects-constant';

export const ThemeFunctions = {
	showValueLabel,
	openModelButton,
	initialsLogo,
	errorMsg,
	showValueLabelForEmailLink,
}


function showValueLabel(label, value, isDate = false, widths) {
	return (
		<tr className="">
			<td width={widths ? widths.td1 : null}>{label}:</td>
			{
				label === 'Barcode' ?
					<td width={widths ? widths.td2 : null}><span className="td_barcode">{value}</span></td>
					: (label === 'Order ID' ? <td width={widths ? widths.td2 : null}><span className="td_orderId">{value}</span></td>
						: (isDate ? <td width={widths ? widths.td2 : null}>{value}</td> : <td width={widths ? widths.td2 : null}>{value}</td>))
			}
		</tr>
	);
}


function showValueLabelForEmailLink(label, value, isDate = false, widths) {
	return (
		<tr className="form-group">
			<td width={widths ? widths.td1 : null}>{label}:</td>
			<td>
				<a rel="noopener noreferrer" href={"mailto:"+value} target="_blank" style={{backgroundColor:'#ffffa0'}} >{value}</a>
			</td>
		</tr>
	);
}

function openModelButton(classButton, name, tooltipText, buttonName) {
	return (
		<OverlayTrigger placement="bottom" trigger={['hover']} overlay={<Popover id="tooltip"> {tooltipText} </Popover>}>
			<button className={classButton} name={name} onClick={HF.openModal.bind(this)}> {buttonName} </button>
		</OverlayTrigger>
	);
}

function initialsLogo(name) {
	var randomColor = OC.RANDOMCOLORS;
	var rand = randomColor[Math.floor(Math.random() * randomColor.length)];
	return (
		<span className="initialsLogo" style={{ background: rand }}>{HF.getInitials(name, " ")}</span>
	);
}

function errorMsg(text, type, icon, extraClass = "") {
	return (
		<div className={"flexElem text-" + type + extraClass} role="alert">
			<img src={icon} alt="Icon" />
			<span style={{ marginLeft: "10px" }}>{text}</span>
		</div>
	);
}
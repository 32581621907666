import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { advertiserUserActions } from 'redux/actions/advertiser-user-action';
import appBackgourndImg from "assets/img/logo_frontEnd.png";
import { Card, CardBody, CardText, CardTitle, Button, Row, Col } from "reactstrap"
export default class NewAd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };
  }
  render() {
    return (
      <Fragment>
        <div className="container">
          <Card className="shadow p-25 my-20">
            <CardBody className=''>
              <Row>
                <Col md={6}>
                  <Button outline block className='btn-primary my-20 text-uppercase font-weight-bold h4'> create new</Button>
                </Col>
                <Col md={6}>
                  <Button outline block className='btn-primary mt-20 my-20 text-uppercase font-weight-bold h4'> use existing</Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="shadow p-25 my-20">
            <CardBody className=''>
              <h4 className='font-weight-bold'>Advertising objectives</h4>
              <h5 className='mb-20'>Choosing the right advertising objective</h5>
              <div className='flexElem alignCenter gap25 w-100'>
                <Card className='w-100 trafficCard'>
                  <CardBody className='p-1'>
                    <h4 className='font-weight-bold mt-10'>Traffic</h4>
                    <div className='flexElem alignCenter'>
                      <img src={appBackgourndImg} width={72} alt="Traffic" className='ml-auto' />
                    </div>
                  </CardBody>
                </Card>
                <Card className='w-100 conversionCard'>
                  <CardBody className='p-1'>
                    <h4 className='font-weight-bold mt-10'>Conversions</h4>
                    <div className='flexElem alignCenter'>
                      <img src={appBackgourndImg} width={72} alt="Conversions" className='ml-auto' />
                    </div>
                  </CardBody>
                </Card>
                <Card className='w-100 appCard'>
                  <CardBody className='p-1'>
                    <h4 className='font-weight-bold mt-10'>App Install</h4>
                    <div className='flexElem alignCenter'>
                      <img src={appBackgourndImg} width={72} alt="App Install" className='ml-auto' />
                    </div>
                  </CardBody>
                </Card>
              </div>
            </CardBody>
          </Card>
        </div>
      </Fragment>
    )
  }
}
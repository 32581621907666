import React from 'react';
import {
	Button, Row, Col
} from "reactstrap";

import upload from "../../../assets/img/upload.svg"
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import FileUpload from "components/Upload/FileUpload";
import ImagePreview from "components/Upload/ImagePreview";
import App from "./ImageCroping1";

import { file } from 'jszip';

import { adService } from '../../../services/ad';

class CampaignForm3 extends React.Component {
	constructor(props) {
		super(props);
		this.validatorForm = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
		this.state = {
			adv: {
				...this.props.recPro,
				user_id: this.props.user_id,
				is_active: 1
			},
			error: '',
			videoPreviewUrl: this.props.recPro ? this.props.recPro.videoFile : null,
			submitted: false,
			formType: this.props.formTypePro || '',
			allowedFormatsStringMime: 'image/jpeg, image/png',
			allowedFormatsStringExt: 'jpg, png',
			allowedFormatsVideoMime: 'video/x-flv, video/mp4',
			allowedFormatsVideoExt: 'flv, mp4',
			videoFileName: '',
			videoAdded: false,

			filePreviewUrl: this.props.recPro ? this.props.recPro.videoFile : null,
			fileName: '',
			fileAdded: false,

			imageFile: null,
			imageFileData: null,
		};

		this.handleRec = this.handleRec.bind(this);
		this.handleRecSelect = this.handleRecSelect.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}



	handleRec(e) {
		const { name, value, type } = e.target;
		const recState = { ...this.state.adv };
		recState[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
		this.setState({ adv: recState });
	}

	handleRecSelect(selectedOptionType, info) {
		if (info['action'] === 'select-option') {
			let recState = { ...this.state.adv };
			recState[info['name']] = selectedOptionType['value'];
			console.log(recState);
			this.setState({ adv: recState });
		}
	}

	// handleSubmitForm(e){
	//     e.preventDefault();
	//     if (this.validatorForm.allValid()) {
	// 		this.setState({submitted: true}, function(){
	// 			this.props.submitForm(this.state.adv, this.state.formType);
	// 		});
	// 	} else {
	// 		this.setState({submitted: false});
	// 		this.validatorForm.showMessages();
	// 		this.forceUpdate();
	// 	}
	// }

	handleSubmitForm(e) {
		e.preventDefault();
		if (this.validatorForm.allValid() && (this.state.error == '' || this.state.error == null)) {
			this.setState({ submitted: true }, function () {
				this.submitForm(this.state.adv);

			});
		} else {
			this.setState({ submitted: false });
			this.validatorForm.showMessages();
			this.forceUpdate();
		}
	}

	submitForm = (formData) => {
		adService.storeAd(formData).then(res => {
			this.props.callFunction(res);
		});
	}


	extractImageData = (value) => {
		const file = {};
		file['file'] = value['file'];
		file['name'] = value['name'];
		file['size'] = value['size'];
		file['extension'] = HF.returnExtFromImage(value['name']);
		file['preview'] = value['preview'];
		file['type'] = value['type'];
		file['lastModified'] = value['lastModified'];
		return file;
	}
	setResourceFile = (key, value) => {
		this.setState({
			adv: { ...this.state.adv, image: this.extractImageData(value) },
		});
	}
	removeFile = (key, index) => {
		this.setState({
			adv: { ...this.state.adv, image: null },
		});
	}


	// setVideoFile = (key, value) => {
	// 	this.setState({
	// 		adv: { ...this.state.adv, videoFile: this.extractImageData(value) },
	// 	});
	// }

	removeVideoFile = (key, index) => {
		this.setState({
			adv: { ...this.state.adv, videoFile: null },
		});
	}

	setVideoFile = (event) => {
		const file = event.target.files[0];

		const fileData = {};

		//fileData['file'] = base64Data;
		fileData['name'] = file['name'];
		fileData['size'] = file['size'];
		fileData['extension'] = HF.returnExtFromImage(file['name']);
		//fileData['preview'] = videoPreviewUrl;
		fileData['type'] = file['type'];
		fileData['lastModified'] = file['lastModified'];


		console.log('file', this.extractImageData(file));
		const video = document.createElement('video');
		const reader = new FileReader();
		video.onloadedmetadata = () => {
			const duration = Math.floor(video.duration);
			if (duration > 60) {
				this.setState({ error: 'Video duration exceeds the maximum allowed (60 seconds)' });

			} else if (file.size > 300 * 1024 * 1024) {
				this.setState({ error: 'Video size exceeds the maximum allowed (300 MB)' });

			} else {
				this.setState({ error: '' });
				const videoPreviewUrl = URL.createObjectURL(file);
				fileData['preview'] = videoPreviewUrl;
				this.setState({ videoDuration: duration, error: null });
                this.setState({ videoPreviewUrl:null},
                    () => {
                        this.setState({ videoPreviewUrl:videoPreviewUrl});

                })

			}
		};
		reader.readAsDataURL(file);
		reader.onload = () => {
			fileData['file'] = reader.result;
			// console.log('base64Data', fileData['file']);

		};
		video.src = URL.createObjectURL(file);

		// console.log("video.src",video.src);


		this.setState({
			adv: { ...this.state.adv, videoFile: fileData },
		});
	};

	handleInputChange = (event) => {
		const videoName = event.target.value.split("\\").pop();
		this.setState({ videoName });
	};

	handleDragOver = (event) => {
		event.preventDefault();
		this.setState({ isDragging: true });
	};
	
	handleDragLeave = () => {
		this.setState({ isDragging: false });
	};
	
	handleDrop = (event) => {
		event.preventDefault();
		const file = event.dataTransfer.files[0];
		if (file) {
			this.handleFileUpload({ target: { files: [file] } });
		}
		this.setState({ isDragging: false });
	};

	handleCrop = (imageFile) => {
		console.log('croppedImageFile on Crop', imageFile);
		// this.setState({ imageFile: croppedImageFile });
		this.setState({ imageFile,
			adv: { ...this.state.adv, videoFile: imageFile, duration: 2 }
	
		});
		
		};

	handleFileUpload = (event) => {
		const file = event.target.files[0];
		if (!file) return;

		if (this.state.filePreviewUrl) {
			URL.revokeObjectURL(this.state.filePreviewUrl);
		}

		const fileData = {
			name: file.name,
			size: file.size,
			type: file.type,
			lastModified: file.lastModified,
		};
		const fileExtension = file.name.split('.').pop().toLowerCase();
		let fileType = file.type.includes('video') ? 'video' :
			file.type.includes('image') ? 'image' : null;

		if(fileExtension== 'heic'){
			fileType = 'image';
			fileData.type = 'image/heic';
		}
		if (fileType === 'video') {
			const video = document.createElement('video');
			const reader = new FileReader();

			video.onloadedmetadata = () => {
				const duration = Math.floor(video.duration);
				if (duration > 60) {
					this.setState({ error: 'Video duration exceeds the maximum allowed (60 seconds)' });
				} else if (file.size > 300 * 1024 * 1024) { // 300 MB
					this.setState({ error: 'Video size exceeds the maximum allowed (300 MB)' });
				} else {
					this.setState({ filePreviewUrl: null, error: '', fileDuration: null });
					const filePreviewUrl = URL.createObjectURL(file);
					fileData.preview = filePreviewUrl;
					this.setState({
						filePreviewUrl,
						fileType: 'video',
						fileDuration: duration,
						fileName: file.name,
						fileAdded: true,
						error: null,
						adv: { ...this.state.adv, videoFile: fileData, duration: duration }
					});
				}
			};

			reader.onload = () => {
				fileData.file = reader.result;
			};

			reader.readAsDataURL(file);
			video.src = URL.createObjectURL(file);

		} else if (fileType === 'image') {
			
			const allowedImageExtensions = ['png', 'jpeg', 'gif', 'jpg', 'heic'];
			if (!allowedImageExtensions.includes(fileExtension)) {
				this.setState({ error: 'Only PNG, JPEG, GIF, JPG, and heic images are allowed' });
				return;
			}

			const filePreviewUrl = URL.createObjectURL(file);
			const reader = new FileReader();

			reader.onload = () => {
				fileData.file = reader.result;
				this.setState({ fileDuration: null });
				this.setState({
					filePreviewUrl,
					fileType: 'image',
					fileName: file.name,
					fileAdded: true,
					error: null,
					adv: { ...this.state.adv, videoFile: fileData, duration: 2 }
				});
				this.setState({
					imageFileData:file
				})
			};

			reader.readAsDataURL(file);

		} else {
			this.setState({ error: 'Unsupported file type' });
		}
	};
	
	render() {
		const { adv, formType, isDragging, allowedFormatsStringMime, allowedFormatsStringExt, allowedFormatsVideoMime, allowedFormatsVideoExt } = this.state;
		const { videoFileName, videoAdded } = this.state;
		return (
			<form onSubmit={this.handleSubmitForm}>
				{formType === 'edit' &&
					<input type="hidden" name="ad_id" className="form-control" value={adv.id} />
				}
				<Row>
					<Col md={6}>
						<div className='form-group'>
							<label htmlFor="ad_title">Ad Title<span className="requiredClass">*</span></label>
							<input type="text" name="ad_title" className="form-control" value={adv.ad_title} onChange={this.handleRec} />
							{this.validatorForm.message('ad_title', adv.ad_title, 'required|max:250')}
						</div>
						<div className='form-group'>
							<label htmlFor="ad_button_text">Ad Text<span className="requiredClass">*</span></label>
							<input type="text" name="ad_button_text" className="form-control" value={adv.ad_button_text} onChange={this.handleRec} />
							{this.validatorForm.message('ad_button_text', adv.ad_button_text, 'required|max:250')}
						</div>
						<div className='form-group'>
							<label htmlFor="ad_button_link">Ad Link<span className="requiredClass">*</span></label>
							<input type="text" name="ad_button_link" className="form-control" value={adv.ad_button_link} onChange={this.handleRec} />
							{this.validatorForm.message('ad_button_link', adv.ad_button_link, 'required|max:250')}
						</div>
						<div className='form-group'>
							<label htmlFor="ad_description">Description<span className="requiredClass">*</span></label>
							<textarea name="ad_description" className="form-control" onChange={this.handleRec}>
								{adv.ad_description}
							</textarea>
							{formType === 'add' &&
								this.validatorForm.message('ad_description', adv.ad_description, 'required')
							}
						</div>
						<div className='text-center'>
							<Button type='submit' bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default " >Save</Button>
							<Button bsSize="large" bsStyle="info" onClick={() => this.props.closeModel()} className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info " >Cancel</Button>
						</div>
					</Col>
					<Col md={6}>
						<div className=''>
							<label>Select File</label>
							{/* <div className='uploadBtn'> */}
							<div
								className={`uploadBtn ${isDragging ? 'dragging' : ''}`}
								onDragOver={this.handleDragOver}
								onDragLeave={this.handleDragLeave}
								onDrop={this.handleDrop}
            				>
								<label for="upload" id='upload-btn' className={`${videoAdded ? "chosen" : ""}`}>
									<img src={upload} alt={upload} width={20} />
									<span id="text">
										{videoFileName ? videoFileName : "Drag / Upload video file"}
									</span>
								</label>
								<input
									type="file"
									accept="image/jpeg, image/png, image/gif, image/jpg, image/heic,  video/mp4, video/quicktime, video/x-flv, video/webm"
									id="upload"
									onChange={this.handleFileUpload}
								/>
							</div>
							{this.state.error && <p className='srv-validation-message'>{this.state.error}</p>}
							{this.state.filePreviewUrl && (
								<div className='text-center'>

									{(this.state.fileType === 'video' || this.state.videos) ? (
										<video controls style={{ maxWidth: '100%', height: 'auto' }}>
											<source src={this.state.filePreviewUrl} type="video/mp4" />
											Your browser does not support the video tag.
										</video>
									) : (
										// <img src={this.state.filePreviewUrl} alt="preview" style={{ maxWidth: '100%', height: 'auto' }} />
										<App  
										imageFileData={this.state.imageFileData}
										// log={console.log("imagePreviewUrl aaaaaaaaaaa ",this.state.imagePreviewUrl)}
										imagePreviewUrl={this.state.imagePreviewUrl}
										onCrop={this.handleCrop} 
										/>
									)}
									{this.state.fileDuration && <p className='text-primary mt-1 text-center'>Video duration: {this.state.fileDuration} seconds</p>}
								</div>
							)}
							{/* {this.state.videoPreviewUrl && (
								<div className='text-center'>
									<video controls style={{ maxWidth: '100%', height: 'auto' }}>
										<source src={this.state.videoPreviewUrl} type="video/mp4" />
										Your browser does not support the video tag.
									</video>
								</div>
							)}
							{this.state.videoDuration && <p className='text-primary mt-1 text-center'>Video duration: {this.state.videoDuration} seconds</p>} */}
						</div>
						{/* <div>
							<label>Select Ad Video</label>
							<div className='uploadBtn'>
								<label for="upload" id='upload-btn' className={`${videoAdded ? "chosen" : ""}`}>
									<img src={upload} alt={upload} width={20} />
									<span id="text">
										{videoFileName ? videoFileName : "Upload video file"}
									</span>
								</label>
								<input
									type="file"
									accept="video/*"
									id="upload"
									onChange={this.setVideoFile}
								/>
							</div>
							<div className='flexElem alignCenter justifyCenter flexColumn gap10p'>
								{this.state.error && <p style={{ color: 'red' }}>{this.state.error}</p>}
								{this.state.videoPreviewUrl && (
									<video controls style={{ maxWidth: '100%', height: 'auto', maxHeight: 440 }}>
										<source src={this.state.videoPreviewUrl} type="video/mp4" />
										Your browser does not support the video tag.
									</video>
								)}
								{this.state.videoDuration && <p>Video duration: {this.state.videoDuration} seconds</p>}
							</div>
						</div> */}
					</Col>
				</Row>
			</form>
		);
	}
}
export default CampaignForm3;

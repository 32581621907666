import { CLMConstants } from '../constant/CLM-constant';

let userAdmin = JSON.parse(localStorage.getItem('user'));
const initialState = userAdmin ? { loggedIn:true, userAdmin:userAdmin, loading:false, dashboardCount:'', menu:{'parent_labels':{}, 'permissions':{}}} : { loggedIn:false, userAdmin:'', loading:false };

export function CLMReducer(state = initialState, action){
	// console.log('type', action.type);
	switch(action.type){
		case CLMConstants.LOGIN_CLM_REQUEST:
			return { ...state, loading:true };
		case CLMConstants.LOGIN_CLM_SUCCESS:
			return { ...state, loading:false, userAdmin : action.payload, loggedIn:true };
		case CLMConstants.LOGIN_CLM_FAILURE:
			return { ...state, loading:false };

		case CLMConstants.GET_DASHBOARD_REQUEST:
			return { ...state, loading:true };
		case CLMConstants.GET_DASHBOARD_SUCCESS:
			return { ...state, loading:false, dashboardCount : action.payload.dashboardCount };
		case CLMConstants.GET_DASHBOARD_FAILURE:
			return { ...state, loading:false, error : action.payload };


		case CLMConstants.GET_MENU_DASHBOARD_REQUEST:
			return { ...state, loading:true };
		case CLMConstants.GET_MENU_DASHBOARD_SUCCESS:
			return { ...state, loading:false, menu : action.payload.menu };
		case CLMConstants.GET_MENU_DASHBOARD_FAILURE:
			return { ...state, loading:false, error : action.payload };

		case CLMConstants.LOGOUT_CLM_REQUEST:
			return {...state};
		case CLMConstants.LOGOUT_CLM_SUCCESS:
			return {...state};
		case CLMConstants.LOGOUT_CLM_FAILURE:
			return {...state};

		default:

			return {...state};
	}
}

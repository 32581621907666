import React, { Component } from "react";
import Login from "components/CLM/Login";
import { withRouter } from "react-router-dom";

class CLMLogin extends Component {
    constructor(props) {
        super(props);
        const queryParams = new URLSearchParams(this.props.location.search);
        const role = queryParams.get("role");
        this.state = {
            role: role || null
        };
    }
    componentDidMount() {}
    render() {
        require("bootstrap/dist/css/bootstrap.min.css");
        require("../../assets/css/animate.min.css");
        require("../../assets/sass/light-bootstrap-dashboard.css?v=1.2.0");
        require("../../assets/css/demo.css");
        require("../../assets/css/pe-icon-7-stroke.css");
        require("../../assets/fonts/robotoFont.css");
        return (
            <div className="container">
                <div className="content">
                    <div className="row">
                        <Login role={this.state.role} />
                    </div>
                </div>
            </div>
        );
    }
}
export default CLMLogin;

import { combineReducers } from "redux";
import { adminReducer } from "./AdminReducer";
import { advertiserReducer } from "./AdvertiserReducer";
import { CLMReducer } from "./CLMReducer";
import { alert } from "./AlertReducer";
import { userReducer } from "./UserReducer";
import { rolesReducer } from "./RolesReducer";
import { permissionsReducer } from "./PermissionReducer";
import { frontEndUserReducer } from './FrontEndReducer';
import { settingsReducer } from './SettingsReducer';
import { moduleReducer } from "./ModuleReducer";
import { resumeReducer } from "./ResumeReducer";

const rootReducer = combineReducers({
  adminReducer,
  advertiserReducer,
  CLMReducer,
  userReducer,
  frontEndUserReducer,
  rolesReducer,
  permissionsReducer,
  settingsReducer,
  alert,
  moduleReducer,
  resumeReducer,
});

export default rootReducer;

import React, { Component } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


class Graph4 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dataopts: this.props.compData
        };



      };

	render() {
		const data = [];
        this.props.compData.map((anObjectMapped, index) => {
           data.push(anObjectMapped)
        })

        console.log(data);

          const getPath = (x, y, width, height) => {
            return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
            ${x + width / 2}, ${y}
            C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
            Z`;
          };

          const TriangleBar = (props) => {
            const { fill, x, y, width, height } = props;

            return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
          };
          const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink'];

		return (
         <ResponsiveContainer width="100%" height="30%">


        <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="title" label={{ value: 'Ages', offset: -2,  position: 'insideBottom' }} />
             <YAxis label={{ value: 'Total', angle: -90, position: 'insideLeft' }} />
            {/* <XAxis dataKey="title" />
            <YAxis /> */}
            <Bar dataKey="total" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % 20]} />
              ))}
            </Bar>
          </BarChart>
          </ResponsiveContainer>
		);
	}
}

export default Graph4;

import { SC } from '../helper/ServerCall';

export const caretPricingService = {
    paginationData,
    storeCaretPricing,
    getCaretPricing,
    editCaretPricing,
    deleteCaretPricing,
    updateCaretPricingStatus,
    updateContactSalesStatus,
    blockCLMRecord,
    getInfluencer,
}
function paginationData(page, filter, sort, pageSize,status,search=null) {
    const data = {'page': page,'filter': filter,'sort': sort,'pageSize': pageSize,'status': status,'search':search};
    return SC.postCall('caretPricing/data', data);
}

function storeCaretPricing(catData) {
    return SC.postCall('caretPricing/listing', catData);
}

function getCaretPricing(id) {

    return SC.getCall('caretPricing/listing/' + id);
}

function editCaretPricing(data, Id) {
    data['caretPricing_id'] = Id;
    return SC.postCall('caretPricing/listing', data);
}

function deleteCaretPricing(catId) {
    return SC.deleteCall('caretPricing/listing/' + catId);
}

function updateCaretPricingStatus(catId, data) {
    data['caretPricing_id'] = catId;
    return SC.postCall('caretPricing/status',data);
}
function updateContactSalesStatus(catId, data) {
    data['caretPricing_id'] = catId;
    return SC.postCall('caretPricing/salesStatus',data);
}
function blockCLMRecord(catId) {
    return SC.getCall('plclmPlansans/block/status/'+catId);
}

function getInfluencer() {
    return SC.getCall('CLMPayments/getInfluencerPlan');
}


export const advertiserConstants = {
	//Auth
	LOGIN_ADVERTISER_REQUEST: 'LOGIN_ADVERTISER_REQUEST',
	LOGIN_ADVERTISER_SUCCESS: 'LOGIN_ADVERTISER_SUCCESS',
	LOGIN_ADVERTISER_FAILURE: 'LOGIN_ADVERTISER_FAILURE',

	LOGOUT_ADVERTISER_REQUEST: 'LOGOUT_ADVERTISER_REQUEST',
	LOGOUT_ADVERTISER_SUCCESS: 'LOGOUT_ADVERTISER_SUCCESS',
	LOGOUT_ADVERTISER_FAILURE: 'LOGOUT_ADVERTISER_FAILURE',

	GET_MENU_DASHBOARD_REQUEST: 'GET_MENU_DASHBOARD_REQUEST',
	GET_MENU_DASHBOARD_SUCCESS: 'GET_MENU_DASHBOARD_SUCCESS',
	GET_MENU_DASHBOARD_FAILURE: 'GET_MENU_DASHBOARD_FAILURE',

	GET_MENU_REQUEST: 'GET_MENU_REQUEST',
	GET_MENU_SUCCESS: 'GET_MENU_SUCCESS',
	GET_MENU_FAILURE: 'GET_MENU_FAILURE',

	GET_DASHBOARD_REQUEST: 'GET_DASHBOARD_REQUEST',
	GET_DASHBOARD_SUCCESS: 'GET_DASHBOARD_SUCCESS',
	GET_DASHBOARD_FAILURE: 'GET_DASHBOARD_FAILURE',

	SET_GLOBAL_LOADER: 'SET_GLOBAL_LOADER',


};

import React, { Component } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

class Graph4 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dataopts: this.props.compData
        };



      };

	render() {
		const data = [];
        this.props.compData.map((anObjectMapped, index) => {
           data.push(anObjectMapped)
        })

        console.log(data);
        const RADIAN = Math.PI / 180;
        const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {

        console.log('genderdata',index, data[index]['title']);
          const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
          const x = cx + radius * Math.cos(-midAngle * RADIAN);
          const y = cy + radius * Math.sin(-midAngle * RADIAN);

          return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
             {data[index]['title'][0]} - {`${(percent * 100).toFixed(0)}%`}
            </text>
          );
        };

          const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

		return (
         <ResponsiveContainer width="100%" height="30%">

        <PieChart width={400} height={400}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="total"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>

          </ResponsiveContainer>
		);
	}
}

export default Graph4;

import React, { Component, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import MessageCom from "layouts/Dashboard/Message";
import { Step, Stepper, StepLabel as span, StepContent as div, FormStep, FormStepper } from 'react-form-stepper';
import { frontEndUserActions } from 'redux/actions/front-end-action';
import caretFooterLogo from '../../assets/img/logo_white.png'
import appBackgourndImg from "assets/img/logo_frontEnd.png";
import user from '../../assets/img/user.png'
import { Button, Form, FormGroup, Label, Input, InputGroup, InputGroupText, FormText, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import { appuserService } from '../../services/appuser';
import { Router } from 'react-router';

class ResetPasswordPage extends Component {

  constructor(props) {
    super(props);

    const { tkn } = this.props.match.params;
    console.log(tkn)
    console.log(this.props)
    this.state = {
      step: 0,
      formData: {
        terms: '',
        email: '',
        password: '',
        password_confirmation:'',
        token:tkn ? tkn : ''
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }



  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });
    const { email, password } = this.state.formData;

    if (email && password) {
        appuserService.resetPassword(this.state.formData)
            .then(res => {
                console.log("resetPassword", res);
                window.location.replace("/Advertiser-Login");
            })
            .catch(err => {
              // console.log("error", err);
              const errorMessage = err && err.password
              ? err.password.join(', ')
              : err.token.join(', ');
              toast.error(errorMessage);
            });
    } else {
        toast.error("Please enter email, password");
    }
}
  handleChange = (e) => {
    const { name, value, type } = e.target;
    const recState = { ...this.state.formData };
    recState[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
    this.setState({formData: recState});
}

  render() {
    const { email, password,password_confirmation, terms,submitted } = this.state.formData;
    const { loggingIn } = this.props;
    return (
      <Fragment>
        <div className='fixLoginWrap'>
          <header>
            <div className='flexElem alignCenter p-1'>
              {/* <p className="text-center logo_container"> */}
              <img className="img-responsive logoImageLogin1" src={caretFooterLogo} alt="backGroundImage" width={72} />
              {/* </p> */}
              <div className='ml-auto'>
                <a href='/Advertiser-Login' className='text-info'> Login </a>
              </div>
            </div>
          </header>
          <div className="main_login w-100">
            <div className="container">
              <div className='flexElem alignCenter justifyCenter'>
                <img src={caretFooterLogo} alt='footer logo' className='' width={100} />
              </div>
              <h2 className="text-center text-white">Rest Password</h2>
              <form name="form" className='signupForm w-100' onSubmit={this.handleSubmit}>
                <Row>
                  <Col md={12}>
                    <div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                      <input type="text" placeholder="Enter Your Email..." className="form-control email_ctrl inputTransparentRound" name="email" value={email} onChange={this.handleChange} />
                      {submitted && !email &&
                        <div className="help-block">Email is required.</div>
                      }
                    </div>

                  </Col>
                  <Col md={12}>
                    <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                      <input type="password" placeholder="Password" className="form-control password_ctrl inputTransparentRound" name="password" value={password} onChange={this.handleChange} autocomplete="off" />
                      {submitted && !password &&
                        <div className="help-block">Password is required.</div>
                      }
                    </div>
                  </Col>

                  <Col md={12}>
                    <div className={'form-group' + (submitted && !password_confirmation ? ' has-error' : '')}>
                      <input type="password" placeholder="password confirmation" className="form-control password_ctrl inputTransparentRound" name="password_confirmation" value={password_confirmation} onChange={this.handleChange} autocomplete="off" />
                      {submitted && !password_confirmation &&
                        <div className="help-block">password confirmation is required.</div>
                      }
                    </div>
                  </Col>


                  <Col md={12}>
                    <Button onClick={this.handleSubmit} color='primary' className='roundedFull py-1' block>Reset</Button>
                  </Col>
                </Row>
              </form>

            </div>
          </div>
          <MessageCom />
          <div className='copyRights text-white flexElem alignCenter gap10p p-1'>
            <p className='font-weight-light small'>Copyright © 2023, Carets Corporation, All Rights Reserved.</p>
            <img src={caretFooterLogo} alt='footer logo' className='ml-auto' width={64} />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { loading, frontEndUser, loggedIn } = state.frontEndUserReducer;
  return {
    loading, frontEndUser, loggedIn
  };
}

const mapDispatchToProps = dispatch => {
  return {
    loginCall: (email, password) => { dispatch(frontEndUserActions.login(email, password)) },
    logoutCall: () => { dispatch(frontEndUserActions.logout()) }
  }
}

const ResetPassword = connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
export default ResetPassword;

import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover,
  Button
} from "react-bootstrap";

import Graph1 from "./Graph1"
import Graph2 from "./Graph2"
import Graph3 from "./Graph3"


import Logo from "../../../assets/img/logo_frontEnd.png"
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { RiVideoLine } from 'react-icons/ri';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import A1 from "../../../assets/img/A1.svg";
import A2 from "../../../assets/img/A2.svg";
import A3 from "../../../assets/img/A3.svg";
import NoData from '../../../assets/img/noData.png';
import 'react-table/react-table.css';
import CampaignAdsForm from "./CampaignAdsForm";
import CampaignAdsForm1 from "./CampaignAdsForm1";
import CampaignForm from "./CampaignForm";
import Confirmalertfordelete from '../../ThemeComponents/confirmAlertForDelete';
import OpenModalButton from '../../ThemeComponents/openModelButton';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import Pagination from '../../ThemeComponents/Pagination';
import { campaignService } from '../../../services/campaign';

import Switch from "react-switch";

import ReactValidator from "simple-react-validator";
import "react-datepicker/dist/react-datepicker.css";
var cloneDeep = require('lodash.clonedeep');
var Modal = require('react-bootstrap-modal');
const moment = window.moment;

class CampaignAdsListingPage extends Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem('user'));
    const { id } = this.props.match.params;
    this.state = {
      addModalForm: false,
      editModalForm: false,
      openVideoModal: false,
      campaignModalForm: false,
      video_url: '',
      sorted: '',
      filtered: '',
      pageSize: 10,
      totalAds: 0,
      campaignID: id,
      campaignID: id,
      userID: user.data ? user.data.id : null,
      campaignDetails: [],
      genderStats: [],
      ageStats: [],
      positionStats: [],
      load1: 0,
      load2: 0,
      load3: 0,

      statusList:[]

    };

    this.editRecord = this.editRecord.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
    this.deleteConfirmModal = this.deleteConfirmModal.bind(this);
    this.getPaginationData = this.getPaginationData.bind(this);
    this.filterSearchHandle = this.filterSearchHandle.bind(this);
    this.callFunction = this.callFunction.bind(this);
    this.closeModal = HF.closeModal.bind(this);
    this.videoModal = this.videoModal.bind(this);
    this.handleNewAdClick = this.handleNewAdClick.bind(this);
  };


  handleNewAdClick = () => {
    this.setState({ campaignModalForm: true });
  }

  videoModal(url) {
    this.setState({ video_url: url }, () => {
      this.setState({ openVideoModal: true });
      console.log('url', this.state.video_url);
    });

  }

  componentDidMount() {
    this.getCampaignDetails();
    this.getStatusCall();

  }

  getCampaignDetails = () => {
    campaignService.getCampaign(this.state.campaignID).then(res => {
      // console.log("getCampaignDetails", res.data);
      this.setState({ campaignDetails: res.data });
      this.setState({ userID: res.data.user_id });
      //###############

      this.setState({ genderStats: res.data.gender_stats });
      this.setState({ ageStats: res.data.age_stats });
      this.setState({ positionStats: res.data.position_stats });

      //###############
      setTimeout(() => this.setState({ load1: 1 }), 500);
      setTimeout(() => this.setState({ load2: 1 }), 1000);
      setTimeout(() => this.setState({ load3: 1 }), 1500);
    });
  }

  getDurationsCall() {
    campaignService.getDuration().then(res => {
      console.log('Durations', res);
      this.setState({ durations: res.data });
    });
  }


  callFunction(formData, formType) {
    if (formType === 'add') {
      this.setState({ addModalForm: false });
      this.pagination.dataCall();
    } else if (formType === 'edit') {
      this.setState({ editModalForm: false });
      this.setState({ campaignModalForm: false });
      this.pagination.dataCall();
    } else if (formType === 'camp') {
      this.setState({ editModalForm: false });
      this.setState({ campaignModalForm: false });
      window.location.reload(false);

    }
  }

  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }

  editRecord(id) {
    campaignService.getCampaignAds(id).then(res => {
      console.log(res);
      this.setState({ editRec: res.data }, () => {
        this.setState({ editModalForm: true });
      });
    });
  }

  deleteRecord(recID) {
    campaignService.deleteCampaignAds(recID).then(res => {
      this.pagination.dataCall();
    });
  }

  deleteConfirmModal(recID) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete customMessage = "Deleting this item will remove this Ad from this Campaign." typeName="Record" description="Are you sure to delete the Record?" onClosePro={onClose} deleteType={() => this.deleteRecord(recID)} />
        )
      }
    })
  }


  getPaginationData(page, filter, sort, pageSize, status) {
    this.setState({ pageSize: pageSize }, function () {
      campaignService.paginationDataAds(this.state.campaignID, page, filter, sort, pageSize, status, this.state.search);
    });
  }

  updateStatus = recID => (e) => {
    // return false;
    campaignService.updateCampaignStatus(recID, { is_active: e.target.checked ? 1 : 0 }).then(
      response => {
        campaignService.paginationDataAds(this.state.campaignID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
        this.pagination.dataCall();
      }
    );
  }

  updateBlocked = recID => (e) => {
    // return false;
    campaignService.blockRecord(recID).then(
      response => {
        campaignService.paginationDataAds(this.state.campaignID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
        this.pagination.dataCall();
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.rec_status !== prevState.rec_status) {
      this.table.state.onFetchData(this.table.state, this.table.instance)
    }

  }

  paginationCall = (data) => {
    return campaignService.paginationDataAds(this.state.campaignID, data.page, data.filter, data.sort, data.pageSize, this.state.rec_status, this.state.search);
  }

  getStatusCall = (val) => {
    campaignService.getStatus(val).then(res => {
        this.setState({statusList:res.data});
        });
    }

  handleUpdateAdStatus = (ad_id, status_id) => {
    const data = {
      ad_id: ad_id,
      status_id: status_id
    };
    // console.log("data", data)
    campaignService.updateAdsStatus(data).then((res) => {
      console.log(res)
      this.pagination.dataCall();
    });
  }

  handleRenewalAds = (ad_id, period_end) => {
    const data = {
      ad_id: ad_id,
      cancel_at_period_end: period_end == 1 ? 0 : 1
    };
    // console.log("data", data)
    campaignService.renewalAds(data).then((res) => {
        this.pagination.dataCall();
    });
  }
  render() {
    const { filtered, editRec, durations } = this.state;
    const columns = [
      {
        Header: "Campaigns Listing",
        headerClassName: 'headerPagination',
        columns: [
          {
            Header: "Ad",
            accessor: "video_url",
            Cell: ({ value, original, }) => (
              original.ad.image_url ? (
              
                <div style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => this.videoModal(original.ad.video_url)}>
                  {/* <img src='{{original.ad.image_url}}' /> */}
                  <img style={{hight:"25px", width:"40px"}} src={original.ad.image_url} />

                </div>
              ) : (
                <div style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => this.videoModal(original.ad.video_url)}>
                  <RiVideoLine />
                </div>
              )

            ),

            className: "",
            sortable: false
          },
          {
            Header: "Ad Title",
            accessor: "ad_title",
            Cell: ({ value, original }) => (
              <div>
                {original.ad ? HF.truncate(original.ad.ad_title, 20): ''}
              </div>
            ),

            className: "",
            sortable: false
          },
          {
            Header: "Plan",
            Cell: ({ value, original }) => (
              <div>
                {original.plan ? " Plan: " + original.plan.duration + "\n" + " Ad Position: " + original.plan.position + "\n Cost / " + original.plan.duration + ": $" + original.plan.amount : ''}
              </div>
            ),
            minWidth: 250,
            sortable: false
          },
          {
            Header: "Total Spent",
            accessor: "spent",
            Cell: ({ value, original }) => (
              <div>
                {original.spent > 0 ? "$" + original.spent : ''}
              </div>
            ),
            sortable: false
          },
          {
            Header: "Start Date",
            accessor: "startedOn",
            Cell: ({ value, original }) => (
              <div>
                {original.startedOn ? HF.TStoDate(original.startedOn) : ''}
              </div>
            ),
            sortable: false
          },

          {
            Header: "End Date",
            Cell: ({ value, original }) => (
              <div>
                {original.cancel_at_period_end > 0 ? "Canceled @ " + HF.TStoDate(original.current_period_end) : HF.TStoDate(original.current_period_end)}
              </div>
            ),
            sortable: false
          },
          {
            Header: "Auto Renewal",
            accessor: "cancel_at_period_end",
            Cell:({row})=>(
              <div>
                {
                (row._original.status_id == 3 || row._original.status_id == 4 || row._original.status_id == 6) &&
                <Switch onChange={()=> this.handleRenewalAds(row._original.id, row._original.cancel_at_period_end)} checked={row._original.cancel_at_period_end == 1?false:true}/>

                }
              </div>
            ),

            sortable: false
          },
          {
            Header: "Views",
            accessor: "watch_count",
            sortable: false
          },
          {
            Header: "Clicks",
            accessor: "totalClicks",
            sortable: false
          },
          {
            Header: "Complete Watch",
            accessor: "totalComplete",
            sortable: false
          },
          // {
          //   Header: "Subscription",
          //   Cell: ({ row }) => (
          //     <div style={{}}>
          //       <select
          //         style={{ cursor : 'pointer', width:"90%", border:"none"}}
          //         value={row._original.status ? row._original.status.id : ''}
          //         onChange={(e) => {
          //           const newStatus = parseInt(e.target.value);
          //           this.handleUpdateAdStatus(row._original.id, );
          //         }}
          //       >
          //         {this.state.statusList.map((status) => (
          //           <option key={status.value} value={status.value}>
          //             {status.label}
          //           </option>
          //         ))}
          //       </select>
          //     </div>
          //   ),
          //   sortable: false
          // },
          {
            Header: "Status",
            Cell: ({ value, original }) => (
              <div className={`${original.status.title == "Active" ? "invoiceStatusActive"  : "invoiceStatus"}`}>
                {original.status ? original.status.title : ''}
              </div>
            ),
            sortable: false
          },
          {
            Header: "Actions",
            Cell: ({  value, original }) => (
              <div>

                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Edit</Popover>}>
                  <span>
                    {
                      // <button type='button' className="editIcon orderDetailsAction" onClick={() => this.editRecord(original.id)}>&nbsp;</button>
                    }

                  </span>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Delete</Popover>}>
                  <span>
                    {
                      <button type='button' className="deleteIcon orderDetailsAction" onClick={() => this.deleteConfirmModal(original.id)}>&nbsp;</button>
                    }
                  </span>

                </OverlayTrigger>
              </div>
            ),
            className: "justifyEnd text-right",
            headerClassName: "justifyEnd",
            sortable: false
          }


        ]
      }
    ];

    return (
      <div className="content">
        <Grid fluid>
        <Row>
          <Col md={12}>
            <h4 className='secHeading'>Campaign Ads Listing</h4>
            <Row className="mt-1 mb-20">

                            <Col md={4}>
                            { this.state.load1==1 &&
                                    <div className='shadow cardH110'>
                                        <div className='p-1'>
                                        <div className='' >
                                        <div>Age Stats</div>
                                            {/* <Graph2 compData={this.state.ageStats}/> */}
                                            {this.state.positionStats.length > 0 ? (
                                              <Graph2 compData={this.state.ageStats}/>
                                          ) : (
                                              <div className='text-center'>
                                              <img src={NoData} alt={NoData} width={84} />
                                              <h3 className='font-bold text-14 mt-md'>Performance Metrics Pending</h3>
                                              </div>
                                          )}
                                        </div>
                                        </div>
                                    </div>
                                }
                            </Col>

                            <Col md={4}>
                                { this.state.load2==1 &&
                                    <div className='shadow cardH110'>
                                        <div className='p-1'>
                                        <div className='' >
                                            <div>Gender Stats</div>
                                            {/* <Graph1 compData={this.state.genderStats}/> */}
                                            {this.state.positionStats.length > 0 ? (
                                              <Graph1 compData={this.state.genderStats}/>
                                          ) : (
                                              <div className='text-center'>
                                              <img src={NoData} alt={NoData} width={84} />
                                              <h3 className='font-bold text-14 mt-md'>Performance Metrics Pending</h3>
                                              </div>
                                          )}
                                        </div>
                                        </div>
                                    </div>
                                }

                            </Col>

                           <Col md={4}>
                            { this.state.load3==1 &&
                                    <div className='shadow cardH110'>
                                        <div className='p-1'>
                                        <div className='' >
                                        <div>Position Stats</div>
                                            {/* <Graph3 compData={this.state.positionStats}/> */}
                                          {this.state.positionStats.length > 0 ? (
                                              <Graph3 compData={this.state.positionStats}/>
                                          ) : (
                                              <div className='text-center'>
                                              <img src={NoData} alt={NoData} width={84} />
                                              <h3 className='font-bold text-14 mt-md'>Performance Metrics Pending</h3>
                                              </div>
                                          )}
                                        </div>
                                        </div>
                                    </div>
                                }
                            </Col>

            </Row>
            <Row className="mt-1 mb-20">
              <Col md={3}>
                <div className='shadow cardH110'>
                  <div className="p-1">
                    <div className="flexElem alignCenter">
                      <div className="text-primary font-weight-bold">Campaign Details: <span className="text-dark">{this.state.campaignDetails.campaign_title}</span></div>
                    </div>
                    <div className="flexElem alignCenter my-1">
                      <p className="mb-0 small">

                        {this.state.campaignDetails.details && this.state.campaignDetails.details.gender ? this.state.campaignDetails.details.genders.title + "\n" : ''}
                        <br />
                        {this.state.campaignDetails.details && this.state.campaignDetails.details.ages ? "For : " + this.state.campaignDetails.details.age.title + " Years" + "\n" : ''}
                        <br />
                        {this.state.campaignDetails.details ? "Region: " + this.state.campaignDetails.details.location : ''}
                      </p>
                      <img src={A1} alt='A1' width={36} className='ml-auto' />
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div className="shadow cardH110">
                  <div className="p-1">
                    <div className="flexElem alignCenter">
                      <div className="text-primary font-weight-bold">Ads: <span className="text-dark"></span></div>
                    </div>
                    <div className="flexElem alignCenter my-1">
                      <p className="mb-0 small">
                        {/* Total Ads: {this.state.campaignDetails.activeAds}<br /> */}
                        <br />Active Ads: {this.state.campaignDetails.activeAds}<br />
                        Pending / Rejected: {this.state.campaignDetails.inActiveAds}
                      </p>
                      <img src={A2} alt='A2' width={36} className='ml-auto' />
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div className="shadow cardH110">
                  <div className="p-1">
                    <div className="flexElem alignCenter">
                      <div className="text-primary font-weight-bold">Campaign Views: <span className="text-dark"></span>
                      </div>
                    </div>
                    <div className="flexElem alignCenter my-1">
                      <p className="mb-0 small">
                        Total Views: {this.state.campaignDetails.campaign_ads_count}<br />
                        Total Clicks: {this.state.campaignDetails.totalClicks}<br />
                        Complete Watch: {this.state.campaignDetails.totalComplete}<br />
                      </p>
                      <img src={A3} alt='A3' width={36} className='ml-auto' />
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <a type='button' className='btn btn-primary text-primary btn-block w-100' onClick={() => this.handleNewAdClick()}>Edit Campaign Targets</a><br />

                <div className="">
                  <OpenModalButton
                    openModal={HF.openModal.bind(this, "addModalForm")}
                    classButton={['btn btn-primary text-primary btn-block w-100']}
                    buttonName="Create New Ads"
                    classIcon={['fa', 'fa-plus']}
                  />
                </div>
              </Col>
            </Row>
            <Pagination
              ref={(p) => this.pagination = p}
              showPagination={false}
              columns={columns}
              pageSize={20}
              getDataCall={this.paginationCall}
              filterView={true}
              filterPlaceHolder={'Campaign Ads'}
              defaultSorted={
                [
                  {
                    id: 'id',
                    desc: true
                  }
                ]
              }
              // downloadData={true}
              // downloadFileName={'Orders'}
              // lowerContent = {null}
              // filterPlaceHolder = {'Orders'}
              noDataText='No Record found'
              getRowProps={this.getRowProps}
            // showAllToggle={true}
            />
            {
              <Modal backdrop={'static'} show={this.state.addModalForm} onHide={HF.closeModal.bind(this, "addModalForm")} aria-labelledby="ModalHeader" className="modalLG modal-lg mx-auto">
                <Modal.Header closeButton>
                  <Modal.Title id='ModalHeader' className="headerTitle">New Ad</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12">
                      <Card bsClass={["innerCard mb-none"]} content={
                        <CampaignAdsForm1
                          closeModel={HF.closeModal.bind(this, "addModalForm")}
                          formTypePro="add"
                          recPro={OC.CAMPAIGNADS}
                          user_id={this.state.userID}
                          campaign_id={this.state.campaignID}
                          callFunction={this.callFunction}
                          componentPro="AdListing"
                        />
                      } />
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            }
            {/*Add Record Modal End*/}
            {/*Edit Record Modal start*/}
            {editRec &&
              <Modal backdrop={'static'} show={this.state.editModalForm} onHide={HF.closeModal.bind(this, "editModalForm")} aria-labelledby="ModalHeader" >
                <Modal.Header closeButton>
                  <Modal.Title id='ModalHeader' className="headerTitle">Edit Ad</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12">
                      <Card bsClass={["innerCard mb-none"]} content={
                        <CampaignAdsForm
                          closeModel={HF.closeModal.bind(this, "editModalForm")}
                          formTypePro="edit"
                          recPro={editRec}
                          user_id={this.state.userID}
                          campaign_id={this.state.campaignID}
                          callFunction={this.callFunction}
                        />
                      } />
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            }
            {
              <Modal backdrop={'static'} show={this.state.openVideoModal} onHide={HF.closeModal.bind(this, "openVideoModal")} aria-labelledby="ModalHeader" >
                <Modal.Header closeButton>
                  <Modal.Title id='ModalHeader' className="headerTitle">Ad</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12" style={{ textAlignVertical: "center", textAlign: "center" }}>
                      <video key={this.state.video_url} id="playVid" controls style={{ width: 'auto', maxHeight: 'calc(100vh - 150px)' }} ><source src={this.state.video_url} type="video/mp4" /></video>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            }

            {this.state.campaignDetails &&
              <Modal backdrop={'static'} show={this.state.campaignModalForm} onHide={HF.closeModal.bind(this, "campaignModalForm")} aria-labelledby="ModalHeader" >
                <Modal.Header closeButton>
                  <Modal.Title id='ModalHeader' className="headerTitle">Edit Campaign Targets</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12">
                      <Card bsClass={["mb-none borderNone shadowNone"]} content={
                        <CampaignForm
                          closeModel={HF.closeModal.bind(this, "campaignModalForm")}
                          formTypePro="camp"
                          recPro={this.state.campaignDetails}
                          user_id={this.state.userID}
                          callFunction={this.callFunction}
                          getCampaignDetails={this.getCampaignDetails}
                        />
                      } />
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            }
          </Col>
        </Row>
        </Grid>
      </div>
    );
  };
}
const CampaignAdsListing = CampaignAdsListingPage;
export default CampaignAdsListing;

import { SC } from '../helper/ServerCall';

export const clmPlanService = {
    paginationData,
    storeCLMPlan,
    getCLMPlan,
    editCLMPlan,
    deleteCLMPlan,
    updateCLMPlanStatus,
    getCLMDuration,
    blockCLMRecord,
    getInfluencer,
    getAllPlan,
}
function paginationData(page, filter, sort, pageSize,status,search=null) {
    const data = {'page': page,'filter': filter,'sort': sort,'pageSize': pageSize,'status': status,'search':search};
    return SC.getCall('clmPlans/listing?page=' + page + '&filter=' + filter + '&sort=' + sort + '&pageSize=' + pageSize);
}
function getCLMDuration() {
    return SC.getCall('clmPlans/durations');
}
function storeCLMPlan(catData) {
    return SC.postCall('clmPlans/listing', catData);
}

function getCLMPlan(id) {

    return SC.getCall('clmPlans/listing/' + id);
}

function editCLMPlan(data, PlanId) {
    data['plan_id'] = PlanId;
    return SC.postCall('CLMPlans/listing', data);
}

function deleteCLMPlan(catId) {
    return SC.deleteCall('clmPlans/listing/' + catId);
}

function updateCLMPlanStatus(catId, data) {
    data['plan_id'] = catId;
    return SC.postCall('clmPlans/listing/status',data);
}
function blockCLMRecord(catId) {
    return SC.getCall('clmPlans/block/status/'+catId);
}

function getInfluencer() {
    return SC.getCall('CLMPayments/getInfluencerPlan');
}
function getAllPlan() {
    return SC.getCall('clmPlans/planData');
}


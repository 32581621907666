import React from 'react';
import {
  Button
} from "react-bootstrap";

import upload from "../../../assets/img/upload.svg"
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import FileUpload from "components/Upload/FileUpload";
import ImagePreview from "components/Upload/ImagePreview";
import { compose } from 'redux';

class LicenseForm extends React.Component {
	constructor(props){
		super(props);
		this.validatorForm = new SimpleReactValidator({autoForceUpdate: this, locale: 'en'});
		this.state = {
			license: {
				...this.props.recPro,
				user_id: this.props.user_id

			},
		    submitted: false,
            error:'',
            videoPreviewUrl:this.props.recPro?this.props.recPro.videoFile:null,
		    formType: this.props.formTypePro 	|| '',
			allowedFormatsStringMime : 'image/jpeg, image/png',
			allowedFormatsStringExt : 'jpg, png',
			allowedFormatsVideoMime : 'video/x-flv, video/mp4',
			allowedFormatsVideoExt : 'flv, mp4',
      videoFileName: '',
			videoAdded: false
		};

		this.handleRec = this.handleRec.bind(this);
        this.handleRecSelect = this.handleRecSelect.bind(this);
  		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}



	handleRec(e){
	    const { name, value, type } = e.target;
        const recState = { ...this.state.license };
	    recState[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
	    this.setState({license: recState});
	}

    handleRecSelect(selectedOptionType, info) {
        if (info['action'] === 'select-option') {
          let recState = { ...this.state.license };
          recState[info['name']] = selectedOptionType['value'];
        //   console.log(recState);
          this.setState({license: recState});
        }
    }

	handleSubmitForm(e){
	    e.preventDefault();
	    if (this.validatorForm.allValid() && (this.state.error == '' || this.state.error == null)) {
			this.setState({submitted: true}, function(){
				this.props.submitForm(this.state.license, this.state.formType);
			});
		} else {
			this.setState({submitted: false});
			this.validatorForm.showMessages();
			this.forceUpdate();
		}
	}

	extractImageData =  (value) =>  {
		const file = {};
		file['file'] = value;
		file['name'] = value['name'];
		file['size'] = value['size'];
		file['extension'] = HF.returnExtFromImage(value['name']);
		file['preview'] = value['preview'];
		file['type'] = value['type'];
		file['lastModified'] = value['lastModified'];
		return file;
	}
	setResourceFile = (key, value) => {
		this.setState({
			license: {...this.state.license, image:this.extractImageData(value) },
		});


	}
	removeFile = (key, index) =>{
		this.setState({
			license: {...this.state.license, image:null },
		});
	}


	// setVideoFile = (key, value) => {
	// 	this.setState({
	// 		adv: {...this.state.adv, videoFile:this.extractImageData(value) },
	// 	});
    // }

	removeVideoFile = (key, index) =>{
		this.setState({
			license: {...this.state.license, videoFile:null },
		});
	}

    setVideoFile = (event) => {
        const file = event.target.files[0];

        const fileData = {};

		//fileData['file'] = base64Data;
		fileData['name'] = file['name'];
		fileData['size'] = file['size'];
		fileData['extension'] = HF.returnExtFromImage(file['name']);
		//fileData['preview'] = videoPreviewUrl;
		fileData['type'] = file['type'];
		fileData['lastModified'] = file['lastModified'];


        // console.log('file',this.extractImageData(file));
        const video = document.createElement('video');
        const reader = new FileReader();
        video.onloadedmetadata = () => {
          const duration = Math.floor(video.duration);
          if (duration > 60) {
            this.setState({ error: 'Video duration exceeds the maximum allowed (60 seconds)' });

          } else if ((file['size'] / 10000) > 300) {
            this.setState({ error: 'Video size exceeds the maximum allowed (300 MB)' });

          } else {
            this.setState({ error: '' });
            const videoPreviewUrl = URL.createObjectURL(file);
            fileData['preview'] = videoPreviewUrl;
            this.setState({ videoDuration: duration, error: null });
            this.setState({ videoPreviewUrl:null},
                () => {
                    this.setState({ videoPreviewUrl:videoPreviewUrl});

            })

          }
        };
        reader.readAsDataURL(file);
        reader.onload = () => {
            fileData['file']  = reader.result;
            // console.log('base64Data',fileData['file']);

        };
        video.src = URL.createObjectURL(file);
        // console.log("video.src",video.src);


        this.setState({
			license: {...this.state.license, videoFile:fileData },
        });
      };






	render(){
		const { license,formType, allowedFormatsStringMime, allowedFormatsStringExt, allowedFormatsVideoMime, allowedFormatsVideoExt} = this.state;
		const { users } = this.props;
    const { videoFileName, videoAdded } = this.state;
    // console.log('license',license,users);
		return(
			<form onSubmit={this.handleSubmitForm}>
                 { formType === 'edit' &&
			        	<input type="hidden" name="license_id" className="form-control"  value={license.id}/>
			    }
				 
				 <div className="row">
					<div className="col-md-6">
						<div className='form-group'>
							<label htmlFor="caret_title">Carets Title<span className="requiredClass">*</span></label>
							<input type="text" name="caret_title" className="form-control"  value={license.caret_title} onChange={this.handleRec} />
							{this.validatorForm.message('caret_title', license.caret_title, 'required|min:5')}
						</div>
					</div>
					<div className="col-md-6">
						<div className='form-group'>
							<label htmlFor="company_name">Company Name</label>
							<input type="text" name="company_name" className="form-control"  value={license.company_name} onChange={this.handleRec} />
							{/* {this.validatorForm.message('company_name', license.company_name, 'required|min:5')} */}
						</div>
					</div>

			    
                </div>
                <div className="row">
			    <div className="col-md-4">
	              <div className=''>
	                <label className="container-check checkbox_down"> Is Active?
	                  <input type="checkbox" name="is_active" className="checkbox" value={license.is_active? 1 : 0} onChange={this.handleRec} checked={license.is_active?1:0} />
	                  <span className="checkmark"></span>
	                </label>
	                {/* {this.validatorPermission.message('is_active', video.is_active, 'required')} */}
	              </div>
	            </div>
  
			  </div>

			  <div className="row">
			    <div className="col-md-12">
			      <div className='text-center'>
			        <Button  type='submit' bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default " >Save</Button>
			        <Button bsSize="large" bsStyle="info" onClick={()=>this.props.closeModel() } className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info " >Cancel</Button>
			      </div>
			    </div>
			  </div>

			</form>
		);
	}
}

export default LicenseForm;



export const CLMConstants = {
	//Auth
	LOGIN_CLM_REQUEST: 'LOGIN_CLM_REQUEST',
	LOGIN_CLM_SUCCESS: 'LOGIN_CLM_SUCCESS',
	LOGIN_CLM_FAILURE: 'LOGIN_CLM_FAILURE',

	LOGOUT_CLM_REQUEST: 'LOGOUT_CLM_REQUEST',
	LOGOUT_CLM_SUCCESS: 'LOGOUT_CLM_SUCCESS',
	LOGOUT_CLM_FAILURE: 'LOGOUT_CLM_FAILURE',

	GET_MENU_DASHBOARD_REQUEST: 'GET_MENU_DASHBOARD_REQUEST',
	GET_MENU_DASHBOARD_SUCCESS: 'GET_MENU_DASHBOARD_SUCCESS',
	GET_MENU_DASHBOARD_FAILURE: 'GET_MENU_DASHBOARD_FAILURE',

	GET_MENU_REQUEST: 'GET_MENU_REQUEST',
	GET_MENU_SUCCESS: 'GET_MENU_SUCCESS',
	GET_MENU_FAILURE: 'GET_MENU_FAILURE',

	GET_DASHBOARD_REQUEST: 'GET_DASHBOARD_REQUEST',
	GET_DASHBOARD_SUCCESS: 'GET_DASHBOARD_SUCCESS',
	GET_DASHBOARD_FAILURE: 'GET_DASHBOARD_FAILURE',

	SET_GLOBAL_LOADER: 'SET_GLOBAL_LOADER',


};

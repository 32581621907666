import React, { Component } from "react";
import { Grid } from "react-bootstrap";
// import {basePathApi} from 'helper/basePathApi';
import { basePathApi } from 'helper/basePathApi';
import { Link } from 'react-router-dom';

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <Grid fluid>
          {/* <div className="flexElem alignCenter spaceBetween gap25 p-1 w-100"> */}
          {/* <Link className="hover:text-blue-800 hover:underline text-14" to="https://admin.carets.tv/Advertiser-login">Advertise with Us</Link> */}
          <p className="copyright pull-right">
            &copy; {new Date().getFullYear()}{" "}
            <a href="https://carets.tv/" target="_blank">Carets Corporation</a>
          </p>
          {/* </div> */}
        </Grid>
      </footer>
    );
  }
}

export default Footer;

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { advertiserUserActions } from 'redux/actions/advertiser-user-action';
import appBackgourndImg from "assets/img/logo_frontEnd.png";
import MessageCom from '../../layouts/Advertiser/Message';
import caretFooterLogo from '../../assets/img/logo_white.png'
import caretLogo from '../../assets/img/caretLogo.svg'
import Appstore from '../../assets/img/appleLogo.svg'
import Playstore from '../../assets/img/playstore.svg'
import videoPreview from '../../assets/img/videoPreview.png'
import { Link } from 'react-router-dom';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    var checkLogin = localStorage.getItem('user');
    if (checkLogin) {
      localStorage.removeItem('user');
      // this.props.dispatch(advertiserUserActions.logout());
    }

    this.state = {
      email: '',
      password: '',
      submitted: false,
      role: props.role,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
   
  }

  componentDidMount() {
    window.location.href = '/';
  }
  
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { email, password } = this.state;
    const { dispatch } = this.props;
    const { role } = this.props;
    // console.log("Role:", role);
    if (email && password) {
      dispatch(advertiserUserActions.login(email, password, role));
    }
  }
  render() {
    const { email, password, submitted } = this.state;
    const { loggingIn } = this.props;
    return (
      <Fragment>
        
        <div className='fixLoginWrap'>
          <div className="yellowBar" />
          <div className='py-50 px-1 bg-whiteF flexElem w-full alignCenter flexWrap'>
            <img src={caretLogo} alt={caretLogo} height={36} className='mx-auto mb-1 mb-md-0 ms-md-0' />
            <div className='ml-auto flexElem gap10p'>
              <a href="https://apps.apple.com/us/app/carets/id1571154682" target="_blank" className="flexElem gap10p text-white py-50 px-2 roundedFull hover:scale-105 transition ease transform scale-100" style={{ background: '#12064b' }}>
                <img src={Appstore} alt='Appstore' width={18} />
                <span className='flexElem flexColumn'>
                  <small className='text-10'>Download from</small>
                  <span className='text-16'>App Store</span>
                </span>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.carets.tv" target="_blank" className="flexElem gap10p text-white py-50 px-2 roundedFull hover:scale-105 transition ease transform scale-100" style={{ background: '#12064b' }}>
                <img src={Playstore} alt='Playstore' width={18} />
                <span className='flexElem flexColumn'>
                  <small className='text-10'>Download from</small>
                  <span className='text-16'>Google Play</span>
                </span>
              </a>
            </div>
          </div>
          <div className="main_login flexRowF">
            <MessageCom />
            <div className="login_form_container">
              <div className="login_form">
                <h4 className="text-center testrrr text-white" >Increase Sales with Carets Ads</h4>
                <h1 className="text-center testrrr text-white"  >Advertiser Login</h1>
                <form name="form" onSubmit={this.handleSubmit}>
                  <div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                    <input type="email" placeholder="Enter Your Email..." className="form-control email_ctrl" name="email" value={email} onChange={this.handleChange} />
                    {submitted && !email &&
                      <div className="help-block">Email is required.</div>
                    }
                  </div>
                  <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                    <input type="password" placeholder="Password" className="form-control password_ctrl" name="password" value={password} onChange={this.handleChange} />
                    {submitted && !password &&
                      <div className="help-block">Password is required.</div>
                    }
                  </div>
                  {/* <div className="form-group ">
                    <label className='text-info'>
                      <input type="checkbox" className='mb-0' /> By clicking you are agree terms & conditions
                    </label>
                  </div> */}
                  <div className="form-group">
                    <button className="btn btn_login">LOGIN</button>
                    {loggingIn &&
                      <img alt="imageLogin" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                  </div>
                  <div className="form-group">
                    <a href='/Advertiser-signup' className="btn btn_login">Create Account</a>
                  </div>
                  <div className='flexElem alignCenter text-white'>
                    <a href='/ForgotPassword' className='text-info'>Forgot Password?</a>
                    <span className='ml-auto'>Goto
                      <a href='https://carets.tv/' className='text-info ml-auto'> ^Carets</a>
                    </span>
                    {/* <span className='ml-auto'>Don't have account,
                      <a href='/Advertiser-signup' className='text-info'> Create Account </a>
                    </span> */}
                  </div>
                  {/* <div className='flexElem alignCenter text-white mt-1'>
                    <a href='https://carets.tv/legal' className='text-info'>Terms & Conditions</a>
                    <Link to='/terms' className='text-info'>Terms & Conditions</Link>
                    <span className='ml-auto'>Goto
                      <a href='https://carets.tv/' className='text-info ml-auto'> ^Carets</a>
                    </span>
                  </div> */}
                  {/* <div className='flexElem alignCenter text-white mt-1'>
                    <a href='/Admin-login' className='text-info ml-auto'>Admin Login</a>
                    <span className='ml-auto'>Don't have account,
                      <a href='/Advertiser-signup' className='text-info'> Sign Up </a>
                    </span>
                  </div> */}
                </form>
              </div>
            </div>
            <div className='text-white loginAdWrapper'>
              <img src={videoPreview} alt='videoPreview' className='w-100' />
            </div>
          </div>
          <div className='copyRights text-white flexElem alignCenter gap10p p-1'>
            <p className='font-weight-light small'>Copyright © 2023, Carets Corporation, All Rights Reserved.</p>
            <img src={caretFooterLogo} alt='footer logo' className='ml-auto' width={64} />
          </div>
        </div>
      </Fragment >
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.advertiserReducer;
  return {
    loggingIn
  };
}

const Login = connect(mapStateToProps)(LoginPage);
export default Login;

import { adminConstants } from '../constant/admin-constant';
import { alertActions } from './alert-actions';
import { history } from '../../helper/history';
import { userService } from '../../services/user';

export const userActions = {
    storeUser,
    getUser,
    editUser,
    getUsers,
    deleteUser,
    getCurrentUser,
    updatePermission,
    getUsersPagination,
    getOrderUser,
    updateOrderModerator,
    updateUserStatus,
};

function updatePermission(userId){

    return dispatch => {
        dispatch(request());
        userService.updatePermission(userId)
        .then(
            response => {
                dispatch(success(response));
                dispatch(alertActions.success(response.message));
                setTimeout(function(){
                    history.go(0);
                }, 500);
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    }

    function request() { return { type: adminConstants.CHANGE_PRIMARY_USER_REQUEST } }
    function success(response)   { return { type: adminConstants.CHANGE_PRIMARY_USER_SUCCESS, payload:response } }
    function failure(error) { return { type: adminConstants.CHANGE_PRIMARY_USER_FAILURE, payload:error } }

}
function getCurrentUser(){
    return dispatch => {
        dispatch(request());
        return new Promise((resolve, reject) => {
            userService.getCurrentUser()
            .then(
                user => {
                    if (user.token) {
                        localStorage.setItem('user', JSON.stringify(user));
                        dispatch(success(user));
                        resolve();
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            );

        });
    }

    function request() { return { type: adminConstants.CHECK_USER_PERMISSIONS_REQUEST } }
    function success(user)   { return { type: adminConstants.CHECK_USER_PERMISSIONS_SUCCESS, payload:user } }
    function failure(error) { return { type: adminConstants.CHECK_USER_PERMISSIONS_FAILURE, payload:error } }
}
function storeUser(UserData){
    return dispatch => {
        dispatch(request());
        userService.storeUser(UserData)
        .then(
            response => {
                // console.log('response 123', response);
                dispatch(success(response));
                dispatch(alertActions.success(response.message));
                // setTimeout(function(){
                //     history.go(0);
                // }, 1500);
            },
            error => {
                // console.log('error 123', error);
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    }

    function request() { return { type: adminConstants.STORE_USER_REQUEST } }
    function success(response)   { return { type: adminConstants.STORE_USER_SUCCESS, payload:response } }
    function failure(error) { return { type: adminConstants.STORE_USER_FAILURE, payload:error } }
}


function getUser(id){
    return dispatch => {
        dispatch(request());
        userService.getUser(id)
        .then(
            User => {
                dispatch(success(User));
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    }

    function request() { return { type: adminConstants.GET_USER_REQUEST } }
    function success(User)   { return { type: adminConstants.GET_USER_SUCCESS, payload:User } }
    function failure(error) { return { type: adminConstants.GET_USER_FAILURE, payload:error } }
}

function editUser(UserData, userId){

    return dispatch => {
        dispatch(request());
        userService.editUser(UserData, userId)
        .then(
            response => {
                dispatch(success(response));
                dispatch(alertActions.success(response.message));
                console.log('edit');
                // setTimeout(function(){
                //     history.go(0);
                // }, 1500);
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    }

    function request() { return { type: adminConstants.EDIT_USER_REQUEST } }
    function success(response)   { return { type: adminConstants.EDIT_USER_SUCCESS, payload:response } }
    function failure(error) { return { type: adminConstants.EDIT_USER_FAILURE, payload:error } }
}


function deleteUser(userId){
    return dispatch => {
        dispatch(request());
        return userService.deleteUser(userId)
        .then(
            response => {
                dispatch(success(response));
                dispatch(alertActions.success(response.message));
                // setTimeout(function(){
                //     history.go(0);
                // }, 1500);
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    }

    function request() { return { type: adminConstants.DELETE_USER_REQUEST } }
    function success(response)   { return { type: adminConstants.DELETE_USER_SUCCESS, payload:response } }
    function failure(error) { return { type: adminConstants.DELETE_USER_FAILURE, payload:error } }
}


function getUsers(companyId){
    return dispatch => {
        dispatch(request());

        userService.getUsers(companyId)
        .then(
            response => {
                dispatch(success(response));
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );

    }

    function request() { return { type: adminConstants.GET_USERS_REQUEST } }
    function success(response)   { return { type: adminConstants.GET_USERS_SUCCESS, payload:response } }
    function failure(error) { return { type: adminConstants.GET_USERS_FAILURE, payload:error } }
}

function getUsersPagination(page, filter, sort, pageSize,status){
    return dispatch => {
        // dispatch(request())
        return userService.getUsersPagination(page, filter, sort, pageSize,status)
        .then(
            response => {
                // dispatch(success(response));
                return response
            },
            error => {
                // dispatch(failure(error));
                // dispatch(alertActions.error(error));
                return Promise.reject(error);
            }
        );
    };

    function request() { return { type: adminConstants.PAGINATION_USERS_REQUEST } }
    function success(response)   { return { type: adminConstants.PAGINATION_USERS_SUCCESS, payload:response } }
    function failure(error) { return { type: adminConstants.PAGINATION_USERS_FAILURE, payload:error } }
}

function getOrderUser(){
    return dispatch => {
        dispatch(request());

        userService.getOrderUsers()
        .then(
            response => {
                dispatch(success(response));
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );

    }

    function request() { return { type: adminConstants.GET_USERS_ORDER_REQUEST } }
    function success(response)   { return { type: adminConstants.GET_USERS_ORDER_SUCCESS, payload:response } }
    function failure(error) { return { type: adminConstants.GET_USERS_ORDER_FAILURE, payload:error } }
}

function updateOrderModerator(data,orderId){
    return dispatch => {
        dispatch(request());

        userService.updateOrderModerator(data,orderId)
        .then(
            response => {
                dispatch(success(response));
                dispatch(alertActions.success(response.message));
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );

    }

    function request() { return { type: adminConstants.UPDATE_USERS_MODERATOR_REQUEST } }
    function success(response)   { return { type: adminConstants.UPDATE_USERS_MODERATOR_SUCCESS, payload:response } }
    function failure(error) { return { type: adminConstants.UPDATE_USERS_MODERATOR_FAILURE, payload:error } }
}

function updateUserStatus(userId, data){
    return dispatch => {
        dispatch(request());

        return userService.updateUserStatus(userId, data)
        .then(
            response => {
                dispatch(success(response));
                dispatch(alertActions.success(response.message));
                return response;
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );

    }

    function request() { return { type: adminConstants.UPDATE_USER_STATUS_REQUEST } }
    function success(response)   { return { type: adminConstants.UPDATE_USER_STATUS_SUCCESS, payload:response } }
    function failure(error) { return { type: adminConstants.UPDATE_USER_STATUS_FAILURE, payload:error } }
}

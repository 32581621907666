import Dashboard from "components/Advertiser/Dashboard";
import NewAd from "../components/Advertiser/Ads/NewAd";
import TargetAudience from "../components/Advertiser/Ads/TargetAudience";
import dashboardIcon from "assets/img/dashboard.svg";


import AdListing from "components/Advertiser/Ads/AdListing";
import CampaignListing from "components/Advertiser/Ads/CampaignListing";
import CardListing from "components/Advertiser/Ads/CardListing";
import PaymentListing from "components/Advertiser/Ads/PaymentListing";
import CampaignAdsListing from "components/Advertiser/Ads/CampaignAdsListing";
import EditProfile from "components/Advertiser/Ads/EditProfile";
import ChangePassword from "components/Advertiser/Ads/ChangePassword";
import CampaignForm1 from "components/Advertiser/Ads/CampaignForm1";
import CampaignForm2 from "components/Advertiser/Ads/CampaignForm2";
import CampaignForm4 from "components/Advertiser/Ads/CampaignForm4";
import CampaignForm5 from "components/Advertiser/Ads/CampaignForm5";


import Graph1 from "components/Advertiser/Ads/Graph1";
import Graph2 from "components/Advertiser/Ads/Graph2";
import Graph3 from "components/Advertiser/Ads/Graph3";




var advertiserRoutes = [

  {
    path: "/Advertiser/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard,
    iconImagePath: dashboardIcon,
    permission: "advertiser-dashboard"
  },
  {
    path: "/Advertiser/NewAd",
    name: "NewAd",
    icon: "pe-7s-graph",
    component: NewAd,
    iconImagePath: dashboardIcon,
    // permission: "advertiser-dashboard"
  },
  {
    path: "/Advertiser/TargetAudience",
    name: "TargetAudience",
    icon: "pe-7s-graph",
    component: TargetAudience,
    iconImagePath: dashboardIcon,
    // permission: "advertiser-dashboard"
  },
  {
    path: "/Advertiser/ads/:id?",
    name: "Ads",
    icon: "pe-7s-graph",
    iconImagePath: dashboardIcon,
    component: AdListing,
    //permission: "ads-list"
  },
  {
    path: "/Advertiser/campaigns/:id?",
    name: "Ads",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: CampaignListing,
    //permission: "campaigns-list"
  },
  {
    path: "/Advertiser/new-campaigns",
    name: "new-campaigns",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: CampaignForm1,
    //permission: "campaigns-list"
  },
  {
    path: "/Advertiser/new-campaigns-ads/:id?",
    name: "new-campaigns",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: CampaignForm2,
    //permission: "campaigns-list"
  },
  {
    path: "/Advertiser/new-card/:id?",
    name: "new-campaigns",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: CampaignForm4,
    //permission: "campaigns-list"
  },
  {
    path: "/Advertiser/active-campaigns/:id?",
    name: "new-campaigns",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: CampaignForm5,
    //permission: "campaigns-list"
  },
  {
    path: "/Advertiser/campaign-details/:id?",
    name: "Ads",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: CampaignAdsListing,
    //permission: "campaigns-ads-list"
  },
  {
    path: "/Advertiser/cards/:id?",
    name: "Ads",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: CardListing,
    //permission: "cards-list"
  },
  {
    path: "/Advertiser/payments/:id?",
    name: "Ads",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: PaymentListing,
    //permission: "payments-list"
  },
  {
    path: "/Advertiser/EditProfile",
    name: "EditProfile",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: EditProfile,
    //permission: "payments-list"
  },
  {
    path: "/Advertiser/changePassword",
    name: "ChangePassword",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: ChangePassword,
    //permission: "payments-list"
  },
  {
    path: "/Advertiser/Graph1",
    name: "Graph1",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: Graph1,
    //permission: "payments-list"
  },
  {
    path: "/Advertiser/Graph2",
    name: "Graph2",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: Graph2,
    //permission: "payments-list"
  },
  {
    path: "/Advertiser/Graph3",
    name: "Graph3",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: Graph3,
    //permission: "payments-list"
  },
 
  { redirect: true, path: "/Advertiser", to: "/Advertiser/dashboard", name: "Dashboard" },

];

export default advertiserRoutes;

import { SC } from '../helper/ServerCall';

export const userService = {
    storeUser,
    getUser,
    editUser,
    deleteUser,
    getCurrentUser,
    updatePermission,
    getUsersPagination,
    getOrderUsers,
    updateOrderModerator,
    updateUserStatus,
    updateBlocked,
}

function storeUser(userData) {
    return SC.postCall('users', userData);
}

function getUser(userId) {

    return SC.getCall('users/' + userId);
}

function editUser(userData, userId) {
    return SC.putCall('users/' + userId, userData);
}


function deleteUser(userId) {
    return SC.deleteCall('users/' + userId);
}

function getUsersPagination(page, filter, sort, pageSize,status) {
    return SC.getCall('users?page=' + page + '&filter=' + filter + '&sort=' + sort + '&pageSize=' + pageSize);
}

function getCurrentUser() {
    return SC.getCall('getUsersData');
}

function updatePermission(userId) {
    return SC.getCall('changePrimaryContact/' + userId);
}

function getOrderUsers() {
    return SC.getCall('getAllUsersDoNotHaveAgency');
}

function updateOrderModerator(data, orderId) {
    return SC.putCall('updateOrderDetailsUsers/' + orderId + '/update', data);
}

function updateUserStatus(userId, data) {
    return SC.putCall('users/updateStatus/'+userId, data);
}

function updateBlocked(userId, data) {
    return SC.putCall('users/blocked/'+userId, data);
}


import { frontEndUserConstants } from '../constant/front-end-constant';
import { history } from '../../helper/history';
import { frontEndUserService } from 'services/frontEndUser';
import { alertActions } from './alert-actions';
import User from '../../helper/User';
export const frontEndUserActions = {
    login,
    logout,
    getAdudicators,
    getAdudicatorsRecordings,
    forgetUsername
}

function login(email, password) {
    return dispatch => {
        dispatch(request({ email }));
        frontEndUserService.login(email, password)
            .then(
                frontEndUser => {
                    const host = window.location.hostname.replace(/www./).split('.');
                    let match, result;
                    match = document.domain.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im);
                    if (match) {
                        result = match[1]
                        match = result.match(/^[^\.]+\.(.+\..+)$/);
                        if ( match ) {
                            result = match[1]
                        }
                    }
                    if (frontEndUser.user.brand.domain_slug !== 'gtv' && (host[0] !== frontEndUser.user.brand.domain_slug)) {
                        window.location = `http://${frontEndUser.user.brand.domain_slug}.${result}/login/${email}/${password}`;
                    } else {
                        if (process.env.NODE_ENV === 'development') {
                            window.location = `/dashboard`;
                        } else {
                            window.location = `http://${frontEndUser.user.brand.domain_slug}.${result}/dashboard`;
                        }
                        localStorage.setItem('user', JSON.stringify(frontEndUser));
                        dispatch(success(frontEndUser));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(username) { return { type: frontEndUserConstants.LOGIN_FRONT_END_USER_REQUEST, payload: username } }
    function success(frontEndUser) { return { type: frontEndUserConstants.LOGIN_FRONT_END_USER_SUCCESS, payload: frontEndUser } }
    function failure(error) { return { type: frontEndUserConstants.LOGIN_FRONT_END_USER_FAILURE, payload: error } }
}


function forgetUsername(email) {
    return dispatch => {
        dispatch(request({ email }));
        frontEndUserService.forgetUsername(email)
            .then(
                response => {
                    // console.log('frontEndUser data', frontEndUser);
                    dispatch(success(response.message));
                    dispatch(alertActions.success(response.message));
                    //history.push('/Dashboard');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(email) { return { type: frontEndUserConstants.FORGET_FRONT_END_USER_REQUEST, payload: email } }
    function success(response) { return { type: frontEndUserConstants.FORGET_FRONT_END_USER_SUCCESS, payload: response } }
    function failure(error) { return { type: frontEndUserConstants.FORGET_FRONT_END_USER_FAILURE, payload: error } }
}

function logout() {
    frontEndUserService.logout();
    User.logout();
    window.location = '/';
    return { type: frontEndUserConstants.LOGOUT_FRONT_END_USER };
}


function getAdudicators(frontEndUserData) {
    return dispatch => {
        dispatch(request());
        frontEndUserService.getAdudicators(frontEndUserData)
            .then(
                response => {
                    dispatch(success(response));
                    history.push('/Dashboard');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: frontEndUserConstants.GET_ADJUDICATORS_REQUEST } }
    function success(response) { return { type: frontEndUserConstants.GET_ADJUDICATORS_SUCCESS, payload: response } }
    function failure(error) { return { type: frontEndUserConstants.GET_ADJUDICATORS_FAILURE, payload: error } }
}

function getAdudicatorsRecordings(adjudicatorObject, page) {
    return dispatch => {
        dispatch(request());
        frontEndUserService.getAdudicatorsRecordings(adjudicatorObject, page)
            .then(
                response => {
                    dispatch(success(response));
                    history.push('/Dashboard');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: frontEndUserConstants.GET_ADJUDICATORS_RECORDINGS_REQUEST } }
    function success(response) { return { type: frontEndUserConstants.GET_ADJUDICATORS_RECORDINGS_SUCCESS, payload: response } }
    function failure(error) { return { type: frontEndUserConstants.GET_ADJUDICATORS_RECORDINGS_FAILURE, payload: error } }
}